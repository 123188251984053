import { AxiosPromise, AxiosResponse } from 'axios';

import { PromisesQueue } from '@loopia-group/utils';
import {
  StandaloneProduct,
  CartItem,
  SetPaymentOptionRequest,
  StoreDomainProfileRequest,
  UpdateDomainProfileRequestExtended,
  StoreBillingProfileRequest,
  UpdateBillingProfileRequestExtended,
  FromBillingProfileDomainProfileRequest,
  ProductBundle,
} from '@/types';
import store from '@/store';
import { XsellItem } from '@/modules/checkout/types';
import network from '@/services/network.service';
import { logError } from '@/services/logger.service';
import {
  trackAddToCart,
  trackRemoveFromCart,
} from '@/services/analytics.service';
import {StateMutations} from '@/store/const.enum';
import {useCartItemsStore} from '@/store/cartItemsStore';

const BILLING_PROFILES = '/billing-profiles';
const DOMAIN_PROFILES = '/domain-profiles';

/*
  CART CHANGE PROMISES CONTROLLING =====================
 */

const queue = new PromisesQueue({
  onComplete: fetchCart,
});

// setTimeout needed because store is undefined at the time of this service initialization
setTimeout(() => {
  store.watch(
    state => state.cart?.uuid,
    (uuid: string) => {
      queue.executeAllImmediately = !!uuid;
    }
  );
}, 0);

/*
  ======================================================
 */

export function fetchCart(): Promise<AxiosResponse> {
  return network.get('/cart').then(updateCartState);
}

export function reloadCfgAndValidate(): Promise<AxiosResponse> {
  return reloadCartCfg().then(validateCart);
}

export function reloadCartCfg(): Promise<AxiosResponse> {
  return network
    .get('/cart/reload-configuration?cart=true')
    .then(updateCartState);
}

export const validateCart = (): Promise<AxiosResponse> =>
  network.get('/cart/items/validate');

export function addBundleToCart(bundle: ProductBundle): Promise<AxiosResponse> {
  return queue.enqueue(() =>
    network
      .post('/cart/bundle', bundle)
      .then(updateCartState)
      .then(async (response: AxiosResponse) => {
        await trackAddToCart(response.data.items.slice(-1));
        return response;
      })
  );
}

export function addToCart(product: StandaloneProduct): Promise<AxiosResponse> {
  return queue
    .enqueue(() => network.post('/cart/items', product).then(updateCartState))
    .then(async (response: AxiosResponse) => {
      await trackAddToCart(response.data.items.slice(-1));
      return response;
    });
}

export function updateNote(note: string): Promise<AxiosResponse> {
  return network.put('/cart/note?cart=true', { note }).then(updateCartState);
}

export function deleteNote(): Promise<AxiosResponse> {
  return network.delete('/cart/note?cart=true').then(updateCartState);
}

export function setPaymentOption(
  option: SetPaymentOptionRequest
): Promise<AxiosResponse> {
  return network
    .put('/cart/payment-option?cart=true', option)
    .then(updateCartState);
}

export function removeFromCart(item: CartItem): Promise<AxiosResponse> {
  return queue
    .enqueue(() => network.delete('/cart/items/' + item.id))
    .then(async (response: AxiosResponse) => {
      trackRemoveFromCart(item);
      return response;
    });
}
export function updateItem(item: CartItem): Promise<AxiosResponse> {
  return queue.enqueue(() => network.patch('/cart/items/' + item.id, item));
}

export function upgradeItem(
  cartItemId: string | number /* ID */,
  item: StandaloneProduct
): Promise<AxiosResponse> {
  return queue
    .enqueue(() =>
      network
        .post(`/cart/items/${cartItemId}/upgrade?cart=true`, item)
        .then(updateCartState)
    )
    .then(async (response: any) => {
      const newItemId = response?.data?.newItems?.[0];
      const newItem = store.getters.cartItemById(newItemId);

      if (newItem) {
        trackAddToCart(newItem);
      } else {
        logError('upgraded cart item not found!', {
          cartItemId,
          newItemId,
          newItem,
        });
      }
      return response;
    });
}

export function setBillingProfile(data: any): Promise<AxiosResponse> {
  return network
    .put('/cart/billing-profile?cart=true', data)
    .then(updateCartState);
}

export function setDomainProfile(data: any): Promise<AxiosResponse> {
  return network
    .put('/cart/domain-profile?cart=true', data)
    .then(updateCartState);
}

export function updateCartState(
  response: AxiosResponse
): Promise<AxiosResponse> {
  const value = response?.data;
  if (value) {
    store.commit(StateMutations.SET_STATE, { prop: 'cart', value });
    const itemsStore = useCartItemsStore();
    if (value.items) {
      itemsStore.updateItems(value.items);
    }
  } else {
    logError('cart value missing');
  }

  return Promise.resolve(response);
}

export function getBillingProfiles(): Promise<AxiosResponse> {
  return network.get(BILLING_PROFILES);
}

export function createBillingProfile(
  profile: StoreBillingProfileRequest
): Promise<AxiosResponse> {
  profile.companyId = profile.companyId?.toString();
  return network.post(BILLING_PROFILES, profile);
}

export function updateBillingProfile(
  profile: UpdateBillingProfileRequestExtended
): Promise<AxiosResponse> {
  profile.companyId = profile.companyId?.toString();
  return network.put(BILLING_PROFILES + '/' + profile.id, profile);
}

export function getDomainProfiles(): Promise<AxiosResponse> {
  return network.get(DOMAIN_PROFILES);
}

export function createDomainProfile(
  profile: StoreDomainProfileRequest
): Promise<AxiosResponse> {
  // BE only accepts strings
  profile.companyId = profile.companyId?.toString();
  return network.post(DOMAIN_PROFILES, profile);
}

export function createDomainProfileFromBilling(
  requestData: FromBillingProfileDomainProfileRequest
): Promise<AxiosResponse> {
  return network.post(DOMAIN_PROFILES + '/from-billing-profile', requestData);
}

export function updateDomainProfile(
  profile: UpdateDomainProfileRequestExtended
): Promise<AxiosResponse> {
  return network.put(DOMAIN_PROFILES + '/' + profile.id, profile);
}

export function setAssistance(
  assistance: boolean | null
): Promise<AxiosResponse> {
  return network
    .put('/promotion-for-migration/need-assistance?cart=true', {
      assistance,
    })
    .then(updateCartState);
}

export function removeFile(fileName: string): Promise<AxiosResponse> {
  return network.delete(`/promotion-for-migration/${fileName}`);
}

export function getPaymentOptions(): Promise<AxiosResponse> {
  return network.get('/payment-options');
}

export function getRequiredDpFields(
  tlds: string[],
  countryCode?: string
): AxiosPromise<AxiosResponse> {
  return network.get(DOMAIN_PROFILES + '/required-fields', {
    params: { tlds, countryCode },
  });
}

export function applyCoupon(code: string): Promise<AxiosResponse> {
  return network.put('/cart/coupon?cart=true', { code }).then(updateCartState);
}

export function removeCoupon(code: string): Promise<AxiosResponse> {
  return network
    .delete('/cart/coupon/' + code + '?cart=true')
    .then(updateCartState);
}

export function checkoutOrder(data: any): Promise<AxiosResponse> {
  return network.post('/checkout', data);
}

export interface DonationOption {
  roundUpVersion: string;
  donation: number;
  totalPriceVatIncluded: number;
}
export interface OrderDonation {
  current: {
    roundUpVersion: string | null;
    customDonation: number;
    donationVatExcluded: number;
    donationVatIncluded: number;
  };
  options: DonationOption[];
}
export interface DonationRequestData {
  roundUpVersion?: string;
  customDonation?: number;
}

export function addDonation(
  donation: DonationRequestData
): Promise<AxiosResponse> {
  return network
    .put('/cart/donation?cart=true', donation)
    .then(updateCartState);
}
export function removeDonation(): Promise<AxiosResponse> {
  return network.delete('/cart/donation?cart=true').then(updateCartState);
}

export function getPropertyDetails(
  code: string,
  property: string,
  cartItemId: number
): Promise<AxiosResponse> {
  return network.get('/cart/items/properties', {
    params: { code, property, cartItemId },
  });
}

export function getXsellItems(): Promise<AxiosResponse> {
  return network.get('/cart/x-sell-up-sell').then(response => {
    const data: {
      xSellProducts: XsellItem[];
      upSellProducts: XsellItem[];
    } = response.data;

    if (data) {
      data.xSellProducts.forEach(product => {
        product.sellType = 'x-sell';
      });
      data.upSellProducts.forEach(product => {
        product.sellType = 'up-sell';
      });
    }

    return response;
  });
}
