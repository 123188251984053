var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-form',{ref:"form",staticClass:"cmp-domain-profile-additional-fields q-pt-sm",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[(_vm.showPhoneInput)?_c('ws-phone-input',{attrs:{"name":"additional-phone","value":_vm.value.phone,"placeholder":_vm.$t('user.phone.label'),"path":_vm.path + '.phone',"autocomplete":"tel-local","rules":[_vm.allOptional ? _vm.always : _vm.required],"required":!_vm.allOptional},on:{"input":function($event){return _vm.reEmit('phone', $event)}}}):_vm._e(),(!_vm.hideFields.email)?_c('ws-input',{staticClass:"ws-field-row",attrs:{"name":"additional-email","value":_vm.value.email,"label":_vm.$t('user.email.label'),"path":_vm.path + '.email',"autocomplete":"email","rules":[_vm.allOptional ? _vm.always : _vm.required, ..._vm.propStaticRules['email']],"required":!_vm.allOptional},on:{"input":function($event){return _vm.reEmit('email', $event)}}}):_vm._e(),(
      !_vm.hideFields.passport &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'passport')
    )?_c('ws-input',{ref:"passport",attrs:{"name":"additional-passport","value":_vm.value.passport,"label":_vm.$t('user.passport.label'),"path":_vm.path + '.passport',"autocomplete":"passport","rules":[
      _vm.allOptional ? _vm.always : _vm.requiredByConfig('passport'),
      ..._vm.propStaticRules['passport'],
    ],"restrict":_vm.propRestriction['passport']},on:{"input":function($event){return _vm.reEmit('passport', $event)}}}):_vm._e(),(_vm.showIdCardInput)?_c('ws-input',{ref:"idCard",attrs:{"name":"additional-id-card","value":_vm.value.idCard,"label":_vm.$t('user.id_card.label'),"path":_vm.path + '.idCard',"autocomplete":"idCard","rules":[
      _vm.allOptional
        ? _vm.always
        : _vm.isSwedishWorkflow && _vm.type === 'personal'
          ? _vm.required
          : _vm.requiredByConfig('idCard'),
      ..._vm.propStaticRules['idCard'],
    ],"restrict":_vm.propRestriction['idCard'],"disable":!!_vm.disable.idCard},on:{"input":function($event){return _vm.reEmit('idCard', $event)}}}):_vm._e(),(
      !_vm.hideFields.companyId &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'companyId')
    )?_c('ws-input',{ref:"companyId",attrs:{"name":"additional-company-id","value":_vm.value.companyId,"label":_vm.$t('wsk.address.company_id.label'),"path":_vm.path + '.companyId',"autocomplete":"organisation-id","rules":[
      _vm.allOptional ? _vm.always : _vm.required,
      ..._vm.propStaticRules['companyId'],
      _vm.requiredCountry,
      _vm.validCompanyID,
    ],"lazy-rules":"","required":!_vm.allOptional,"debounce":"300"},on:{"input":function($event){return _vm.reEmit('companyId', $event)}}}):_vm._e(),(
      !_vm.hideFields.taxId &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'taxId')
    )?_c('ws-input',{ref:"taxId",attrs:{"name":"additional-tax-id","value":_vm.value.taxId,"label":_vm.$t('wsk.address.tax_id.label'),"path":_vm.path + '.taxId',"autocomplete":"organisation-id","rules":[
      _vm.allOptional ? _vm.always : _vm.required,
      ..._vm.propStaticRules['taxId'],
      _vm.requiredCountry,
      _vm.validTaxID,
    ],"lazy-rules":"","required":!_vm.allOptional,"debounce":"300"},on:{"input":function($event){return _vm.reEmit('taxId', $event)}}}):_vm._e(),(
      !_vm.hideFields.vatId &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'vatId')
    )?_c('ws-input',{ref:"vatId",attrs:{"name":"additional-vat-id","value":_vm.value.vatId,"label":_vm.$t(
        /*
          NOTICE!
          Relabeled for CZ based adresses, so it comes to correct validator
          Cannot be done just by translation because it is not dependent on market but on
          actual country of profile address
        */
        _vm.countryCodeComputed === 'CZ'
          ? 'wsk.address.tax_id.label'
          : 'wsk.address.vat_id.label'
      ),"path":_vm.path + '.vatId',"autocomplete":"vat-id","rules":[
      _vm.allOptional ? _vm.always : _vm.required,
      ..._vm.propStaticRules['taxId'],
      _vm.requiredCountry,
      _vm.validVatID,
    ],"lazy-rules":"","required":!_vm.allOptional,"debounce":"300"},on:{"input":function($event){return _vm.reEmit('vatId', $event)}}}):_vm._e(),(
      !_vm.hideFields.noridPid &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'noridPid')
    )?_c('ws-input',{ref:"noridPid",attrs:{"name":"additional-norid-pid","value":_vm.value.noridPid,"label":_vm.$t('user.norid_pid.label'),"path":_vm.path + '.noridPid',"autocomplete":"noridPid","rules":[_vm.allOptional ? _vm.always : _vm.requiredByConfig('noridPid')],"lazy-rules":""},on:{"input":function($event){return _vm.reEmit('noridPid', $event)}}}):_vm._e(),(
      !_vm.hideFields.birthday &&
        _vm.isInRequiredFields(_vm.type, _vm.countryCodeComputed, 'birthday')
    )?_c('ws-input',{ref:"birthday",attrs:{"name":"additional-birthday","default-view":"Years","value":_vm.birthDate,"label":_vm.$t('user.birthday.label'),"path":_vm.path + '.birthday',"rules":[_vm.allOptional ? _vm.always : _vm.requiredByConfig('birthday')],"lazy-rules":""},on:{"click":_vm.birthdayClicked},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ws-icon',{staticClass:"cursor-pointer",attrs:{"wrap":"","name":"date"}},[_c('q-popup-proxy',{ref:"birthdayPopup"},[_c('q-date',{attrs:{"value":_vm.value.birthday || '1995/01/01',"locale":_vm.dateInputLabels,"default-view":"Years"},on:{"input":function($event){return _vm.birthdayChanged($event)}}})],1)],1)]},proxy:true}],null,false,1591074423)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }