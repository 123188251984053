
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {QInput} from 'quasar';

import {WsFieldMixin} from './WsFieldMixin.vue';
import WsOptional from './WsOptional.vue';
import {Theme} from '@loopia-group/services';

// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
@Component({
  components: {
    QInput,
    WsOptional,
  },
})
export class WsInput extends mixins(WsFieldMixin) {
  @Prop(Boolean) readonly dark!: boolean;
  @Prop(Boolean) readonly huge!: boolean;
  @Prop(Boolean) readonly responsive!: boolean; // changing haight according to window width
  @Prop(Boolean) readonly optionalText!: boolean;
  @Prop({type: String}) readonly afterText!: string;
  @Prop({default: false}) declare readonly messagesOverflow: boolean;

  Theme = Theme;
}

export default WsInput;
