
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';

import {isInteger} from 'lodash-es';
import {WsRadio} from './WsRadio.vue';

@Component({
  components: {
    WsRadio,
  },
})
export class WsRadioList extends Vue {
  @Prop() readonly value!: any;
  @Prop({default: () => []}) readonly items!: any[];
  @Prop({default: 'index'}) readonly mode!: string; // 'index' | 'item'
  @Prop({
    type: Boolean,
    default: true,
  })
  readonly show!: boolean;
  @Prop(Boolean) readonly preselect!: boolean;
  @Prop(Boolean) readonly invisibleWrap!: boolean;
  @Prop(Boolean) readonly noSpacingX!: boolean;
  @Prop(Boolean) readonly noSpacingY!: boolean;
  @Prop(Boolean) readonly small!: boolean;
  @Prop(Boolean) readonly withoutDelimiter!: boolean;
  @Prop({default: 'id'}) readonly uidProp!: string;
  @Prop(Boolean) readonly emitValue!: boolean;
  @Prop(Boolean) readonly showSelectedOnly!: boolean;
  @Prop(Boolean) readonly selectedBolded!: boolean;
  selectedIndex: number | null = null;

  created() {
    if (this.preselect) {
      const validOptions = this.items.filter(item => !item?.disable);
      if (validOptions.length > 0) {
        this.selectedIndex = this.items.indexOf(validOptions[0]);
      }
    }
  }

  @Watch('items')
  onItemsChange() {
    this.onValueChange(this.value);
  }

  @Watch('value', {immediate: true})
  onValueChange(newVal: any) {
    if (newVal === null || newVal === undefined || !this.items.length) {
      this.selectedIndex = null;
    } else if (isInteger(newVal)) {
      this.selectedIndex = newVal;
    } else {
      this.selectIndex(newVal);
    }
  }

  selectIndex(newVal: any) {
    let index = this.items.indexOf(newVal);
    if (index === -1) {
      if (typeof newVal === 'object') {
        index = this.items.findIndex(
          item => item[this.uidProp] === newVal[this.uidProp]
        );
      } else if (typeof newVal === 'string') {
        index = this.items.findIndex(item => item[this.uidProp] === newVal);
      }
    }

    if (index > -1) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = null;
    }
  }

  @Watch('selectedIndex')
  // eslint-disable-next-line sonarjs/cognitive-complexity
  onSelectedIndexChange() {
    const selectedIndex = this.selectedIndex;

    let item: any;
    if (selectedIndex !== null && selectedIndex < this.items.length) {
      if (this.mode === 'index') {
        this.$emit(
          'input',
          this.emitValue ? this.items[selectedIndex] : selectedIndex
        );
      } else {
        item = this.items[selectedIndex];
        this.$emit('input', this.emitValue ? item[this.uidProp] : item);
      }
    } else {
      this.selectedIndex = null;
    }
  }
}

export default WsRadioList;
