
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { QCard, QCardSection } from 'quasar';
import WsButton from '@WS_Components/WsButton.vue';
import HostingTutorialProduct from '@/components/hostingTutorial/HostingTutorialProduct.vue';
import HostingTutorialPrompt from '@/components/hostingTutorial/HostingTutorialPrompt.vue';
import { HostingTutorialStep } from '@/types';
import { HOSTING_TUTORIAL_STEPS } from '@/services/const';
import { trans } from '../../WS_UIkit/src/services/i18n/i18n.service';

@Component({
  components: {
    QCard,
    QCardSection,
    HostingTutorialProduct,
    HostingTutorialPrompt,
    WsButton,
  },
})
export default class HostingTutorialContent extends Vue {
  @Prop() readonly step?: HostingTutorialStep;
  @Prop() readonly products?: { [key: string]: any } | null;
  @Prop({ default: false }) readonly isList?: boolean;

  activeProduct: number | string | null = null;
  activeCard: number | string | null = null;
  showPrompt: boolean = false;
  trans = trans;

  @Watch('step')
  onPropertyChanged() {
    this.setActiveProduct(null);
    this.setActiveCard(null);
    this.showPrompt = false;
  }

  get hasShowAllLink(): boolean {
    return this.step?.showAllLink ? this.step.showAllLink : false;
  }

  isActiveProduct(index: number | string | null) {
    return this.activeProduct === index;
  }

  isActiveCard(index: number | string | null) {
    return this.activeCard === index;
  }

  navigate(to: string, index: number | null = null): void {
    this.activeCard = index;

    if (to === HOSTING_TUTORIAL_STEPS.SKIP) {
      this.showPrompt = true;
      return;
    }
    this.$emit('navigate', to);
  }

  setActiveProduct(index: number | string | null): void {
    this.activeProduct = index;
  }

  setActiveCard(index: number | string | null): void {
    this.activeCard = index;
  }
}
