
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';
import {QField} from 'quasar';

import {Message, MessagePath} from '../services/message.service.d';
import {Throttle} from '@loopia-group/utils';
import {isEqual} from 'lodash-es';

@Component({
  inheritAttrs: false,
})
export class WsFieldMixin extends Vue {
  @Prop() readonly value!: string | boolean | number | any[];
  @Prop() readonly default!: string | boolean | number;
  // restrict is like native pattern attribute but it blocks unallowed characters instatly
  @Prop() readonly restrict!: string;
  @Prop() readonly path!: MessagePath;
  // required attribute for semantic reasons
  // ref: https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/required
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly recursiveMessages!: boolean;
  @Prop({type: [Boolean, String], default: true}) readonly lazyRules!:
    | boolean
    | 'ondemand';
  restrictionRegexp: RegExp | null = null;
  destroyMessages: () => void = () => {
  };
  private tmpValue: string | boolean | number | any[] | undefined = undefined;

  created() {
    if (!this.value && this.default) {
      this.$emit('input', this.default);
    }
  }

  destroyed() {
    if (this.path) {
      this.$root.$messageService.unsubscribe(this.path, {
        recursive: this.recursiveMessages,
      });
    }
  }

  get messages(): Message[] {
    return this.$root.$messageService.getters.messagesByPath(this.path, {
      recursive: this.recursiveMessages,
    });
  }

  public get error() {
    return (this.$refs.qfield as QField)?.error;
  }

  public get errorMessage() {
    return (this.$refs.qfield as QField)?.errorMessage;
  }


  // !!!! HOTFIX - this breaks all input messages, but is only used in WsInputAdditional
  // allow parents to know when WsField is in error state
  // public get hasError(): boolean {
  //   const qfield = this.$refs.qfield as QField;
  //   return !!(this.messages?.length || (qfield as any)?.hasError);
  // }
  //
  // @Watch('hasError')
  // onHasErrorChanged(hasError: boolean) {
  //   this.$emit('has-error', hasError);
  // }

  @Watch('path', {immediate: true})
  onPathChanges(path: MessagePath, oldPath: MessagePath) {
    // equivalency check for filtering true changes
    if (isEqual(path, oldPath)) {
      return;
    }
    if (oldPath) {
      this.clearMessages(oldPath);
      this.$root.$messageService.unsubscribe(oldPath, {
        recursive: this.recursiveMessages,
      });
    }
    if (path) {
      this.$root.$messageService.subscribe(path, {
        recursive: this.recursiveMessages,
      });
    }
  }

  @Watch('restrict', {immediate: true})
  public setRestriction(restrict: string) {
    this.restrictionRegexp = restrict
      ? new RegExp(`[^${restrict}]*`, 'g')
      : null;
  }

  mounted() {
    this.onLazyRulesChanged();
  }

  @Watch('lazyRules')
  onLazyRulesChanged() {
    const qfield = this.$refs.qfield as QField;
    if (qfield) {
      if (this.lazyRules === true) {
        qfield.$on('blur', this.onBlur);
      } else {
        qfield.$off('blur', this.onBlur);
      }
    }
  }

  @Watch('value', {immediate: true})
  onValueChangesImmediate(value: string | boolean | number | undefined) {
    // apply restricted characters cleanup
    if (typeof value === 'string' && this.restrictionRegexp) {
      const replacedValue = value.replace(this.restrictionRegexp, '');
      // don't emit if value is unchanged after restriction applied
      if (replacedValue !== value) {
        this.$emit('input', replacedValue);
      }
    }
  }

  @Watch('value')
  onValueChanges(
    value: string | boolean | number | undefined,
  ) {
    // reset validation messages on value changes
    if (this.messages?.length) {
      this.clearMessages();
    }

    if (value !== undefined && (this.$refs.qfield as QField)?.resetValidation) {
      (this.$refs.qfield as QField).resetValidation();
    }

    if (this.$parent) {
      this.$parent.$emit('dirty');
    }
  }

  @Watch('required')
  onRequiredChange() {
    // setTimeouted because there was problem there were racing condition
    // between validators state and validation execution, this solve that problem
    // as it waits for validator to react on requirements change and then this
    // watcher runs validation
    setTimeout(() => {
      this.validate();
    }, 0);
  }

  onBlur() {
    if (this.tmpValue !== this.value) {
      // only if value changed from previous run
      this.validate();
    }
    this.tmpValue = this.value;
  }

  removeMessage(index: number) {
    this.$root.$messageService.removeMessage({
      path: this.path,
      index,
    });
  }

  public async validate(val?: any) {
    const result = await (this.$refs.qfield as QField)?.validate?.(val);
    // undefined means there are no validable fields, so we count that as valid state
    return result === undefined || result === true;
  }

  @Throttle(500, {trailing: true})
  public clearMessages(path?: MessagePath) {
    if (path || this.path) {
      this.$root.$messageService.clearMessages(this.path);
    }
  }

  public focus() {
    (this.$refs.qfield as any)?.$el?.focus?.();
  }
}

export default WsFieldMixin;
