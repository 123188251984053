
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {QBanner} from 'quasar';

import {UI_VARIANT} from './const.enum';
import {
  Message,
  MessagePath,
  MessagesProviderOptions,
} from '../services/message.service.d';
import {isArray} from 'lodash-es';
import WsIcon from './WsIcon.vue';
import WsButton from './WsButton.vue';
import WsStateBanner from './WsStateBanner.vue';

@Component({
  components: {
    QBanner,
    WsButton,
    WsIcon,
    WsStateBanner,
  },
})
export class WsMessage extends Vue {
  @Prop({default: undefined}) readonly path!: MessagePath | undefined;
  @Prop({default: null}) readonly messages!:
    | Message[]
    | string[]
    | string
    | null;
  @Prop({default: ''}) readonly prepend!: string; // prepend message text
  @Prop({default: ''}) readonly description!: string;
  @Prop(Boolean) readonly recursive!: boolean;
  @Prop(Boolean) readonly fallback!: boolean;

  // features
  @Prop({type: String, default: UI_VARIANT.DEFAULT})
  readonly type!: UI_VARIANT;
  @Prop([Boolean, String]) readonly icon!: boolean | string;
  @Prop({default: () => ({})}) readonly iconAttrs!: Object; // WsIcon attributes
  @Prop() readonly button!: {
    translationKey: string;
    values?: any;
    action: Function | string;
  }; // function -> callback, string -> url
  @Prop({default: () => ({})}) readonly buttonAttrs!: Object; // WsButton attributes, (i) can be also "class"!
  // @Prop(Boolean) readonly canClose!: boolean;

  // versions
  @Prop(Boolean) readonly inline!: boolean; // inline
  @Prop(Boolean) readonly banner!: boolean; // ws-state-banner

  get messagesProviderOptions(): MessagesProviderOptions {
    return {
      recursive: this.recursive,
      fallback: this.fallback,
    };
  }

  get msgService() {
    return this.$root.$messageService;
  }

  get msgs(): Message[] {
    const messages =
      typeof this.messages === 'string' && this.messages
        ? [{translationKey: this.messages}]
        : this.messages;
    const noMessagesCase =
      this.$slots.content || this.$slots.title ? ([{}] as any) : [];
    return isArray(messages)
      ? (messages as (Message | string)[]).map(msg =>
        typeof msg === 'string' ? {translationKey: msg} : msg
      )
      : (this.path &&
        this.$root.$messageService.getters.messagesByPath(
          this.path,
          this.messagesProviderOptions
        )) ||
      noMessagesCase;
  }

  get hasMessages() {
    return !!this.msgs?.length;
  }

  get texts() {
    return this.msgs.map(
      (msg: any) =>
        msg.text || msg.translation || this.$t(msg.translationKey, msg.values)
    );
  }

  get descriptions() {
    return this.msgs.map((msg: any) =>
      this.$te(msg.translationKey + '_description')
        ? this.$t(msg.translationKey + '_description')
        : (this.description && this.$t(this.description)) || ''
    );
  }

  get iconAttrsResolved() {
    return {size: 22, color: this.featuresColor, ...this.iconAttrs};
  }

  get buttonAttrsResolved() {
    return {
      flat: true,
      small: true,
      noWrap: true,
      icon: 'external_link',
      color: this.featuresColor,
      ...this.buttonAttrs,
    };
  }

  get iconResolved(): string | undefined {
    const icon = this.icon;
    if (!icon) {
      return undefined;
    } else if (typeof icon === 'string') {
      return icon;
    }
    return {
      [UI_VARIANT.DEFAULT]: 'info',
      [UI_VARIANT.SUCCESS]: 'success',
      [UI_VARIANT.ALERT]: 'error',
      [UI_VARIANT.NEUTRAL]: 'question',
    }[this.type];
  }

  get featuresColor(): string | undefined {
    return {
      [UI_VARIANT.DEFAULT]: undefined,
      [UI_VARIANT.SUCCESS]: 'ws-success',
      [UI_VARIANT.ALERT]: 'ws-error',
      [UI_VARIANT.NEUTRAL]: 'ws-info',
    }[this.type];
  }

  @Watch('path', {immediate: true})
  onPathChanged(path: MessagePath, oldPath: MessagePath) {
    // skip empty strings or undefined
    if (path) {
      this.$root.$messageService.subscribe(path, this.messagesProviderOptions);
    }

    if (oldPath) {
      this.unsubscribe(oldPath);
    }
  }

  unsubscribe(path: MessagePath) {
    this.$root.$messageService.unsubscribe(path, this.messagesProviderOptions);
  }

  remove(index: number, msg: Message) {
    if (this.path) {
      this.$root.$messageService.removeMessage({
        path: this.path,
        msg,
      });
    }
    this.$emit('remove', index, msg);
  }

  destroyed() {
    if (this.path) {
      this.unsubscribe(this.path);
    }
  }
}

export default WsMessage;
