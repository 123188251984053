import { cloneDeep } from 'lodash-es';
import env from '@env';
import { STEPS } from '@/services/const.enum';
import { AppState, DomainCheckerState } from './const';
import { cartServiceInitialState } from '@/services/cart/cart.store';
import {SidePanels} from '@/store/const.enum';

export const initialDomainCheckerState: DomainCheckerState = {
  requestedDomains: [],
  domains: [], // all domains
  bundles: [],
  previouslySearchedDomain: '',

  searching: false,
  endOfStream: false,

  cartItemsLoading: [],
};

export const hostingTutorial = {
  products: [],
  rememberedConfigurations: {},
};


const initialState: AppState = {
  cart: {
    initial: true,
    items: [],
    uuid: '',
    migrationPromotion: {
      applicable: true,
      needAssistance: null,
    },
  },
  hostingTutorial: hostingTutorial,
  cartService: cartServiceInitialState,
  ui: {
    sidePanel: SidePanels.Hidden,
  },
  mandatorySettingsHasIssue: false, // TODO: temporary solution
  userStep: STEPS.CART,
  inEditMode: null,
  useDifferentBillingProfile: false,
  checkoutInProgress: false,
  quickOrderInProgress: false,
  devUtils: !!env.development,
  domainChecker: initialDomainCheckerState,
  domainCheckerCategories: [],
  userMetaData: {
    animationsReduced: window.matchMedia
      ? window.matchMedia('(prefers-reduced-motion: reduce)').matches
      : false,
  },
  userDomains: []
};

export default function getInitialState() {
  return cloneDeep(initialState);
}
