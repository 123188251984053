
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Subscription } from 'rxjs';

import { get } from 'lodash-es';
import { isBrowser, redirect } from '@loopia-group/utils';
import { i18nService, getLang } from '@WS_UIkit';
import { logError } from '@/services/logger.service';
import config from '@/services/config.service';

const PATH_LANG_REGEXP = /^\/([a-zA-Z]{2})\//;
const langs = Object.entries(config.languages || {});
langs.forEach(entry => {
  entry[0] = entry[0].slice(0, 2);
});

@Component({})
export default class LangSelect extends Vue {
  @Prop()
  readonly templateSelectEl!: HTMLElement;
  initialPlacement = true;
  langs = langs;
  selectedLang = '';
  subscription: Subscription | null = null;

  created() {
    i18nService.afterInit.then(() => {
      this.selectedLang = getLang().slice(0, 2);
      this.subscription = i18nService.langChanges.subscribe(
        (lang: string) => (this.selectedLang = lang.slice(0, 2))
      );
    });
  }

  mounted() {
    this.hijack(this.templateSelectEl);
  }

  @Watch('templateSelectEl', { immediate: true })
  hijack(templateSelectEl: HTMLElement) {
    if (!isBrowser || !templateSelectEl || !this.$el) {
      return;
    }

    if (templateSelectEl.style.display === 'none') {
      // already hijacked, hot reload?
      return;
    }

    const parentNode = templateSelectEl.parentNode;
    if (!parentNode) {
      // something went wrong, did someone change HF template?
      logError(`parentNode missing. parentNode: ${parentNode}`);
      return;
    }
    parentNode.insertBefore(this.$el, templateSelectEl);

    templateSelectEl.style.display = 'none';
    this.initialPlacement = false;
  }

  setLanguage(event: Event) {
    const location = window.location;
    const targetLang = get(event, 'target.value');
    const currentLang = getLangFromLocation(location);

    if (targetLang === currentLang) {
      return; // no change
    }

    let href: string;
    if (targetLang === config.companyLangShort) {
      // current lang is in path as only company lang is omited from url by default,
      // therefore we need to compose url without it
      href =
        location.protocol +
        '//' +
        location.host +
        location.pathname.replace(PATH_LANG_REGEXP, '/') +
        location.search;
    } else if (currentLang === config.companyLangShort) {
      // no lang in path as company lang is omited from url by default, therefore we need to add it
      href =
        location.protocol +
        '//' +
        location.host +
        '/' +
        targetLang +
        location.pathname +
        location.search;
    } else {
      // replacing current lang with new one, both are non-company
      href = location.href.replace(
        '/' + currentLang + '/',
        '/' + targetLang + '/'
      );
    }

    redirect(href);
  }

  destroyed() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

function getLangFromLocation(location: Location) {
  const pathLangMatch = location.pathname.match(PATH_LANG_REGEXP);
  const pathLang = pathLangMatch && pathLangMatch[1];

  return pathLang || config.companyLangShort;
}
