
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'WsLabelLink',
  props:{
    href: {
      type: String,
      required: true
    },
    target: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: 'wsk.general.link'
    }
  },

  setup(props) {
    const handleClick = () =>  window.open(props.href, props.target);

    return {handleClick};
  }

});
