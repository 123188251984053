/* eslint-disable no-unused-vars */

// place for global enums
// Enums in WS FE docs: https://loopiagroup.atlassian.net/wiki/spaces/ITD/pages/2067562542/Enums

export enum CompanyId {
  // in sync with PHP
  // eslint-disable-next-line
  // https://gitlab.com/loopia-group/platform/software/php/lib/company/-/blob/master/src/Domain/CompanyId.php#L27
  WEBSUPPORT_SK = 'sk-ws',
  SK_WEBSUPPORT_CZ = 'cz-ws',
  WEBSUPPORT_HU = 'hu-ws',
  WEBSUPPORT_SE = 'se-ws',
  SK_WEBSUPPORT_HU = 'hu-skws',
  ACTIVE_24_SK = 'sk-a24',
  ACTIVE_24_CZ = 'cz-a24',
  SK_WEBSUPPORT_AT = 'at-ws',
  LOOPIA_SE = 'se-lp',
  LOOPIA_NO = 'no-lp',
}

// eslint-disable-next-line
export const emailPattern = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,63}$/i;
export const webPattern = /^(\*\.)*?([A-Za-z0-9-]+(\.[A-Za-z0-9-]+)+)$/i;
