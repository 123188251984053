
import {QMenu} from 'quasar';
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'WsMenu',
  components: {
    QMenu,
  },
});
