import store from './store';
import {
  AppState,
  CartExtended,
  SetStatePayload,
} from './const';
import {getItem} from '@WS_UIkit';
import { set } from 'lodash-es';
import { StorageKeys, inputMasks } from '@/services/const';
import {StateMutations} from '@/store/const.enum';

/*
 synchronize local value with store value and vice versa

 USAGE:
  computed: {
    ...mapState([...]),
    ...mapGetters([...]),
    ...syncStore('syncedProperty'),
    ...syncStore('anotherSyncedProperty.deeper.state', 'withDifferentNameOfLocalProperty'),
  },
*/
export function syncStore(storePath: string, localProperty?: any) {
  if (localProperty === undefined) {
    localProperty = storePath;
  }
  return {
    [localProperty]: {
      get() {
        return store.getters.partialState(storePath);
      },
      set(value: any) {
        const payload: Partial<AppState> = set({}, storePath, value);
        store.commit(StateMutations.MERGE_STATE, payload);
      },
    },
  };
}

/**
 * Helper util to destructure categories array from Cart item gaee.categories obj
 * If the input isn't array and not empty string,
 * then it returns an obj /w single 'item_category' param
 * @param arr[string]
 * @returns {key: string}
 */
export function mapCategories(arr: string | string[]) {
  if (Array.isArray(arr)) {
    return arr.reduce(function (returnObj: {[key: string]: string}, value: string, i: number) {
      const label = 'item_category' + ((i === 0) ? '' : i + 1);
      return { ...returnObj, [label]: value };
    }, {});
  }
  else if (arr !== '') {
    return {['item_category']: arr};
  }

  return {};
}

let unwatchCart: Function;
const ensureCartLoadedStack: Function[] = []; // stack of callbacks after cart is loaded

export function ensureCartLoaded(cb: Function) {
  const cart = store.state.cart;

  if (!cart.initial) {
    cb(cart);
  } else {
    ensureCartLoadedStack.push(cb);
  }

  if (!unwatchCart) {
    unwatchCart = store.watch(
      state => state.cart,
      (newCart: CartExtended) => {
        if (newCart.initial) {
          return;
        }

        ensureCartLoadedStack.forEach(cb => cb(newCart));
        unwatchCart(); // stop watching
      }
    );
  }
}

export function requiredDpFieldsHash(tld: string[], profileCountry?: string) {
  return tld.join(':') + (profileCountry ? ':(' + profileCountry + ')' : '');
}

export function loadPersistentState() {
  const persistentState = getItem(StorageKeys.persistentState) as Record<
    string,
    SetStatePayload
  > | null;
  if (persistentState) {
    Object.values(persistentState).forEach(
      (setStatePayload: SetStatePayload) => {
        store.commit(StateMutations.SET_STATE, setStatePayload);
      }
    );
  }
}

export function dateFormatInputMask(currentLang: string) {
  return inputMasks.hasOwnProperty(currentLang) ? inputMasks[currentLang] : inputMasks['default'];
}

