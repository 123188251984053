import config from '@/services/config.service';
import { setContextProvider, logError } from '@/services/logger.service';
import '@/crowdin';

import Vue from 'vue';
import 'vue-class-component/hooks'; // import hooks type to enable auto-complete
// ref: https://class-component.vuejs.org/guide/additional-hooks.html
import Component from 'vue-class-component';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
]);
import 'quasar/dist/quasar.ie.polyfills';
import {Quasar, Intersection, Notify, QuasarPluginOptions, QTooltip} from 'quasar';
import iconSet from 'quasar/icon-set/svg-mdi-v4';
import {
  matExpandLess as iconArrowUp,
  matExpandMore as iconArrowDown,
  matChevronRight as iconArrowRight,
} from '@quasar/extras/material-icons';

if (config.env === 'staging') {
  // must be before store loading to have store features in devtools work correctly
  Vue.config.devtools = true;
}

import App from '@/App.vue';
import router from '@/router';
import store, { loadPersistentState } from '@/store';
import env from '@env';
import('@/services/theming.service');

// @WS_UIkit
import { get } from 'lodash-es';
import { isBrowser, docReady } from '@loopia-group/utils';
import { deviceInfo } from '@WS_UIkit';
import { setConfigValues, Theme } from '@loopia-group/services';
import WsOutdatedModal from '@/WS_UIkit/src/components/WsOutdatedModal.vue';

setConfigValues(config);

loadPersistentState();

import VueI18n from 'vue-i18n';
import { i18nService } from '@WS_UIkit';

import DEFAULT_LANG_MESSAGES from '@/assets/translations/source.json';

import '@/filters';
import { fetchCart } from '@/services/cart/cart-api.service';
import { changeAxiosConfig } from '@/services/network.service';

import '@/services/message.service';

import { updateStateFromBackend } from '@/services/order.service';

import PortalVue from 'portal-vue';
import { initAnalytics } from '@/services/analytics.service';

import '@/hotjar';
import { vueMessagePlugin } from '@/services/message.service';

import ResizeSensor from '@/vendor/resize-sensor/ResizeSensor.js';
import { hostingTutorialSetProducts } from '@/services/hosting-tutorial.service';
import {setupIwp} from '@/modules/iwp';
import {setupCheckout} from '@/modules/checkout';
import {WsAnalyticsDirective} from '@loopia-group/vue2-utils';

// Pinia setup, currently limited to IWP module
import {createPinia, PiniaVuePlugin, setActivePinia} from 'pinia';
import {StoreActions} from '@/store/const.enum';
const pinia = createPinia();
Vue.use(PiniaVuePlugin);

(window as any).ResizeSensor = ResizeSensor;

setActivePinia(pinia);

initAnalytics();

// HACK: until following issue won't be solved https://github.com/quasarframework/quasar/issues/7950
// to see all parts of hack, search sources for "QPortal hack"
if (isBrowser) {
  docReady(() => {
    const elm = document.createElement('div');
    // added also lgpi-checkout used by new web not to interfere SPA
    elm.className = 'wsk-root wsk-app wsk-portal ws-typography lgpi-checkout';
    document.body.appendChild(elm);
  });
}

// Sentry
setContextProvider(
  () => ({
    cart: store.state.cart,
    ui: store.state.ui,
    bpsLength: get(store, 'state.billingProfiles.length', 0),
    dpsLength: get(store, 'state.domainProfiles.length', 0),
    login: get(store, 'state.cart.user.login', '_unknown_user_'),
  }),
  true
);

let quasarOpts: Partial<QuasarPluginOptions> = {
  iconSet,
  directives: {
    Intersection,
  },
};

if (!env.isProductionBuild) {
  quasarOpts = {
    ...quasarOpts,
    plugins: {
      Notify,
    },
  };
}

Vue.use(vueMessagePlugin, {});
Vue.use(Quasar, quasarOpts);
Vue.use(PortalVue);
Vue.use(VueI18n);
Vue.config.productionTip = false;

Vue.prototype.$q.iconSet.table.arrowUp = iconArrowUp;
Vue.prototype.$q.iconSet.tree.icon = iconArrowRight;
Vue.prototype.$q.iconSet.arrow.dropdown = iconArrowDown;

// hotfix quasar breaking language features
// ref: https://github.com/quasarframework/quasar/issues/7785
if (isBrowser) {
  document.documentElement.setAttribute('lang', config.language!);
}

i18nService.init({
  messages: DEFAULT_LANG_MESSAGES,
  // https://kazupon.github.io/vue-i18n/guide/lazy-loading.html
  provider: (lang: string) => {
    return import(`@/assets/translations/app.${lang}.json`);
  },
  currency: config.currency,
  logger: logError,
});

Vue.component('WsOutdatedModal', WsOutdatedModal);
Vue.component('QTooltip', QTooltip);

deviceInfo.init();
// globaly available mixins
Vue.mixin({
  data: function() {
    return {
      wsDeviceInfo: deviceInfo,
    };
  },
  methods: { get },
});



Vue.directive('ws-analytics', WsAnalyticsDirective);

//===========>MODULES
/// "I want a website" flow module
setupIwp(router);
setupCheckout(router);

new Vue({
  router,
  store,
  // TODO: Fix TS error caused by Pinia: TS2769
  // @ts-ignore
  pinia,
  i18n: i18nService.i18n,

  computed: {
    themeCary() {
      return this.theme === Theme.CARY;
    },
    themeLoopia() {
      return this.theme === Theme.LOOPIA;
    },
    themeActive24() {
      return this.theme === Theme.ACTIVE_24;
    },
    themeAdmin() {
      return this.theme === Theme.ADMIN || !config.theme; // fallback to admin
    },
  },

  beforeCreate: function() {
    // aligning breakpoints with SCSS ones
    // https://quasar.dev/options/screen-plugin#configuration
    this.$q.screen.setSizes({ md: 768, lg: 1260 });

    changeAxiosConfig({ baseURL: config.apiUrl });

    // initially load cart
    fetchCart();
    // Fetch and load domain checker categories
    this.$store.dispatch(StoreActions.FETCH_DOMAIN_CATEGORIES);
    // Load hosting tutorial products into store
    hostingTutorialSetProducts();

    // automatically fetch cart changes on tab getting focus by user
    // solves scenario when user handles order in multiple tabs or devices
    if (isBrowser && !env.dev?.disableAutoUpdateOnRefocus) {
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState) {
          updateStateFromBackend();
        }
      });
      // also immediate
      updateStateFromBackend(true);
    }
  },

  methods: {
    isTheme(theme: Theme) {
      return this.$store.getters['theme/currentTheme'] === theme;
    },
    currentTheme () {
      return this.$store?.getters?.['theme/currentTheme'];
    }
  },
  render: h => h(App),
}).$mount(
  (config.appRootElm && document.querySelector(config.appRootElm)) ||
    '#main-content'
);
