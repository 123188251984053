
import {Component, Vue, Watch, Prop} from 'vue-property-decorator';

import {castArray, omit} from 'lodash-es';
import {WsInput} from './WsInput.vue';
import WsFieldMixin from './WsFieldMixin.vue';

// implements transparent wrapper component described here: https://github.com/chrisvfritz/7-secret-patterns
// and also some inspiration from: https://zendev.com/2018/05/31/transparent-wrapper-components-in-vue.html
@Component({
  components: {
    WsInput,
  },
})
export class WsEmailsInput extends Vue {
  @Prop({type: [String, Array]}) declare readonly value: string | string[];
  model = '';

  get listeners() {
    return omit({...this.$listeners}, 'input');
  }

  @Watch('value', {immediate: true})
  onValueChanged(newVal: any) {
    this.setValue(newVal);

    if (this.$parent) {
      this.$parent.$emit('dirty', newVal);
    }
  }

  setValue(val?: string[] | string) {
    if (!val || !val.length) {
      this.model = '';
      return;
    }
    val = castArray(val);
    //@ts-ignore
    this.model = val.join(', ');
  }

  onBlur() {
    (this.$refs.qfield as WsFieldMixin)?.validate?.();
    this.emit();
  }

  emit(model?: string) {
    model = model || this.model;
    const valueArray = model
      .split(',')
      .map((email: string) => email.trim())
      .filter((email: string) => !!email);
    this.$emit('input', valueArray);
  }
}

export default WsEmailsInput;
