
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

import WsBack from '@WS_Components/WsBack.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import { COMPLETED } from '@/store/const';
import {
  navigateToUserStep,
  progressBack,
  setEditMode,
} from '@/services/order.service';
import { STEPS } from '@/services/const.enum';

@Component({
  components: {
    WsBack,
    WsButton,
    WsMessage,
    WsIcon,
  },
  computed: {
    ...mapGetters(['progressByUser']),
    ...mapState(['inEditMode']),
  },
})
export class OrderProcessItem extends Vue {
  @Prop()
  readonly step!: STEPS;
  @Prop()
  readonly path!: string;
  @Prop()
  readonly buttonText!: string;
  @Prop(Boolean)
  readonly loading!: boolean;

  readonly inEditMode!: STEPS;
  readonly progressByUser!: Record<STEPS, number>;

  data() {
    return {
      COMPLETED,
      setEditMode,
    };
  }

  get isCompletedByUser() {
    return this.progressByUser[this.step] === COMPLETED;
  }

  get editMode() {
    return this.inEditMode === this.step;
  }

  @Watch('editMode')
  onEditModeChanged(newVal: boolean) {
    if (!newVal && !this.isCompletedByUser) {
      // if turning off edit mode and step is not completed
      // consider it as back step, but without routing
      progressBack(this.step, true);
    }
  }

  goBackOrCancel() {
    if (this.isCompletedByUser) {
      // is cancel
      navigateToUserStep();
    } else {
      // is back
      progressBack(this.step);
    }
  }
}
export default OrderProcessItem;
