
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { QList, QItem, QItemSection, QItemLabel, QIcon } from 'quasar';
import { mdiInformation, mdiPlus } from '@quasar/extras/mdi-v4';

import { get } from 'lodash-es';
import { cardHash } from '@loopia-group/utils';
import WsBadge from '@WS_Components/WsBadge.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import { Cart, PaymentOption, PaymentOptionGroup } from '@/types';
import americanexpresLogo from '@/assets/images/Cards/americanexpres.svg';
import dinnersClubLogo from '@/assets/images/Cards/dinnersClub.svg';
import discoverLogo from '@/assets/images/Cards/discover.svg';
import maestroLogo from '@/assets/images/Cards/maestro.svg';
import mastercardLogo from '@/assets/images/Cards/mastercard.svg';
import unionpayLogo from '@/assets/images/Cards/unionpay.svg';
import visaLogo from '@/assets/images/Cards/visa.svg';
import PaymentCardInfo from './PaymentCardInfo.vue';
import {INVOICE_DELIVERY_TYPE} from '@/components/paymentOptions/const';
import { mapState } from 'vuex';

interface PaymentOptionExtended extends PaymentOption {
  isDisabled: boolean;
}

@Component({
  components: {
    WsBadge,
    WsIcon,
    WsCheckbox,
    PaymentCardInfo,
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QIcon,
  },
  computed: {
    ...mapState(['cart']),
  },
  filters: {
    cardHash,
  },
  data() {
    return {
      mdiInformation,
      mdiPlus,
    };
  },
})
export default class PaymentOptionCard extends Vue {
  @Prop({ default: () => ({}) })
  readonly optionGroup!: PaymentOptionGroup;
  @Prop({ default: () => ({}) })
  readonly value!: PaymentOption | PaymentOptionExtended;
  @Prop(Boolean)
  readonly editMode!: boolean;
  @Prop(Boolean)
  readonly compact!: boolean;

  readonly cart!: Cart;

  selectedOption: PaymentOptionExtended | null = null;
  imgs: { [key: string]: any } = {
    americanexpres: americanexpresLogo,
    dinnersClub: dinnersClubLogo,
    discover: discoverLogo,
    maestro: maestroLogo,
    mastercard: mastercardLogo,
    unionpay: unionpayLogo,
    visa: visaLogo,
  };

  isNewCardOpts(opt: PaymentOption): boolean {
    return ['new-card-nets-easy', 'new-card'].includes(opt.id);
  }

  get showHeader(): boolean {
    return this.editMode && this.opts.length === 1 ? false : true;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get opts(): PaymentOptionExtended[] {
    if (!this.editMode && this.value) {
      return this.optionGroup.paymentOptions
        .filter((opt: PaymentOption) => opt.id === this.value.id)
        .map((opt: PaymentOption) => ({
          ...opt,
          isDisabled: !!get(opt, 'errors.length') || !opt.available,
        }));
    }

    let newCardOpt;
    const opts = this.optionGroup.paymentOptions.map((opt: PaymentOption) => {
      const tmpOpt: PaymentOptionExtended = {
        ...opt,
        isDisabled: !!get(opt, 'errors.length') || !opt.available,
      };
      if (tmpOpt.id === 'new-card' || tmpOpt.id === 'new-card-nets-easy') {
        newCardOpt = tmpOpt;
      }
      return tmpOpt;
    });

    // Do not pre-check recurrent payment in case the e-invoices are enabled
    if (
      newCardOpt
      &&
      get(this.cart.billingProfile, 'invoiceDelivery') === INVOICE_DELIVERY_TYPE.EINVOICE
    ) {
      (newCardOpt as PaymentOption).recurrenceState = false;
    }

    // option for new card, always last
    if (!this.optionGroup.paymentOptions.length) {
      newCardOpt = {
        id: 'new-card',
        available: true,
        isDisabled: false,
        recurrenceState:
          this.value && this.value.id === 'new-card'
            ? this.value.recurrenceState
            : true,
        recurrenceCanBeChanged: true,
        errors: [],
        ...(newCardOpt || {}),
      };

      // preselect
      this.selectedOption = newCardOpt;

      // allows recurrency option to be handled
      return [newCardOpt];
    } else if (this.optionGroup.paymentOptions.length === 1) {
      this.selectedOption = opts[0];
    } else if (newCardOpt) {
      // more than one option, move 'new-cart' or 'new-card-nets-easy' to the end
      const index = opts.indexOf(newCardOpt);
      opts.push(opts.splice(index, 1)[0]);
    }

    return opts;
  }

  @Watch('value', { immediate: true })
  onValueChanged(newVal: PaymentOption) {
    if (!newVal) {
      this.selectedOption = null;
      return;
    }
    if (this.selectedOption && this.selectedOption.id === newVal.id) {
      if (this.selectedOption.recurrence) {
        this.selectedOption.recurrenceState = newVal.recurrenceState;
      }
      return;
    }
    this.autoSelect();
  }

  @Watch('selectedOption.recurrenceState')
  onRecurrenceChanged() {
    this.$emit('selected', this.selectedOption);
  }

  @Watch('optionGroup')
  autoSelect() {
    if (!this.opts) {
      return;
    }
    if (!this.editMode || (!this.selectedOption && this.opts.length === 1)) {
      this.selectedOption = this.opts[0];
      return;
    }
    if (this.value) {
      for (let i = 0; i < this.opts.length; i++) {
        const opt = this.opts[i];
        if (opt.id === this.value.id) {
          this.selectedOption = opt;
          break;
        }
      }
    }
  }

  recurrence(opt: any) {
    return (
      !this.compact && opt.recurrence && this.selectedOption?.id === opt.id
    );
  }

  selectOption(opt: PaymentOptionExtended) {
    if (opt.isDisabled || !this.editMode || opt.oldOtpBank) {
      return;
    }
    this.selectedOption = opt;
    this.$emit('selected', opt);
  }

  date(input: string) {
    if (!input) {
      return '-/-';
    }
    const dateObj = new Date(input);
    const year = ('' + dateObj.getFullYear()).substr(2, 2);
    return `${dateObj.getMonth() + 1}/${year}`;
  }
}
