var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.inline)?_c('span',{staticClass:"ws-message inline",class:_vm.type},[(_vm.hasMessages)?_vm._l((_vm.msgs),function(msg,index){return _c('span',{key:_vm.texts[index] + index},[(index)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(_vm._s(_vm.texts[index]))])}):[_vm._t("default")]],2):(_vm.hasMessages)?_c('div',{staticClass:"ws-message",class:{
    banner: _vm.banner,
    prepend: _vm.$slots.prepend || _vm.icon,
    append: _vm.$slots.append || _vm.button,
    [_vm.type]: true,
  }},[_vm._l((_vm.msgs),function(msg,index){return [(_vm.banner)?_c('ws-state-banner',_vm._b({key:_vm.texts[index] + index,attrs:{"type":_vm.type}},'ws-state-banner',_vm.$attrs,false),[(_vm.$slots.prepend || _vm.iconResolved)?_c('div',{staticClass:"prepend"},[_vm._t("prepend",function(){return [_c('ws-icon',_vm._b({attrs:{"name":_vm.iconResolved}},'ws-icon',_vm.iconAttrsResolved,false))]})],2):_vm._e(),_c('div',{staticClass:"content q-ml-md"},[(_vm.$slots.title || _vm.texts[index])?_c('div',{staticClass:"title",class:!_vm.$slots.title || _vm.descriptions[index]
              ? 'text-bold text-' + (_vm.featuresColor || 'bold')
              : ''},[_vm._t("title",function(){return [_c('small',[(_vm.prepend)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.prepend))+" ")]):_vm._e(),_vm._v(_vm._s(_vm.texts[index]))])]})],2):_vm._e(),_vm._t("content",function(){return [(_vm.descriptions[index])?_c('small',[_vm._v(_vm._s(_vm.descriptions[index]))]):_vm._e()]})],2),(_vm.$slots.append || _vm.button)?_c('div',{staticClass:"append q-ml-lg"},[_vm._t("append",function(){return [_c('ws-button',_vm._b({class:{
              'default-button-margins': !_vm.get(
                _vm.buttonAttrs,
                'clearDefaultButtonMargins'
              ),
            }},'ws-button',_vm.buttonAttrsResolved,false),[_vm._v(" "+_vm._s(_vm.$t(_vm.button.translationKey || 'wsk.general.link', _vm.button.values))+" ")])]})],2):_vm._e()]):_c('q-banner',{key:_vm.texts[index] + index},[(_vm.prepend)?_c('span',[_vm._v(_vm._s(_vm.$t(_vm.prepend))+" ")]):_vm._e(),_vm._v(_vm._s(_vm.texts[index])+" ")])]})],2):(_vm.$slots.default)?_c('div',{staticClass:"ws-message",class:{
    banner: _vm.banner,
    inline: _vm.inline,
    prepend: _vm.$slots.prepend || _vm.icon,
    append: _vm.$slots.append || _vm.button,
    [_vm.type]: true,
  }},[_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }