
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'WsDelimiter',
  props: {
    text: {
      type: String,
      default: ''
    },
    textClass: {
      type: String,
      default: ''
    },
    lineClass: {
      type: String,
      default: ''
    },
  }
});
