
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { required } from '@WS_UIkit';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsModal from '@WS_Components/Dialogs/WsModal.vue';
import { Cart } from '@/types';
import config, { isSwedishWorkflow } from '@/services/config.service';
import ThirdPartyInfo from '@/components/ThirdPartyInfo.vue';

@Component({
  components: {
    WsCheckbox,
    WsModal,
    ThirdPartyInfo,
  },

  computed: {
    ...mapState(['cart']),
  },
})
export default class ConsentCheckboxes extends Vue {
  @Prop(Boolean)
  readonly value!: boolean;
  @Prop(Boolean)
  readonly forceRequired!: boolean;
  readonly cart!: Cart;
  thirdPartyDialog = false;
  public termsAndConditionsConsent = false;
  public thirdPartyConsent = false;
  required = required;
  isSwedishWorkflow = isSwedishWorkflow;

  pathTos: string = 'user.personalInformation.tos';
  pathThirdParty: string = 'user.thirdPartyConsent';

  get termsAndConditionsUrl(): string {
    return config.tosUrl;
  }

  public validateCheckboxes() {
    let result = true;

    if (
      (this.forceRequired || !this.cart.termsAndConditionsConsent) &&
      !this.termsAndConditionsConsent
    ) {
      this.$messageService.setStandardMessage(
        'user.personalInformation.tos',
        'validation.consent_tos.required'
      );
      result = false;
    }

    if (
      (this.forceRequired ||
        (!this.isSwedishWorkflow &&
          this.cart.consentThirdPartyRequired &&
          !this.cart.thirdPartyConsent)) &&
      !this.thirdPartyConsent
    ) {
      this.$messageService.setStandardMessage(
        'user.thirdPartyConsent',
        'validation.consent_third_party.required'
      );
      result = false;
    }
    return result;
  }

  @Watch('cart.termsAndConditionsConsent', { immediate: true })
  onTosChanged(consentValue: boolean) {
    this.$messageService.clearMessages(this.pathTos);
    this.termsAndConditionsConsent = !!consentValue;
  }

  @Watch('cart.thirdPartyConsent', { immediate: true })
  on3rdPartyChanged(consentValue: boolean) {
    this.$messageService.clearMessages(this.pathThirdParty);
    this.thirdPartyConsent = !!consentValue;
  }

  public reset() {
    this.termsAndConditionsConsent = false;
    this.thirdPartyConsent = false;
    this.thirdPartyDialog = false;
  }

  goToTos() {
    window.open(this.termsAndConditionsUrl, '_blank');
  }
}
