
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QLayout, QPageContainer } from 'quasar';

import { CompanyId } from '@WS_UIkit';
import { get } from 'lodash-es';
import { isBrowser } from '@loopia-group/utils';

import WsSpinner from '@WS_Components/WsSpinner.vue';
import WsDrawer from '@WS_Components/WsDrawer.vue';
import { Cart } from '@/types';
import config from '@/services/config.service';
import LoginSection from '@/components/LoginSection.vue';
import TopLoginLogout from '@/components/TopLoginLogout.vue';
import TopMiniCart from '@/components/TopMiniCart.vue';
import LangSelect from '@/components/LangSelect.vue';
import {SidePanels, StateMutations} from '@/store/const.enum';

@Component({
  components: {
    QLayout,
    QPageContainer,
    LoginSection,
    TopLoginLogout,
    TopMiniCart,
    LangSelect,
    WsSpinner,
    WsDrawer,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class LayoutDefault extends Vue {
  readonly cart!: Cart;
  drawerWidth = 550;
  templateLoginEls: NodeList | null = null;
  templateCartEls: NodeList | null = null;
  templateSelectEl: HTMLElement | null = null;
  isNonSwedishCompany = config.company !== CompanyId.WEBSUPPORT_SE;
  checkInterval: any = null;

  get sidePanel() {
    return this.$store.state.ui.sidePanel !== SidePanels.Hidden;
  }
  set sidePanel(newVal: boolean) {
    this.$store.commit(StateMutations.MERGE_STATE, {
      ui: { sidePanel: newVal ? SidePanels.Login : SidePanels.Hidden },
    });
  }

  get allElementsLoaded(): boolean {
    return !!(
      get(this.templateLoginEls, 'length') &&
      get(this.templateCartEls, 'length') &&
      this.templateSelectEl
    );
  }

  mounted() {
    if (!isBrowser) {
      return;
    }

    this.checkInterval = setInterval(() => {
      this.collectElements();
      if (this.allElementsLoaded) {
        removeElements(
          document.querySelectorAll('.account-menu-cart--placeholder') as any
        );
        clearInterval(this.checkInterval);
      }
    }, 100);

    const tmplElm = document.getElementsByClassName('template-checkout')?.[0];
    if (tmplElm) {
      (tmplElm as HTMLElement).style.minHeight = '0';
    }
  }

  collectElements() {
    // Hijacking login buttons in header/footer template
    this.templateLoginEls = document.querySelectorAll(
      '.js-cart-login:not(.ws-vue-login-hijack)'
    );

    // Hijacking cart buttons in header/footer template
    this.templateCartEls = document.querySelectorAll(
      '.top-menu-cart:not(.ws-vue-cart-hijack)'
    );

    // Hijacking language selector
    this.templateSelectEl = document.querySelector(
      '#footer-lang-select:not(.ws-vue-lang-select-hijack)'
    );
  }

  destroyed() {
    clearInterval(this.checkInterval);
  }
}

// helpers

function removeElements(elements: HTMLElement[] = []) {
  for (const element of elements) {
    if (element.remove) {
      element.remove();
    }
  }
}
