
import {computed, defineComponent, Ref, ref} from 'vue';

import WsIcon from '../WS_UIkit/src/components/WsIcon.vue';
import {useRoute, useRouter} from 'vue-router/composables';


export default defineComponent({
  name: 'ProductFlowStepper',
  components: {
    WsIcon,
  },
  props: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    type FlowId = 'website';
    const flowId: Ref<FlowId> = ref('website' as FlowId);

    const currentStep = computed(() => flow.value.findIndex((step) => step.isCurrent()));

    const flowConfigs = {
      website: [
        {
          title: 'modules.i_want_product.website.breadcrumbs.step_1',
          isCurrent: () => route.name === 'domain-checker',
          nav: () => {
            router.push({
              name: 'domain-checker',
              query: {
                flow: 'website',
                qd: route.query.domain || '',
              }
            });
          },
        },
        {
          title: 'modules.i_want_product.website.breadcrumbs.step_2',
          isCurrent: () => route.name === 'IWP',
          nav: () => {},
        },
        {
          title: 'modules.i_want_product.website.breadcrumbs.step_3',
          isCurrent: () => false,
          nav: () => {},
        }
      ]
    };

    const flow = computed(() => flowConfigs[flowId.value]);

    return {route, flowId, currentStep, flowConfigs, flow};
  },

});
