/* eslint-disable sonarjs/no-duplicate-string */
import { getShortUID } from './utilities';
import MockAdapter from 'axios-mock-adapter/types';

export const signupDataMock = {
  personalInformation: {
    login: 'exampleLogin' + getShortUID(),
    password: 'MyPass1212.',
    emails: ['example@example.com'],
    phone: '+421909999999',
  },
  billingProfile: {
    type: 'company',
    firstName: 'Jozef',
    lastName: 'Mak',
    organisation: 'My Company v.' + getShortUID() + '  s. r. o.',
    street: 'Kavova 99',
    city: 'Bratislava',
    postalCode: '821 08',
    countryCode: 'SK',
    companyId: '36421928',
    taxId: '2021869234',
    vatId: 'SK2021869234',
    emails: ['example@example.com'],
  },
  domainProfileAdditionalInfo: {
    createDomainProfile: true,
    passport: 'BH123456',
    idCard: 'BH123456',
  },
};

export const userDomainsMock = {
  domains: ['example.com', 'jozko.sk', 'erika.sk'],
};

export const duplicatedResponse = {
  data: {
    redirect:
    // eslint-disable-next-line vue/max-len
      'https://api-cart.no.feature.websupport.cz/login?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2MDE4MDAxMzgsImV4cCI6MTYwMTgwMDQzOCwicm9sZXMiOlsiUk9MRV9VU0VSIl0sInN1YiI6ImFzZGZnaGprbHdkZXdxIiwiYXVkIjoiY2FydCIsImp0aSI6ImhPOTlkRlprRk1Oc1NwR2cifQ.tfltlr6_J-baplRgtMvy8Us0YnP5ndHk7PTLuTPcbClQWA_mH-28SrTZnjx79MYz8zEVKHstjpW9NYRCoiI3CqyqMoYV0RwT5gu-wzjdO2vvwjlJPmAxZm7CIFfuOcLzF_WnBlWmXfnZzbi3jTZuxz8owJYOem-tT0GhRmnf-NSiDg9MdkpPdI0134vrkp2n9-DwQiGRyT129bW0gheUebxW7BkSakLaE7UFnxVIcxlUrdEBmr4AWS7D9DCryf4JDR6yPd2QZ5bk4DajzAyLg6uSw8XdCjYSRRM6TUcfUKRMKKINJdJIUnvLh1_8OSaomDOJk7iFfTF9spxdC6x6mw',
    domainProfile: {
      response: {
        type: '/errors/httpException',
        status: 409,
        title: 'Duplicate domain profile already exists',
        key: 'validation.duplicate_domain_profile',
        context: {
          domainProfile: {
            id: null,
            userId: null,
            type: 'personal',
            name: 'Jozef Mak',
            street: 'Karadžičova 12',
            city: 'Bratislava',
            postalCode: '11231',
            countryCode: 'SK',
            email: 'example@example.com',
            phone: '+421909999999',
            passport: null,
            idCard: null,
          },
          duplicateId: 95625,
        },
      },
    },
  },
  status: 201,
  statusText: '',
  headers: {
    'cache-control': 'private, must-revalidate',
    'content-length': '1070',
    'content-type': 'application/json',
    expires: '-1',
    pragma: 'no-cache',
  },
  config: {
    url: '/users',
    method: 'post',
    data:
    // eslint-disable-next-line vue/max-len
      '{"personalInformation":{"emails":["example@example.com"],"login":"asdfghjklwdewq","password":"dwqsAFAF.32436545","phone":"+421909999999"},"billingProfile":{"type":"personal","name":"Jozef Mak","street":"Karadžičova 12","postalCode":"11231","city":"Bratislava","countryCode":"SK","emails":["example@example.com"]},"termsAndConditionsConsent":true,"thirdPartyConsent":true,"domainProfileAdditionalInfo":{"createDomainProfile":true}}',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'WS-Company': 'cz-ws',
      'ws-language': 'dev-EN',
      'Content-Type': 'application/json;charset=utf-8',
    },
    baseURL: 'https://api-cart.no.feature.websupport.cz',
    transformRequest: [null],
    transformResponse: [null],
    timeout: 0,
    withCredentials: true,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    maxContentLength: -1,
  },
  request: {
    __sentry_xhr__: {
      method: 'POST',
      url: 'https://api-cart.no.feature.websupport.cz/users',
      status_code: 201,
    },
    ea: {
      method: 'POST',
      url: 'https://api-cart.no.feature.websupport.cz/users',
      status_code: 201,
    },
  },
};

export const commonProfileMock = {
  type: 'company',
  name: 'Jozef Mak',
  organisation: 'Kokosy na snehu v:' + getShortUID(),
  street: 'Cokoladova 12',
  city: 'Bratislava',
  postalCode: '821 08',
  countryCode: 'SK',
  companyId: '36421928',
  vatId: 'SK2021869234',
  emails: ['example@example.com', 'example2@example.com'],
  email: 'example@example.com',
  phone: '+421903111111',
};

export const userLoginFail = {
  type: '/errors/validation',
  status: 422,
  title: 'The given data was invalid.',
  violations: [
    { propertyPath: 'login', errors: ['This value is already used.'] },
  ],
};

export const userProfileMock = {
  id: 185005,
  login: 'kokosvelky',
  loginProfile: {
    emails: ['kokosynasnehu22@gmail.com'],
    phone: '+421909123123',
  },
};

export const userLoginCheckMock = {
  free: true,
};

export function registerUserMocks(mock: MockAdapter) {
  // Write a message to console as developer notice
  // eslint-disable-next-line no-console
  console.info('Registering user API mocks');

  mock.onGet(/\/api\/users$/).reply(200, userProfileMock);
  mock.onGet(/\/api\/users\/domains/).reply(200, userDomainsMock);
  mock.onPost(/\/api\/users\/check-login/).reply(200, userLoginCheckMock);
}
