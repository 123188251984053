
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QForm } from 'quasar';

import {CartItem, DomainProfileExtended} from '@/types';
import { STEPS } from '@/services/const.enum';
import { getVatTaxTKeyInterceptor } from '@/services/message.service';
import { updateItem } from '@/services/cart/cart-api.service';
import { getNextStep, mayGoNext } from '@/services/order.service';
import { Theme } from '@loopia-group/services';

import {get, cloneDeep, isNumber} from 'lodash-es';
import {getSnakedKeyFactory,} from '@loopia-group/utils';

import {
  productPropertyList,
  addValidations,
  generalPropertyList,
  propertyRules,
  getLang,
} from '@WS_UIkit';
import WsDrawer from '@WS_Components/WsDrawer.vue';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import ProfileSettings from '@/modules/checkout/components/cart/ProfilesSettings.vue';
import {
  Message,
  MessagesProviderOptions,
  Validator,
} from '@WS_UIkit/types';
import CartItemSettings from '@/modules/checkout/components/cart/CartItemSettings.vue';
import ProfilesSettings from '@/modules/checkout/components/cart/ProfilesSettings.vue';
import OrderProcessError from '@/components/OrderProcessError.vue';
import { updateEditItem } from '@/services/cart/cart.service';

type PeriodOption = {
  period: number;
  price: number;
  onlyCardPayment: boolean;
};

const DEFAULT_PERIOD = 12;
const DEFAULT_PERIOD_OPT: PeriodOption = {
  period: DEFAULT_PERIOD,
  price: 0,
  onlyCardPayment: false,
};

@Component({
  components: {
    OrderProcessError,
    QForm,
    WsDrawer,
    WsDelimiter,
    WsButton,
    WsMessage,
    ProfileSettings,
    CartItemSettings,
  },
  computed: {
    ...mapState(['cartService']),
  },
})
export default class SideDrawer extends Vue {
  @Prop(Boolean) readonly value!: boolean;
  editItem: CartItem | null = null;
  selectedOpt: PeriodOption | null = null;
  domainProfile: DomainProfileExtended | null = null;
  isNumber = isNumber;
  path = 'cart_item_settings';
  isDomainListVisible = true;
  loading = false;
  domainRules!: Validator[];

  businessMailCode = 'business-mail';

  Theme = Theme;
  language: string = getLang();
  domainMessageServicePath: string = `${this.path}.properties.domain`;
  messagesProviderOptions: MessagesProviderOptions = {
    recursive: true,
    fallback: true,
  };

  get itemCode() {
    return this.item?.code;
  }


  created() {
    addValidations([generalPropertyList, productPropertyList]);
    this.domainRules = propertyRules('domain');
  }

  mounted() {
    this.$root.$messageService.subscribe(
      this.domainMessageServicePath,
      this.messagesProviderOptions
    );
  }

  destroyed() {
    this.$root.$messageService.unsubscribe(
      [this.domainMessageServicePath],
      this.messagesProviderOptions
    );
  }

  get domainProfileList(): ProfilesSettings | null {
    return get(this.$refs.profileSettingsRef as any, 'domainProfileList', null);
  }

  async updateItem(emitUpdate = true) {
    if (!this.editItem) {
      this.loading = false;
      this.$emit('updated');
      return;
    }

    this.loading = true;
    const dpList = this.domainProfileList;

    if (dpList) {
      this.saveDomainProfile(dpList, true);
    } else {
      this.innerUpdateItem(emitUpdate);
    }
  }

  updateEditItemHandler(editItem: CartItem | undefined) {
    if (editItem) {
      this.editItem = cloneDeep(editItem);
    }
  }

  async saveDomainProfile(dpList: any, emitUpdate = true) {
    try {
      await dpList
        .saveProfile()
        .then(() => this.innerUpdateItem(emitUpdate))
        .finally(() => (this.loading = false));
    } catch (error) {
      this.$messageService.errorHandler(this.path, {
        keyInterceptor: getVatTaxTKeyInterceptor(
          get(this.$refs.domainProfileList as any, 'domainProfile')
        ),
      })(error);
    }
    this.loading = false;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async innerUpdateItem(emitUpdate?: boolean, editItem = this.editItem) {
    if (editItem) {
      this.editItem = cloneDeep(editItem);
    }

    try {
      this.loading = true;
      const response = await updateItem(editItem!);
      // need to be set prior to update event so callback can close drawer,
      // because drawer prevents close while loading

      if (this.editItem && response?.data?.items) {
        const updatedItem = response.data.items.find((item: CartItem) => item.id === this.editItem!.id);

        if (updatedItem) {
          updateEditItem(updatedItem);
        }
      }

      this.loading = false;
      if (!!emitUpdate) {
        this.$emit('updated');
      }
      if (
        this.$store.state.userStep === STEPS.RECAPITULATION &&
        getNextStep(STEPS.CART) !== STEPS.RECAPITULATION
      ) {
        // if user edits item in recapitulation
        // getNextStep will return broken step or recapitulation
        // if this update introduce some unfinished state in previous steps it will return
        // other step then recapitulation and mayGoNext will navigate to it
        mayGoNext();
      }
    } catch (err) {
      if (err.violations?.propertyPath === 'properties.domain') {
        this.$messageService.errorHandler(this.domainMessageServicePath)(err);
      } else {
        err = this.handleRequiredFieldErr(err);
        this.$messageService.errorHandler(this.path)(err);
      }
    } finally {
      this.loading = false;
    }
  }

  handleRequiredFieldErr(err: any) {
    err.response.data.violations?.forEach((violation: any) => {
      violation.errors = violation.errors.map((error: any) => {
        if (error.translationKey === 'validation.required' && violation.propertyPath) {
          error.translationKey = `validation.required_${violation.propertyPath}`;
        }
        return error;
      });
    });
    return err;
  }

  clearMessages(): void {
    if (this.generalMessages.length > 0) {
      this.$root.$messageService.clearMessages({
        path: this.path,
      });
    }

    if (this.domainMessages.length > 0) {
      this.$root.$messageService.clearMessages({
        path: this.domainMessageServicePath,
      });
    }
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('service_settings.props');
    return this.getKey(prop, key);
  }

  getProductKey(prop: string, key: string): string {
    this.getProductKey = getSnakedKeyFactory('product');
    return this.getProductKey(prop, key);
  }

  get item(): CartItem {
    // @ts-ignore
    return this.cartService.itemInEdit;
  }

  get drawerTitle() {
    const code = get(this.editItem, 'code');
    if (code === 'domain-register') {
      return 'product_settings.domain_title';
    } else if (code === this.businessMailCode) {
      return 'product.business_mail.title';
    } else {
      return 'wsk.general.edit';
    }
  }

  get drawerSubtitle() {
    const code = get(this.editItem, 'code');
    if (code === 'domain-register') {
      // @ts-ignore
      return this.editItem.properties.domain;
    } else if (code === this.businessMailCode) {
      return this.editItem?.properties?.domain;
    } else {
      // @ts-ignore
      return code ? this.$t(this.getProductKey(code, 'title')) : '';
    }
  }

  get generalMessages(): Message[] {
    return this.$root.$messageService.getters.messagesByPath(this.path, {
      recursive: true,
    });
  }

  get domainMessages(): Message[] {
    return this.$root.$messageService.getters.messagesByPath(
      this.domainMessageServicePath,
      { recursive: true }
    );
  }

  @Watch('item', { immediate: true })
  onItemChange(item: CartItem | null) {
    const period = isNumber(item?.period) ? item!.period : DEFAULT_PERIOD;

    this.editItem = item ? cloneDeep(item) : null;
    this.selectedOpt = get(this.editItem, 'options.period', [
      DEFAULT_PERIOD_OPT,
    ]).find((opt: PeriodOption) => opt.period === period);
  }

  @Watch('domainProfile', { immediate: true, deep: true})
  onProfileChange(profile: DomainProfileExtended | null) {
    if (this.editItem && profile) {
      this.editItem.properties = this.editItem.properties || {};
      this.editItem.properties.domainProfileId = profile.id;
    }
  }
}
