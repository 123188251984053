
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { QForm } from 'quasar';
import { AxiosResponse, AxiosError } from 'axios';

import { set, get, cloneDeep, find } from 'lodash-es';
import { redirect } from '@loopia-group/utils';
import {
  validateForm,
  setItem,
  CompanyId,
  required,
  emails,
  minLength,
  maxLength,
  AddressConfig,
  validLogin,
  propertyRules,
} from '@WS_UIkit';
import WsInput from '@WS_Components/WsInput.vue';
import WsPasswordInput from '@WS_Components/WsPasswordInput.vue';
import WsPhoneInput from '@WS_Components/WsPhoneInput.vue';
import WsEmailsInput from '@WS_Components/WsEmailsInput.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsAddress from '@WS_Components/WsAddress.vue';

import {
  SignUpUserRequest,
  BillingProfile as BillingProfileType,
  Cart,
  DomainProfileAdditionalInfoRequest,
  DomainProfileExtended,
  FromBillingProfileDomainProfileRequest,
} from '@/types';
import { StateMutations, StoreActions } from '@/store/const.enum';
import config, {
  isStandardWorkflow,
  isSwedishWorkflow,
} from '@/services/config.service';
import {
  createDomainProfileFromBilling,
  fetchCart,
  setDomainProfile,
} from '@/services/cart/cart-api.service';
import {
  cleanProfileByType,
  normalizeDomainProfile,
} from '@/services/profiles.service';
import { getVatTaxTKeyInterceptor } from '@/services/message.service';
import { uniqueLogin } from '@/services/validation.service';
import { signUp } from '@/services/user-api.service';
import {
  mayGoNext,
  isAfterCurrentStep,
  domainFromBillingProfile,
} from '@/services/order.service';
import { StorageKeys } from '@/services/const';
import { STEPS } from '@/services/const.enum';
import { setQueryParam } from '@/utilities';

import BillingProfileList from '@/components/BillingProfileList.vue';
import ConsentCheckboxes from '@/components/ConsentCheckboxes.vue';
import BonusCard from '@/components/BonusCard.vue';
import DomainProfileAdditionalFields from '@/components/DomainProfileAdditionalFields.vue';
import OrderProcessActions from '@/components/OrderProcessActions.vue';
import OrderProcessError from '@/components/OrderProcessError.vue';

const DUPLICATE_ERROR_KEY = 'validation.duplicate_domain_profile';

@Component({
  components: {
    OrderProcessError,
    QForm,
    WsInput,
    WsPasswordInput,
    WsPhoneInput,
    WsEmailsInput,
    WsButton,
    WsMessage,
    WsCheckbox,
    WsAddress,
    OrderProcessActions,
    BillingProfileList,
    DomainProfileAdditionalFields,
    ConsentCheckboxes,
    BonusCard,
  },
  computed: {
    ...mapState(['cart', 'domainProfiles', 'userStep', 'devUtils']),
    ...mapGetters([
      'isInRequiredFields',
      'billingProfileById',
      'loggedIn',
      'stepHasIssues',
    ]),
  },
})
export default class BillingProfile extends Vue {
  readonly cart!: Cart;
  readonly loggedIn!: boolean;
  readonly domainProfiles!: DomainProfileExtended[];
  readonly billingProfileById!: (id: any) => BillingProfileType;
  readonly isInRequiredFields!: (
    type: string,
    countryCode: string,
    field: string
  ) => boolean;

  createDomainProfile = false;
  useDifferentBillingProfile = false;
  step = STEPS.BILLING_PROFILE;
  path = 'cart.' + this.step;
  // selected billing profile in case of !isSignUp
  selectedBillingProfile: Partial<BillingProfileType> | null = null;
  // selected before edit
  originallySelected: Partial<BillingProfileType> | null = null;
  loading = false;
  emailsRules = propertyRules('address_emails');

  mounted() {
    this.emailsRules = propertyRules('address_emails');
  }
  companyCountry = config.companyCountry;
  isStandardWorkflow = isStandardWorkflow;
  isSwedishWorkflow = isSwedishWorkflow;
  // domainProfileAdditionalInfo is reused for all
  // scenarios, signUp, selecting or creating profile
  domainProfileAdditionalInfo: DomainProfileAdditionalInfoRequest = {};
  noAdditionalDpFields = false;

  newUser: Partial<SignUpUserRequest> = {
    personalInformation: {
      emails: [],
      // } as Partial<PersonalInformationRequest>,
    } as any,
    billingProfile: {
      type: 'personal',
      // } as Partial<BillingProfileRequest>,
    } as any,
    domainProfile: {
      type: 'personal',
    } as any,
  };

  domainProfileDifferentUser = {
    type: 'personal',
    idCard: null,
    countryCode: config.companyCountry,
  };

  required = required;
  emails = emails;
  minLength = minLength;
  maxLength = maxLength;
  uniqueLogin = uniqueLogin;
  validLogin = validLogin;

  get editMode() {
    return this.$store.state.inEditMode === this.step;
  }

  get bonusCardAvailable() {
    return (
      this.isSignUp &&
      config.company !== CompanyId.WEBSUPPORT_SE &&
      this.profileModel.type === 'personal'
    );
  }

  get showCreateDomainProfile(): boolean {
    return !!(
      this.isSignUp ||
      (this.cart?.domainProfileRequired &&
        this.selectedBillingProfile &&
        !(this.selectedBillingProfile as any)?.id)
    );
  }

  get billingProfile() {
    return this.isSignUp
      ? this.newUser.billingProfile
      : this.selectedBillingProfile || { type: 'personal' };
  }

  get profileIsEdited() {
    return this.isSignUp || !this.selectedBillingProfile?.id;
  }

  get sourceProfile() {
    return this.isSignUp ? {} : this.savedProfile;
  }

  get savedProfile() {
    if (this.isSignUp) {
      return null;
    }

    if (this.isSwedishWorkflow) {
      return normalizeDomainProfile(this.cart.domainProfile || null);
    } else {
      const savedBillingProfile =
        this.billingProfileById(this.selectedBillingProfile?.id) ||
        this.cart.billingProfile ||
        null;
      if (!savedBillingProfile) {
        return null;
      }
      const emails: string[] = savedBillingProfile.emails || [];
      return {
        ...savedBillingProfile,
        email: emails[0],
      };
    }
  }

  get signUpCountryCode() {
    return this.newUser?.billingProfile?.countryCode || config.companyCountry;
  }

  get profileModel() {
    return this.isSwedishWorkflow
      ? this.newUser.domainProfile
      : this.newUser.billingProfile;
  }

  set profileModel(value: any) {
    if (this.isSwedishWorkflow) {
      this.newUser.domainProfile = value;
    } else {
      this.newUser.billingProfile = value;
    }
  }

  get isSignUp() {
    return !this.loggedIn;
  }

  get userAddressConfig(): AddressConfig {
    const profileModel = this.profileModel || {};
    const profileType = get(profileModel, 'type', 'personal');
    const companyCountry = this.companyCountry;
    const createDomainProfile = this.createDomainProfile;
    const isSwedishWorkflow = this.isSwedishWorkflow;

    return {
      companyCountry,
      optional: {
        emails: !createDomainProfile,
        companyId:
          profileType === 'company' &&
          // always required for swedish workflow ref:
          // https://loopiagroup.atlassian.net/wiki/spaces/PROJ/pages/850034947/Profiles#User-profile
          ((!isSwedishWorkflow && !createDomainProfile) ||
            !this.isInRequiredFields(
              'company',
              profileModel.countryCode || companyCountry,
              'companyId'
            )),
        taxId:
          profileType === 'company' &&
          // for HU vatId is always required for billing profile (PNO-1340)
          this.signUpCountryCode !== 'HU' &&
          (!createDomainProfile ||
            !this.isInRequiredFields(
              'company',
              profileModel.countryCode || companyCountry,
              'taxId'
            )),
        vatId:
          (profileType === 'company' && !createDomainProfile) ||
          !this.isInRequiredFields(
            'company',
            profileModel.countryCode || companyCountry,
            'vatId'
          ),
      },
      oneOfThreeRuleEnabled: true,
      street2: isSwedishWorkflow,
    };
  }

  get differentBillingAddressConfig(): AddressConfig {
    const companyCountry = this.companyCountry;
    const profile = get(
      this.newUser,
      'billingProfile',
      {} as Partial<BillingProfileType>
    );

    return {
      companyCountry,
      optional: {
        companyId: true,
        taxId: true,
        vatId: true,
        name: profile.type === 'personal',
      },
      vatId: profile.countryCode === 'NO',
      phone: true,
      emails: true,
      street2: this.isSwedishWorkflow,
    };
  }

  @Watch('cart.billingProfile.emails.0')
  emailOfSelectedProfile(email: string = '') {
    this.domainProfileAdditionalInfo = {
      ...this.domainProfileAdditionalInfo,
      email: get(this.domainProfileAdditionalInfo, 'email', email),
    };
  }

  @Watch('editMode', { immediate: true })
  onEditModeChanged /* editMode: boolean */() {
    if (this.$refs.consents) {
      (this.$refs.consents as ConsentCheckboxes).reset();
    }

    // logic turned off because of simplification PNO-1406
    // kept for future later use (planned to get back to same logic)
    // if (editMode) {
    //   if (this.loggedIn) {
    //     this.$store
    //       .dispatch(StoreActions.ENSURE_DOMAIN_PROFILES)
    //       .then((profiles: DomainProfile[]) => {
    //         this.createDomainProfile = !profiles.length;
    //       });
    //   } else {
    //     this.createDomainProfile = true;
    //   }
    // }
    this.createDomainProfile = this.isSignUp;
  }

  created() {
    if (this.isSignUp && this.isSwedishWorkflow) {
      this.step = STEPS.DOMAIN_PROFILE;
      this.path = 'cart.' + this.step;
    }
  }

  clearMessages() {
    this.$messageService.clearMessages(
      [this.path, 'cart.summary.quick_order', 'general'],
      true
    );
  }

  submit() {
    if (this.loading) {
      return; // already submitted
    }
    this.$messageService.clearMessages([this.path, 'user', 'general'], true);
    if (this.isSignUp) {
      this.submitSignUp();
    } else {
      this.submitBillingProfile();
    }
  }

  // eslint-disable-next-line max-lines-per-function
  submitSignUp() {
    Promise.all([
      this.validateAdditionalFields() as any,
      validateForm(this.$refs.form as Element & QForm),
    ])
      // eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
      .then((results: [boolean, boolean]) => {
        if (
          !(this.$refs.consents as ConsentCheckboxes).validateCheckboxes() ||
          !results[0] ||
          !results[1]
        ) {
          return this.$messageService.scrollToError();
        }

        this.loading = true;
        const requestData = {
          ...cloneDeep(this.newUser),
          termsAndConditionsConsent: (this.$refs.consents as ConsentCheckboxes)
            .termsAndConditionsConsent,
          thirdPartyConsent: (this.$refs.consents as ConsentCheckboxes)
            .thirdPartyConsent,
        };

        if (this.isSwedishWorkflow) {
          // copy data from personal info to domain profile
          // for SE workflow PNO-1821
          set(
            requestData,
            'domainProfile.email',
            get(requestData, 'personalInformation.emails[0]')
          );
          set(
            requestData,
            'domainProfile.phone',
            get(requestData, 'personalInformation.phone')
          );
          if (this.useDifferentBillingProfile &&
              this.domainProfileDifferentUser.idCard) {
            set(
              requestData,
              'billingProfile.idCard',
              get(this.domainProfileDifferentUser, 'idCard')
            );
          }
          if (!this.useDifferentBillingProfile) {
            delete requestData.billingProfile;
          }
        } else {
          set(
            requestData,
            'billingProfile.emails',
            get(requestData, 'personalInformation.emails')
          );
        }

        requestData.domainProfileAdditionalInfo = {
          ...this.domainProfileAdditionalInfo,
          createDomainProfile: this.createDomainProfile,
        };

        cleanProfileByType(requestData.billingProfile);
        cleanProfileByType(requestData.domainProfile);

        signUp(requestData)
          .then((response: any) => {
            const data = response.data;

            if (data.domainProfile) {
              this.domainProfileCreationFail(data.domainProfile);
            }
            // set payment step so after return user goes to correct step
            // do not worry, if domain profile creation fails, it will go to
            // domain profile step automatically as domain profile
            // won't be set in cart
            setItem(
              StorageKeys.userStep,
              {
                step: STEPS.PAYMENT_METHOD,
                uuid: this.cart.uuid,
              },
              true
            );

            if (data.redirect) {
              const pinfo = get(this, 'newUser.personalInformation');

              if (pinfo) {
                // PNO-1348
                pinfo.login = '';
                this.$nextTick();
              }

              let redirectAfterLogin = setQueryParam(
                window.location.href,
                'lang',
                this.$i18n.locale.slice(0, 2) // two letter format
              );

              // fixes PNO-1806 (url step precedence)
              redirectAfterLogin = setQueryParam(
                redirectAfterLogin,
                'step',
                STEPS.PAYMENT_METHOD
              );

              const redirectUrl = setQueryParam(
                data.redirect,
                'redirectAfterLogin',
                redirectAfterLogin
              );

              // Commented out for now till we investigate other approaches
              // due to session cookies GTM fails during the order for new user
              // and never sends data.
              // this.submitTracking();

              this.$store.dispatch(StoreActions.SET_USER_STEP, {
                step: STEPS.PAYMENT_METHOD,
              });

              if (!this.$route.query.forceStay) {
                redirect(redirectUrl);
              }
            } else {
              this.loading = false;
            }
          })
          .catch((err: any) => {
            this.$messageService.errorHandler('user', {
              keyInterceptor: getVatTaxTKeyInterceptor(
                (requestData.billingProfile as unknown) as BillingProfileType
              ),
            })(err);
            this.loading = false;
          });
      })
      .catch(this.$messageService.errorHandler(this.path));
  }

  submitBillingProfile() {
    this.loading = true;
    // keeping here because selected got cleared by profileList during process
    const billingProfileId = this.selectedBillingProfile?.id;
    // TODO why cannot be typed to BillingProfileList?
    // (this.$refs.billingProfileList as BillingProfileList)
    (this.$refs.billingProfileList as any)
      .saveProfile()
      .then(() =>
        this.continueToDomainProfileCreation(
          billingProfileId || this.cart.billingProfile!.id
        )
      )
      .catch(
        this.$messageService.errorHandler(this.path, {
          keyInterceptor: getVatTaxTKeyInterceptor(
            (this.$refs.billingProfileList as any)?.billingProfile
          ),
        })
      )
      // fetchCart due to possible changes in cart due to billing profile change
      // also moved to finally, because of concurrency of creating domain profile
      // from billing vs fetching cart
      .finally(() => {
        this.loading = false;
        fetchCart().catch(
          this.$messageService.errorHandler('general', {
            unknownErrorKey: 'exception.cart.not_fetched',
          })
        );
      });
  }

  // Commented out for now till we investigate other approaches
  // due to session cookies GTM fails during the order for new user
  // and never sends data.
  //
  // submitTracking() {
  //   let emails: string[] = [];
  //   const form = this.$refs.form as QForm;
  //   const formId: string = form.$el.id;
  //   const textAreaSelector = 'textarea[name="emails"]';
  //   const emailInputId = document?.querySelector(textAreaSelector)?.id || '';
  //   const url = window.location.href;
  //
  //   if (this.newUser?.personalInformation?.emails !== undefined) {
  //     emails = this.newUser.personalInformation.emails;
  //   }
  //
  //   trackNewProfile() {
  //     formId,
  //     emailInputId,
  //     emails,
  //     url
  //   }
  // }

  get lastResortDpFromBp() {
    if (!this.billingProfile) {
      return null;
    }
    return domainFromBillingProfile(
      this.billingProfile as BillingProfileType,
      this.domainProfileAdditionalInfo
    );
  }

  continueToDomainProfileCreation(billingProfileId: number): Promise<any> {
    if (this.createDomainProfile) {
      const requestData: FromBillingProfileDomainProfileRequest = {
        ...this.domainProfileAdditionalInfo,
        billingProfileId,
      };
      return createDomainProfileFromBilling(requestData)
        .then(this.domainProfileCreationSucceed)
        .catch(this.domainProfileCreationFail);
    } else {
      this.mayGoNext();
      return Promise.resolve();
    }
  }

  domainProfileCreationSucceed(response: AxiosResponse) {
    const createdProfile = normalizeDomainProfile(
      response.data
    ) as DomainProfileExtended;
    this.$store.commit(StateMutations.ADD_PROFILE, {
      type: 'domain',
      profile: createdProfile,
    });
    this.saveDpAndGoToPayment(createdProfile);
    return Promise.resolve();
  }

  domainProfileCreationFail(error?: AxiosError) {
    // continue with save as we want to progress to next step "domain profile"
    // and show errors only if domain is required (billing is saved)
    if (this.cart.domainProfileRequired) {
      if (
        error?.response?.data?.key === DUPLICATE_ERROR_KEY ||
        (error?.response as any)?.key === DUPLICATE_ERROR_KEY
      ) {
        // handle duplicate profile, try to preselect domain profile
        return this.handleDuplicateProfile(
          get(error, 'response.data.context') ||
          get(error, 'response.context')
        );
      }

      this.handleErrorsInDomainStep(
        error?.response?.data?.data ||
          error?.response?.data ||
          this.lastResortDpFromBp,
        error
      );
    } else {
      // ignore errors when domain profile not required
      this.createDomainProfile = false;
      this.mayGoNext();
    }
    // resolve as errors are handled here and may be suppressed by design
    return Promise.resolve();
  }

  handleDuplicateProfile({
    domainProfile,
    duplicateId,
  }: {
    domainProfile: Partial<DomainProfileExtended>;
    duplicateId: number;
  }) {
    const found = find(this.domainProfiles, { id: duplicateId });
    let promise: Promise<any>;

    if (found) {
      promise = setDomainProfile({
        domainProfileId: found.id,
      });
      if (!this.isSignUp) {
        promise
          .then(() => {
            this.saveDpAndGoToPayment(found);
            return Promise.resolve();
          })
          .catch((error: any) => {
            this.handleErrorsInDomainStep(domainProfile, error);
            // resolve as errors are handled here and may be suppressed
            // by design
            return Promise.resolve();
          });
      }
    } else {
      this.handleErrorsInDomainStep(domainProfile, {
        translationKey: DUPLICATE_ERROR_KEY,
      });
      promise = Promise.resolve();
    }

    return promise;
  }

  handleErrorsInDomainStep(
    failedProfile: Partial<DomainProfileExtended>,
    error?: any
  ) {
    if (this.isSignUp) {
      setItem(StorageKeys.tmpDomainProfileData, {
        domainProfile: failedProfile,
        error,
        uuid: this.cart.uuid,
      });
    } else {
      this.$store.commit(StateMutations.SET_STATE, {
        prop: 'tmpDomainProfileData',
        value: {
          domainProfile: failedProfile,
          error,
        },
      });
      this.mayGoNext(STEPS.DOMAIN_PROFILE);
    }
  }

  // save domain profile to vuex and go to payment step
  saveDpAndGoToPayment(profile: Partial<DomainProfileExtended>) {
    this.$store.commit(StateMutations.SET_STATE, {
      path: 'cart',
      prop: 'domainProfile',
      value: profile,
    });
    if (isAfterCurrentStep(STEPS.PAYMENT_METHOD)) {
      // only set step if it is move forward
      this.$store.dispatch(StoreActions.SET_USER_STEP, {
        step: STEPS.PAYMENT_METHOD,
      });
    }
    this.mayGoNext();
  }

  validateAdditionalFields(): Promise<boolean> {
    return this.$refs.additionalFields
      ? (this.$refs
        .additionalFields as DomainProfileAdditionalFields).validateWithReject()
      : Promise.resolve(true);
  }

  private mayGoNext(forceEdit?: STEPS) {
    this.resetDomainProfileAdditionalInfo();
    mayGoNext(forceEdit);
  }

  resetDomainProfileAdditionalInfo() {
    this.domainProfileAdditionalInfo = {};
  }

  devFill() {
    (this as any).$wsDev?.fillSignupData?.(this);
  }
}
