import { Route } from 'vue-router';

import env from '@env';
import { get } from 'lodash-es';
import { isBrowser } from '@loopia-group/utils';
import { Domain } from '@/components/domain-checker/dc.types';
import {UATrackingIDs} from '@/services/const';
import {STEPS} from '@/services/const.enum';
import {store, ensureCartLoaded} from '@/store';
import { router } from '@/router';
import { AppState } from '@/store/const';
import config from '@/services/config.service';
import { CartItem } from '@/types/api-types.d';
import {logError} from '@/services/logger.service';
import {ROUTENAMES} from '@/const.enum';
import {useCartItemsStore, mapCategories} from '@/store/cartItemsStore';

const win: any = window;
let dataLayer = win.dataLayer || [];

// facebook pixel script load
if (
  !env.test &&
  env.isProductionBuild &&
  isBrowser &&
  config.facebookId &&
  document.cookie.includes('ws_privacy_policy_consent=1')
) {
  /* original script (kept for reference on updates):
    <!-- Facebook Pixel Code -->
    <script>
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '697765060292973');
      fbq('track', 'PageView');
    </script>
    <noscript><img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=697765060292973&ev=PageView&noscript=1"
    /></noscript>
    <!-- End Facebook Pixel Code -->
  */
  (function(f, b, e, v, n: any, t: any, s: any) {
    if (f.fbq) {return;}
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) {f._fbq = n;}
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window as any,
    document as any,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
    undefined,
    undefined,
    undefined
  );
  fbq('init', config.facebookId);
  fbq('track', 'PageView');
}

declare const fbq: any;
const tldRegexp = /\..*$/; // 'example.co.uk'.match(/\..*$/) -> '.co.uk', 'example.sk'.match(/\..*$/) -> '.sk'

// safely get fbq
function _fbq(...args: any) {
  if (typeof fbq !== 'undefined') {
    fbq.apply(fbq, Array.from(args));
  }
}

let eventDataHash = '';

/**
 * Google Universal Analytics setup
 */
export const universalAnalyticsId = () => {
  return !!config.companyCountry ? UATrackingIDs[config.companyCountry] : false;
};
export function initUniversalAnalytics(trackingID: string | boolean) {
  if (!!trackingID) {
    // because it's vanilla JS, it would cause a bunch of any type related errors/warnings
    /* eslint-disable */
    (function (i, s, o: "script", g, r: "ga", a?: HTMLScriptElement, m?: Element) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
          i[r] ||
          function () {
            (i[r].q = i[r].q || []).push(arguments);
          }
      ),
        (i[r].l = new Date().getTime());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = true;
      a.src = g;
      m ? m.parentNode?.insertBefore(a, m) : null;
    })(window, document, "script", "//www.google-analytics.com/analytics.js", "ga");

    ga('create', trackingID, 'auto');
    ga('send', 'pageview');
    /* eslint-enable */
  } else {
    logError(
      'Universal Analytics: Missing UA ID, probably config.companyCountry is undefined',
      trackingID
    );
  }
}

// Safely set ga
declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    ga:any;
    GoogleAnalyticsObject:any;
  }
}
const ga = (...args: (any)) =>  window.ga(...args);

function prepareDataLayer() {
  if (!win.dataLayer) {
    win.dataLayer = dataLayer;
  } else {
    [].push.apply(win.dataLayer, dataLayer);
    dataLayer = win.dataLayer;
  }
}

function getItemsGA() {
  const itemsStore = useCartItemsStore();
  return itemsStore.itemsGA;
}

export function initAnalytics() {
  prepareDataLayer();
  const itemsGA = getItemsGA();

  // ref: https://matteo-gabriele.gitbook.io/vue-gtag/methods/events
  router.afterEach((to: Route, from: Route) => {
    // https://loopiagroup.atlassian.net/browse/DPF-1202
    if (from.name === ROUTENAMES.CART && to.name === ROUTENAMES.ORDER) {
      dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          items: [...itemsGA]
        },
      });
    }

    const eventData = {
      event: 'page_view',
      page_type:
        ({
          [ROUTENAMES.CART]: 'cart',
          [ROUTENAMES.DOMAIN_CHECKER]: 'domaincheck',
        } as any)[to.name || ''] || to.name,
      login_status: !!store.state.cart?.user,
    };

    const newEventDataHash = JSON.stringify(eventData);
    if (newEventDataHash !== eventDataHash) {
      eventDataHash = newEventDataHash;
      dataLayer.push(eventData);
    }
  });

  store.watch(
    (state: AppState) => {
      return state.userStep;
    },
    (step: STEPS) => {
      const trackingSteps: STEPS[] = [
        STEPS.CART,
        STEPS.BILLING_PROFILE,
        STEPS.RECAPITULATION,
      ];
      const index = trackingSteps.indexOf(step);
      if (index > -1) {
        trackStep(index + 1);
      }
      if (step === STEPS.PAYMENT_METHOD || step === STEPS.RECAPITULATION) {
        trackPageViewFromStep(step);
      }
    },
    { immediate: true }
  );

  router.afterEach(() => {
    _fbq('track', 'PageView');
  });
}

export function trackStep(step: number) {
  // ref: https://matteo-gabriele.gitbook.io/vue-gtag/methods/events
  dataLayer.push({
    event: 'checkout',
    ecommerce: {
      currencyCode: config.currency,
      checkout: {
        actionField: { step },
        products: store.getters.itemsGaee,
      },
    },
  });
}

export function trackPageViewFromStep(step: STEPS) {
  ensureCartLoaded(() => {
    // ensureCartLoaded to get updated info about user is logged or not
    // https://loopiagroup.atlassian.net/browse/DPF-1202?focusedCommentId=151628
    dataLayer.push({
      event: 'page_view',
      page_type:
        ({
          [STEPS.PAYMENT_METHOD]: 'payment',
          [STEPS.RECAPITULATION]: 'checkout',
        } as any)[step] || step,
      login_status: !!store.state.cart?.user,
    });
  });
}

export function trackPaymentMethod(paymentOptionGroupID: string) {
  const itemsGA = getItemsGA();

  dataLayer.push({
    event: 'add_payment_info',
    ecommerce: {
      payment_type: paymentOptionGroupID === 'card' ? 'automatic' : 'manual',
      items: [...itemsGA]
    },
  });
}

export function trackPurchase(checkoutData: any) {
  const getters = store.getters;

  /*
   ref:
   https://git.websupport.sk/devel/web/-/blob/master/protected/modules/cart/views/checkout/_partials/orderTracking.php
  */
  _fbq('track', 'Purchase', {
    content_ids: getters.productIds,
    content_type: 'product',
    value: getters.cartPrice,
    currency: config.currency,
  });

  // Init Universal Analytics
  initUniversalAnalytics(universalAnalyticsId());

  // https://loopiagroup.atlassian.net/browse/DPF-1202
  getters.cartItems.forEach((item: CartItem) => {
    // GTM dataLayer per item purchase
    dataLayer.push({
      event: 'purchase_item',
      item: item.gaee?.name,
      transaction_id: checkoutData.orderId,
      tax: getters.vatValue(item.price?.vatExcluded || 0),
      currency: config.currency,
      value: item.price?.vatExcluded,
      coupon: item.coupon?.code,
      product_service_name: item.properties?.name || item.properties?.domain,
      product_id: item.gaee?.id,
      customer_type: store.state.cart.user?.customerType,
    });

    // Google UA per item transaction
    ga('ecommerce:addItem', {
      id: checkoutData.orderId,
      name: item.gaee?.name,
      sku: item.gaee?.id,
      category: item.gaee?.categories[0],
      price: item.price?.vatExcluded,
      quantity: item.gaee?.quantity
    });
  }
  );

  const itemsGA = getItemsGA();

  // GA4 format
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      currency: config.currency,
      value: getters.cartPrice,
      transaction_id: checkoutData.orderId,
      customer_type: store.state.cart.user?.customerType,
      items: [...itemsGA]
    },
  });

  // Google UA purchase event
  ga('ecommerce:addTransaction', {
    id: checkoutData.orderId,
    revenue: getters.cartPrice,
    tax: getters.vatValue(getters.cartPrice).toFixed(2)
  });

  // Only send Google UA data when in production
  if(env.isProductionBuild) {
    ga('ecommerce:send');
  }
}

export function trackInitiateCheckout() {
  // ref: https://git.websupport.sk/devel/web/-/blob/master/protected/modules/cart/views/checkout/index.php
  _fbq('track', 'InitiateCheckout');
}

export function trackSearch({ query }: { query: string }) {
  /*
   ref:
   https://git.websupport.sk/devel/web/-/blob/master/protected/modules/cart/views/checkout/default/domainSearch.php
  */
  _fbq('track', 'Search', {
    search_string: query,
    content_ids: store.getters.productIds,
    content_type: get(query.match(tldRegexp), '[0]'),
  });
}

export async function trackAddToCart(product: any) {
  // https://loopiagroup.atlassian.net/browse/DPF-1202
  const [item] = product;
  const categories = mapCategories(item.gaee?.categories);

  if(item.hasOwnProperty('gaee')) {
    dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        items: [{
          item_name: item.gaee?.name ?? item.name,
          item_id: item.gaee?.id ?? item.code,
          price: item.gaee?.price ?? item.price.vatExcluded,
          item_brand: item.gaee?.brand ?? 'Websupport',
          ...categories,
          quantity: item.gaee?.quantity ?? 1,
        }]
      },
    });
  }
  else {
    logError('add_to_cart GA event failed: gaee prop missing', item);
  }
}

export async function trackRemoveFromCart(item: CartItem) {
  const categories = mapCategories(item.gaee?.categories);

  // https://loopiagroup.atlassian.net/browse/DPF-1202
  dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      items: [{
        item_name: item.gaee.name,
        item_id: item.gaee.id,
        price: item.gaee.price,
        item_brand: item.gaee.brand,
        ...categories,
        quantity: item.gaee.quantity,
      }],
    },
  });
}

export function trackSearchResult({
  query,
  result,
}: {
  query: string;
  result: Domain;
}) {
  // https://loopiagroup.atlassian.net/browse/DPF-1202
  let status: string;
  if (query !== result.name) {
    status = 'no-tld';
  } else if (result.availableForPurchase) {
    status = 'available';
  } else {
    status = 'not_available';
  }

  dataLayer.push({
    event: 'domain_check',
    currency: config.currency,
    product_service_name: query,
    price: result.price?.actualPrice || 0,
    status,
  });
}

// Commented out for now till we investigate other approaches
// due to session cookies GTM fails during the order for new user
// and never sends data.
//
// export function trackNewProfile(
//   formId: string,
//   emailInputId: string,
//   emails: string[],
//   url: string,
// ) :void {
//   dataLayer.push({
//     event: 'form_submit',
//     eventModel: {
//       form_id: formId,
//       form_destination: url,
//       email_id: emailInputId,
//       emails: emails,
//     },
//   });
// };
