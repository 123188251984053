
import {Component, Vue, Prop} from 'vue-property-decorator';
import {QBtn} from 'quasar';
import {config, Theme} from '@loopia-group/services';
import WsTooltip from './WsTooltip.vue';
import WsIcon from './WsIcon.vue';
import axios, {Method} from 'axios';
import {responseAction} from '../services/reponseAction.service';
import type { ActionOptions } from '../services/responseAction.service';
import WsSpinner from './WsSpinner.vue';

@Component({
  components: {
    WsSpinner,
    QBtn,
    WsIcon,
    WsTooltip,
  },
})
export class WsButton extends Vue {
  @Prop(String) readonly label!: string;
  //  Styles
  @Prop(Array) readonly buttonClasses!: string[];
  @Prop(Boolean) readonly primary!: boolean;
  @Prop(Boolean) readonly secondary!: boolean;
  @Prop(Boolean) readonly ghost!: boolean;
  @Prop(Boolean) readonly light!: boolean;
  @Prop(Boolean) readonly flat!: boolean;
  @Prop(Boolean) readonly small!: boolean;
  @Prop(Boolean) readonly smaller!: boolean;
  @Prop(Boolean) readonly forcedDesktop!: boolean; // forced desktop font-size/line-height for every screen size
  @Prop(Boolean) readonly dark!: boolean; // dark mode
  @Prop(Boolean) readonly usePaddings!: boolean; // force to use paddings for button text if needed
  @Prop() readonly loaderColor!: string;
  @Prop({default: null}) readonly icon!: string;
  @Prop(String) readonly iconColor?: string;
  @Prop(Boolean) readonly iconRight!: boolean; // icon on right side oflabel
  @Prop() readonly iconSize!: number;
  @Prop() readonly darkenOnHover!: boolean;
  @Prop(Boolean) readonly loading!: boolean;

  readonly desktop!: boolean;
  readonly tablet!: boolean;
  readonly mobile!: boolean;

  //  Tooltip
  @Prop(String) readonly tooltip!: string;
  @Prop({type: String, default: 'top middle'})
  readonly tooltipAnchor!: string;
  @Prop({type: String, default: 'bottom middle'})
  readonly tooltipSelf!: string;
  @Prop({type: String, default: 'ws-tooltip ws-arrow-bottom'})
  readonly tooltipClass!: string;

  // Ajax
  @Prop({type: Boolean, default: false})
  readonly ajax!: boolean;
  @Prop(String) readonly ajaxMethod!: Method;
  @Prop(String) readonly url!: string;
  @Prop([Object, Array]) readonly ajaxParams!: any;
  @Prop(Object) readonly onSuccess!: ActionOptions;

  config = config;
  Theme = Theme;
  ajaxLoading = false;

  async buttonAjaxCall() {
    this.ajaxLoading = true;

    try {
      const {data, headers} = await axios({
        url: this.url,
        data: this.ajaxParams,
        method: this.ajaxMethod,
        headers: this.requestHeaders
      });

      const actionResponse = await responseAction(data, headers, this.onSuccess, this);

      this.ajaxLoading = await actionResponse.actionPromise as boolean;
    } catch (error) {
      this.$root.$messageService.errorHandler()(error);
      this.ajaxLoading = false;
    }
  }

  get requestHeaders() {
    const headers: any = {};

    // @ts-ignore
    if (!this.onSuccess?.reload && !this.onSuccess?.redirect) {
      headers['X-WITH-FLASH-MESSAGES'] = '1';
    }

    return headers;
  }

  get loaderColorResolved() {
    if (this.loaderColor) {
      return this.loaderColor;
    } else if (this.primary) {
      return 'dark';
    } else if (this.secondary) {
      return 'white';
    }
    return undefined; // default
  }

  get useThemeSpinner() {
    return config.themeOptions?.spinner;
  }

  get slots() {
    const slots = {...this.$scopedSlots};
    // all but default, because default is handled differently
    delete slots.default;
    if (config.themeOptions?.spinner) {
      delete slots.loading;
    }
    return slots;
  }

  // I don't see how breaking this into multiple functions would help
  // eslint-disable-next-line sonarjs/cognitive-complexity
  get iconSizeCalc() {
    // CAUTION! icon height affects button height!
    let defaultSize = config.themeOptions?.iconSize;

    if (!defaultSize && config.themeOptions?.btnDynamicIconSize) {
      defaultSize =
        (this.forcedDesktop && 24) ||
        (this.desktop && 24) ||
        (this.tablet && 20) ||
        (this.mobile && 16.8);
    } else {
      defaultSize = defaultSize || 22;
    }

    return this.iconSize || (this.flat && this.small ? 14 : defaultSize);
  }

  get iconOnly() {
    return this.icon && !this.$slots.default && !this.label;
  }

  get iconAndContent() {
    return this.icon && (this.$slots.default || this.label);
  }
}

export default WsButton;
