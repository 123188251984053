
import { Component, Vue } from 'vue-property-decorator';
import config from '@/services/config.service';
import xss from 'xss';

@Component({})
export class ThirdPartyInfo extends Vue {
  xss = xss;
  get selectedLinks() {
    switch (config.companyCountry) {
    case 'SE':
      return ['se', 'nu', 'eu', 'pl', 'uk', 'icann'];
    case 'SK':
      return ['sk', 'cz', 'hu', 'at', 'eu', 'rest', 'ssl'];
    case 'CZ':
      return ['cz', 'sk', 'hu', 'at', 'eu', 'rest', 'ssl'];
    case 'HU':
      return ['cz', 'sk', 'hu', 'at', 'eu', 'rest', 'ssl'];
    default:
      return [
        'se',
        'nu',
        'eu',
        'uk',
        'pl',
        'sk',
        'cz',
        'hu',
        'at',
        'eu',
        'rest',
        'ssl',
      ];
    }
  }
}

export default ThirdPartyInfo;
