import VueI18n from 'vue-i18n';
import { throttle, get } from 'lodash-es';

import { i18nService } from '@WS_UIkit';
import { ValidatorAsync, ValidationAsync } from '@WS_UIkit/types';
import { LoginCheckResponse } from '@/types';
import { checkLogin } from './user-api.service';
import { serverNameAvailable } from './scf-api.service';
import { UniqueGeneralOptions } from './validation.service.d';

let i18n: VueI18n;

i18nService.afterInit.then(() => {
  i18n = i18nService.i18n;
});


export const uniqueLogin: ValidatorAsync = (login: string) => {
  return uniqueGeneral<LoginCheckResponse>({
    requestMethod: checkLogin,
    requestData: login,
    availabilityProp: 'free',
    negativeKey: 'validation.login.unique',
    validationKey: 'validation.login',
  });
};

export const uniqueServerName: ValidatorAsync = (serverName: string) => {
  return uniqueGeneral<any>({
    requestMethod: serverNameAvailable,
    requestData: serverName,
    availabilityProp: 'available',
    negativeKey: 'validation.server_name.unique',
    validationKey: 'validation.server_name',
  });
};

export function uniqueGeneral<ResponseDataType>(
  options: UniqueGeneralOptions
): ValidationAsync {
  if (!options.requestData || !options.requestMethod) {
    return Promise.resolve(true);
  }
  const reqMethodThrottled: any = throttle(options.requestMethod, 1000, {
    leading: true,
    trailing: true,
  });
  return new Promise(resolve => {
    reqMethodThrottled(options.requestData!)
      .then((data: ResponseDataType) =>
        resolve(
          (data as any)[options.availabilityProp]
            ? true
            : (i18n.t(options.negativeKey) as string)
        )
      )
      .catch((e: any) => {
        let message = get(
          e,
          'response.data.violations[0].errors[0].translationKey'
        );
        message = message ? '.' + message : '';
        resolve(i18n.t(options.validationKey + message) as string);
      });
  });
}
