import {AxiosError} from 'axios';
import {
  BillingProfile,
  Cart,
  CartItem,
  DomainProfile,
  DomainProfileExtended,
  HostingTutorialStandaloneProduct,
  PaymentOption,
  PaymentOptionGroup,
  RequiredDpFieldsGroup,
  User,
} from '@/types';
import {STEPS} from '@/services/const.enum';
import {Domain} from '@/components/domain-checker/dc.types';
import {CartServiceStore} from '@/services/cart/cart.store.d';
import {SidePanels} from '@/store/const.enum';

export interface CartExtended extends Cart {
  initial?: true;
}

export interface CartItemInfo {
  price?: CartItem['price'];
  prices?: {
    period: number;
    actualPrice: number;
    marketingPrice: number;
  }[];
  period?: number;
  // optional translations
  [key: string]: any; // TODO: I would like to restrict rest of props to string
}

export interface HostingTutorialState {
  products: HostingTutorialStandaloneProduct[];
  rememberedConfigurations: Record<string, object>;
}

export interface DomainCheckerState {
  requestedDomains: string[];
  domains: Domain[];
  bundles: Object[];
  previouslySearchedDomain: string

  searching: boolean;
  endOfStream: boolean;

  cartItemsLoading: string[];
}

export interface DomainCheckerCategory {
  translationKey: string;
  name: string;
}

export interface TransferPaymentData {
  options?: PaymentOption[];
  price?: number;
}

export interface UserMetaData {
  animationsReduced: boolean;
}

export interface HostingTutorial {
  products: Array<HostingTutorialStandaloneProduct>;
  rememberedConfigurations: Record<string, object>;
}


export interface AppState {
  cart: CartExtended;
  cartService: CartServiceStore;
  ui: {
    sidePanel: SidePanels;
  };
  hostingTutorial: HostingTutorial;
  mandatorySettingsHasIssue: boolean; // TODO: temporary handles backend error
  userStep: STEPS; // user farest step he achieved
  inEditMode: STEPS | null; // user actual step editing
  billingProfiles?: BillingProfile[];
  domainProfiles?: DomainProfileExtended[];
  paymentOptions?: PaymentOptionGroup[];
  transferPaymentData?: TransferPaymentData;
  requiredDpFields?: RequiredDpFieldsGroup; // required Domain Profile fields
  useDifferentBillingProfile: boolean; // relevant only for workflow 2
  devUtils: boolean;
  tmpDomainProfileData?: { domainProfile: DomainProfile; error: AxiosError };
  checkoutInProgress: boolean;
  quickOrderInProgress: boolean;
  userProfile?: User;
  userMetaData?: UserMetaData;
  domainChecker: DomainCheckerState;
  domainCheckerCategories: Array<DomainCheckerCategory>;
  invoiceId?: string;
  userDomains: string[];
}

// steps named states
export const COMPLETED = 100; // 100%
export const UNTOUCHED = 0; // 0%

export interface ModifyStatePayload {
  path?: string; // path to object prop belongs to, if not defined -> handled as root path
  prop?: string; // property name to be modified
}
export interface RemoveStatePayload extends ModifyStatePayload {
  prop: string; // makes prop required
}
export interface SetStatePayload extends ModifyStatePayload {
  value: any; // value to be set at the property
  // persistent:  whether save value to local storage and reload to store on every
  // next page loaded, until local sotrage is cleared
  persistent: boolean;
}

export interface ChangeBillingProfilePayload {
  type: 'billing';
  profile: BillingProfile;
  upsert?: boolean;
}
export interface ChangeDomainProfilePayload {
  type: 'domain';
  profile: DomainProfile;
  upsert?: boolean;
}
export type ChangeProfilePayload =
  | ChangeBillingProfilePayload
  | ChangeDomainProfilePayload;

export interface SetRequiredFieldsPayload {
  tlds: string[];
  profileCountry: string;
  requiredFields: RequiredDpFieldsGroup;
}
