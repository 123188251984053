import Vue from 'vue';


/*
  dynamic device info see docs for reasons:
  https://loopiagroup.atlassian.net/wiki/spaces/ITD/pages/2053439502/Distinguish+mobile+vs+touchable+devices

  USAGE:
  you can make mixin in project (global/local..), or import this objec directly in component
  mixin might look like:

    deviceInfo.init(); // DO NOT FORGET TO INIT FEATURE WHEN APP IS READY

    Vue.mixin({
      data: function() {
        return {
          wsDeviceInfo: deviceInfo,
        };
      },
      methods: { get },
    });
*/
export const deviceInfo = Vue.observable({
  touchInUse: 'ontouchstart' in window || window.navigator.maxTouchPoints > 0,
  init() {
    let deactivatedMouse = false;
    let timeOut: any = null;
    document.body.addEventListener(
      'mouseover',
      () => (this.touchInUse = deactivatedMouse)
    );
    document.body.addEventListener('touchstart', () => {
      this.touchInUse = true;
      deactivatedMouse = true;

      if (timeOut) {
        clearTimeout(timeOut);
      }

      // deactivating time for 1sec because touch devices fire mouse events right
      // after touch events to support backward compatibility
      timeOut = setTimeout(() => {
        deactivatedMouse = false;
      }, 1000);
    });
  },
});
