export enum UI_VARIANT {
  DEFAULT = 'gray',
  NEUTRAL = 'neutral',
  ALERT = 'alert',
  SUCCESS = 'success',
}

export enum UI_SIZE {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum UI_SPACING {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  XL2 = '2xl',
  XL3 = '3xl',
  XL4 = '4xl',
}
