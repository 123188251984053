import { AxiosPromise, AxiosResponse } from 'axios';
import network from './network.service';

/*
  ======================================================
 */

export function getServerInfo(uuid: string): AxiosPromise {
  return network.get('/server-contact-form/dedicated-servers/' + uuid);
}

export function getMonitoringInfo(): AxiosPromise {
  return network.get('/server-contact-form/monitoring');
}

export function getManagementInfo(): AxiosPromise {
  return network.get('/server-contact-form/management');
}

export function getServerOptions(): AxiosPromise {
  return network.get('/server-contact-form/dedicated-server-options');
}

export function getMonitoringOptions(): AxiosPromise {
  return network.get('/server-contact-form/monitoring-options');
}

export function orderServer(requestData: any): AxiosPromise {
  return network.post('/server-contact-form', requestData);
}

export function orderManagement(requestData: any): AxiosPromise {
  return network.post('/server-contact-form/management-forms', requestData);
}

export function orderMonitoring(requestData: any): AxiosPromise {
  return network.post('/server-contact-form/monitoring-forms', requestData);
}

export function serverNameAvailable(name: string): AxiosPromise {
  return network
    .post('/server-contact-form/availability/dedicated-server-name', {
      name,
    })
    .then((response: AxiosResponse) => response.data);
}
