var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ws-wrapper',{staticClass:"ws-button-wrapper",attrs:{"wrap":!!_vm.tooltip,"element":"span"}},[_c('q-btn',_vm._g(_vm._b({ref:"qBtn",staticClass:"ws-button",class:[
      ...(_vm.buttonClasses ? _vm.buttonClasses : []),
      {
        primary: _vm.primary,
        secondary: _vm.secondary,
        ghost: _vm.ghost,
        light: _vm.light,
        small: _vm.small,
        smaller: _vm.smaller,
        dark: _vm.dark,
        'darken-on-hover': _vm.darkenOnHover,
        'is-loading': _vm.loading || _vm.ajaxLoading,
        'use-paddings': _vm.usePaddings,
        'icon-only': _vm.iconOnly,
        'icon-right': _vm.iconRight,
      }
    ],attrs:{"unelevated":"","loading":_vm.ajax ? _vm.ajaxLoading : _vm.loading,"flat":_vm.flat,"ripple":_vm.isTheme(_vm.Theme.ADMIN)},scopedSlots:_vm._u([_vm._l((_vm.slots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,scope)]}}}),(_vm.useThemeSpinner)?{key:"loading",fn:function(){return [_c('ws-spinner',{attrs:{"color":_vm.loaderColorResolved}})]},proxy:true}:null],null,true)},'q-btn',_vm.$attrs,false),!_vm.ajax ? _vm.$listeners : { click: _vm.buttonAjaxCall }),[(_vm.icon && !_vm.iconRight)?_c('ws-icon',{staticClass:"ws-button-icon",class:`ws-button-icon--${_vm.icon}`,attrs:{"color":_vm.iconColor,"name":_vm.icon,"size":_vm.iconSizeCalc}}):_vm._e(),(_vm.$slots.default || _vm.label)?_c('div',{staticClass:"slot-content",class:{
        'q-pl-sm': _vm.iconAndContent && !_vm.iconRight,
        'q-pr-sm': _vm.iconAndContent && _vm.iconRight,
        'ws-typo-button-desktop': _vm.forcedDesktop,
      }},[_vm._t("default"),(_vm.label)?_c('span',{class:{ 'ws-typo-button-desktop': _vm.forcedDesktop }},[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e()],2):_vm._e(),(_vm.icon && _vm.iconRight)?_c('ws-icon',{staticClass:"ws-button-icon",class:`ws-button-icon--${_vm.icon}`,attrs:{"name":_vm.icon,"size":_vm.iconSizeCalc}}):_vm._e()],1),(_vm.tooltip)?_c('ws-tooltip',{attrs:{"content-class":_vm.tooltipClass,"anchor":_vm.tooltipAnchor,"self":_vm.tooltipSelf,"delay":200}},[_vm._v(" "+_vm._s(_vm.$t(_vm.tooltip))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }