
import {QIcon} from 'quasar';
import {mdiCheck} from '@quasar/extras/mdi-v4';
import WsIcon from './WsIcon.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WsBadge',
  components:{
    WsIcon,
    QIcon,
  },
  props: {
    check: Boolean,
    tertiary: Boolean,
    icon: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {mdiCheck};
  }
});
