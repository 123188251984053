/* eslint-disable */


function EventQueue() {
  this.q = [];
  this.add = function(ev) {
    this.q.push(ev);
  };
  this.call = function() {
    for (const el of this.q) {
      el.call();
    }
  };
}
/**
 * @param {HTMLElement} element
 * @param {String}      prop
 * @returns {String|Number}
 */
function getComputedStyle(element, prop) {
  if (element.currentStyle) {
    return element.currentStyle[prop];
  } else if (window.getComputedStyle) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
  }
  return element.style[prop];
}
/**
 *
 * @param {HTMLElement} element
 * @param {Function}    resized
 */
function attachResizeEvent(element, resized) {
  if (!element.resizedAttached) {
    element.resizedAttached = new EventQueue();
    element.resizedAttached.add(resized);
  } else if (element.resizedAttached) {
    element.resizedAttached.add(resized);
    return;
  }
  element.resizeSensor = document.createElement('div');
  element.resizeSensor.className = 'resize-sensor';
  var style =
    'position: absolute; left: 0; top: 0; right: 0; bottom: 0; overflow: hidden; z-index: -1; visibility: hidden; opacity: 0;direction: ltr;';
  var styleChild = 'position: absolute; left: 0; top: 0; transition: 0s;';
  element.resizeSensor.style.cssText = style;
  element.resizeSensor.innerHTML =
    `<div class="resize-sensor-expand" style="${style}">` +
      `<div style="${styleChild}"></div>` +
    '</div>' +
    `<div class="resize-sensor-shrink" style="${style}">` +
      `<div style="${styleChild}' width: 200%; height: 200%"></div>`+
    '</div>';
  element.appendChild(element.resizeSensor);
  if (getComputedStyle(element, 'position') == 'static') {
    element.style.position = 'relative';
  }
  var expand = element.resizeSensor.childNodes[0];
  var expandChild = expand.childNodes[0];
  var shrink = element.resizeSensor.childNodes[1];
  var reset = function() {
    expandChild.style.width = 100000 + 'px';
    expandChild.style.height = 100000 + 'px';
    expand.scrollLeft = 100000;
    expand.scrollTop = 100000;
    shrink.scrollLeft = 100000;
    shrink.scrollTop = 100000;
  };
  reset();
  var dirty = false;
  var dirtyChecking = function() {
    if (!element.resizedAttached) return;
    if (dirty) {
      element.resizedAttached.call();
      dirty = false;
    }
    window.requestAnimationFrame(dirtyChecking);
  };
  window.requestAnimationFrame(dirtyChecking);
  var lastWidth, lastHeight;
  var cachedWidth, cachedHeight;
  //useful to not query offsetWidth twice
  var onScroll = function() {
    if (
      (cachedWidth = element.offsetWidth) != lastWidth ||
      (cachedHeight = element.offsetHeight) != lastHeight
    ) {
      dirty = true;
      lastWidth = cachedWidth;
      lastHeight = cachedHeight;
    }
    reset();
  };
  var addEvent = function(el, name, cb) {
    if (el.attachEvent) {
      el.attachEvent('on' + name, cb);
    } else {
      el.addEventListener(name, cb);
    }
  };
  addEvent(expand, 'scroll', onScroll);
  addEvent(shrink, 'scroll', onScroll);
}
/**
 * Class for dimension change detection.
 *
 * @param {Element} element
 * @param {Function} callback
 *
 * @constructor
 */
var ResizeSensor = function(element, callback) {
  attachResizeEvent(element, callback);
};
ResizeSensor.prototype.detach = function() {
  var element = this._element;
  ResizeSensor.detach(element);
};
ResizeSensor.detach = function(element) {
  if (element.resizeSensor) {
    element.removeChild(element.resizeSensor);
    delete element.resizeSensor;
    delete element.resizedAttached;
  }
};

export default ResizeSensor;
