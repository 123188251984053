import Vue from 'vue';
import {Screen} from 'quasar';
import type { QVueGlobals } from 'quasar';

import {get} from 'lodash-es';
import {deviceInfo} from './services/deviceinfo.service';
import type {Theme} from '@loopia-group/services';

const wsDev: any /* singleton */ = {
  /*
    will be filled after loading developer utils
  */
};

Vue.prototype.$wsDev = wsDev;

// globaly available mixins
Vue.mixin({
  data: function () {
    return {
      deviceInfo,
    };
  },
  computed: {
    mobile(): boolean {
      return Screen.sizes.md > (this.$q as QVueGlobals)?.screen.width;
    },
    tablet(): boolean {
      const screenWidth = (this.$q as QVueGlobals)?.screen.width;
      return Screen.sizes.md <= screenWidth && Screen.sizes.lg > screenWidth;
    },
    desktop(): boolean {
      return Screen.sizes.lg <= (this.$q as QVueGlobals)?.screen.width;
    },
  },
  methods: {
    get,
    isTheme(theme: Theme) {
      // @ts-ignore
      return this.$root.isTheme(theme) ?? this.$store?.getters?.['theme/currentTheme'] === theme;
    },
    currentTheme () {
      // @ts-ignore
      return this.$store?.getters?.['theme/currentTheme'];
    }
  },
});
