export enum STEPS {
  CART = 'cart',
  BILLING_PROFILE = 'billing-profile',
  DOMAIN_PROFILE = 'domain-profile',
  PAYMENT_METHOD = 'payment-method',
  SERVICE_SETTINGS = 'service-settings',
  RECAPITULATION = 'recapitulation',
}

export enum HostingTutorialStepTypes {
  Default,
  List,
}

export enum CartItemCode {
  DOMAIN_REGISTER = 'domain-register',
  DOMAIN_TRANSFER = 'domain-transfer',
}
