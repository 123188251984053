var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.cart.items.length)?_c('sidebar-layout',{staticClass:"view-cart ws-typography",class:{
    'q-pt-2xl q-pb-3xl': _vm.desktop,
    'q-pt-md q-pb-2xl': _vm.tablet,
    'q-pt-md': _vm.mobile,
  },scopedSlots:_vm._u([{key:"side",fn:function(){return [_c('side-summary',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('coupon-list',{staticClass:"q-mb-sm",class:{
              'q-mt-2xl': _vm.desktop,
              'q-mt-md': _vm.tablet,
              'q-mt-sm': _vm.mobile,
            }})],1),_c('quick-order-button',{staticClass:"q-mt-sm"})]},proxy:true}],null,false,981561787)})]},proxy:true}],null,false,2579015875)},[_c('cart-items',{attrs:{"xsell":"","bordered":"","settings-icon-visible":""}}),(!_vm.quickOrderInProgress)?_c('ws-message',{staticClass:"error q-mt-md",attrs:{"path":"general"}}):_vm._e(),(_vm.isStandardWorkflow || _vm.cart.migrationPromotion.applicable)?_c('div',{staticClass:"row extra-settings",class:{
      'q-mb-sm': _vm.cart.migrationPromotion.applicable,
      'q-mt-3xl': _vm.desktop,
      'q-mt-xl': _vm.tablet,
      'q-mt-lg': _vm.mobile,
    }},[_c('div',{staticClass:"col"},[_c('h6',{class:_vm.mobile ? 'q-mb-md' : 'q-mb-lg'},[_vm._v(" "+_vm._s(_vm.$t('cart.extra_settings.title'))+" "),_c('ws-optional',{staticClass:"text-normal"})],1),(_vm.isStandardWorkflow)?_c('ws-checkbox',{attrs:{"label":_vm.$t('cart.extra_settings.note.label')},model:{value:(_vm.noteReveal),callback:function ($$v) {_vm.noteReveal=$$v},expression:"noteReveal"}}):_vm._e(),_c('transition',{attrs:{"name":"slide-fade-vertical"}},[(_vm.noteReveal && _vm.isStandardWorkflow)?_c('div',{staticClass:"row q-pl-lg q-py-sm"},[_c('div',{staticClass:"col"},[_c('ws-input',{staticClass:"ws-textarea",attrs:{"type":"textarea","autogrow":"","placeholder":_vm.$t('cart.extra_settings.note.text'),"debounce":"300","required":""},on:{"input":_vm.updateNote},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}}),_c('ws-message',{attrs:{"type":"alert","path":"note"}})],1)]):_vm._e()])],1)]):_vm._e(),(_vm.cart.migrationPromotion.applicable)?_c('migration-upload'):_vm._e()],1):_c('empty-cart')
}
var staticRenderFns = []

export { render, staticRenderFns }