var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-form',{ref:"form",staticClass:"cmp-domain-profile ws-typography",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)}}},[_c('div',[_c('div',{staticClass:"title-section",class:[_vm.desktop ? 'q-mb-2xl' : 'q-mb-xl']},[_c('h5',[_vm._v(_vm._s(_vm.title))]),_c('small',[_vm._v(" "+_vm._s(_vm.$t(_vm.isSwedishWorkflow ? 'user_info.intro' : 'domain_profile.intro'))+" ")])]),(_vm.allDomainProfilesDefined && !_vm.stepHasIssues(_vm.step))?[_c('ws-message',{staticClass:"domain-profile-specified-message",attrs:{"banner":"","type":"gray","icon":"info","messages":"domain_profile.all_domain_profiles_defined.explicit"}}),_c('q-tooltip',{attrs:{"target":".domain-profile-specified-message","content-class":"ws-tooltip ws-arrow-bottom","anchor":"top left","self":"bottom left"}},[_vm._v(" "+_vm._s(_vm.$t('domain_profile.all_domain_profiles_defined.tooltip'))+" ")])]:[(_vm.stepHasIssues(_vm.step))?_c('ws-message',{class:{ 'q-mb-lg': _vm.editMode },attrs:{"banner":"","type":"alert","path":_vm.path,"messages":[
          {
            translationKey: 'cart.invalid_step.' + _vm.step,
          },
        ]}}):_vm._e(),(!_vm.stepHasIssues(_vm.step) || _vm.editMode)?_c('domain-profile-list',{ref:"domainProfileList",attrs:{"edit-mode":_vm.editMode},on:{"selection-changed":_vm.clearMessages}}):_vm._e()]],2),(_vm.editMode)?[_c('order-process-error',{attrs:{"path":_vm.path}}),_c('order-process-actions',{attrs:{"button-text":_vm.$t(
          _vm.step === _vm.userStep
            ? 'domain_profile.submit_btn'
            : 'wsk.general.save_changes'
        ),"path":_vm.path,"step":_vm.step,"loading":_vm.loading},on:{"click":_vm.submit}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }