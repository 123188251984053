import Vue from 'vue';
// src: https://docs.sentry.io/platforms/javascript/vue/
import * as Sentry from '@sentry/vue';

import env from '@env';
import config from '@/services/config.service';

let contextProvider: (() => any) | null = null;
let sentryCfg: any;

const ignoredErrors = [
  'ws-abort',
];

if (!env.development && !env.test) {
  sentryCfg = {
    Vue,
    dsn: env.sentryURL,
    logErrors: true,
    environment: config.env || 'production',
    replaysSessionSampleRate: 0.01, // 1% of sessions
    replaysOnErrorSampleRate: 1.0, // 100% if an error occurs
    integrations: [new Sentry.Replay()],
  };
  Sentry.init(sentryCfg);
  // DEV: test sentry by throwing any error
  (window as any).__test_sentry_integration__ = (
    logExplicit: boolean = false
  ) => {
    const errorMessage = 'example error to test sentry';
    if (logExplicit) {
      logError(errorMessage);
    }
    throw new Error(errorMessage);
  };
}

export function logError(
  value: any,
  additionalContext?: any /* must be value which can be JSON.stringify(-ied) */
) {
  if(ignoredErrors.includes(value)) {
    return;
  }
  if (contextProvider) {
    const context = contextProvider();
    if (additionalContext) {
      context._additionalContext = JSON.stringify(additionalContext);
    }
    setContext(context);
  }

  value = normalizeErrorValue(value);

  if (!env.development) {
    Sentry.captureException(value, value instanceof Error ? undefined : value);
  } else {
    // eslint-disable-next-line no-console
    console.error(value);
  }
}

export function setContextProvider(provider: () => any, immediate = true) {
  contextProvider = provider;
  if (immediate) {
    setContext();
  }
}

export function setContext(context?: any) {
  context = context || (contextProvider && contextProvider());

  Sentry.configureScope(scope => {
    scope.setUser({ username: context.login });
    Object.keys(context).forEach(key => {
      scope.setExtra(key, context[key]);
    });
  });
}

export function normalizeErrorValue(value: any) {
  if (typeof value === 'string') {
    value = new Error(value);
  } else if (typeof value === 'object' && !(value instanceof Error)) {
    value = new Error(JSON.stringify(value));
  }
  return value;
}
