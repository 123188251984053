
import { defineComponent, Ref, ref, computed, watch } from 'vue';
import { QItem, QItemSection } from 'quasar';
import { WsSelect } from './WsSelect.vue';

import { AddressComponentsExtended } from '../services/google-api.service.d';
import {
  getPlacePredictions,
  getPlaceAddress,
  askForLocation,
} from '../services/google-api.service';
import { get, omit, throttle, uniq } from 'lodash-es';

// Leaving this comment for future generations, as it's too special to be deleted.
// TODO: This component has very messy implementation, need refactor


const hasNumber = (val: string): boolean => {
  return /[0-9]/.test(val);
};

const filter = (v: string, update: Function) => {
  // update immediately
  update();
};


export default defineComponent({
  name: 'WsPlacesInput',
  components: {
    QItem,
    QItemSection,
    WsSelect,
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: '',
    },
    secondaryInfo: {
      type: String,
      default: ''
    },
  },
  emits: ['input', 'address'],
  // eslint-disable-next-line max-lines-per-function
  setup(props, vm) {
    const optionSelected: any = ref('');
    const searchResults: Ref<any> = ref([]);
    const query = ref('');

    let latestCall: Promise<any> | null = null;
    let userSelectedAddress = false;
    let queryWithoutNumbers: string = '';
    let queryNumberParts: string = '';

    /***  Methods  ***/

    const onSelectedChanged = (selected: string) => {
      // TODO: refactor to get full option object as "selected" param here and then
      // take description value for "input" usage
      if (!selected || !searchResults.value) {
        return;
      }

      const selectedOption = searchResults.value.find(
        (result: any) => result.description === selected
      );

      if (selectedOption) {
        getAddress(selectedOption);
      }
    };

    const handleNoSelected = () => {
      if (userSelectedAddress) {
        return;
      }

      // select first result automatically
      if (latestCall) {
        latestCall.then(results => {
          const description = get(results, '[0].description');
          onSelectedChanged(description);
        });
      }
    };


    /***  On created  ***/

    askForLocation();


    /***  Computed  ***/

    const listeners = computed(() => {
      return omit({...vm.listeners}, 'input');
    });

    const options = computed(() => {
      if (searchResults.value?.length) {
        return searchResults.value.map(
          (result: any /* TODO: Fix this typing: AutocompletePrediction[] */) =>
            result?.description
        );
      }
      return [];
    });


    /***  Watchers  ***/

    watch(
      () => props.value,
      (value: boolean) => {
        if (optionSelected.value !== value) {
          optionSelected.value = value;
        }
      },
      {immediate: true}
    );

    watch(
      query,

      (input: string) => {
        vm.emit('input', input);

        if (query.value.length < 2) {
          // reset feature handleNoSelected
          userSelectedAddress = false;
        }

        if (!query.value) {
          query.value = '';
          queryWithoutNumbers = '';
          queryNumberParts = '';
          return;
        }

        // is option selected
        if (searchResults.value?.some((result: any) => result.description === input)) {
          return;
        }

        // For some reason, empty space after query creates better results
        queryWithoutNumbers = input
          .split(' ')
          .filter((part) => !hasNumber(part) ? part : undefined)
          .join(' ') + ' ';

        queryNumberParts = input
          .split(' ')
          .filter((part) => hasNumber(part) ? part : undefined)
          .join(' ');

        query.value = input;

        innerGetPredictions();
      }
    );


    /***  Throttles  ***/

    const getAddress = throttle(
      (optObj: any) => {
        getPlaceAddress(optObj.place_id).then((data: any) => {
          data.query = query.value;
          data.queryWithoutNumbers = queryWithoutNumbers;
          data.queryNumberParts = queryNumberParts;
          userSelectedAddress = true;
          vm.emit('address', data as AddressComponentsExtended);
        });
      },
      100,
      {trailing: true}
    );

    const innerGetPredictions = throttle(
      () => {
        if (!queryWithoutNumbers) {
          return;
        }

        const query = `${props.secondaryInfo}, ${queryWithoutNumbers}`;

        latestCall = Promise.all([
          getPlacePredictions(query, 'geocode'),
          getPlacePredictions(queryWithoutNumbers, '(regions)'),
        ])
          .then((results: any[][]) => {
            results = results[0].concat(results[1]);
            searchResults.value = results.map((result: any) => {
              // sometimes contains info to distinguish two results
              const secondary = get(result, 'structured_formatting.secondary_text');
              const parts = result.description
                .split(',')
                .concat(secondary.split(','))
                .map((part: string) => part.trim?.());
              result.ws_label = uniq(parts).join(', ');
              return result;
            });
            return searchResults;
          })
          .catch(() => {
            searchResults.value = [];
          });
      },
      100,
      {trailing: true}
    );

    watch(
      optionSelected,
      onSelectedChanged
    );


    return {
      listeners,
      options,
      optionSelected,
      query,
      filter,
      handleNoSelected,
      searchResults,
    };
  }
});
