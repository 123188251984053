import Vue from 'vue';

Vue.component('WsWrapper', {
  props: {
    wrap: Boolean,
    element: {type: String, default: 'div'},
  },
  render(h) {
    // Only render the contents if no wrap is required
    if (!this.wrap) {
      return this.$slots.default[0];
    }

    // Otherwise, wrap the contents in an {element} and render the contents
    return h(this.element, this.$slots.default);
  },
});
