
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { QForm, QTooltip } from 'quasar';
import { required } from '@WS_UIkit';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import { isSwedishWorkflow } from '@/services/config.service';
import { STEPS } from '@/services/const.enum';
import { syncStore } from '@/store';
import { getVatTaxTKeyInterceptor } from '@/services/message.service';
import { mayGoNext } from '@/services/order.service';
import DomainProfileList from '@/components/DomainProfileList.vue';
import BonusCard from '@/components/BonusCard.vue';
import OrderProcessActions from '@/components/OrderProcessActions.vue';
import Button from '@/components/developer/components/Button.vue';
import OrderProcessError from '@/components/OrderProcessError.vue';

@Component({
  components: {
    OrderProcessError,
    Button,
    QForm,
    WsMessage,
    QTooltip,
    WsCheckbox,
    DomainProfileList,
    BonusCard,
    OrderProcessActions,
    WsStateBanner
  },
  computed: {
    ...mapState(['userStep', 'inEditMode']),
    ...mapGetters([
      'equalBillingAndDomainProfiles',
      'stepHasIssues',
      'allDomainProfilesDefined',
    ]),
    ...syncStore('useDifferentBillingProfile'),
  },
})
export default class DomainProfile extends Vue {
  readonly inEditMode!: STEPS | null;

  // temporary disabled, waiting for discussion, search code for ref: SKIP_OVER_BILLING
  // useDifferentBillingProfile!: boolean;
  // equalBillingAndDomainProfiles!: boolean;
  step = STEPS.DOMAIN_PROFILE;
  path = 'cart.' + this.step;
  required = required;
  loading = false;
  isSwedishWorkflow = isSwedishWorkflow;

  get editMode() {
    return this.inEditMode === this.step;
  }

  @Watch('editMode')
  async onEditModeChange(editMode: boolean) {
    if (editMode) {
      await this.$nextTick();
      (this.$refs.domainProfileList as any)?.validate();
    }
  }

  clearMessages() {
    this.$messageService.clearMessages(
      [this.path, 'cart.summary.quick_order', 'general'],
      true
    );
  }

  // temporary disabled, waiting for discussion, search code for ref: SKIP_OVER_BILLING

  // @Watch('equalBillingAndDomainProfiles', { immediate: true })
  // onEqualityChanges(equal: boolean) {
  //   this.useDifferentBillingProfile = !equal;
  // }

  // @Watch('useDifferentBillingProfile')
  // onUseDifferentChanges(useDifferent?: boolean) {
  //   if (!this.isSwedishWorkflow) {
  //     return Promise.resolve();
  //   }
  //   if (useDifferent === undefined) {
  //     useDifferent = this.useDifferentBillingProfile;
  //   }
  //   if (!useDifferent && !this.equalBillingAndDomainProfiles) {
  //     const billingProfileUpdate = createBpFromDp(
  //       get(this.$store, 'state.cart.domainProfile')
  //     );
  //     billingProfileUpdate.id = get(
  //       this.$store,
  //       'state.cart.billingProfile.id'
  //     );
  //     return updateBillingProfile(billingProfileUpdate);
  //   }
  //   return Promise.resolve();
  // }

  get title() {
    const keyBase = this.isSwedishWorkflow ? 'user_info' : 'domain_profile';
    return this.$t(
      this.editMode && !this.isSwedishWorkflow
        ? keyBase + '.title_in_edit'
        : keyBase + '.title',
      { _fallback: keyBase + '.title' }
    );
  }

  submit() {
    this.clearMessages();
    this.loading = true;
    // TODO why cannot be typed to DomainProfileList?
    // (this.$refs.domainProfileList as DomainProfileList)
    (this.$refs.domainProfileList as any)
      .saveProfile()
      // temporary disabled, waiting for discussion, search code for ref: SKIP_OVER_BILLING
      // .then(() => this.onUseDifferentChanges())
      .then(this.mayGoNext)
      .catch(
        (err: any) => {
          err = this.handleRequiredFieldErr(err);
          this.$messageService.errorHandler(this.path, {
            keyInterceptor: getVatTaxTKeyInterceptor(
              (this.$refs.domainProfileList as any)?.domainProfile
            ),
          })(err);
          this.$root.$messageService.getters.messagesByPath(this.path, {recursive: true});
        }
      )
      .finally(() => (this.loading = false));
  }

  handleRequiredFieldErr(err: any) {
    err.response.data.violations?.forEach((violation: any) => {
      violation.errors = violation.errors.map((error: any) => {
        if (error.translationKey === 'validation.required' && violation.propertyPath) {
          error.translationKey = `validation.required_${violation.propertyPath}`;
        }
        return error;
      });
    });
    return err;
  }

  mayGoNext() {
    mayGoNext();
  }
}
