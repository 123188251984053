
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {QCheckbox} from 'quasar';

import {WsFieldMixin} from './WsFieldMixin.vue';
import {WsMessage} from './WsMessage.vue';

@Component({
  components: {
    QCheckbox,
    WsMessage,
  },
})
export class WsCheckbox extends mixins(WsFieldMixin) {
  @Prop({default: false}) declare readonly value: boolean | any[];
  @Prop(Boolean) readonly forceBoolean!: boolean;
  @Prop(Boolean) readonly fullWidth!: boolean;
  @Prop(Boolean) readonly large!: boolean;

  get localValue() {
    return this.forceBoolean ? !!this.value : this.value;
  }

  set localValue(newVal: boolean | any[]) {
    if (newVal !== this.value) {
      this.$emit('input', newVal);
    }
  }

  get hasError() {
    // @ts-ignore
    return !!this.messages.length || this.innerError;
  }

  get errors() {
    // @ts-ignore
    const messages = this.messages;
    return messages?.length
      ? messages
      : this.errorMessage && [{text: this.errorMessage}];
  }

  onKeyPress(evt: KeyboardEvent) {
    if (evt.key && (evt.key === ' ' || evt.key === 'Enter')) {
      this.$emit('input', !this.value);
    }
  }
}

export default WsCheckbox;
