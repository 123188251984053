
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { ClosePopup } from 'quasar';

import { toggleEditItem } from '@/services/cart/cart.service';
import WsPeriod from '@WS_Components/WsPeriod.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMenu from '@WS_Components/WsMenu.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsSelectNumber from '@WS_Components/WsSelectNumber.vue';

import { STEPS } from '@/services/const.enum';
import { setLoader } from '@/services/cart/cart.service';
import { CartServiceStore } from '@/services/cart/cart.store.d';
import RemoveBtn from '@/components/RemoveBtn.vue';
import PriceCell from '@/components/domain-checker/resultsTable/PriceCell.vue';
import PremiumBadge from '@/components/domain-checker/PremiumBadge.vue';
import CartItemLabel from './CartItemLabel.vue';
import CartItemMessage from './CartItemMessage.vue';
import { updateItem } from '@/services/cart/cart-api.service';
import CartItemInfoBox from './CartItemInfoBox.vue';
import { Theme } from '@loopia-group/services';
import WsSpinner from '@WS_Components/WsSpinner.vue';

@Component({
  components: {
    WsSpinner,
    CartItemInfoBox,
    WsPeriod,
    WsButton,
    WsMenu,
    WsCheckbox,
    WsSelectNumber,
    RemoveBtn,
    PriceCell,
    CartItemLabel,
    CartItemMessage,
    PremiumBadge,
  },
  directives: {
    ClosePopup,
  },
  computed: {
    ...mapState(['cartService']),
  },
})
export default class CartItem extends Vue {
  @Prop() readonly item!: any;
  @Prop(Boolean) readonly disableExtraSection!: boolean;
  @Prop(Boolean) readonly isSubItem!: boolean;
  @Prop(Boolean) readonly isBundleCartItem!: boolean;
  @Prop({ type: Boolean, default: true }) readonly isPriceLoaded!: boolean;
  @Inject({ from: 'readOnlyMobile', default: false })
  readonly readOnlyMobile!: boolean; // do not show control buttons (settings, delete) in mobile view
  @Inject({ from: 'settingsIconVisible', default: false })
  readonly settingsIconVisible!: boolean;
  @Inject({ from: 'removeItem', default: () => {} })
  readonly removeItem!: Function;
  readonly cartService!: CartServiceStore;
  readonly mobile!: boolean;
  showMenu = false;
  Theme = Theme;

  @Prop({ type: Boolean, default: true })
  readonly showPeriodPriceCell!: boolean;

  toggleEditItem = toggleEditItem;

  set loading(loading: boolean) {
    setLoader(this.item.id, loading);
  }
  get loading(): boolean {
    return this.cartService.loaders[this.item.id];
  }

  get showPeriod(): boolean {
    return !this.item?.properties?.freeTransfer;
  }

  get showLicenses() {
    return typeof this.item.licenses === 'number'; // licenses property added in CartItemBundle.util.ts
  }

  get hasError(): boolean {
    return this.$messageService.hasMessages(STEPS.CART + '.' + this.item.id, {
      recursive: true,
    });
  }

  get isLicensesInputDisabled() {
    return this.$route.path !== '/' && this.$route.path !== '/order' && this.$route.path !== '/checkout/';
  }

  get isDisabled() {
    return this.item.isDisabled || this.loading || this.hasError;
  }

  get readOnlyMobileApplied() {
    return this.readOnlyMobile && this.mobile;
  }

  async updateLicencesNumber(val: number) {
    const licenseCode = this.item.code.replace('business_mail_', '');
    const item = {
      id: this.item.id,
      properties: {
        packageId: this.item.properties.packageId,
        addons: {
          ...this.item.properties.addons,
          [`${licenseCode}_licences_limit`]: val,
        }
      },
    };
    try {
      this.loading = true;
      //@ts-ignore
      await updateItem(item);
    } catch (err) {
      this.$messageService.errorHandler('cartItem-updateLicenses', err);
    }
    this.loading = false;
  }

  remove() {
    this.loading = true;
    this.removeItem(this.item).finally(() => {
      this.showMenu = false;
      this.loading = false;
    });
  }
}
