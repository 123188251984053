
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { QDate, QField, QForm, QInput, QPopupProxy } from 'quasar';

import { cloneDeep, get } from 'lodash-es';
import {
  ValidatorFactory,
  validateForm,
  requiredByConfig,
  required,
  email,
  always,
  validateCompany,
  validateVat,
  validateTax,
  addressPropertyList,
  propertyValidation,
  addValidations,
  profilePropertyList,
} from '@WS_UIkit';
import { Validator } from '@WS_UIkit/types';
import { EditableAddressProps } from '@/WS_UIkit/src/components/WsAddress.enum';
import WsButton from '@WS_Components/WsButton.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsInput from '@WS_Components/WsInput.vue';
import WsPhoneInput from '@WS_Components/WsPhoneInput.vue';
import {
  Cart,
  UserProfileType,
  CommonProfile,
  CommonProfileKeys,
  User,
  RequiredDpFields,
} from '@/types';
import { StoreActions } from '@/store/const.enum';
import config, { isSwedishWorkflow } from '@/services/config.service';
import {INVALID_FORM_ERROR, QDateLocale} from '@/services/const';
import { dateFormatInputMask } from '@/store';
import i18nService from '../WS_UIkit/src/services/i18n/i18n.service';
import { format, parse } from 'date-fns';

const ADDITIONAL_FIELDS: Array<CommonProfileKeys> = [
  // additional as they are never in profile form
  'idCard',
  'passport',
  'noridPid',
  'birthday',
];

@Component({
  components: {
    WsButton,
    WsIcon,
    WsInput,
    WsPhoneInput,
    QForm,
    QInput,
    QDate,
    QPopupProxy,
  },
  computed: {
    QDateLocale() {
      return QDateLocale;
    },
    ...mapState(['cart', 'requiredDpFields', 'userProfile']),
    ...mapGetters(['isInRequiredFields', 'requiredFields']),
  },
})
export default class DomainProfileAdditionalFields extends Vue {
  @Prop({ required: true }) readonly resolveFields!: {
    formHasPhone?: boolean;
    editFormVisible: boolean;
    sourceProfile: CommonProfile;
  };
  @Prop() readonly countryCode!: string;
  @Prop({ default: 'personal' }) readonly type!: UserProfileType;
  @Prop({ default: () => ({}) }) readonly disable!: Record<string, boolean>;
  @Prop(Boolean) readonly allOptional!: boolean;
  @Prop({ default: 'user.personalInformation' }) readonly path!: string;
  @Prop({ default: () => ({}) }) readonly value!: {
    password?: string;
    idCard?: string;
    email?: string;
    phone?: string;
    vatId?: string;
    taxId?: string;
    companyId?: string;
    countryCode?: string;
    birthday?: string;
  };
  readonly cart!: Cart;
  userProfile!: User;
  isInRequiredFields!: (
    type: string,
    countryCode: string,
    prop: string
  ) => boolean;
  requiredFields!: (type: string, countryCode: string) => RequiredDpFields;
  requiredByConfig: ValidatorFactory = () => always;
  required = required;
  always = always;
  propStaticRules: Record<string, Validator[]> = Object.create(null);
  propRestriction: Record<string, string> = Object.create(null);
  askForEmail = false;
  isSwedishWorkflow = isSwedishWorkflow;
  currentLang = i18nService.getLang();
  dateMask = dateFormatInputMask(this.currentLang);
  dateInputLabels = QDateLocale[this.currentLang] ?? QDateLocale['sk-SK'];

  get hideFields() {
    const { editFormVisible, formHasPhone } = this.resolveFields;
    const sourceProfile = this.resolveFields.sourceProfile;
    let getValueByKey: (key: CommonProfileKeys) => boolean;
    if (sourceProfile) {
      getValueByKey = this.handleSourceProfile(editFormVisible, formHasPhone, sourceProfile);
    } else {
      getValueByKey = () => true;
    }

    const hidenFields: Partial<Record<CommonProfileKeys, boolean>> = {};
    const keys: Array<CommonProfileKeys> = [
      'phone',
      'email',
      'companyId',
      'taxId',
      'vatId',
      ...ADDITIONAL_FIELDS,
    ];
    keys.forEach(key => (hidenFields[key] = getValueByKey(key)));
    this.$emit(
      'no-content',
      keys.every(key => hidenFields[key])
    );
    return hidenFields;
  }

  handleSourceProfile(editFormVisible: boolean, formHasPhone: boolean | undefined, sourceProfile: any) {
    return editFormVisible
      ? // we set all to true -> hide for editFormVisible, it is when profile
      // is edited in form. Except cardId and passport those do not have fields in forms,
      // also billing profile does not have phone in form but sing up does, therefore phone
      // is handled extra by formHasPhone
      (key: CommonProfileKeys) =>
        ![...ADDITIONAL_FIELDS, formHasPhone ? '' : 'phone'].includes(key)
      : (key: CommonProfileKeys) => {
        if (key === 'email' && !(sourceProfile as any)[key]) {
          return !!get(sourceProfile, 'emails[0]');
        } else {
          return !!get(sourceProfile, key);
        }
      };
  }

  get countryCodeComputed(): string {
    return (
      this.countryCode ||
      get(this.value, 'countryCode') ||
      config.companyCountry
    );
  }

  get showPhoneInput() {
    return (
      !this.hideFields.phone &&
      this.isInRequiredFields(this.type, this.countryCodeComputed, 'phone')
    );
  }

  get birthDate(): string {
    if (this.value.birthday) {
      return format(
        parse(this.value.birthday, 'yyyy/MM/dd', new Date()),
        this.dateMask
      );
    } else {
      return '';
    }
  }

  get showIdCardInput() {
    return (
      (!this.hideFields.idCard &&
        this.isInRequiredFields(
          this.type,
          this.countryCodeComputed,
          'idCard'
        )) ||
      (this.isSwedishWorkflow && this.type === 'personal')
    );
  }

  get requiredDpFields(): RequiredDpFields {
    return this.requiredFields(this.type, this.countryCodeComputed);
  }

  @Watch('countryCodeComputed', { immediate: true })
  onCountryCodeChanged(countryCode: string) {
    this.$store.dispatch(StoreActions.FETCH_REQ_FIELDS, countryCode);
  }

  @Watch('showPhoneInput', { immediate: true })
  // eslint-disable-next-line sonarjs/cognitive-complexity
  onShowPhoneChanged(showPhoneInput: boolean) {
    if (showPhoneInput && !this.value.phone) {
      if (this.userProfile && this.userProfile.loginProfile) {
        this.reEmit('phone', this.userProfile.loginProfile.phone);
      } else {
        this.$store
          .dispatch(StoreActions.FETCH_USER_PROFILE)
          .then((userProfile: User) => {
            // prefill phone from user profile
            if (!this.value.phone && userProfile && userProfile.loginProfile) {
              this.reEmit('phone', userProfile.loginProfile.phone);
            }
          });
      }
    }
  }

  @Watch('value', { immediate: true })
  onValueChanged() {
    this.askForEmail = !this.value?.email || email(this.value.email) !== true;
  }

  @Watch('requiredDpFields', { immediate: true })
  initValidators() {
    setTimeout(() => {
      // timeouted to wait for $refs update
      this.requiredByConfig = requiredByConfig(
        new Map([
          ...ADDITIONAL_FIELDS.map(
            (field: CommonProfileKeys) =>
              [field!, this.$refs[field]] as [string, QField]
          ),
        ]),
        this.requiredDpFields
      );
    }, 0);
  }

  created() {
    addValidations([addressPropertyList, profilePropertyList]);
    ([
      'emails',
      'companyId',
      'taxId',
      'vatId',
    ] as EditableAddressProps[]).forEach(prop => {
      const { rules, restriction } = propertyValidation(`address_${prop}`);
      this.propStaticRules[prop] = rules;
      this.propRestriction[prop] = restriction;
    });
    ['idCard', 'passport'].forEach(prop => {
      const { rules, restriction } = propertyValidation(`profile_${prop}`);
      this.propStaticRules[prop] = rules;
      this.propRestriction[prop] = restriction;
    });
  }

  // TODO following three validators are taken from WsAddress.vue
  // rething how to reuse implementation

  validCompanyID(companyId: string) {
    return validateCompany({
      companyId,
      countryCode: this.countryCodeComputed!,
    });
  }

  validVatID(vatId: string) {
    return validateVat({
      vatId,
      countryCode: this.countryCodeComputed!,
    });
  }

  validTaxID(taxId: string) {
    return validateTax({
      taxId,
      countryCode: this.countryCodeComputed!,
    });
  }

  requiredCountry(val: string) {
    if (!val) {
      return true;
    }
    return (
      !!this.countryCodeComputed || this.$t('wsk.validation.country_required')
    );
  }

  validate(): Promise<boolean> {
    return validateForm(this.$refs.form as Element & QForm);
  }

  validateWithReject(): Promise<true> {
    return this.validate().then((result: boolean) =>
      result ? Promise.resolve(true) : Promise.reject(INVALID_FORM_ERROR)
    );
  }

  reEmit(prop: string, value: any) {
    this.$emit('input', { ...cloneDeep(this.value), [prop]: value });
  }

  birthdayClicked() {
    if (!this.value.birthday) {
      (this.$refs.birthdayPopup as QPopupProxy).show();
    }
  }

  birthdayChanged(value: string) {
    this.reEmit('birthday', value);
    (this.$refs.birthdayPopup as QPopupProxy).hide();
  }
}
