
import {computed, defineComponent, inject, PropType, ref, watch} from 'vue';
import {useTranslation} from '@/WS_UIkit/src/composables/translation';
import WsButton from '@WS_Components/WsButton.vue';
import loopiaLogo from '@/assets/images/loopia-logo.svg';
import wordpressNice from '@/assets/images/wordpress-nice.svg';
import woocommerce from '@/assets/images/woocommerce.svg';
import xss from 'xss';
import {CategoryItemConfig} from '@/modules/iwp/components/Items/ProductItem';
import {SelectedProduct} from '@/modules/iwp/views/Product';

export default defineComponent({
  name: 'PackageItem',
  components: {WsButton},
  props: {
    isRecommended: { type: Boolean, default: false },
    item: { type: Object as PropType<CategoryItemConfig>, default: () => {}},
  },
  setup (props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {$t} = useTranslation();
    const itemTitle = computed(() => props.item?.packageId?.additionalProperties.name);
    const logoSrc = computed(() => {
      return props.item?.path.includes('siteBuilder') ?
        loopiaLogo :
        (props.item?.path.includes('nonEcommerce') ? wordpressNice : woocommerce);
    });

    const selectedRef = ref(false);

    const selectedProduct = inject('iwpProvider') as SelectedProduct;
    const currentNode = selectedProduct.currentNode;
    const selectedPackage = selectedProduct.selectedPackage || ref('');
    const updateSelectedPackage = selectedProduct.updateSelectedPackage;

    const select = (): void => {
      updateSelectedPackage(props.item?.packageId.value);
    };

    const printBenefit = (benefit: string): string => {
      let amount: number = props.item?.packageId?.additionalProperties[benefit] || 0;
      benefit === 'capacity' ? amount = amount/1024 : amount;
      return benefit === 'capacity' ?
        $t( 'modules.i_want_product.website.attributes.' + benefit, {amount: amount} ) :
        $t( 'modules.i_want_product.website.attributes.' + benefit );
    };

    watch(selectedPackage, () => {
      selectedRef.value = props.item.packageId.value === selectedPackage.value.toString();
    });

    const selectedBtnLabel = computed(() => {
      return selectedRef.value ?
        'modules.i_want_product.website.remove_btn_label' :
        'modules.i_want_product.website.select_btn_label';
    });

    return {
      currentNode,
      itemTitle,
      logoSrc,
      selectedBtnLabel,
      selectedRef,

      printBenefit,
      select,
    };
  },
  methods: {
    xss
  }
});
