export interface EnvironmentValues {
  // enviroments
  production?: true;
  development?: true;
  test?: true;

  isProductionBuild: boolean;
  dev?: {
    // utility to be able to quickly test checkouts
    instantCheckout: boolean;
    // disable feature that update cart, profiles, etc. from BE after refocused tab
    disableAutoUpdateOnRefocus: boolean;
  };
  apiUrl?: string;
  baseUrl?: string;
  mockURL?: string;
  sentryURL: string;
  GOOGLE_API_KEY: string;
}

export const enviromentBaseValues: Partial<EnvironmentValues> = {
  GOOGLE_API_KEY: 'AIzaSyCJOx3OA4ggy9xP617dPp0-v7dlV0QVGWA',
  sentryURL: 'https://9eb97f5549ea44e5bdb577362dae0771@sentry.websupport.sk/7',
};

export default enviromentBaseValues;
