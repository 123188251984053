
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QItem, QItemLabel, QItemSection, QForm } from 'quasar';

import donationLogo from '@/assets/images/slovenskodigital-logo.svg';
import { Cart } from '@/types';
import { get } from 'lodash-es';
import { QOption } from '@WS_UIkit';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsInput from '@WS_Components/WsInput.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import {
  DonationOption,
  OrderDonation as OrderDonationType,
  DonationRequestData,
  addDonation,
} from '../services/cart/cart-api.service';
import { Theme } from '@loopia-group/services';

interface ExtendedOption extends QOption {
  donationOpt?: DonationOption;
}

const LABEL_CUSTOM = 'donation.option_custom';
const LABELS: Record<string, string> = {
  v1: 'donation.option_basic',
  v2: 'donation.option_plus05',
  v3: 'donation.option_plus1',
};

@Component({
  components: {
    WsSelect,
    WsInput,
    WsButton,
    WsMessage,
    QItem,
    QItemLabel,
    QItemSection,
    QForm,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export class OrderDonation extends Vue {
  readonly cart!: Cart;
  donationLogo = donationLogo;
  donationOptions: ExtendedOption[] = [];
  selectedOption: ExtendedOption | null = null;
  customValue: string | null = null;
  loading = false;
  PATH = 'donation';
  Theme = Theme;

  get isCustom(): boolean {
    return get(this.selectedOption, 'label') === LABEL_CUSTOM;
  }

  get messages(): any[] {
    // return of messagesByPath must be returned from this computed to keep reactivity
    return this.$messageService.getters.messagesByPath(this.PATH);
  }

  get hasError(): boolean {
    return !!this.messages.length;
  }

  @Watch('cart.donation', { immediate: true })
  onPriceChanged(donationData: OrderDonationType) {
    if (!donationData) {
      return;
    }

    this.donationOptions = donationData.options.map(item => ({
      label: LABELS[item.roundUpVersion],
      donationOpt: item,
    }));
    this.donationOptions.push({
      label: LABEL_CUSTOM,
      donationOpt: ({} as unknown) as DonationOption,
    });

    if (!this.selectedOption) {
      this.selectedOption = this.donationOptions[0];
    }
  }

  @Watch('customValue')
  @Watch('selectedOption')
  clearError() {
    this.$messageService.clearMessages(this.PATH);
  }

  @Watch('isCustom')
  isCustomChanged(isCustom: boolean) {
    if (isCustom) {
      // setTimout waits for render of input
      setTimeout(() => (this.$refs.customValue as any).focus(), 0);
    } else {
      this.customValue = null;
    }
  }

  add() {
    let donationRequestData: DonationRequestData;
    if (this.isCustom) {
      donationRequestData = {
        customDonation: parseFloat(this.customValue || '0'),
      };
    } else if (!this.selectedOption || !this.selectedOption.donationOpt) {
      return this.$messageService.unknownError('donation');
    } else {
      donationRequestData = {
        roundUpVersion: this.selectedOption.donationOpt.roundUpVersion,
      };
    }
    this.loading = true;
    addDonation(donationRequestData)
      .catch(this.$messageService.errorHandler('donation'))
      .finally(() => (this.loading = false));
  }

  // remove() {
  //   this.loading = true;
  //   removeDonation()
  //     .catch(this.$messageService.errorHandler('donation'))
  //     .finally(() => (this.loading = false));
  // }
}
export default OrderDonation;
