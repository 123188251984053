import { DomainProfile, BillingProfile, DomainProfileExtended } from '@/types';
import { pick } from 'lodash-es';
import { COMMON_PROFILE_KEYS } from './const';

export function normalizeDomainProfile(
  profile: DomainProfile | null
): DomainProfileExtended | null {
  if (!profile) {
    return null;
  }
  return {
    ...profile,
    name:
      (profile as any).name ||
      [profile.firstName, profile.lastName].join(' ').trim(),
  };
}

// create billing profile from domain profile
export function createBpFromDp(dp: DomainProfile): BillingProfile {
  dp = normalizeDomainProfile(dp) as DomainProfile;
  const bp: Partial<BillingProfile> = {
    ...pick(dp, COMMON_PROFILE_KEYS),
    emails: [dp.email],
  };
  return bp as BillingProfile;
}

// cleaning type specific props from profile, befre it is
// send to BE so it pass BE validation correctly
export function cleanProfileByType(profile: any) {
  if (!profile) {
    // pass trough
    return profile;
  }
  if (profile.type === 'company') {
    delete profile.bonusCard;
    delete profile.passport;
    delete profile.idCard;
    delete profile.noridPid;
  } else {
    delete profile.organisation;
    delete profile.companyId;
    delete profile.vatId;
    delete profile.taxId;
  }

  return profile;
}
