import { Subject, Observable } from 'rxjs';

import { get, isArray, omit } from 'lodash-es';
import { redirect } from '@loopia-group/utils';

import { getItem, removeItem, setItem } from '@WS_UIkit';
import store from '@/store';
import {
  COMPLETED,
  TransferPaymentData,
  UNTOUCHED,
} from '@/store/const';
import router, { safePush } from '@/router';
import {
  BillingProfile,
  DomainProfile,
  DomainProfileAdditionalInfoRequest,
  DomainProfileExtended,
} from '@/types';
import { constructUrlFromRoute } from '@/utilities';
import { PAY_OPTIONS } from '@/components/paymentOptions/const';
import {
  checkoutOrder,
  fetchCart,
  updateCartState,
} from './cart/cart-api.service';
import network from '@/services/network.service';
import { messageService } from './message.service';
import { trackPurchase, trackInitiateCheckout, trackPaymentMethod } from './analytics.service';
import { StorageKeys } from './const';
import { STEPS } from './const.enum';
import config from './config.service';
import { normalizeDomainProfile } from './profiles.service';
import { ErrorContext } from '@WS_UIkit/types';
import {StateMutations, StoreActions} from '@/store/const.enum';
import {ROUTENAMES, WORKFLOW} from '@/const.enum';
import {useCartItemsStore} from '@/store/cartItemsStore';

export const scrollToStepSubject = new Subject<STEPS>();
export const scrollToStepObservable: Observable<STEPS> = scrollToStepSubject.asObservable();

export function stepEnabled(
  step: STEPS,
  skipIndexCheck: boolean = false
): boolean {
  const cart = store.state.cart;
  const itemsStore = useCartItemsStore();

  if (!skipIndexCheck) {
    const userStepIndex = store.getters.userStepIndex;
    const stepIndex = orderSteps().indexOf(step);

    if (stepIndex > userStepIndex) {
      return false;
    }
  }

  switch (step) {
  // temporary disabled, waiting for discussion, search code for ref: SKIP_OVER_BILLING
  // case STEPS.BILLING_PROFILE:
  //   return (
  //     config.workflow !== WORKFLOW.SWEDISH ||
  //     !!store.state.useDifferentBillingProfile
  //   );
  case STEPS.DOMAIN_PROFILE:
    return (
      config.workflow === WORKFLOW.SWEDISH || !!cart.domainProfileRequired
    );
  case STEPS.SERVICE_SETTINGS:
    return itemsStore.hasMandatory;
  default:
    return true;
  }
}

export function getPrevStep(step: STEPS = store.state.userStep) {
  const _orderSteps = orderSteps();
  let index = _orderSteps.indexOf(step) - 1;
  // skip disabled stepps
  while (index > 0 && !stepEnabled(_orderSteps[index], true)) {
    index--;
  }
  return _orderSteps[index];
}

export function getNextStep(step: STEPS = store.state.userStep) {
  const _orderSteps = orderSteps();
  let index = _orderSteps.indexOf(step) + 1;
  const lastIndex = _orderSteps.length - 1;
  const progressByData = store.getters.progressByData;
  const progressByUser = store.getters.progressByUser;

  // skip disabled steps
  while (
    // index must be in range
    index < lastIndex &&
    // skip because step is not enabled
    (!stepEnabled(_orderSteps[index], true) ||
      // skip because step is completed
      (progressByData[_orderSteps[index]] === COMPLETED &&
        progressByUser[_orderSteps[index]] === COMPLETED))
  ) {
    index++;
  }

  return _orderSteps[index];
}

export function setEditMode(step: STEPS | null): void {
  if (step === STEPS.RECAPITULATION) {
    // recapitulation does not have edit mode, so just close edit of others
    step = null;
  }
  // TODO return promise which is resolved after the same time
  // the animation is set to take
  store.commit(StateMutations.SET_STATE, {
    prop: 'inEditMode',
    value: step,
  });
}

export function toggleEditMode(step: STEPS | null): void {
  setEditMode(store.state.inEditMode === step ? null : step);
}

export function mayGoNext(forceEdit?: STEPS): void {
  let nextStep =
    store.state.inEditMode === store.state.userStep
      ? // user is at current latest step so move further in order process
      getNextStep(store.state.inEditMode)
      : // otherwise get unfinished step closest to begining (cart step)
      getNextStep(STEPS.CART);

  if (
    nextStep === STEPS.SERVICE_SETTINGS &&
    store.getters.mandatorySetsStepSkipped
  ) {
    nextStep = getNextStep(nextStep);
  }

  if (isAfterCurrentStep(nextStep)) {
    // set new userStep only for moving forward
    setUserStep(nextStep);
  }

  safePush({ name: ROUTENAMES.ORDER, query: { step: nextStep } });
  const stepForEdit: STEPS = forceEdit || nextStep;
  if (stepForEdit === STEPS.RECAPITULATION) {
    // only close edit
    setEditMode(null);
    // and scroll to as recapitulation does not have edit mode, but we want to move to it
    setTimeout(() => {
      // TODO use nextTick
      // settimeout to wait for setEditMode to apply changes on DOM
      scrollToStepSubject.next(stepForEdit);
    }, 0);
  } else {
    setEditMode(stepForEdit);
  }
}

export function progressBack(step: STEPS, withoutRouting = false) {
  const prev = getPrevStep(step);

  if (withoutRouting) {
    // do not continue to routing part
    return;
  }
  if (prev === STEPS.CART) {
    safePush({ name: ROUTENAMES.CART });
  } else {
    setEditMode(prev);
  }
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function updateStateFromBackend(restore = false) {
  if (store?.state?.checkoutInProgress) {
    // do not fetch cart while checkout is in progress PNO-1798
    return;
  }

  router.onReady(async () => {
    const currentRouteName = (router?.currentRoute?.name || '') as ROUTENAMES;
    if (
      [ROUTENAMES.ORDER, ROUTENAMES.CART, ROUTENAMES.EMPTY_CART].includes(
        currentRouteName
      )
    ) {
      await fetchCart().then(async () => {
        const { step, uuid } = getItem(StorageKeys.userStep, true) || ({} as any);
        const routeIsOrder = currentRouteName === ROUTENAMES.ORDER;
        const cart = store?.state?.cart;

        if (cart?.uuid !== uuid) {
          removeItem(StorageKeys.userStep);
          setUserStep(STEPS.CART);
          if (routeIsOrder) {
            navigateToStep(STEPS.CART);
          }
        } else {
          setUserStep(cart?.items?.length ? step : STEPS.CART);
        }

        if (store.getters.loggedIn && routeIsOrder) {
          // also profiles, as they could be modified in other tabs too :/
          await Promise.all([
            store.dispatch(StoreActions.FETCH_BILLING_PROFILES),
            store.dispatch(StoreActions.FETCH_DOMAIN_PROFILES),
          ]);
        }
      });
    } else if (
      [
        ROUTENAMES.THANK_YOU,
        ROUTENAMES.OPTIONAL,
        ROUTENAMES.DOMAIN_CHECKER,
      ].includes(currentRouteName)
    ) {
      await fetchCart();
    }

    if (restore) {
      restoreStep();
    }
  });
}

export function restoreStep(): void {
  // assumes that runs right after updateStateFromBackend
  // eslint-disable-next-line prefer-const
  let { step, uuid: savedCartUuid } =
    getItem(StorageKeys.userStep, true) || ({} as any);

  const currentRoute = router?.currentRoute;
  const queryStep: string = (currentRoute?.query?.step as string) || '';
  const cart = store?.state?.cart;
  const routeIsOrder = currentRoute.name === ROUTENAMES.ORDER;

  if (!routeIsOrder) {
    // restore step only on order screen
    return;
  }

  // step from query has precedense due to "redirectAfterLogin" use case, where we want
  // to return user not to CART again but to ORDER
  if (queryStep) {
    step = queryStep;
  }

  if (
    !cart?.items?.length ||
    cart?.uuid !== savedCartUuid ||
    step === STEPS.CART
  ) {
    removeItem(StorageKeys.userStep);
    setUserStep(STEPS.CART);
    navigateToStep(STEPS.CART);
  } else {
    // only if cart is the same, restore user step
    // check weather not skipping step uncompleted by data
    const _orderSteps = [...orderSteps()];
    const progressByData = store.getters.progressByData;
    const farestStepBydata =
      _orderSteps.find((step: STEPS) => progressByData[step] === UNTOUCHED) ||
      _orderSteps.pop();

    if (stepIsAfterStep(step, farestStepBydata)) {
      step = farestStepBydata;
    }

    setUserStep(step);
    navigateToStep(step);
  }
}

export function navigateToUserStep() {
  return navigateToStep(store.state.userStep);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export function navigateToStep(step: STEPS, edit = true) {
  let routeOptions = {};

  // need to set edit mode first so it resizes
  // components as they open/close
  const editModeChange = edit && store.state.inEditMode !== step;
  if (editModeChange) {
    setEditMode(step);
    if (isOrderStep(step)) {
      // set step query param only for edit changes PNO-1766
      routeOptions = { query: { step } };
    }
  }

  const route =
    step === STEPS.CART
      ? { name: ROUTENAMES.CART }
      : { name: ROUTENAMES.ORDER, ...routeOptions };

  return new Promise((resolve, reject) => {
    setTimeout(async () => {
      // setTimeout waits for setEditMode to take effect
      // and open/close sections so heights of components change
      // TODO try to use nextTick, ref: https://vuejs.org/v2/api/#Vue-nextTick
      if (router.currentRoute.name !== route.name || editModeChange) {
        try {
          await safePush(route);
        } catch (error) {
          return reject(error);
        }
      }

      if (isOrderStep(step)) {
        scrollToStepSubject.next(step);
      }
      resolve(true);
    }, 0);
  });
}

export function isAfterCurrentStep(step: STEPS) {
  return stepIsAfterStep(step, store.state.userStep);
}

export function stepIsAfterStep(step1: STEPS, step2: STEPS) {
  return orderSteps().indexOf(step1) > orderSteps().indexOf(step2);
}

export function domainFromBillingProfile(
  billingProfile: BillingProfile,
  additionalFields: DomainProfileAdditionalInfoRequest
): DomainProfileExtended {
  return normalizeDomainProfile(
    (omit(
      {
        ...billingProfile,
        ...additionalFields,
        email: additionalFields.email || get(billingProfile, 'emails[0]'),
      },
      'id'
    ) as unknown) as DomainProfile
  )!;
}

export function processOrder(
  termsAndConditionsConsent: boolean = !!store.state.cart
    .termsAndConditionsConsent,
  thirdPartyConsent: boolean = !!store.state.cart.thirdPartyConsent
) {
  // You have some step unsaved
  const stepInEditMode = store.state.inEditMode;
  if (stepInEditMode && stepInEditMode !== STEPS.CART) {
    return navigateToStep(stepInEditMode);
  }

  if (!store.getters.allStepsDone) {
    safePush({ name: ROUTENAMES.ORDER });

    mayGoNext();
    // TODO: mayGoNext should not set editMode, it is not needed always...
    return setEditMode(null);
  }

  const paymentOptGroupId: string = get(
    store.state.cart,
    'paymentOption.groupId'
  );
  if (paymentOptGroupId === PAY_OPTIONS.TRANSFER) {
    const price = store.state.cart?.summary?.price?.vatIncluded || 0;

    // localStorage backup (solves PNO-1566 - loss of vuex transferPrice after refresh)
    setItem(StorageKeys.transferPaymentData, { price }, true);

    store.commit(StateMutations.SET_STATE, {
      prop: 'transferPaymentData',
      value: { price },
    });
  }
  setCheckoutInProgress(true);

  messageService.clearMessages(['general', 'checkout']);

  trackInitiateCheckout();

  checkoutOrder({
    ...{
      redirectAfterCancel: getReturnUrl('cancel'),
      redirectAfterPaySuccess: getReturnUrl('success'),
      redirectAfterPayFailure: getReturnUrl('fail'),
    },
    ...{
      termsAndConditionsConsent,
      thirdPartyConsent,
    },
  })
    .then(processCheckoutResponse(paymentOptGroupId))
    .catch(processCheckoutError);

  goThankYou('processing');
}

export function isOrderStep(step: STEPS | null): boolean {
  return !!step && step !== STEPS.CART;
}

function processCheckoutResponse(paymentOptGroupId: string) {
  return (response: any) => {
    const data = response.data;

    trackPurchase({ ...data, paymentOptGroupId });

    if (data.redirect) {
      redirect(data.redirect);
    } else if (data.zeroOrder) {
      goThankYou('success');
    } else if (data.key === 'checkout.invoice.waiting_for_approval') {
      goThankYou('confirm');
    } else if (paymentOptGroupId === PAY_OPTIONS.LATER) {
      goThankYou('mail');
    } else if (paymentOptGroupId === PAY_OPTIONS.TRANSFER) {
      if (data.paymentOptions) {
        // localStorage backup (solves PNO-1566 - loss of vuex transferPaymentData after refresh)
        const savedData = get(
          store,
          'state.transferPaymentData',
          getItem(StorageKeys.transferPaymentData, true)
        ) as TransferPaymentData;
        const transferPaymentData = {
          ...savedData,
          options: data.paymentOptions,
        };
        setItem(StorageKeys.transferPaymentData, transferPaymentData, true);

        store.commit(StateMutations.SET_STATE, {
          prop: 'transferPaymentData',
          value: transferPaymentData,
        });
        goThankYou(PAY_OPTIONS.TRANSFER);
      } else {
        goThankYou('fail');
        throw new Error('paymentOption missing for transfer view');
      }
    } else {
      goThankYou('success');
    }

    fetchCart().finally(() => {
      setCheckoutInProgress(false);
    });
  };
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function processCheckoutError(err: any) {
  setCheckoutInProgress(false);

  const errData = get(err, 'response.data', {});

  if (errData.invoiceId) {
    store.commit(StateMutations.SET_STATE, {
      prop: 'invoiceId',
      value: errData.invoiceId,
    });
  }

  if (errData.violations) {
    if (!isArray(errData.violations)) {
      for (const item in errData.violations) {
        (errData.violations as any)[item].forEach((violation: any) => {
          if (violation?.propertyPath?.includes('configuration')) {
            store.commit(StateMutations.SET_STATE, {
              prop: 'mandatorySettingsHasIssue',
              value: true,
            });
          }
        });
      }
      Object.entries(errData.violations).some((violation: any) => {
        return violation?.propertyPath?.includes('configuration');
      });
    }

    safePush({ name: ROUTENAMES.ORDER });
    messageService.errorHandler(
      isArray(errData.violations) ? 'cart.items' : 'cart'
    )(err);
  } else {
    messageService.errorHandler('checkout')(err);
    if (errData.context?.errors) {
      if (
        (errData.context as ErrorContext).errors.some(
          (error: any) => error?.context?.step === STEPS.CART
        )
      ) {
        messageService.clearMessages(['checkout', 'cart']);
        safePush({ name: ROUTENAMES.CART });

        // wait for route/view change
        setTimeout(() => {
          setUserStep(STEPS.CART);
        }, 0);
      } else {
        // handle multierrors in order view
        safePush({ name: ROUTENAMES.ORDER });

        // wait for route/view change
        setTimeout(async () => {
          await setUserStep(STEPS.RECAPITULATION);
          if (store.getters.firstStepWithIssues) {
            navigateToStep(store.getters.firstStepWithIssues, false);
          }
        }, 0);
      }
    } else {
      goThankYou('exception', errData.key);
    }
  }
}

function getReturnUrl(messageKey?: string) {
  return constructUrlFromRoute(router, {
    name: ROUTENAMES.THANK_YOU,
    query: { messageKey: messageKey || 'success' },
  });
}

function goThankYou(messageKey: string, exception?: string) {
  safePush({
    name: ROUTENAMES.THANK_YOU,
    query: { messageKey, exception },
  });
}

function orderSteps() {
  return store.getters.orderSteps;
}

export function ensureOrderStep() {
  if (!isOrderStep(store.state.userStep)) {
    setUserStep(orderSteps()[1]);
  }
}

export function setUserStep(step: STEPS) {
  return store.dispatch(StoreActions.SET_USER_STEP, { step });
}

function setCheckoutInProgress(value: boolean) {
  store.commit(StateMutations.SET_STATE, {
    prop: 'checkoutInProgress',
    value,
  });
}

function setQuickOrderInProgress(value: boolean) {
  store.commit(StateMutations.SET_STATE, {
    prop: 'quickOrderInProgress',
    value,
  });
}

export function performQuickOrder(): Promise<any> {
  setQuickOrderInProgress(true);
  // ITDEV-8159 - Track selected payment method separately from purchase
  trackPaymentMethod(get(
    store.state.cart,
    'paymentOption.groupId'
  ));
  return network
    .post('/quick-order')
    .then(updateCartState)
    .then(() => setQuickOrderInProgress(false))
    .catch((error: any) => {
      fetchCart().finally(() => setQuickOrderInProgress(false));
      return Promise.reject(error);
    });
}
