import {replacePlaceholders, redirect} from '@loopia-group/utils';
import {get} from 'lodash-es';
import axios from 'axios';
import type { ActionOptions } from './responseAction.service.d';
import type { DialogActions } from './dialogActions.d';
import type { MessageService } from './message.service';
import type Vue from 'vue';
import type { QDialog } from 'quasar';

// This service is WIP: It should unify all actions that should follow after a request.

export const responseAction = async (data: any, headers: any, opts: ActionOptions, context: Vue) => {
  const actionPromise = new Promise((res) => {
    res(executeAction(data, headers, opts, context));
  });
  return {
    actionPromise,
    killAction: () => {/* TODO kill action (mostly for polling action) */
    },
  };
};

function getPollUrl(data: any, opts: ActionOptions) {
  return data?.action === 'poll' ? data.url || opts?.poll : opts?.poll;
}

function getRedirectUrl(data: any, opts: ActionOptions) {
  return data?.action === 'redirect' ? data.url || opts?.redirect : data?.redirectTo || opts?.redirect;
}

function handleDialog(context: Vue, opts: ActionOptions, dialogAction: DialogActions = 'toggle') {
  if (opts?.openModal) {
    const dialog: QDialog = (context?.$root.$refs[opts.openModal] as QDialog);

    dialog ? dialog[dialogAction]() : console.error(`Modal ${opts.openModal} not found`);
  }
}

async function handlePollingDialog(context: Vue, opts: ActionOptions, formattedPollUrl: string, pollLimit: number) {
  handleDialog(context, opts, 'show');
   
  if (!formattedPollUrl) {
    handleDialog(context, opts, 'hide');
    return false;
  }

  await pollStatusAfterSubmit(formattedPollUrl, pollLimit);
  handleDialog(context, opts, 'hide');
  return false;
}

const executeAction = async (data: any, headers: any, opts: ActionOptions, context: Vue): Promise<boolean> => {
  const contentHeader = headers['content-disposition'];
  // Check if request includes download attachment
  const isFileDownload = contentHeader?.includes('attachment');

  if (isFileDownload) {
    // Extract download filename from reponse headers
    const fileName = contentHeader.replaceAll(/"/g, '').slice(contentHeader.indexOf('=') + 1);

    executeFileDownload(data, headers, fileName);
    return false;
  }

  const pollUrl = getPollUrl(data, opts);
  const pollLimit = 30;
  const formattedPollUrl = replacePlaceholders(pollUrl, get(data, 'data', data));

  let redirectUrl = getRedirectUrl(data, opts);

  if (formattedPollUrl) {
    // pending modal usecase
    if (opts?.openModal) {
      return handlePollingDialog(context, opts, formattedPollUrl, pollLimit);
    } else {
      redirectUrl = await pollStatusAfterSubmit(formattedPollUrl, pollLimit) || redirectUrl;
    }
  }
  
  if (redirectUrl) {
    redirect(redirectUrl);
    return true;
  }

  if (opts?.reload) {
    window.location.reload();
    return true;
  }

  handleDialog(context, opts);

  if (headers['x-flash-messages']?.length) {
    setFlashMessages(headers['x-flash-messages'], context.$root.$messageService);
  }

  // reload components on success
  if (opts?.reloadComponents) {
    reloadComponents(opts?.reloadComponents);
    return false;
  }

  return false;
};

function reloadComponents(componentsToReload: string[]) {
  //@ts-ignore
  const rootRefs = window.__WS_Admin.$root.$refs;

  componentsToReload.forEach(cmp => {
    //@ts-ignore
    rootRefs[cmp]?.reload();
  });
}

const pollStatusAfterSubmit = (url: string, limit: number): Promise<string> => new Promise(async (res, rej) => {
  if (limit <= 0) {
    return rej('Server polling exceeded it\'s connection limit.');
  }
  try {
    const resp = await axios({url});
    if (resp.status === 200) {
      limit = -1; // Just to be sure that we won't continue spamming the server if something goes wrong
      return res(resp.data.redirectTo);
    }
  } catch (err) {
    /* Ignore errors, we just wait until the response is correct */
  }

  setTimeout(async () => {
    try {
      res(await pollStatusAfterSubmit(url, limit));
    } catch (err) {
      rej(err);
    }
  }, 2000);

});

const executeFileDownload = (data: any, headers: any, fileName: string) => {
  const blob:any = new Blob([data], { type: headers['content-type'] });
  const href = URL.createObjectURL(blob);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const setFlashMessages = (messagesJson: any, messageService: MessageService) => {
  const messagesArray = JSON.parse(messagesJson);

  messageService.setFlashMessages(messagesArray);
};
