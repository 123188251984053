/*
  Slovensko Digital autofill
  https://loopiagroup.atlassian.net/browse/PNO-1836
  - for billing profiles
  - only for SK market
  - hooks for "Company name" and "Company ID" fields
  - If any of suggested companies is selected from a dropdown, replace all fetched values in corresponding input fields

  All fields should stay editable (so not "readonly" as
  in https://ekosystem.slovensko.digital/sluzby/autoform example) ...
  customer should still be able to update those values even if he/she
  used autoform functionality

  docs: https://ekosystem.slovensko.digital/sluzby/autoform/integracny-manual#api
 */

import Axios from 'axios';

const network = Axios.create({withCredentials: false});

import type {AutofillQuery, AutofillCompanyItem} from './autocomplete.service.d';

export function getAutofillSuggestions({
  name = null,
  cin = null,
  limit = 5,
  filter = 'active',
}: AutofillQuery): Promise<AutofillCompanyItem[]> {
  const apiToken =
    'f639d8d3ff0b9172721f468f8d71c99699cbcda3366b6ac7278446f012fc538483a62815865264cc';

  // at least one query param filled
  if (!name && !cin) {
    return Promise.reject();
  }

  const q = name ? `name:${name}` : `cin:${cin}`;
  const endpoint =
    'https://autoform.ekosystem.slovensko.digital/api/corporate_bodies/search?q=' +
    encodeURIComponent(q) +
    '&limit=' +
    encodeURIComponent(limit) +
    '&filter=' +
    encodeURIComponent(filter) +
    '&private_access_token=' +
    apiToken;

  return network
    .get(endpoint)
    .then(result => {
      return result.data as AutofillCompanyItem[];
    })
    .catch(() => []); // if error, just return "No results" in autocomplete... It's not that important
}
