
import { Component, Prop, Vue, Ref } from 'vue-property-decorator';
import HostingTutorialPriceFrom from './HostingTutorialPriceFrom.vue';
import HostingTutorialAddToCart from '@/components/hostingTutorial/HostingTutorialAddToCart.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import { HostingTutorialStandaloneProduct, PricesItem } from '@/types';
import { getSnakedKeyFactory } from '@loopia-group/utils';
import { trans } from '../../WS_UIkit/src/services/i18n/i18n.service';

@Component({
  components: {
    WsIcon,
    HostingTutorialPriceFrom,
    HostingTutorialAddToCart,
  },
})
export default class HostingTutorialProduct extends Vue {
  @Prop({ required: true }) readonly product!: HostingTutorialStandaloneProduct;
  @Prop({ default: false }) readonly isActive!: boolean;

  @Ref('list') readonly list!: HTMLUListElement;

  productHasDynamicBullets = this.product.properties.type !== undefined;
  selectedType: string = '';
  trans = trans;

  valueSelected(value: string) {
    this.selectedType = value;
  }

  get dynamicBullets() {
    let index = 1;
    const dynamicTranslations = [];
    const dynamicBulletKey = (index: number) => {
      return this.trans(
        this.getProductTranslation(
          `${this.product.name}.type.${this.selectedType}.bullet${index}`
        )
      );
    };

    do {
      dynamicTranslations.push(dynamicBulletKey(index));
      index += 1;
    } while (dynamicBulletKey(index) !== '');

    return dynamicTranslations;
  }

  get bullets() {
    let index = 1;
    const translations = [];
    const bulletKey = (index: number) => {
      return this.trans(
        this.getProductTranslation(`${this.product.name}.bullet${index}`)
      );
    };

    do {
      translations.push(bulletKey(index));
      index += 1;
    } while (bulletKey(index) !== '');

    return translations;
  }

  get priceItemFrom(): PricesItem | null {
    return this.product.minPriceConfiguration;
  }

  get activeListColumnClass(): string {
    return this.isActive ? 'col-md-12' : 'col-md-8';
  }

  getProductTranslation(key: string): string {
    const getSnakedTranslation = getSnakedKeyFactory(
      'hosting_tutorial.product'
    );

    return getSnakedTranslation(key);
  }
}
