import config from '@/services/config.service';
import { getLang, i18nService } from '@WS_UIkit';
import Router, { RawLocation } from 'vue-router';
import { byteConvert } from '@loopia-group/utils';
import { get, isNumber } from 'lodash-es';
import { Price } from '@/components/domain-checker/dc.types';
import { PricesItem, ProductPrice } from '@/types';
import {ROUTENAMES} from '@/const.enum';


const contactSkHelpdeskUrl = 'https://www.websupport.sk/kontakt/';

export function getLoginUrl(router: Router, name: string, step?: string) {
  let resultloginUrl = config.loginUrl;
  const route = { name, query: undefined };
  if (name === ROUTENAMES.DOMAIN_CHECKER) {
    // pass all the query params from URL
    route.query = get(router, 'route.query', {});
  }

  let routeUrl = constructUrlFromRoute(router, route);
  if (name !== ROUTENAMES.DOMAIN_CHECKER && step) {
    routeUrl = setQueryParam(routeUrl, 'step', step);
  }

  resultloginUrl = setQueryParam(
    resultloginUrl,
    'redirectAfterLogin',
    routeUrl
  );
  resultloginUrl = setQueryParam(resultloginUrl, 'lang', getLang().slice(0, 2));
  return resultloginUrl;
}

export function constructUrlFromRoute(
  router: Router,
  route: RawLocation
): string {
  const routeUrl = new URL(window.location.origin + router.resolve(route).href);
  const params = new URLSearchParams(routeUrl.search);
  const originalParams = new URLSearchParams(window.location.search);
  originalParams.forEach(function(value, key) {
    params.set(key, value);
  });
  return routeUrl.origin + routeUrl.pathname + '?' + params.toString();
}

export function getDomainTransferUrl(): string {
  switch (getLang()) {
  case 'sk-SK': {
    return 'https://www.websupport.sk/domeny/transfer/';
  }
  case 'cz-CZ': {
    return 'https://www.websupport.cz/domeny/transfer/';
  }
  case 'hu-HU': {
    return 'https://www.websupport.hu/domain/transzfer/;';
  }
  case 'se-SE': {
    return 'https://websupport.se/doman/domantjanster/';
  }
  case 'en-SE': {
    return 'https://websupport.se/en/domains/domain-services/';
  }
  default:
    return 'https://www.websupport.sk/domeny/transfer/';
  }
}

export function getContactHelpdeskUrl(): string {
  switch (getLang()) {
  case 'sk-SK': {
    return contactSkHelpdeskUrl;
  }
  case 'cz-CZ': {
    return 'https://www.websupport.cz/kontakt/';
  }
  case 'hu-HU': {
    return 'https://www.websupport.hu/kapcsolat/;';
  }
  case 'se-SE': {
    return 'https://websupport.se/support/';
  }
  case 'en-SE': {
    return 'https://websupport.se/en/support/';
  }
  default: {
    return contactSkHelpdeskUrl;
  }
  }
}

export function getEditBusinessMailLicensesTutorialUrl(): string {
  const language = getLang();

  if (language === 'sk-SK') {
    return 'https://www.websupport.sk/podpora/kb/uprava-poctu-licencii-v-sluzbe-biznis-mail/';
  } else {
    return contactSkHelpdeskUrl;
  }
}

export function setQueryParam(fullUrl: string, param: string, value: string) {
  return changeQuery(fullUrl, params => params.set(param, value));
}

export function deleteQueryParam(fullUrl: string, param: string) {
  return changeQuery(fullUrl, params => params.delete(param));
}

// ref: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/set#Examples
export function changeQuery(
  fullUrl: string,
  // eslint-disable-next-line no-unused-vars
  operation: (params: URLSearchParams) => void
): string {
  const url = new URL(fullUrl);
  const params = new URLSearchParams(url.search);
  operation(params);
  return url.origin + url.pathname + '?' + params.toString();
}

export function processProps(
  properties: Record<string, any> | undefined,
  requests: { convert?: string[]; ensure?: string[] }
): Record<string, string> {
  const props: Record<string, any> = { ...(properties ?? {}) };

  requests.ensure?.forEach(prop => {
    props[prop] = props[prop] || i18nService.t('general.not_provided');
  });

  requests.convert?.forEach(prop => {
    props[prop] = byteConvert(props[prop], 'MB');
  });

  return props;
}

function resolvePricesArray(period: number | undefined, prices: PricesItem[], useMarketingPrice?: boolean) {
  let price: any;
  const priceToUse = useMarketingPrice ? 'marketingPrice' : 'actualPrice';
  period = isNumber(period) ? period : 12;
  price = prices?.find(p => p.period === period)?.[priceToUse];

  if (price === undefined) {
    // fallback if prices does have just "period: 1" case
    price = prices?.find(p => p.period === 1)?.[priceToUse];
    if (isNumber(price)) {
      //@ts-ignore
      price *= period;
    }
  }

  return price;
}

export function resolvePrice(
  prices: number | null | undefined | Price | ProductPrice | PricesItem[],
  period?: number,
  useMarketingPrice: boolean = false
): number | null | undefined {
  if (isNumber(prices)) {
    return prices;
  }

  if (Array.isArray(prices)) {
    return resolvePricesArray(period, prices, useMarketingPrice);
  }

  const priceToUse = useMarketingPrice? 'marketingPrice' : 'actualPrice';

  const price = (prices as Price)?.[priceToUse];
  const vatExcluded = (prices as ProductPrice)?.vatExcluded;
  if (price !== undefined) {
    return price;
  } else if (vatExcluded !== undefined) {
    return vatExcluded;
  } else {
    return prices as null | undefined;
  }
}

export function getDiscount(
  actualPrice: number,
  marketingPrice: number
): number | null {
  if (marketingPrice && actualPrice && actualPrice < marketingPrice) {
    const discount = ((marketingPrice - actualPrice) / marketingPrice) * 100;

    return discount && discount > 0 ? Math.round(discount) : null;
  }

  return null;
}
