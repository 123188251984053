import type { QField, QForm } from 'quasar';
import type VueI18n from 'vue-i18n';

import {
  email as vEmail,
  minLength as vMinLength,
  maxLength as vMaxLength,
} from 'vuelidate/lib/validators';

import { isArray } from 'lodash-es';
import type { QFormComponent } from '@loopia-group/ts/types/wsk_types.d';
import { i18nService } from './../i18n/i18n.service';
import { defaultValidationApiProvider } from './validation-api.service';
import type {
  ValidatorBoolean,
  Validator,
  PropertyValidation,
  ValidationApiProvider,
  CheckIsicRequest,
  PropertyValidationList,
  CheckVatIdRequest,
  ValidationAsync,
  ValidatorAsync,
  CheckCompanyIdRequest,
  CheckTaxIdRequest,
  PropertyValidationLists,
} from './validation.service.d';
import { castArray, merge } from 'lodash-es';
import * as punycode from 'punycode';

let i18n: VueI18n;
let validationApiProvider: ValidationApiProvider = defaultValidationApiProvider;
// reuse forst call to validation API if params are same and it already waits for response
const duplicatesProtection: Record<
  string,
  Promise<string | true>
> = Object.create(null);

i18nService.afterInit.then(() => {
  i18n = i18nService.i18n;
});

/**********************************************
 *                  Methods                  *
 **********************************************/

export function setValidationApiProvider(provider: ValidationApiProvider) {
  validationApiProvider = provider;
}

/**********************************************
 *                Validators                  *
 **********************************************/

export function required(val: any): true | string {
  const validationMessage = i18n.t('validation.field.required') as string;
  switch (true) {
  case isArray(val):
    return (!!val.length && (!!val[0] || val[0] === 0)) || validationMessage;
  case typeof val === 'object' && val !== null:
    val = val.value;
    // falls through
  default:
    return !!val || val === 0 || validationMessage;
  }
}

export function email(val: string | string[]): true | string {
  // vEmail as any ---> due to typing and implementation mismatch in vuelidate lib
  const result = oneOrArray(val, vEmail as any);
  return result || (i18n.t('validation.field.invalid_email') as string);
}

// validator which takes emails as one string with comma separated emails
export function emails(val: string): true | string {
  const parsed = val.split(',').map(email => email.trim());
  return email(parsed);
}

// TODO create universal factory for creation array supporting third party validators
export function minLength(limit: number): Validator {
  const vMinLengthWithParam = vMinLength(limit);
  return (val: string | string[]) => {
    if (!limit) {
      return true;
    }
    const result = oneOrArray(val, vMinLengthWithParam);
    return result || (i18n.t('validation.field.too_short') as string);
  };
}

export function maxLength(limit: number): Validator {
  const vMaxLengthWithParam = vMaxLength(limit);
  return (val: string | string[]) => {
    if (!limit) {
      return true;
    }
    const result = oneOrArray(val, vMaxLengthWithParam);
    return result || (i18n.t('validation.field.too_long') as string);
  };
}

// Regexp taken from https://regexr.com/3au3g, but removed numbers from tld! As currently there is no tld with numbers
// ref: https://stackoverflow.com/questions/9071279/number-in-the-top-level-domain
const DOMAIN_REGEXP = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z][a-z-]{0,61}[a-z]$/;
export function isDomain(val: string): true | string {
  const domain = punycode.toASCII(val);
  const result = oneOrArray(domain, (v: string) => DOMAIN_REGEXP.test(v));

  return result || (i18n.t('validation.domain_name.not_valid') as string);
}

export function nameValidator(name: string): true | string {
  const result = oneOrArray(name, (val: string) => val.trim().includes(' '));
  return result || (i18n.t('validation.name.not_full') as string);
}

export function always(): true {
  return true;
}

const validLoginRegexp = /^[0-9A-Za-z@\-._]*$/;
export function validLogin(login: string): true | string {
  return login.match(validLoginRegexp)
    ? true
    : (i18n.t('validation.login.validation.regex') as string);
}

export const validateIsic: ValidatorAsync = (params: CheckIsicRequest) => {
  if (!params.isic) {
    return Promise.resolve(true);
  }
  return validationApiProvider
    .checkIsic(params)
    .then(data =>
      Promise.resolve(
        data.valid
          ? true
          : (i18n.t('validation.bonus_card.not_valid') as string)
      )
    );
};

/**********************************************
 *     Unified-validation across system       *
 **********************************************/

/*
 *
 * HOW TO USE:
 *
 * 1. in component where you want to use it, ensure that propertyList which you want
 *    to use is added, don't worry it can be added multiple times as it just rewrites
 *    itself (in future, this can be somehow optimized, maybe using some identification,
 *    not to load same list again, or even some better way, but keep it tree-shakable pls)
 *
 *    ```
 *      addValidations(addressPropertyList);
 *    ```
 *
 *    if you forget to load and then try to use, it just does not validate (worst scenario
 *    which can occur) so it does not lock system, it should fall on BE validation instead
 *
 * 2. get rules for validation or restriction where needed
 *    ```
 *      const { rules, restriction } = propertyValidation('some_key');
 *    ```
 *    or one of them separatelly
 *    ```
 *      const rules = propertyRules('some_key');
 *      const restriction = propertyRestriction('some_key');
 *    ```
 */

const richAlfaNumeric = '0-9A-Za-z\\u00C0-\\u024F\'\\- ';
const alfaNumericAndDash = '0-9A-Za-z\\-'; // cannot use \w in restriction as it includes "_"
const streetValidation: PropertyValidation = {
  rules: [minLength(3), maxLength(64)],
  restriction: '',
};
const emailValidation: Partial<PropertyValidation> = { rules: [email] };

// list of properties and their validations or restrictions in one place so they are
// unified in system aka validated and restricted same way in all places
// BUT I splitted them to multiple lists, so it can be treeshaked
export const propertyList: PropertyValidationList = {
  /* this will be loaded by addValidations */
};
export const generalPropertyList: PropertyValidationList = {
  domain: { rules: [isDomain] },
  login: {
    rules: [minLength(2), maxLength(125)],
    restriction: '0-9A-Za-z\\-\\.',
  },
  email: emailValidation,
};
export const productPropertyList: PropertyValidationList = {
  vdc_name: { rules: [minLength(3), maxLength(70)], restriction: '-\\w' },
  vps_name: { rules: [minLength(5), maxLength(64)], restriction: 'a-z0-9' },
  server_name: {
    rules: [minLength(2), maxLength(125)],
    restriction: '0-9A-Za-z',
  },
};
export const addressPropertyList: PropertyValidationList = {
  address_organisation: { rules: [maxLength(100)] },
  address_name: {
    rules: [minLength(2), maxLength(64)],
    restriction: richAlfaNumeric,
  },
  address_email: emailValidation,
  address_emails: { rules: [emails] },
  address_street: streetValidation,
  address_street2: streetValidation,
  address_city: { rules: [minLength(1), maxLength(64)] },
  address_postalCode: {
    rules: [minLength(2), maxLength(10)],
    restriction: alfaNumericAndDash,
  },
};
export const profilePropertyList: PropertyValidationList = {
  profile_passport: { rules: [minLength(5)], restriction: alfaNumericAndDash },
  profile_idCard: { rules: [minLength(5)], restriction: alfaNumericAndDash },
};

export function addValidations(lists: PropertyValidationLists) {
  lists = castArray(lists) as PropertyValidationList[];
  merge(propertyList, ...lists);
}

export function propertyValidation(key: string): PropertyValidation {
  return {
    rules: [],
    restriction: '',
    ...propertyList[key],
  };
}

export function propertyRules(key: string): PropertyValidation['rules'] {
  return propertyValidation(key).rules || [];
}

export function propertyRestriction(
  key: string
): PropertyValidation['restriction'] {
  return propertyValidation(key).restriction || '';
}

/**********************************************
 *              Semi-validators               *
 **********************************************/
// semi-validators are validators which cannot be
// used directly on value but needs to be supported
// by other dynamic values, therefore they are used
// to help create final validator in controllers

const countryRequiredKey = 'wsk.validation.country_required';

export const validateVat: ValidatorAsync = async (params: CheckVatIdRequest) => {
  if (!params.vatId) {
    // let it fail on "required" validator if needed
    return Promise.resolve(true);
  }
  if (!params.countryCode) {
    return Promise.resolve(i18n.t(countryRequiredKey) as string);
  }
  const hash = 'vat:' + params.vatId + '::' + params.countryCode;
  if (await duplicatesProtection[hash]) {
    return duplicatesProtection[hash];
  }
  duplicatesProtection[hash] = validationApiProvider
    .checkVatID(params)
    .then(data => {
      delete duplicatesProtection[hash];
      return Promise.resolve(
        data.valid
          ? true
          : (i18n.t(
            params.countryCode === 'CZ'
              ? 'wsk.validation.tax_id.not_valid'
              : 'wsk.validation.vat_id.not_valid'
          ) as string)
      );
    });
  return duplicatesProtection[hash];
};

export const validateTax: ValidatorAsync = async (params: CheckTaxIdRequest) => {
  if (!params.taxId) {
    // let it fail on "required" validator if needed
    return Promise.resolve(true);
  }
  if (!params.countryCode) {
    return Promise.resolve(i18n.t(countryRequiredKey) as string);
  }
  const hash = 'tax:' + params.taxId + '::' + params.countryCode;
  if (await duplicatesProtection[hash]) {
    return duplicatesProtection[hash];
  }
  duplicatesProtection[hash] = validationApiProvider
    .checkTaxID(params)
    .then(data => {
      delete duplicatesProtection[hash];
      return Promise.resolve(
        data.valid
          ? true
          : (i18n.t('wsk.validation.tax_id.not_valid') as string)
      );
    });
  return duplicatesProtection[hash];
};

export const validateCompany: ValidatorAsync = async (
  params: CheckCompanyIdRequest
) => {
  if (!params.companyId) {
    // let it fail on "required" validator if needed
    return Promise.resolve(true);
  }
  if (!params.countryCode) {
    return Promise.resolve(i18n.t(countryRequiredKey) as string);
  }
  const hash = 'company:' + params.companyId + '::' + params.countryCode;
  if (await duplicatesProtection[hash]) {
    return duplicatesProtection[hash];
  }

  duplicatesProtection[hash] = validationApiProvider
    .checkCompanyID(params)
    .then(data => {
      delete duplicatesProtection[hash];
      return Promise.resolve(
        data.valid
          ? true
          : (i18n.t('wsk.validation.company_id.not_valid') as string)
      );
    });
  return duplicatesProtection[hash];
};

/**********************************************
 *              Other methods                 *
 **********************************************/

// eslint-disable-next-line no-unused-vars
export type ValidatorFactory = (fieldId: string) => Validator;

// TODO: try to make simplified implementation using
// advantage of quasar "reactive-rules"
export function requireOneOf(
  fields: Map<string /* fieldId */, QField>,
  errorMessage?: string
): ValidatorFactory {
  // this layer is used in controller where all related fields are handled
  const fieldsArray = Array.from(fields);
  const fieldsSize = fields.size;
  const stack: string[] = [];
  const touched: { [key: string /* fieldId */]: true } = Object.create(null);
  let promise: ValidationAsync | null;
  let resolve: Function | null;

  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function fieldValidatorFactory(fieldId: string) {
    // this layer is used to provide customized validator for one exact field of related fields
    return function fieldValidator(/* val: any */):
      | ValidationAsync
      | true
      | string {
      // this layer is validator function it self used in quasar validation mechanism
      if (!touched[fieldId] && !promise) {
        // tracking touched for case when user tabs over fields
        // so we want to give him a chance not to hit error
        // until he left last one of related fields
        touched[fieldId] = true;
      }

      if (!promise) {
        // this field is validated as first from all related fields
        promise = new Promise<true | string>(resolveFn => {
          resolve = resolveFn;
        });
        validateOthers();
      }
      return processField(fieldId);
    } as Validator;

    function processField(fieldId: string) {
      stack.push(fieldId);
      if (stack.length === fieldsSize) {
        // this was the last field to init so we start validation
        const results = Array.from(fields, ([, field]) => {
          return required((field as QFormComponent).value);
        });

        const result: true | string =
          results.some(res => res === true) ||
          errorMessage ||
          !fieldsArray.every(([key]) => !!touched[key]) ||
          (i18n.t('validation.required_one_of_many') as string);

        resolve!(result);
        reset();

        return Promise.resolve(result);
      } else {
        return promise!;
      }
    }

    function validateOthers() {
      fieldsArray.forEach(([key, field]) => {
        if (key !== fieldId && field) {
          field.validate();
          if (!stack.includes(key)) {
            stack.push(key);
          }
        }
      });
    }

    function reset() {
      resolve = null;
      promise = null;
      stack.splice(0);
    }
  };
}

export interface RequireConfig {
  require?: string[];
  requireOneOf?: string[][];
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function requiredByConfig(
  fields: Map<string /* fieldId */, QField>,
  config: RequireConfig,
  errorMessage?: string
): ValidatorFactory {
  const rulesPerField: {
    [key: string]: Validator[];
  } = Object.create(null);
  if (!config) {
    return (/* fieldId */) => {
      return always;
    };
  }
  if (config.require) {
    config.require.forEach(fieldId => (rulesPerField[fieldId] = [required]));
  }
  if (config.requireOneOf) {
    config.requireOneOf.forEach(group => {
      const groupFields: Map<string /* fieldId */, QField> = new Map();
      group.forEach(fieldId => {
        groupFields.set(fieldId, fields.get(fieldId)!);
      });
      const validatorProvider = requireOneOf(groupFields, errorMessage);
      group.forEach(fieldId => {
        rulesPerField[fieldId] = [
          ...(rulesPerField[fieldId] || []),
          validatorProvider(fieldId),
        ];
      });
    });
  }

  return function fieldValidatorFactory(fieldId: string) {
    return function fieldValidator(val: any): ValidationAsync {
      const promises: ValidationAsync[] = [];
      if (!rulesPerField[fieldId]) {
        // TODO report to sentry
        // eslint-disable-next-line no-console
        console.error(fieldId + ' field not found');
        return Promise.resolve(true);
      }
      rulesPerField[fieldId].forEach(rule => {
        const result = rule(val);
        promises.push(
          result instanceof Promise ? result : Promise.resolve(result)
        );
      });
      return Promise.all(promises).then(results => {
        let message = '';
        for (let index = 0; index < results.length; index++) {
          const result = results[index];
          if (typeof result === 'string') {
            message = result;
            break;
          }
        }
        return message || true;
      });
    };
  };
}

export function validateForm(form: Element & QForm): Promise<boolean> {
  if (!form) {
    return Promise.resolve(true);
  }
  return form.validate().then(result => {
    if (result === false) {
      // run validate on every field inside form to get all fields red and to display all errors
      // otherwise form will highlight only first error
      // eslint-disable-next-line
      // docs: https://github.com/quasarframework/quasar/blob/ca1ef4f97487d957c930d81418b12b10166ed86b/ui/src/components/form/QForm.js#L155
      (form as any).getValidationComponents().forEach((cmp: QField) => {
        cmp.validate && cmp.validate();
      });
    } else if (result === undefined) {
      // TODO: WHY? I dont know how is this happning but sometimes form is
      //  validated twice, then second
      // validations causes first validation promise to end with "undefined"
      // result, but as promise is resolved it
      // is passed here, therefore this is a temporary fix until
      // I figure uot what is happening
      result = true;
    }
    return Promise.resolve(result);
  });
}

// ensures always return promise from QField validation, never boolean
// ref: https://v1.quasar.dev/vue-components/field#qfield-api
export function validateInput(field: any): Promise<boolean> {
  if (!field.validate) {
    return Promise.resolve(true); // no validate method, consider valid
  }
  const validation: Promise<boolean> | boolean = (field as QField).validate();
  if ((validation as any).then) {
    return validation as Promise<boolean>;
  }
  return Promise.resolve(validation);
}

/**********************************************
 *                  Helpres                   *
 **********************************************/

export function oneOrArray(val: any, validator: ValidatorBoolean): boolean {
  return isArray(val) ? !val.some((v: any) => !validator(v)) : validator(val);
}
