
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QItem, QItemSection, QItemLabel } from 'quasar';

import { CartItem } from '@/types';
import { Theme } from '@loopia-group/services';

import {get, cloneDeep, isNumber, debounce} from 'lodash-es';
import {getSnakedKeyFactory} from '@loopia-group/utils';

import {
  productPropertyList,
  addValidations,
  generalPropertyList,
  getLang,
} from '@WS_UIkit';
import WsInput from '@WS_Components/WsInput.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsSelectNumber from '@WS_Components/WsSelectNumber.vue';
import CartItemInfoBox from '@/modules/checkout/components/cart/CartItemInfoBox.vue';
import { getLoginUrl } from '@/utilities';
import { ROUTENAMES } from '@/const.enum';
import {
  Message,
  MessagesProviderOptions,
} from '@WS_UIkit/types';
import {getContactHelpdeskUrl, getDomainTransferUrl, getEditBusinessMailLicensesTutorialUrl} from '@/utilities';
import WsToggle from '@WS_Components/WsToggle.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import {AddonItem, AddonNumeric, AddonOptions} from '@/modules/checkout/types';
import WsFormSlider from '@WS_Components/WsFormSlider.vue';
import WsSlider from '@WS_Components/WsSlider.vue';

type PeriodOption = {
  period: number;
  price: number;
  onlyCardPayment: boolean;
};

type SelectInputOption = {
  value: string;
  monthlyPrice: string;
};

const DEFAULT_PERIOD = 12;
const DEFAULT_PERIOD_OPT: PeriodOption = {
  period: DEFAULT_PERIOD,
  price: 0,
  onlyCardPayment: false,
};

enum AddonType {
  NUMERIC = 'numeric',
  BOOLEAN = 'boolean',
  OPTIONS = 'options',
};

@Component({
  components: {
    WsSlider,
    WsCheckbox,
    WsToggle,
    QItem,
    QItemSection,
    QItemLabel,
    WsInput,
    WsButton,
    WsSelect,
    WsFormSlider,
    CartItemInfoBox,
    WsSelectNumber,
  },
  computed: {
    ...mapState(['cartService']),
  },
})
export default class CartItemSettings extends Vue {
  @Prop(Boolean) readonly value!: boolean;
  @Prop(Object) readonly initEditItem!: CartItem | null;

  editItem: CartItem | null = null;
  selectedOpt: PeriodOption | null = null;
  isNumber = isNumber;
  path = 'cart_item_settings';
  businessMailCode = 'business-mail';

  Theme = Theme;
  language: string = getLang();
  domainMessageServicePath: string = `${this.path}.properties.domain`;
  messagesProviderOptions: MessagesProviderOptions = {
    recursive: true,
    fallback: true,
  };
  AddonType = AddonType;
  addons: AddonItem[] = [];

  created() {
    addValidations([generalPropertyList, productPropertyList]);

    this.addons = this.initEditItem?.settings?.addons?.map((addon: AddonItem) => {
      return addon.type === AddonType.OPTIONS ? this.createOptions(addon as AddonOptions) : addon;
    });
  }

  // The assignment of this object is deferred to mounted() since jest has problem detecting $t() otherwise.
  mounted() {
    this.$root.$messageService.subscribe(
      this.domainMessageServicePath,
      this.messagesProviderOptions
    );
  }

  destroyed() {
    this.$root.$messageService.unsubscribe(
      [this.domainMessageServicePath],
      this.messagesProviderOptions
    );
  }

  updateNumberAddonDebounced = debounce(this.updateAddon, 400);

  createOptions(addon: AddonOptions) {
    const clonedAddon = cloneDeep(addon);
    return Object.assign({}, clonedAddon, {
      options: clonedAddon.options.map((opt: any) => ({
        ...opt,
        label: this.$t(`product.addons.options.${addon.name}.${opt.value}`),
      }))
    });
  }

  updateAddon(name: string, value: number | boolean | SelectInputOption) {
    const selectedAddon = this.addons.find((item: AddonItem) => item.name === name);
    if (selectedAddon) {
      if (selectedAddon.type === AddonType.OPTIONS) {
        selectedAddon.value = (value as SelectInputOption).value;
      } else {
        selectedAddon.value = value as string | number | boolean;
      }
      this.clearMessages();
      this.updateItem(false);
    }
  }

  periodToLabel(period: number) {
    return period % 12 === 0
      ? this.$t('wsk.general.period.years', { period: period / 12 })
      : this.$t('wsk.general.period.months', { period });
  }

  setPeriod(periodOption: PeriodOption) {
    this.clearMessages();
    if (this.editItem && this.editItem.period !== periodOption.period) {
      this.editItem.period = periodOption.period;
      this.updateItem(false);
    }
  }

  async updateItem(emitUpdate?: boolean) {
    if (!this.editItem) {
      return;
    }

    if (this.addons?.length > 0) {
      this.addons.forEach((addon: AddonItem) => {
        if (addon.type === AddonType.NUMERIC) {
          this.editItem!.properties!.addons[addon.name] = (addon as AddonNumeric).value - (addon as AddonNumeric).base;
        } else {
          this.editItem!.properties!.addons[addon.name] = addon.value;
        }
      });
    }

    this.$emit('settings', emitUpdate, this.editItem);
  }

  clearMessages(): void {
    if (this.generalMessages.length > 0) {
      this.$root.$messageService.clearMessages({
        path: this.path,
      });
    }

    if (this.domainMessages.length > 0) {
      this.$root.$messageService.clearMessages({
        path: this.domainMessageServicePath,
      });
    }
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('service_settings.props');
    return this.getKey(prop, key);
  }

  updateDomainName(): void {
    this.$emit('domainupdate', this.editItem);
  }

  get domainErrorMessages() {
    const messages = [];
    for (const domainMessage of this.domainMessages) {
      const translationKey = domainMessage.translationKey;

      const message =
        this.$t(`${translationKey}`) || this.$t(`${translationKey}.message`);
      const redirectPrompt = this.$t(`${translationKey}.redirect_prompt`);
      let redirectUrl;
      switch (translationKey) {
      case 'not_available' || 'not_logged_in':
        redirectUrl = getLoginUrl(this.$router, ROUTENAMES.CART);
        break;
      case 'current_user_is_not_the_owner' || 'has_active_mailboxes' || 'please_contact_helpdesk':
        redirectUrl = getContactHelpdeskUrl();
        break;
      case 'is_registered_elsewhere':
        redirectUrl = getDomainTransferUrl();
        break;
      case 'already_uses_business_mail':
        redirectUrl = getEditBusinessMailLicensesTutorialUrl();
        break;
      default:
        redirectUrl = null;
      }

      messages.push({
        message,
        redirectPrompt,
        redirectUrl,
      });
    }

    return messages;
  }

  get item(): CartItem {
    // @ts-ignore
    return this.cartService.itemInEdit;
  }

  get generalMessages(): Message[] {
    return this.$root.$messageService.getters.messagesByPath(this.path, {
      recursive: true,
    });
  }

  get domainMessages(): Message[] {
    return this.$root.$messageService.getters.messagesByPath(
      this.domainMessageServicePath,
      { recursive: true }
    );
  }

  get isBusinessMail(): boolean {
    return this.editItem?.code === this.businessMailCode;
  }

  @Watch('item')
  updateCount(item: CartItem) {
    if (item?.properties?.addons && this.addons.length > 0) {
      this.addons.forEach((addon: AddonItem) => {
        if (addon.type === AddonType.NUMERIC) {
          this.editItem!.properties!.addons[addon.name] = (addon as AddonNumeric).value - (addon as AddonNumeric).base;
        } else {
          this.editItem!.properties!.addons[addon.name] = addon.value;
        }
      });
    }
  }

  @Watch('item', { immediate: true, deep: true })
  onItemChange(item: CartItem | null) {
    const period = isNumber(item?.period) ? item!.period : DEFAULT_PERIOD;

    this.editItem = item ? cloneDeep(item) : null;
    this.selectedOpt = get(this.editItem, 'options.period', [
      DEFAULT_PERIOD_OPT,
    ]).find((opt: PeriodOption) => opt.period === period);
  }
}
