
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QTooltip } from 'quasar';

import WsMessage from '@WS_Components/WsMessage.vue';
import { UI_VARIANT } from '@WS_Components/const.enum';
import { CartItem } from '@/types';
import config, { AppConfig } from '@/services/config.service';

@Component({
  components: {
    QTooltip,
    WsMessage,
  },
})
export default class CartItemMessage extends Vue {
  @Prop({ default: () => ({}) }) item!: CartItem;
  cfg: AppConfig = config;

  get properties(): CartItem['properties'] {
    return this.item.properties || {};
  }

  get messages() {
    return this.$root.$messageService.getters.messagesByPath('general');
  }

  get messageByFlag():
    | { key: string; type: string; urls?: string[]; noTitle?: boolean }
    | false {

    if (
      this.messages.length > 0 &&
      this.messages.find(({translationKey}) => translationKey === 'validation.hosting_package_change.not_available') &&
      this.messages.find(({originalPath}) => originalPath?.includes(this.item?.id.toString()))
    ) {
      return {
        key: 'validation.cart_item.cannot_downgrade',
        type: UI_VARIANT.ALERT,
        urls: [this.cfg.webAdminUrl, this.cfg.hostingsUrl],
      };
    }

    return false;
  }
}
