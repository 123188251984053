import store from '../../../../store/store';
import {CartItem} from '@/types';
import {BusinessMailLicense, LicencesCount} from './CartItemBundle';

export const getSubItems = (item: CartItem) => {
  if (item.code === 'business-mail') {
    return getBusinessMailSubItems(item);
  }
  return [];
};

export const isItemBundle = (item: CartItem) => !!getSubItems(item).length;

//BUSINESS MAIL
export const getBusinessMailSubItems = (item: CartItem): CartItem[] => {
  if (!item?.properties?.addons) {
    return [];
  }
  const licensesCount = countLicenses(item.properties.addons);
  const licenses = [
    { type: 'basic' },
    { type: 'advanced' },
    { type: 'premium' },
  ];
  return licenses.reduce((acc: CartItem[], license: BusinessMailLicense) => {
    if (licensesCount[license.type]) {
      const subItem = formatBusinessMailSubItem(
        item,
        license,
        licensesCount[license.type]
      );
      acc.push(subItem);
    }
    return acc;
  }, []);
};

const formatBusinessMailSubItem = (
  item: CartItem,
  license: BusinessMailLicense,
  licenseCount: number
): CartItem => {
  const { type } = license;
  const getLicensePrices = store.getters['businessMail/licensePrice'];
  const prices = getLicensePrices(item.id, type, licenseCount) || undefined;
  return {
    ...item,
    code: `business_mail_${type}`,
    prolongPrice: undefined,
    licenses: licenseCount,
    price: {
      ...item.price,
      vatExcluded: prices.actualPrice,
      originalPrice: prices.marketingPrice,
    },
  };
};

export const countLicenses = (
  addons: {[key: string]: any}
): LicencesCount => {
  return {
    basic: Number(addons['basic_licences_limit']) || 0,
    advanced: Number(addons['advanced_licences_limit']) || 0,
    premium: Number(addons['premium_licences_limit']) || 0,
  };
};
