
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {QToggle} from 'quasar';

import {isDomain} from '@WS_UIkit';
import WsInput from '@WS_Components/WsInput.vue';
import WsButton from '@WS_Components/WsButton.vue';

import DomainBadge from './DomainBadge.vue';
import {DomainError, SearchQuery} from './dc.types';
import { Theme } from '@loopia-group/services';

@Component({
  components: {
    QToggle,
    DomainBadge,
    WsInput,
    WsButton,
  },
})
export default class DomainSearchBar extends Vue {
  @Prop()
  readonly search!: SearchQuery | null;
  @Prop(Boolean)
  readonly loading!: boolean;
  @Prop(Boolean)
  readonly responsive!: boolean; // input changing haight according to window width
  @Prop(Boolean)
  readonly multipleMode!: boolean;
  @Prop(String)
  readonly placeholder!: string;
  @Prop(Boolean)
  readonly error!: boolean;
  @Prop(Boolean)
  readonly noValidation!: boolean;
  @Prop(Boolean)
  readonly customSingleError!: boolean;

  searchString = '';
  freeOnly = true;
  hasError = false;
  Theme  = Theme;

  // in case freeOnly value is comming from empty cart screen
  mounted() {
    if (this.search) {
      this.search.freeOnly = this.freeOnly;
    }
  }

  @Watch('freeOnly')
  onFreeOnlyChanged(freeOnly: boolean) {
    window.history.replaceState(
      {} ,
      'title',
      window.location.pathname + this.replaceQueryParam('freeOnly', ''+freeOnly, window.location.search)
    );
    this.$emit('freeOnlyChanged', freeOnly);
  }

  replaceQueryParam(param: string, newval: string, search: string){
    const regex = new RegExp('([?;&])' + param + '[^&;]*[;&]?');
    const query = search.replace(regex, '$1').replace(/&$/, '');
    return (query.length > 2 ? query + '&' : '?') + (newval ? param + '=' + newval : '');
  }

  @Watch('error')
  onErrorChanged(error: boolean) {
    this.hasError = error;
  }

  domains: string[] = [];
  validationErrors: DomainError[] = [];

  @Watch('search', { immediate: true })
  onValueChanges(search: SearchQuery | null) {
    if (search) {
      this.searchString = search.domains.join(', ');
      this.domains = search.domains;
      this.freeOnly = search.freeOnly || false;
    }
  }

  focusMainInput() {
    // @ts-ignore
    this.$refs.mainInput.focus();
  }

  validateDomain(domain: string): false | string {
    // reset errors bag
    this.validationErrors = [];

    domain = domain.trim().toLowerCase() || '';
    domain = domain.replace(/\\|\//g, ''); // remove all (back)slashes

    if (this.noValidation) {
      return domain;
    }

    // TODO: each domain should be unique (create a validation for it)

    // TODO: possibility to validate without TLD (doesn't contain DOT character)

    if (domain.includes('.')) {
      const isDomainCheck = isDomain(domain);
      if (typeof isDomainCheck === 'string') {
        this.validationErrors.push({
          error: isDomainCheck,
          domainName: domain,
        });
        return false;
      }
    }

    return domain;
  }

  updateDomain(index: number, newValue: string) {
    const validatedDomain = this.validateDomain(newValue);

    if (typeof validatedDomain === 'string') {
      this.domains[index] = validatedDomain;
    }
  }

  addDomainToBag(): boolean {
    let validatedDomain = this.validateDomain(this.searchString);

    if (typeof validatedDomain === 'string') {
      validatedDomain = validatedDomain.trim().toLowerCase();
      // add this domain to domains[] only if not a duplicity
      if (this.multipleMode && !this.domains.includes(validatedDomain)) {
        this.domains.push(validatedDomain);
      } else {
        // single-mode
        this.domains = [validatedDomain];
      }
    } else {
      if (this.customSingleError && !this.multipleMode) {
        // handle single check errors in check-status-message component
        this.validationErrors = [];
        this.$emit('error', 'validation.domain_name.unsupported_chars');
      }
      return false;
    }

    if (this.multipleMode) {
      // reset search input
      this.searchString = '';
    }

    return true;
  }

  // emit on enter or button click
  searchDomains() {
    // get back to outer controlled state
    this.hasError = this.error;

    if (!(this.searchString.trim().length && this.addDomainToBag())) {
      return false;
    }

    if (this.domains.length) {
      this.$emit('submit', {
        domains: this.domains,
        freeOnly: this.freeOnly,
      });
    }
  }

  /*
    Event Handlers
   */
  onCommaPressed(event: KeyboardEvent) {
    event.preventDefault();

    // TODO: multiple checker in next phase
    // this.addDomainToBag();

    // if (this.domains.length > 0) {
    //   this.multipleMode = true;
    //   this.searchString = '';
    // }
  }

  onEnterPressed(event: KeyboardEvent) {
    event.preventDefault();
    event.stopPropagation();

    // if there is already at least one domain in bag
    this.addDomainToBag();
    if (!this.multipleMode) {
      this.searchDomains();
    }
  }
}
