import { PaymentOptionGroup } from '@/types';
import { QOption } from '@WS_UIkit/types';

export type ProcessedOption = PaymentOptionGroup & QOption;

/* eslint-disable no-unused-vars */
export enum PAY_OPTIONS {
  CARD = 'card',
  CREDIT = 'credit',
  BANKING = 'banking',
  TRANSFER = 'transfer',
  QRCODE = 'qrcode',
  LATER = 'later',
}

export enum INVOICE_DELIVERY_TYPE {
  EMAIL = 'email',
  EINVOICE = 'einvoice',
  POST = 'post',
}

export const OPTIONS_ORDER: string[] = [
  PAY_OPTIONS.CARD,
  PAY_OPTIONS.CREDIT,
  PAY_OPTIONS.BANKING,
  PAY_OPTIONS.TRANSFER,
  PAY_OPTIONS.QRCODE,
  PAY_OPTIONS.LATER,
];
