import Vue from 'vue';
import Router, { RouteConfig, RawLocation } from 'vue-router';

import env from '@env';
import { isBrowser } from '@loopia-group/utils';
import config from '@/services/config.service';
import { logError } from '@/services/logger.service';
import { CF_USECASE } from '@/components/contactForm/const.enum';
import {ROUTENAMES} from '@/const.enum';

const OrderLayout = () =>
  import(/* webpackChunkName: "cart" */ './views/OrderLayout.vue');
const DomainCheckerView = () =>
  import(
    /* webpackChunkName: "domain-checker" */ './views/DomainCheckerView.vue'
  );
const Developer = () =>
  import(/* webpackChunkName: "developer" */ './views/Developer.vue');
const Dashboard = () =>
  import(
    /* webpackChunkName: "developer" */ '@/components/developer/Dashboard.vue'
  );
const State = () =>
  import(
    /* webpackChunkName: "developer" */ '@/components/developer/State.vue'
  );
const Config = () =>
  import(
    /* webpackChunkName: "developer" */ '@/components/developer/Config.vue'
  );
const Components = () =>
  import(
    /* webpackChunkName: "developer" */ '@/components/developer/Components.vue'
  );
const ThankYou = () =>
  import(/* webpackChunkName: "thankYou" */ './views/ThankYou.vue');
const ContactForm = () =>
  import(/* webpackChunkName: "contactForm" */ './views/ContactForm.vue');
const OptionalStep = () =>
  import(/* webpackChunkName: "cart" */ './views/OptionalStep.vue');
const Page404 = () =>
  import(/* webpackChunkName: "Page404" */ '@/components/Page404.vue');

Vue.use(Router);

export const routes: RouteConfig[] = [
  {
    name: ROUTENAMES.DOMAIN_CHECKER,
    path: '/' + ROUTENAMES.DOMAIN_CHECKER,
    meta: { layout: OrderLayout },
    component: DomainCheckerView,
    beforeEnter: (to, from, next) => {
      if (to.query.qd) {
        if(!to.query.freeOnly){
          // @ts-ignore
          to.query.freeOnly = true;
          // @ts-ignore
          return next(to);
        }
        next();
      } else {
        next('/');
      }
    }
  },
  {
    path: '/' + ROUTENAMES.DEVELOPER,
    meta: {
      name: ROUTENAMES.DEVELOPER,
    },
    component: Developer,
    children: [
      {
        path: '',
        name: ROUTENAMES.DASHBOARD,
        component: Dashboard,
      },
      {
        path: ROUTENAMES.STATE,
        name: ROUTENAMES.STATE,
        component: State,
      },
      {
        path: ROUTENAMES.CONFIG,
        name: ROUTENAMES.CONFIG,
        component: Config,
      },
      {
        path: ROUTENAMES.COMPONENTS + '/:component?',
        name: ROUTENAMES.COMPONENTS,
        component: Components,
        props: true,
      },
    ]
  },
  {
    name: ROUTENAMES.THANK_YOU,
    path: '/' + ROUTENAMES.THANK_YOU,
    component: ThankYou,
  },
  {
    name: ROUTENAMES.SERVER_FORM,
    path: '/' + ROUTENAMES.SERVER_FORM,
    component: ContactForm,
    props: {
      usecase: CF_USECASE.SERVER,
    },
  },
  {
    name: ROUTENAMES.MONITORING_FORM,
    path: '/' + ROUTENAMES.MONITORING_FORM,
    component: ContactForm,
    props: {
      usecase: CF_USECASE.MONITORING,
    },
  },
  {
    name: ROUTENAMES.MANAGEMENT_FORM,
    path: '/' + ROUTENAMES.MANAGEMENT_FORM,
    component: ContactForm,
    props: {
      usecase: CF_USECASE.MANAGEMENT,
    },
  },
  {
    name: ROUTENAMES.OPTIONAL,
    path: '/' + ROUTENAMES.OPTIONAL,
    component: OptionalStep,
  },
  { path: '*', name: '404', component: Page404 }
];

export const router = new Router({
  mode: 'history',
  base: (isBrowser && config.baseUrl) || env.baseUrl,
  routes,
});

export function safePush(route: RawLocation) {
  return safeRouterMethod('push', route);
}

export function safeReplace(route: RawLocation) {
  return safeRouterMethod('replace', route);
}

function safeRouterMethod(method: 'push' | 'replace', route: RawLocation) {
  return router[method](route).catch(err => {
    // Ignore the vuex err regarding  navigating to the page they are already on.
    if (
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes(
        'Avoided redundant navigation to current location'
      ) &&
      !err.message.includes('via a navigation guard') &&
      !err.message.includes('Navigation cancelled')
    ) {
      // But print any other errors to the console
      logError(err);
      return Promise.reject(err);
    }
  });
}

// TODO: don't allow user to go to another route if store.state.domainChecker.cartItemsLoading.length > 0

export default router;
