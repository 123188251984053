
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QTooltip } from 'quasar';

import WsIcon from '@WS_Components/WsIcon.vue';

@Component({
  components: {
    WsIcon,
    QTooltip,
  },
})
export class BonusList extends Vue {
  @Prop()
  readonly bonuses!: any[];
  @Prop()
  readonly info!: string;

  bonusValue(bonus: any) {
    if (bonus.property === 'bonus.property.price' && !bonus.percentage) {
      return this.$n(bonus.value, 'currency');
    }
    return bonus.value + (bonus.percentage ? '%' : '');
  }
}
export default BonusList;
