
import {Component, Vue, Prop} from 'vue-property-decorator';

import {simpleWrapperCmp} from '@loopia-group/utils';
import {UI_SPACING} from '../WS_UIkit/src/components/const.enum';

@Component
export class WsCard extends Vue {
  @Prop({type: String, default: 'lg'}) readonly spacing!: UI_SPACING;
  @Prop(String) readonly spacingTablet!: UI_SPACING;
  @Prop(String) readonly spacingMobile!: UI_SPACING;
  @Prop(String) readonly rootElement!: string;

  get componentSwitch() {
    return simpleWrapperCmp(this.rootElement || undefined);
  }
}

export default WsCard;
