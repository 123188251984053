
import {Component, Vue, Prop, Watch, Inject} from 'vue-property-decorator';
import {
  QUploader,
  QUploaderAddTrigger,
  QBtn,
  QTooltip,
  QSpinner,
  QCard,
  QCardSection,
  QCardActions,
  Ripple,
} from 'quasar';
import {mdiDelete, mdiClose} from '@quasar/extras/mdi-v4';
import WsIcon from './WsIcon.vue';
import {Theme} from '@loopia-group/services';

import type {QuasarFile} from '@loopia-group/ts/types/wsk_types.d';
import dndImg from '../assets/images/dnd.jpg';
import {uniqBy} from 'lodash-es';

@Component({
  components: {
    QUploader,
    QUploaderAddTrigger,
    QBtn,
    QTooltip,
    QSpinner,
    QCard,
    QCardSection,
    QCardActions,
    WsIcon,
  },
  directives: {Ripple},
})
export class WsUploader extends Vue {
  @Prop({default: () => []}) readonly files!: any[];
  @Prop(Boolean) readonly blockRemove!: boolean;

  @Prop(String) readonly fieldName!: string;
  @Prop(String) readonly title!: string;
  @Prop({type: String, default: null}) readonly text!: string|null;
  @Prop(Boolean) readonly hideStatus!: boolean;

  data() {
    return {
      dndImg,
      mdiDelete,
      mdiClose,
    };
  }

  Theme = Theme;
  overrideFiles: File[] = [];

  @Inject({from: 'formDataOverride', default: null}) formDataOverride: any;

  concatFiles(scopedFiles: any[]): any[] {
    return uniqBy(scopedFiles.concat(this.files), 'name');
  }

  @Watch('overrideFiles', {deep: true})
  updateFormOverride() {
    if (this.formDataOverride) {
      this.formDataOverride[this.fieldName] = this.overrideFiles;
    }
  }

  added(files: File[]) {
    this.overrideFiles = this.overrideFiles.concat(files);

    if (this.$parent) {
      this.$parent.$emit('dirty');
    }
  }

  removed(files: File[]) {
    const filesStr = files.map(file => JSON.stringify(file));
    this.overrideFiles = this.overrideFiles.filter(
      file => !filesStr.includes(JSON.stringify(file))
    );
  }

  // internal method
  onRemove(file: QuasarFile) {
    if (!this.blockRemove) {
      (this.$refs.qUploader as QUploader).removeFile(file);
    }
    this.$emit('removeFile', file);
  }

  // public api method
  removeFile(file: QuasarFile) {
    (this.$refs.qUploader as QUploader).removeFile(file);
  }
}

export default WsUploader;
