import MockAdapter from 'axios-mock-adapter/types';

export const serverInfoMock = {
  uuid: 'de6f98de-e3a3-42e9-aa8a-6d0feb3125b7',
  brand: 'Dell S4',
  cpuName: '1x Intel Xeon Silver 4214R',
  cpuCores: '12C/24T',
  ram: '32',
  primaryDrives: '2x 480GB SSD',
  secondaryDrives: '',
  tag: 'premium',
  prices: [
    {
      period: 1,
      actualPrice: 239,
      marketingPrice: 279,
    },
    {
      period: 3,
      actualPrice: 567,
      marketingPrice: 837,
    },
    {
      period: 6,
      actualPrice: 1074,
      marketingPrice: 1674,
    },
    {
      period: 12,
      actualPrice: 2028,
      marketingPrice: 3348,
    },
  ],
};

export const serverOptionsMock = {
  monitoring: [
    {
      type: 'basic',
      prices: [
        {
          period: 1,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 3,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 6,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 12,
          actualPrice: 0,
          marketingPrice: null,
        },
      ],
    },
    {
      type: 'advanced',
      prices: [
        {
          period: 1,
          actualPrice: 19,
          marketingPrice: null,
        },
        {
          period: 3,
          actualPrice: 57,
          marketingPrice: null,
        },
      ],
    },
  ],
  ipAddress: [
    {
      type: 'one',
      prices: [
        {
          period: 1,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 3,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 6,
          actualPrice: 0,
          marketingPrice: null,
        },
        {
          period: 12,
          actualPrice: 0,
          marketingPrice: null,
        },
      ],
    },
    {
      type: 'more',
      prices: [
        {
          period: 1,
          actualPrice: null,
          marketingPrice: null,
        },
        {
          period: 3,
          actualPrice: null,
          marketingPrice: null,
        },
        {
          period: 6,
          actualPrice: null,
          marketingPrice: null,
        },
        {
          period: 12,
          actualPrice: null,
          marketingPrice: null,
        },
      ],
    },
  ],
  os: [
    {
      type: 'debian9',
      name: 'Debian 9 (Stretch) x86-64',
      licences: null,
    },
    {
      type: 'ubuntu20',
      name: 'Ubuntu 20.04 LTS (Focal Fossa) x86-64',
      licences: null,
    },
    {
      type: 'ubuntu18',
      name: 'Ubuntu 18.04 LTS (Bionic Beaver) x86-64',
      licences: null,
    },
    {
      type: 'centos8',
      name: 'CentOS 8 x86-64',
      licences: null,
    },
    {
      type: 'centos7',
      name: 'CentOS 7 x86-64',
      licences: null,
    },
    {
      type: 'microsoftWS2019SEN',
      name: 'Microsoft Windows Server 2019 Standard EN',
      licences: [
        {
          type: 'wss',
          name: 'Windows Server Standard',
          required: true,
          prices: [
            {
              period: 1,
              actualPrice: 23.4,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 70.2,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 140.4,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 280.8,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'rdp',
          name: 'RDP',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 8.6,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 25.8,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 51.6,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 103.2,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'sqlSpC',
          name: 'SQL Standard per Core',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 170,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 510,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 1020,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 2040,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'sqlSpS',
          name: 'Sql Standard per Seal',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 19,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 57,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 114,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 228,
              marketingPrice: null,
            },
          ],
        },
      ],
    },
    {
      type: 'microsoftWS2016SEN',
      name: 'Microsoft Windows Server 2016 Standard EN',
      licences: [
        {
          type: 'wss',
          name: 'Windows Server Standard',
          required: true,
          prices: [
            {
              period: 1,
              actualPrice: 23.4,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 70.2,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 140.4,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 280.8,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'rdp',
          name: 'RDP',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 8.6,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 25.8,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 51.6,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 103.2,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'sqlSpC',
          name: 'SQL Standard per Core',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 170,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 510,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 1020,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 2040,
              marketingPrice: null,
            },
          ],
        },
        {
          type: 'sqlSpS',
          name: 'SQL Standard per Seal',
          required: false,
          prices: [
            {
              period: 1,
              actualPrice: 19,
              marketingPrice: null,
            },
            {
              period: 3,
              actualPrice: 57,
              marketingPrice: null,
            },
            {
              period: 6,
              actualPrice: 114,
              marketingPrice: null,
            },
            {
              period: 12,
              actualPrice: 228,
              marketingPrice: null,
            },
          ],
        },
      ],
    },
    {
      type: 'other',
      name: 'Other distribution',
      licences: null,
    },
    {
      type: 'self',
      name: 'I will install the operating system myself',
      licences: null,
    },
  ],
  backup: {
    min: 1,
    max: 1000,
    prices: [
      {
        period: 1,
        actualPrice: 0.4,
        marketingPrice: null,
      },
      {
        period: 3,
        actualPrice: 1.2,
        marketingPrice: null,
      },
      {
        period: 6,
        actualPrice: 2.4,
        marketingPrice: null,
      },
      {
        period: 12,
        actualPrice: 4.8,
        marketingPrice: null,
      },
    ],
  },
  network: {
    prices: [
      {
        period: 1,
        actualPrice: null,
        marketingPrice: null,
      },
      {
        period: 3,
        actualPrice: null,
        marketingPrice: null,
      },
      {
        period: 6,
        actualPrice: null,
        marketingPrice: null,
      },
      {
        period: 12,
        actualPrice: null,
        marketingPrice: null,
      },
    ],
  },
};

export const serverFromModelMock = {
  name: '',
  monitoring: { type: 'basic', price: { actualPrice: 0 } },
  ipAddress: { type: 'one', price: { actualPrice: 0 } },
  os: null,
  licences: [],
  backup: {
    volume: 0,
    min: 1,
    max: 1000,
    price: {
      actualPrice: 0.4,
    },
  },
  contact: {},
  period: 12,
};

export const serverInfoWithSelectedMock = {
  ...serverInfoMock,
  prices: serverInfoMock.prices,
};

export const monitoringInfoMock = {
  prices: [{ period: 1, actualPrice: 19, marketingPrice: 19 }],
};
export const managementInfoMock = {
  prices: [{ period: 1, actualPrice: 120, marketingPrice: 120 }],
};
export const monitoringOptionsMock = {
  general: [
    {
      name: 'os',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'mount',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'disk',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
  ],
  webServer: [
    {
      name: 'apache',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'nginx',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'varnish',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'haProxy',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'coroSync',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'ssl',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'availability',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
  ],
  databaseServer: [
    {
      name: 'mysql',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'postgreSql',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'redis',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
  ],
  application: [
    {
      name: 'memcached',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'phpFpm',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'postfix',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
    {
      name: 'proFtpd',
      prices: [{ period: 1, actualPrice: null, marketingPrice: null }],
    },
  ],
};

export function registerContactFormMocks(mock: MockAdapter) {
  // Write a message to console as developer notice
  // eslint-disable-next-line no-console
  console.info('Registering server form API mocks');

  mock
    .onGet(/\/server-contact-form\/dedicated-servers\/*./)
    .reply(200, serverInfoMock);
  mock
    .onGet(/\/server-contact-form\/dedicated-server-options/)
    .reply(200, serverOptionsMock);
}
