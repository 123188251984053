
import { QField } from 'quasar';

import {EditableAddressProps} from './WsAddress.enum';
import type {AddressConfig, Address} from '@loopia-group/ts/types/wsk_types.d';
import {merge, get} from 'lodash-es';
import {config} from '@loopia-group/services';
import {AddressComponentsExtended} from '../services/google-api.service.d';
import {
  parseCityFromComponents,
  parseStreetFromComponents,
} from '../services/google-api.service';
import {
  required,
  always,
  requireOneOf as requireOneOfRule,
  validateVat,
  validateTax,
  validateCompany,
  ValidatorFactory,
  propertyValidation,
  addressPropertyList,
  addValidations,
} from '../services/validation/validation.service';
import {Validator} from '../services/validation/validation.service.d';
import {AutofillCompanyItem} from '../services/autocomplete.service.d';
import {WsInput} from './WsInput.vue';
import WsPlacesInput from './WsPlacesInput.vue';
import WsBtnToggle from './WsBtnToggle.vue';
import WsPhoneInput from './WsPhoneInput.vue';
import {WsEmailsInput} from './WsEmailsInput.vue';
import WsSpinner from './WsSpinner.vue';
import WsAutocomplete from './Forms/WsAutocomplete.vue';
import {computed, onMounted, ref, watch, Ref, defineComponent, nextTick,onBeforeMount} from 'vue';
import {useTranslation} from '../composables/translation';
import WsTooltip from './WsTooltip.vue';


const defaultConfig: AddressConfig = {
  path: '',
  email: false,
  emails: false,
  phone: false,
  companyId: true,
  vatId: true,
  taxId: true,
  companyCountry: config.companyCountry,
  optional: {
    phone: false,
    email: false,
    emails: false,
    taxId: true,
    vatId: true,
    companyId: true,
  },
  disable: {},
  defaultType: 'personal',
};

export default defineComponent({
  name: 'WsAddress',
  components: {
    WsTooltip,
    WsInput,
    WsPlacesInput,
    WsBtnToggle,
    WsPhoneInput,
    WsEmailsInput,
    WsSpinner,
    WsAutocomplete,
  },
  props:{
    value: {type: Object, default: () => ({})},
    path: {
      type: String,
      default: '',
    },
    config: {type: Object, default: () => defaultConfig}
  },
  defineEmits: ['input','taxId'],
  // eslint-disable-next-line
  setup (props, vm) {
    const i18n = useTranslation();

    const companyId: Ref<QField | null> = ref(null);
    const vatId: Ref<QField | null> = ref(null);
    const taxId: Ref<QField | null> = ref(null);

    const requireOneOf: Ref<ValidatorFactory> = ref(() => always);
    const propStaticRules: Ref<Record<EditableAddressProps, Validator[]> | any > = ref({});

    const propRestriction: Ref<Record<EditableAddressProps, string>> = ref(Object.create(null));
    const innerFirstFilled: Ref<string> = ref('') ; // helper state pro);
    const companySuggestions: Ref<AutofillCompanyItem[]> = ref([]);
    const cinSuggestions: Ref<AutofillCompanyItem[]> = ref([]);
    const model: Ref<Partial<Address>> = ref({});


    // additional context to places input to get more accurate results
    const secondaryInfo = computed((): string => {
      return [model.value.street, model.value.street2].join(' ').trim();
    });

    const countryCode = computed((): string => {
      // not using get() method to have countryCode reactive, not just model.value
      return (model.value && model.value.countryCode) || cfg.value.companyCountry!;
    });

    const modifiedRequired = computed((): Validator => {
      // globally turns on/off requirement of whole address
      return vm.attrs.required === '' ? required : always;
    });

    const pathComputed = computed((): string => {
      return props.path || cfg.value.path || '';
    });

    // other rule, means other than oneOfThree rule
    const noneOfThreeIsRequiredByOtherRule = computed((): boolean => {
      return (
        !companyIdIsRequiredByOtherRule.value &&
        !taxIdIsRequiredByOtherRule.value &&
        !vatIdIsRequiredByOtherRule.value
      );
    });

    const requiredCompanyId = computed((): boolean => {
      return companyIdIsRequiredByOtherRule.value || oneOfThreeNOTSattisified.value;
    });

    const companyIdIsRequiredByOtherRule = computed((): boolean => {
      return !get(cfg.value, 'optional.companyId');
    });

    const requiredTaxId = computed((): boolean => {
      return taxIdIsRequiredByOtherRule.value || oneOfThreeNOTSattisified.value;
    });

    const taxIdIsRequiredByOtherRule = computed((): boolean => {
      return !get(cfg.value, 'optional.taxId');
    });

    const requiredVatId = computed((): boolean => {
      return vatIdIsRequiredByOtherRule.value || oneOfThreeNOTSattisified.value;
    });

    const vatIdIsRequiredByOtherRule = computed((): boolean => {
      return !get(cfg.value, 'optional.vatId');
    });

    const oneOfThreeRuleEnabled = computed((): boolean => {
      return (
        !!props.config.oneOfThreeRuleEnabled &&
        noneOfThreeIsRequiredByOtherRule.value
      );
    });

    const allThreeEmpty = computed((): boolean => {
      return !model.value.companyId && !model.value.taxId && !model.value.vatId;
    });

    const oneOfThreeNOTSattisified = computed((): boolean => {
      return !!oneOfThreeRuleEnabled.value && (!model.value || allThreeEmpty.value);
    });


    watch(
      [() => model, () => allThreeEmpty],
      () => {
        if (allThreeEmpty.value) {
          return innerFirstFilled.value = '';
        }
        switch (true) {
        case !!model.value.companyId:
          innerFirstFilled.value = 'companyId';
          break;
        case !!model.value.vatId:
          innerFirstFilled.value = 'vatId';
          break;
        case !!model.value.taxId:
          innerFirstFilled.value = 'taxId';
          break;
        }
      }
    );

    // first of three company fields, so we can mark other two as optional
    const firstFilled = computed((): string => innerFirstFilled.value);

    const nameFieldsOrder = computed((): [string, string] => {
      return config.companyCountry !== 'HU' ? ['firstName', 'lastName'] : ['lastName', 'firstName'];
    });

    const cfg = computed((): AddressConfig => {
      return merge({}, defaultConfig, props.config);
    });


    onBeforeMount(() => {
      addValidations(addressPropertyList);
      model.value.type = cfg.value.defaultType;
      initValidators(model.value.type);
    });

    onMounted(() => {
      initValidators(model.value?.type);
    });

    watch(
      () => props.value,
      (value: Address) => {model.value = {...value};},
      { immediate: true }
    );

    watch(() => model.value.type, () => {
      initValidators(model.value.type);
    });

    watch(() => model.value.vatId, () => {syncTaxId(model.value.vatId);});

    watch(
      () => model.value.countryCode,
      // @ts-ignore
      (newCode: string, oldCode: string) => {
        if (newCode === 'CZ') {
          syncTaxId();
        } else if (oldCode === 'CZ') {
          reEmit('taxId', '');
        }
      }
    );

    /*
      Autocomplete suggestions
     */

    const autocompleteModels = (suggestion: AutofillCompanyItem) =>  {
      model.value = {
        ...model.value,
        organisation: suggestion.name,
        street: suggestion.formatted_street,
        city: suggestion.municipality,
        postalCode: suggestion.postal_code,
        countryCode: config.companyCountry,
        country: suggestion.country,
        companyId: suggestion.cin,
        taxId: suggestion.tin as any,
        vatId: suggestion.vatin as any,
      };
      vm.emit('input', model.value);
    };

    const initValidators = async (val?: string) => {
      Object.values(EditableAddressProps).forEach(prop => {
        const {rules, restriction} = propertyValidation(`address_${prop}`);
        propStaticRules.value[prop] = rules;
        propRestriction.value[prop] = restriction;
      });

      await nextTick();

      if (val === 'company') {
        // @ts-ignore
        const fields: [keyof AddressConfig, QField][] = ([
          'companyId',
          'taxId',
          'vatId',
        ] as Partial<(keyof AddressConfig)[]>)
          .filter(field => !!cfg.value[field!])
          .map((field) => {
            const refs = {companyId, vatId, taxId};
            return [field!, refs[(field as 'companyId' | 'vatId' | 'taxId')!]];
          });
        requireOneOf.value =
          fields.length > 1
            ? requireOneOfRule(new Map(fields))
            : () => modifiedRequired.value;
      } else {
        requireOneOf.value = () => always;
      }
    };

    // local custom validator that checks whether country is set
    // because it is required parameter for next validator in a row
    const requiredCountry = (val: string) => {
      if (!val) {
        return true;
      }
      return !!countryCode.value || i18n.t('wsk.validation.country_required');
    };

    const validVatID = (vatId: string) => {
      return validateVat({
        vatId,
        countryCode: countryCode.value,
      });
    };

    const validTaxID = (taxId: string) =>  {
      return validateTax({
        taxId,
        countryCode: countryCode.value,
      });
    };

    const validCompanyID = (companyId: string) =>  {
      return validateCompany({
        companyId,
        countryCode: countryCode.value,
      });
    };

    watch(countryCode, () => {
      setTimeout(validate, 0);
    });
    const validate = () => {
      // setTimeout to wait until render (in some cases, example: autofill)
      if (model.value.companyId && companyId.value) {
        companyId.value.validate();
      }
      if (model.value.vatId && vatId.value) {
        vatId.value.validate();
      }
      if (model.value.taxId && taxId.value) {
        taxId.value.validate();
      }

    };

    const mapAddressComponentsStreet = (components: AddressComponentsExtended) => {
      mapAddressComponents(components);
    };

    const mapAddressComponentsCity = (components: AddressComponentsExtended) => {
      mapAddressComponents(components, true);
    };

    const mapAddressComponents = (components: AddressComponentsExtended, skipStreet = false) => {
      const newAddress: Partial<Address> = {};

      if (!skipStreet) {
        newAddress.street = parseStreetFromComponents(components);
      }
      newAddress.city = parseCityFromComponents(components);
      if (components.postal_code) {
        newAddress.postalCode = components.postal_code;
      }
      newAddress.countryCode = components.country_code || '';
      syncTaxId();

      model.value = {
        ...model.value,
        ...newAddress,
      };
      vm.emit('input', model.value);
    };

    const syncTaxId = (input?: string) =>  {
      // solves taxId duplicity for CZ profiles PNO-1387/6
      if (countryCode.value === 'CZ') {
        reEmit('taxId', input === undefined ? model.value?.vatId : input);
      }
    };

    const reEmit = (key: string, value: any) =>  {
      model.value = {
        ...model.value,
        [key]: value,
      };
      vm.emit('input', model.value);
    };

    return {
      always, syncTaxId, model, reEmit, mapAddressComponents, mapAddressComponentsStreet, mapAddressComponentsCity, cfg,
      validCompanyID, validTaxID, validVatID, requiredCountry, autocompleteModels, initValidators, nameFieldsOrder,
      modifiedRequired, companySuggestions, cinSuggestions, countryCode, requireOneOf, propRestriction, propStaticRules,
      requiredCompanyId, pathComputed, secondaryInfo, oneOfThreeRuleEnabled, requiredTaxId, firstFilled, requiredVatId,
      i18n,validate
    };
  },


});
