
import {defineComponent} from 'vue';

import WsIcon from './WsIcon.vue';


export default defineComponent({
  name: 'WsList',
  components: {
    WsIcon,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
});
