var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.items)?_c('div',{staticClass:"ws-radio-list",class:{
    'invisible-wrap': _vm.invisibleWrap,
    'no-spacing-y': _vm.noSpacingY,
    'no-spacing-x': _vm.noSpacingX,
    small: _vm.small,
  }},[_vm._l((_vm.showSelectedOnly
      ? [_vm.items[_vm.selectedIndex || 0]]
      : _vm.items),function(item,index){return [(
        !(
          _vm.get(item, 'disable') &&
          _vm.$scopedSlots.disabled &&
          _vm.$scopedSlots.disabled({ item })
        )
      )?_c('div',{key:_vm.get(item, _vm.uidProp, JSON.stringify(item)) + '_item_' + index,staticClass:"ws-radio-item item-spacing",class:{
        disabled: _vm.get(item, 'disable'),
        selected: _vm.selectedIndex === index || _vm.showSelectedOnly,
        'selected-bolded': _vm.selectedBolded,
      }},[_c('ws-radio',{staticClass:"full-width ws-hover-fly-in-trigger",class:{ selected: _vm.selectedIndex === index || _vm.showSelectedOnly },attrs:{"val":index,"disable":_vm.get(item, 'disable'),"invisible-wrap":_vm.invisibleWrap},on:{"input":function($event){return _vm.$emit('ws-radio-selected')}},model:{value:(_vm.selectedIndex),callback:function ($$v) {_vm.selectedIndex=$$v},expression:"selectedIndex"}},[_vm._t("default",null,{"item":item,"index":index,"isSelected":_vm.selectedIndex === index || _vm.showSelectedOnly})],2),(_vm.$scopedSlots.info)?_c('div',{staticClass:"info"},[_vm._t("info",null,{"item":item,"index":index,"isSelected":_vm.selectedIndex === index || _vm.showSelectedOnly})],2):_vm._e(),_c('transition',{attrs:{"name":"slide-fade-vertical"}},[(_vm.show && index === _vm.selectedIndex)?_c('div',{staticClass:"item-content"},[_vm._t("content",null,{"item":item,"index":index})],2):_vm._e()])],1):_c('div',{key:_vm.get(item, _vm.uidProp, JSON.stringify(item)) + '_disabled_' + index,staticClass:"item-spacing slot-disabled"},[_vm._t("disabled",null,{"item":item,"index":index})],2)]}),_vm._t("extra")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }