
import {
  defineComponent,
  Ref,
  ComputedRef,
  computed,
  ref,
  provide,
  onMounted,
} from 'vue';
import {useIwpStore} from '../store';

import ProductFlowStepper from '@/components/ProductFlowStepper.vue';
import PackageList from '@/modules/iwp/components/PackageList.vue';
import ProductList from '@/modules/iwp/components/ProductList.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsBtnToggle from '@WS_Components/WsBtnToggle.vue';
import CartNavigation from '@/modules/iwp/components/CartNavigation.vue';

import xss from 'xss';
import {useRouter, useRoute} from 'vue-router/composables';
import {useTranslation} from '@/WS_UIkit/src/composables/translation';
import {filterProducts, getPackages} from '@/modules/iwp/utils/utils';
import {SelectedProduct} from '@/modules/iwp/views/Product';
import {config} from '@/services/config.service';
import {StandaloneProduct} from '@/types';
import {addToCart} from '@/services/cart/cart-api.service';
import {ROUTENAMES} from '@/const.enum';
import {Theme} from '@loopia-group/services';
import WsSpinner from '@WS_Components/WsSpinner.vue';

// TODO handle 'no data' fallback > loading state

export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Product',
  components: {
    WsSpinner,
    CartNavigation, 
    PackageList, 
    WsBtnToggle, 
    ProductList, 
    ProductFlowStepper, 
    WsButton
  },

  // eslint-disable-next-line max-lines-per-function
  setup() {
    const store = useIwpStore();
    const router = useRouter();
    const route = useRoute();
    // init store upon render
    store.getProducts();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {$t} = useTranslation();
    // Check if beData is falsy
    const isLoading: ComputedRef<Boolean> = computed(() => store.isLoading);
    const productComparisonUrl = ref(config.hostingsUrl);
    // BE FORMAT & FE LOGIC: website.non_ecommerce | URL FORMAT: website_non_ecommerce
    const currentPath: ComputedRef<string> = computed(() => (route.params.path || '').split('_').join('.'));
    const previousPath: Ref<string> = ref('');
    const currentNode = computed(()  => store.getIWPNode(currentPath.value));
    const childNodes = computed(()  => currentNode.value.childNodes);

    // Title split to separate lines, because you cannot style html entities created by vue-i18n via v-html
    const pageTitle: ComputedRef<string> = computed(() => currentNode.value.title || '');
    const subTitle: ComputedRef<string> = computed(
      () => !!currentNode.value?.title ?
        currentNode.value?.title.split('.').slice(0,-1).join('.') + '.subtitle' : ''
    );

    const categories: Ref<Array<any>> = computed(() => currentNode.value?.categories || []);
    const selectedCategory: Ref<string> = ref(categories.value?.[0]?.value || '');
    const selectedProduct: Ref<string> = ref('');
    const selectedPackage: Ref<string> = ref('');
    const isCategory: ComputedRef<boolean> = computed(() => !!categories.value.length);

    const products = computed(() => store.products);
    const packages = computed(() => getPackages(products.value, selectedProduct.value));

    const productsFiltered = computed(() => filterProducts(products.value, selectedCategory.value));

    const updateSelectedCategory = (value: string): void => { selectedCategory.value = value; };
    const updateSelectedPackage = (value: string): void => {
      selectedPackage.value !== value ? selectedPackage.value = value : selectedPackage.value = ''; };
    const updateSelectedProduct = (value: string): void => {
      selectedProduct.value !== value ? selectedProduct.value = value : selectedProduct.value = ''; };

    const cartRoute = ROUTENAMES.CART;

    const submitCartItem = (): void => {
      if (packages.value && selectedPackage.value) {
        const selectedPackageDetails = packages.value.filter(
          (packageItem: any) => packageItem.packageId.value === selectedPackage.value
        )[0];
        const cartItem: StandaloneProduct = {
          code: selectedPackageDetails.productName,
          sellType: 'x-sell',
          properties: {
            packageId: selectedPackageDetails.packageId.value,
          },
        };

        addToCart(cartItem);
        router.push({ name: cartRoute });
      }
    };

    const navigateToStep = (value: string) => {
      previousPath.value = currentPath.value;
      const productSlug: any = value.split('.').slice(1).join('_');
      router.push({name: 'IWP', params: { path: `${currentPath.value}_${productSlug}` }});
    };

    const navigateBack = (): void => {
      // early eval whenever the user has option to go back within the IWAPF steps
      // and if not, return the user to the previous url
      if(previousPath.value.length === 0) {
        router.back();
      }
      // do the navigation when we have previousPath
      else {
        // unsetting previous values
        previousPath.value = '';
        selectedProduct.value = '';

        let steps = currentPath.value.split('.');
        steps = steps.slice(0,-2);
        router.push({name: 'IWP', params: { path: steps.join('_') }});
      }
    };

    provide('iwpProvider', {
      currentNode,
      selectedProduct,
      selectedPackage,
      previousPath,
      navigateBack,
      navigateToStep,
      submitCartItem,
      updateSelectedProduct,
      updateSelectedPackage,
    } as SelectedProduct);

    onMounted(() => {
      // handle reload on deeper than first level path - set selectedProduct
      if(previousPath.value.toString().length === 0 && selectedCategory.value.toString().length === 0) {
        selectedProduct.value = currentPath.value;
        selectedCategory.value = currentPath.value.toString().split('.').slice(0,-1).join('.');
        previousPath.value = currentPath.value.toString().split('.')[0];
      }
    });

    return {
      categories,
      childNodes,
      currentNode,
      currentPath,
      isCategory,
      isLoading,
      packages,
      pageTitle,
      previousPath,
      productComparisonUrl,
      products,
      productsFiltered,
      selectedCategory,
      selectedPackage,
      selectedProduct,
      subTitle,

      navigateBack,
      navigateToStep,
      updateSelectedCategory,
      updateSelectedPackage,
      updateSelectedProduct,
    };
  },
  computed: {
    Theme() {
      return Theme;
    }
  },
  methods: {
    xss,
  },
});
