
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { QSpinner } from 'quasar';

import { Cart } from '@/types';
import { isBrowser } from '@loopia-group/utils';
import { getLoginUrl } from '@/utilities';
import config from '@/services/config.service';
import { logError } from '@/services/logger.service';
import loginIcon from '@/assets/images/loginIcon.svg';
import logoutIcon from '@/assets/images/logoutIcon.svg';
import {ROUTENAMES} from '@/const.enum';

@Component({
  components: {
    QSpinner,
  },
  computed: {
    ...mapState(['cart']),
    ...mapGetters(['loggedIn']),
  },
})
export default class LoginLogout extends Vue {
  @Prop({ default: 0 })
  readonly index!: number;
  @Prop()
  readonly templateLoginEl!: HTMLElement;
  readonly cart!: Cart;
  readonly loggedIn!: boolean;
  initialPlacement = true;
  loginUrlWithParam = '';
  logoutUrl = '';

  data() {
    return {
      loginIcon,
      logoutIcon,
    };
  }

  created() {
    this.loginUrlWithParam = getLoginUrl(this.$router, ROUTENAMES.CART);
    this.logoutUrl = config.logoutUrl;
  }

  mounted() {
    this.hijack(this.templateLoginEl);
  }

  @Watch('templateLoginEl', { immediate: true })
  hijack(templateLoginEl: HTMLElement) {
    if (!isBrowser || !templateLoginEl || !this.$el) {
      return;
    }

    if (templateLoginEl.style.display === 'none') {
      // already hijacked, hot reload?
      return;
    }
    const parentNode = templateLoginEl.parentNode;

    if (!parentNode) {
      // something went wrong, did someone change HF template?
      logError(`parentNode missing. parentNode: ${parentNode}`);
      return;
    }
    parentNode.insertBefore(this.$el, templateLoginEl);

    templateLoginEl.style.display = 'none';
    this.initialPlacement = false;
  }
}
