
import {QItem, QItemSection, QItemLabel} from 'quasar';
import WsSelect from '../WsSelect.vue';
import {
  AutofillCompanyItem,
  QueryParam,
} from '../../services/autocomplete.service.d';
import {get} from 'lodash-es';
import {getAutofillSuggestions} from '../../services/autocomplete.service';
import xss from 'xss';
import {defineComponent, PropType, ref, Ref} from 'vue';


export default defineComponent({
  name: 'WsAutocomplete',
  components: {
    QItem,
    QItemSection,
    QItemLabel,
    WsSelect,
  },

  defineEmits: ['input', 'suggestion-selected'],
  props: {
    value: {
      type: String, Number,
      default: '',
    },
    // directly pass custom translated string (not a key)
    noResultsLabel: {
      type: String,
      default: '',
    },
    // possible values on SK market: name or cin
    queryParam: {default: 'name', type: String as PropType<QueryParam>}
  },
  // eslint-disable-next-line
  setup(props,vm) {
    const wsSelect: Ref<null | WsSelect> = ref(null);
    const suggestions: Ref<AutofillCompanyItem[]> = ref([]);
    const loading: Ref<boolean> = ref(false);

    // API request
    const getAutocompleteItems = async (val: string | number, update: any, abort: any) => {
      suggestions.value.splice(0); // clear
      loading.value = true;


      try {
        const newSuggestions: AutofillCompanyItem[] =
          await getAutofillSuggestions(
            {[props.queryParam]: formatValue(val)});

        const updateCallback = () => {
          suggestions.value = newSuggestions.map((suggestion, index) =>
            ({
              ...suggestion,
              index,
              value: get(suggestion, props.queryParam),
              label: get(suggestion, props.queryParam),
            })
          );
        };
        update(updateCallback);
      } catch (err) {
        abort();
      }
      loading.value = false;
    };

    const formatValue = (value: string | number): string =>  {
      return typeof value === 'number' ? value.toString() : value;
    };

    /*
      Events
     */

    const onInputValue = (value: string | number) =>  {
      // prevent calling input event on first init (not needed)
      if (props.value !== value) {
        vm.emit('input', formatValue(value));
      }
    };

    // Click on the option event
    const onSuggestionSelected = (suggestion: string | AutofillCompanyItem) =>  {
      if (typeof suggestion === 'string') {
        // do nothing because this usecase is covered by onInputValue() method
        return;
      }

      // emit custom event, so parent component can handle it according to its needs
      vm.emit('suggestion-selected', {
        ...suggestion,
        // fix - https://loopiagroup.atlassian.net/browse/PNO-1886
        // truthy condition fixes "null" strings filled
        // force string and prevent validation error on BE if number is received
        cin: suggestion.cin ? String(suggestion.cin) : undefined,
        // force string and prevent validation error on BE if number is received
        tin: suggestion.tin ? String(suggestion.tin) : undefined,
      });
    };

    const onKeyEnter = () =>  {
      const optionIndex = wsSelect.value?.optionIndex || null;
      if (optionIndex !== null) {
        const suggestion = suggestions.value[optionIndex];
        if (suggestion) {
          onSuggestionSelected(suggestion);
        }
      }
    };


    return  {
      onKeyEnter, xss, onSuggestionSelected, onInputValue, getAutocompleteItems, suggestions, loading
    };
  }
});
