
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import { Cart, PaymentOptionGroup } from '@/types';
import { scrollToElement, Throttle } from '@loopia-group/utils';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsButton from '@WS_Components/WsButton.vue';
import { STEPS } from '@/services/const.enum';
import { mayGoNext } from '@/services/order.service';
import PaymentOptions from '@/components/paymentOptions/PaymentOptions.vue';
import OrderProcessActions from '@/components/OrderProcessActions.vue';
import OrderProcessError from '@/components/OrderProcessError.vue';

type CartPaymentOption = Cart['paymentOption'];

@Component({
  components: {
    OrderProcessError,
    WsMessage,
    WsButton,
    PaymentOptions,
    OrderProcessActions,
  },
  computed: {
    ...mapState(['cart', 'userStep', 'inEditMode']),
    ...mapGetters(['mandatorySetsStepSkipped', 'stepHasIssues']),
  },
})
export default class PaymentMethod extends Vue {
  readonly cart!: Cart;
  readonly userStep!: STEPS;
  readonly inEditMode!: STEPS;
  readonly paymentOptions!: PaymentOptionGroup[];

  mandatorySetsStepSkipped!: boolean;
  step = STEPS.PAYMENT_METHOD;
  selectedOption: CartPaymentOption | null = null;
  originallySelected: CartPaymentOption | null | false = false; // false -> disable feature to keep actual selection
  loading = false;
  PATH = 'cart.paymentMethod';

  get editMode() {
    return this.inEditMode === this.step;
  }

  get buttonTranslationKey() {
    if (this.step === this.userStep) {
      return this.mandatorySetsStepSkipped
        ? 'payment_method.submit_btn.recapitulation'
        : 'payment_method.submit_btn.service_settings';
    }
    return 'wsk.general.save_changes';
  }

  @Throttle(300, { leading: false, trailing: true })
  scrollIntoView() {
    if (!this.$refs.paymentMethod) {
      return;
    }

    let headerHeight = 0;
    const el = document.querySelectorAll('.new-order-spa-header')[0];
    if (el) {
      headerHeight = (el as HTMLElement).offsetHeight;
    }

    scrollToElement(this.$refs.paymentMethod as HTMLElement, {
      align: {
        top: 0,
        topOffset: headerHeight + 100,
      },
    });
  }

  @Watch('editMode')
  onEditChanged(editMode: boolean) {
    if (editMode) {
      // edit starts, save original value
      this.originallySelected = this.selectedOption;

      // validate
      if (
        this.paymentOptions &&
        this.cart.paymentOption &&
        (this.cart.paymentOption?.groupId || this.cart.paymentOption?.optionId)
      ) {
        const paymentGroup = this.paymentOptions.find(group => {
          return group.id === this.cart.paymentOption?.groupId;
        });

        const paymentOption = this.getPaymentOption(paymentGroup);

        if (!paymentOption || !paymentOption.available) {
          this.selectedOption = null;
          this.$messageService.errorHandler(this.PATH)({
            translationKey: 'validation.payment_option.option_invalid',
          });
        }
      }
    } else if (this.originallySelected !== false) {
      // return to original selected value as user did not save new selection
      this.selectedOption = this.originallySelected;
    }
  }

  getPaymentOption(paymentGroup: PaymentOptionGroup | undefined) {
    // if no paymentGroup.paymentOptions, the paymentGroup is paymentOption
    return paymentGroup?.paymentOptions && this.cart.paymentOption?.optionId
      ? paymentGroup.paymentOptions.find(
        (option: any) => option.id === this.cart.paymentOption?.optionId
      )
      : paymentGroup;
  }

  @Watch('cart.paymentOption', { immediate: true })
  onCartOptionChanged(newOption: CartPaymentOption) {
    if (!this.editMode) {
      // only if !editMode becausee refocus of tab will fetch cart and it would set option from
      // cart otherwise, even on edit, we do not want that
      this.selectedOption = newOption && newOption.groupId ? newOption : null;
    }
  }

  selectionChanged() {
    this.clearMessages();
    this.$messageService.clearMessages([
      'cart.summary.quick_order',
      'cart.' + this.step,
    ]);
    this.scrollIntoView();
  }

  clearMessages() {
    this.$messageService.clearMessages([this.PATH, 'general'], true);
  }

  mounted() {
    this.clearMessages();
  }

  created() {
    this.selectedOption = this.cart?.paymentOption || this.selectedOption;
  }

  submit() {
    this.$messageService.clearMessages([this.PATH, 'general'], true);
    this.loading = true;
    (this.$refs.paymentOptions as PaymentOptions)
      .savePaymentOption()
      .then(() => {
        this.originallySelected = false;
        this.mayGoNext();
      })
      .catch(this.$messageService.errorHandler(this.PATH))
      .finally(() => (this.loading = false));
  }

  mayGoNext() {
    mayGoNext();
  }
}
