/**    hotjar integration     */

import env from '@env';
import { isBrowser } from '@loopia-group/utils';

// h,o,t,j,a,r
if (
  env.isProductionBuild &&
  isBrowser &&
  document.cookie.includes('ws_privacy_policy_consent=1')
) {
  (function(h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function() {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 587, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    (r as any).async = 1;
    (r as any).src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    (a as any).appendChild(r);
  })(
    window as any,
    document as any,
    'https://static.hotjar.com/c/hotjar-',
    '.js?sv='
  );
}
