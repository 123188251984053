
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { QField } from 'quasar';

import { validateIsic } from '@WS_UIkit';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsInput from '@WS_Components/WsInput.vue';

@Component({
  components: {
    WsCheckbox,
    WsInput,
  },
})
export default class BonusCard extends Vue {
  @Prop({ default: '' }) readonly value!: string;
  @Prop({ default: '' }) readonly path!: string;
  @Prop({ default: '' }) readonly firstName!: string;
  @Prop({ default: '' }) readonly lastName!: string;


  bonusCardEnabled = false;

  get name() {
    if (this.firstName && this.lastName) {
      return `${this.firstName.trim()} ${this.lastName.trim()}`;
    } else {
      return;
    }
  }

  @Watch('value', { immediate: true })
  onValueChanged(newVal: string) {
    if (newVal) {
      this.bonusCardEnabled = true;
    }
  }

  @Watch('name')
  onNameChanged() {
    if (this.value && this.$refs.bonusCard) {
      (this.$refs.bonusCard as QField).validate();
    }
  }

  validIsic(isic: string) {
    if (!this.name) {
      return this.$t('user.bonus_card.name_required');
    }
    return validateIsic({
      isic,
      name: this.name,
    }).catch(this.$messageService.errorHandler(this.path));
  }
}
