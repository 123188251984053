// API docs: https://git.websupport.sk/devel/invoice-validation/blob/master/app/routes.php

import Axios, {AxiosResponse, AxiosRequestConfig} from 'axios';

import {merge} from 'lodash-es';
import type {BaseConfig} from '@loopia-group/services/types/config';
import {config, configObservable} from '@loopia-group/services';
import {i18nService} from '../i18n/i18n.service';
import type {
  CheckTaxIdRequest,
  CheckVatIdRequest,
  CheckCompanyIdRequest,
  CheckIsicRequest,
  ValidationResponse,
} from './validation.service.d';

const customAxiosConfig: Partial<AxiosRequestConfig> = {
  baseURL: config.validationApiUrl || undefined,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
    'ws-language': i18nService.getLang(),
  },
};

i18nService.afterInit.then(() => {
  i18nService.langChanges.subscribe((lang: string) => {
    axios.defaults.headers.common['ws-language'] = lang;
  });
});

const axios = Axios.create(customAxiosConfig);

configObservable.subscribe((cfg: BaseConfig) => {
  if (cfg.validationApiUrl) {
    customAxiosConfig.baseURL = cfg.validationApiUrl;
  }
  changeAxiosConfig(customAxiosConfig);
});

export function changeAxiosConfig(config: Partial<AxiosRequestConfig>) {
  merge(axios.defaults, config);
}

export function checkVatID(
  params: CheckVatIdRequest
): Promise<ValidationResponse> {
  return axios
    .post('/validations/vat-id', params)
    .then((response: AxiosResponse<ValidationResponse>) => response.data);
}

export function checkTaxID(
  params: CheckTaxIdRequest
): Promise<ValidationResponse> {
  return axios
    .post('/validations/tax-id', params)
    .then((response: AxiosResponse<ValidationResponse>) => response.data);
}

export function checkCompanyID(
  params: CheckCompanyIdRequest
): Promise<ValidationResponse> {
  // BE only accepts strings
  params.companyId = params.companyId.toString();

  return axios
    .post('/validations/company-id', params)
    .then((response: AxiosResponse<ValidationResponse>) => response.data);
}

export function checkIsic(
  params: CheckIsicRequest
): Promise<ValidationResponse> {
  return axios
    .post('/validations/isic', params)
    .then((response: AxiosResponse<ValidationResponse>) => response.data);
}

export const defaultValidationApiProvider = {
  checkVatID,
  checkTaxID,
  checkCompanyID,
  checkIsic,
};
