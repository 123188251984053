
import { Vue, Component, Prop } from 'vue-property-decorator';
import WsButton from '@WS_Components/WsButton.vue';
import { hostingTutorialGetPreviousStep } from '@/services/hosting-tutorial.service';

@Component({
  components: {
    WsButton,
  },
})
export default class HostingTutorialNavigation extends Vue {
  @Prop() readonly step?: string;
  @Prop() readonly to?: string | null;

  get canNavigate(): boolean {
    return !!this.step && this.step.split('_').length > 1;
  }

  navigate(): void {
    let to = '';

    if (this.to) {
      to = this.to;
    } else if (this.canNavigate) {
      to = hostingTutorialGetPreviousStep(this.step!);
    }

    if (!to) {
      return;
    }

    this.$emit('navigate', to);
  }

  close(): void {
    this.$emit('close');
  }
}
