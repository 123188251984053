
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { Route } from 'vue-router';
import { Subscription } from 'rxjs';

import { QSkeleton } from 'quasar';
import { scrollToElement, Throttle } from '@loopia-group/utils';

import WsButton from '@WS_Components/WsButton.vue';
import WsBadge from '@WS_Components/WsBadge.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsSpinner from '@WS_Components/WsSpinner.vue';

import { Cart } from '@/types/index.d';
import { ensureCartLoaded } from '@/store';
import { safePush } from '@/router';
import { ROUTENAMES } from '@/const.enum';
import {
  CartExtended,
  COMPLETED,
  UNTOUCHED,
  UserMetaData,
} from '@/store/const';
import { StateMutations, StoreActions } from '@/store/const.enum';
import { STEPS } from '@/services/const.enum';
import config, {
  isStandardWorkflow,
  isSwedishWorkflow,
} from '@/services/config.service';
import { reloadCartCfg } from '@/services/cart/cart-api.service';
import { Theme } from '@loopia-group/services';
import {
  isOrderStep,
  processOrder,
  scrollToStepObservable,
  scrollToStepSubject,
  setEditMode,
  ensureOrderStep,
} from '@/services/order.service';

import SidebarLayout from '@/components/SidebarLayout.vue';
import CartItems from '../components/cart/CartItems.vue';
import SideSummary from '@/components/SideSummary.vue';
import DomainProfile from '@/components/DomainProfile.vue';
import BillingProfile from '@/components/BillingProfile.vue';
import OrderDonation from '@/components/OrderDonation.vue';
import PaymentMethod from '@/components/PaymentMethod.vue';
import OrderProcessItem from '@/components/OrderProcessItem.vue';
import ServiceSettings from '@/components/ServiceSettings.vue';
import MigrationRecap from '@/components/MigrationRecap.vue';
import ConsentCheckboxes from '@/components/ConsentCheckboxes.vue';
import SidebarLayoutItem from '@/components/SidebarLayoutItem.vue';
import {useCartItemsStore} from '@/store/cartItemsStore';

@Component({
  components: {
    QSkeleton,
    WsSpinner,
    WsBadge,
    WsMessage,
    WsButton,
    SidebarLayout,
    CartItems,
    SideSummary,
    DomainProfile,
    BillingProfile,
    OrderDonation,
    PaymentMethod,
    OrderProcessItem,
    ServiceSettings,
    MigrationRecap,
    ConsentCheckboxes,
    SidebarLayoutItem,
  },
  computed: {
    ...mapState([
      'cart',
      'inEditMode',
      'checkoutInProgress',
      'useDifferentBillingProfile',
      'userStep',
      'userMetaData',
      'quickOrderInProgress',
    ]),
    ...mapGetters([
      'progressByUser',
      'progressByData',
      'equalBillingAndDomainProfiles',
      'orderSteps',
      'loggedIn',
      'firstStepWithIssues',
      'allDomainProfilesDefined',
    ]),
  },
})
export default class OrderView extends Vue {
  readonly cart!: CartExtended;
  readonly inEditMode!: STEPS | null;
  readonly userStep!: STEPS | null;
  readonly userMetaData!: UserMetaData;
  readonly progressByUser!: Record<STEPS, number>;
  readonly progressByData!: Record<STEPS, number>;
  readonly orderSteps!: STEPS[];
  readonly loggedIn!: boolean;
  readonly quickOrderInProgress!: boolean;
  readonly firstStepWithIssues!: STEPS | null;

  cartItemsStore = useCartItemsStore();
  isSwedishWorkflow = isSwedishWorkflow;
  donationAvailable = config.company === 'sk-ws';
  scrollToStepSubscription: Subscription | null = null;
  scrollAnimOff = true;
  animationCtrl: any | null = null;
  otherLoading = false;
  timeouts = new Set<NodeJS.Timeout>();
  Theme = Theme;

  data() {
    return {
      isStandardWorkflow,
      STEPS,
      COMPLETED,
      UNTOUCHED,
    };
  }

  get hasMandatory() {
    return this.cartItemsStore.hasMandatory;
  }

  get recapitulationVisible() {
    return (
      this.loggedIn && this.progressByUser[STEPS.RECAPITULATION] !== UNTOUCHED
    );
  }

  get isLoading(): boolean {
    return this.quickOrderInProgress || this.otherLoading;
  }

  created() {
    // disable scroll animation in the beging PNO-1732
    const timeOutId = setTimeout(() => {
      this.timeouts.delete(timeOutId);
      // enable after 2s
      this.scrollAnimOff = this.userMetaData.animationsReduced;
    }, 2000);
    this.timeouts.add(timeOutId);

    // pre-fetching for better UX
    // https://loopiagroup.atlassian.net/browse/PNO-1360
    this.$store.dispatch(StoreActions.FETCH_USER_PROFILE);

    this.$nextTick(() => {
      // nextTick to execute after setting of useDifferentBillingProfile default
      // value (false) into store by syncState

      if (this.isSwedishWorkflow && this.userStep === STEPS.BILLING_PROFILE) {
        this.$store.commit(StateMutations.SET_STATE, {
          prop: 'useDifferentBillingProfile',
          value: true,
        });
      }
    });

    ensureOrderStep();
  }

  reloadCartCfg() {
    if (this.loggedIn) {
      this.otherLoading = true;
      reloadCartCfg()
        .catch(this.$messageService.errorHandler())
        .finally(() => (this.otherLoading = false));
    }
  }

  @Watch('inEditMode')
  onEditModeChange(newVal: STEPS | null, oldVal: STEPS | null) {
    // scroll with timeout waits for setEditMode to take effect
    // and open/close sections so heights of components change
    if (!newVal && oldVal) {
      // scroll to top of the section when closing section
      this.scrollToStep(oldVal, 500);
    } else if (newVal) {
      this.scrollToStep(newVal, 500);
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: Function) {
    next((vm: OrderView) => {
      // do not reload cfg when coming from cart, because there is already check
      if (from.name !== ROUTENAMES.CART) {
        vm.reloadCartCfg();
      }
    });
  }

  beforeRouteUpdate(to: Route, from: Route, next: Function) {
    this.scrollToStep(to.query.step as STEPS);
    next();
  }

  mounted() {
    this.scrollToStepSubscription = scrollToStepObservable.subscribe(
      (step: STEPS) => {
        this.scrollToStep(step, 500);
      }
    );

    ensureCartLoaded(async () => {
      // Ensure that getters are stabilized
      await this.$store.dispatch(StoreActions.FETCH_REQ_FIELDS);
      await this.$nextTick();

      // setTimeout used to perform scroll to next step when steps already mounted
      setTimeout(() => {
        this.setAndScrollToCorrectStep();
      }, 0);
    });
  }

  setAndScrollToCorrectStep() {
    if (this.firstStepWithIssues) {
      this.firstStepWithIssues === STEPS.CART
        ? safePush({ name: ROUTENAMES.CART })
        : scrollToStepSubject.next(this.firstStepWithIssues);
    } else if (isOrderStep(this.userStep)) {
      scrollToStepSubject.next(this.userStep!);
    }

    ensureCartLoaded((cart: Cart) => {
      if (!cart.items?.length) {
        safePush({ name: ROUTENAMES.EMPTY_CART });
      }
    });
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  scrollToStep(step: STEPS, timeout = 0) {
    const scrollingHandler = () => {
      if (this.isLoading) {
        this.timeouts.add(setTimeout(scrollingHandler, 500));
        return;
      }

      this.timeouts.delete(timeOutId);
      let target: HTMLElement | null = null;
      const ref: Vue = this.$refs[step] as Vue;
      if (ref && ref.$el) {
        target = ref.$el as HTMLElement;
      }

      if (target) {
        if (this.animationCtrl) {
          if (this.animationCtrl.el === target) {
            return null;
          } else {
            this.animationCtrl.cancel();
          }
        }
        setTimeout(() => {
          this.animationCtrl = this.doScrollToStep(step, target);
        }, 0);
      }
    };

    const timeOutId = setTimeout(
      scrollingHandler,
      this.scrollAnimOff ? 0 : timeout
    );
    this.timeouts.add(timeOutId);
  }

  doScrollToStep(step: STEPS, target: HTMLElement | null) {
    return scrollToElement(
      target,
      {
        time: this.scrollAnimOff ? 0 : 300,
        align: {
          top: 0,
          topOffset:
            ({
              [STEPS.BILLING_PROFILE]: 500 /* enough to be top */,
            } as Record<STEPS, number>)[step] || 250 /* default */,
        },
      },
      () => {
        // animation ends
        this.animationCtrl = null;
      }
    );
  }


  @Throttle(1000, {trailing: false})
  submit() {
    const consents = this.$refs.consents as ConsentCheckboxes;
    if (!consents.validateCheckboxes()) {
      return this.$messageService.scrollToError();
    }
    processOrder(consents.termsAndConditionsConsent, consents.thirdPartyConsent);
  }

  setEditMode(step: STEPS) {
    setEditMode(step);
  }

  destroyed() {
    if (this.scrollToStepSubscription) {
      this.scrollToStepSubscription.unsubscribe();
    }

    this.timeouts.forEach(id => clearTimeout(id));
    this.timeouts.clear();
  }
}
