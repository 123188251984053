
import WsIcon from './WsIcon.vue';
import {defineComponent} from 'vue';

export default defineComponent({
  components: {
    WsIcon,
  },
  props: {
    small: Boolean
  }
});
