var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-cart-item full-width",class:{
    'item-disabled': _vm.isDisabled,
    'ws-hover-fly-in-trigger': !_vm.isDisabled,
    'bundle-cart-item': _vm.isBundleCartItem,
  }},[_c('div',{staticClass:"main-row full-width",class:{
      'is-sub-item': _vm.isSubItem,
    }},[_c('div',{staticClass:"main-row-item no-wrap row justify-between items-center"},[_c('cart-item-label',{staticClass:"col-shrink",attrs:{"item":_vm.item}}),(!_vm.mobile && _vm.get(_vm.item, 'properties.premium'))?_c('premium-badge',{staticClass:"float-right",class:{
          'q-ml-md': _vm.desktop,
          'q-ml-sm': _vm.tablet,
        },attrs:{"icon-only":_vm.tablet}}):_vm._e()],1),(_vm.showLicenses)?_c('div',{staticClass:"ws-typo-xs q-ml-sm",attrs:{"id":"licenses"}},[_c('ws-select-number',{staticClass:"number-select",attrs:{"value":_vm.item.licenses,"min":0,"disabled":_vm.isLicensesInputDisabled},on:{"input":_vm.updateLicencesNumber}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.mobile),expression:"!mobile"}],staticClass:"q-ml-md-sm"},[_vm._v(" "+_vm._s(_vm.$t('product.business_mail.licenses', {count: _vm.item.licenses}))+" ")])],1):(!_vm.mobile && _vm.showPeriod)?_c('ws-period',{staticClass:"main-row-item",class:{
        'q-ml-2xl': _vm.desktop,
        'q-ml-sm': _vm.tablet,
      },attrs:{"period":_vm.item.period}}):_vm._e(),(_vm.isPriceLoaded)?_c('price-cell',{staticClass:"main-row-item inline-block text-right q-ml-md",class:{
        'col-shrink': _vm.mobile,
      },attrs:{"price":{
        ..._vm.item.price,
        prolongPrice: _vm.get(_vm.item, 'prolongPrice.vatExcluded'),
      },"show-period":_vm.showPeriodPriceCell,"period":_vm.item.period,"prolong-period":_vm.item.prolongPeriod}}):_c('div',{staticStyle:{"text-align":"right"}},[_c('ws-spinner')],1),(!_vm.readOnlyMobileApplied)?[(_vm.deviceInfo.touchInUse || _vm.mobile)?[(_vm.settingsIconVisible || _vm.isSubItem)?_c('ws-button',{staticClass:"tool-btn",attrs:{"flat":"","small":"","icon-size":24,"icon":"elipsis_vertical","text-color":_vm.showMenu ? 'ws-ui-active' : 'ws-base090'}}):_vm._e(),_c('ws-menu',{attrs:{"anchor":"top right","self":"top right","offset":[24, -36]},model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[(!_vm.isSubItem)?[_c('ws-button',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"flat":"","small":"","icon-size":24,"icon":"cog","label":"wsk.general.settings"},on:{"click":function($event){return _vm.toggleEditItem(_vm.item)}}}),_c('br')]:_vm._e(),_c('remove-btn',{staticClass:"cy--cart-item--remove",attrs:{"label":"wsk.general.remove","loading":_vm.loading},on:{"click":function($event){_vm.isSubItem ? _vm.updateLicencesNumber(0) : _vm.remove()}}})],2)]:(_vm.settingsIconVisible)?_c('ws-button',{staticClass:"tool-btn",attrs:{"text-color":"ws-base090","flat":"","small":"","icon-size":24,"icon":"cog","tooltip":"cart.item.settings_tooltip"},on:{"click":function($event){return _vm.toggleEditItem(_vm.item)}}}):_vm._e(),(!_vm.deviceInfo.touchInUse && !_vm.mobile && !_vm.disableExtraSection)?_c('div',{staticClass:"extra-section-wrap position-relative"},[_c('div',{staticClass:"extra-section ws-hover-fly-in-reveal row no-wrap items-center",class:{
            'q-ml-2xl': _vm.isSubItem,
          },attrs:{"settings-icon-visible":_vm.settingsIconVisible}},[(!_vm.settingsIconVisible & !_vm.isSubItem)?_c('ws-button',{attrs:{"text-color":"ws-base090","flat":"","small":"","icon-size":24,"icon":"cog","tooltip":"cart.item.settings_tooltip"},on:{"click":function($event){return _vm.toggleEditItem(_vm.item)}}}):_vm._e(),_c('remove-btn',{staticClass:"cy--cart-item--remove",attrs:{"loading":_vm.loading},on:{"click":function($event){_vm.isSubItem ? _vm.updateLicencesNumber(0) : _vm.remove()}}})],1)]):_vm._e()]:_vm._e()],2),(_vm.mobile && (_vm.get(_vm.item, 'properties.premium') || _vm.showPeriod))?_c('div',{staticClass:"mobile-row row items-center q-mt-sm"},[(_vm.get(_vm.item, 'properties.premium'))?_c('premium-badge',{staticClass:"q-mr-md"}):_vm._e(),(_vm.showPeriod)?_c('ws-period',{attrs:{"period":_vm.item.period}}):_vm._e()],1):_vm._e(),_c('cart-item-info-box',{staticClass:"q-mt-md",attrs:{"item":_vm.item}}),_c('cart-item-message',{staticClass:"q-mt-sm",attrs:{"item":_vm.item}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }