const cache: Record<string, unknown> = Object.create(null);

export function setItem(key: string, value: any, useCache?: boolean) {
  const parsed = JSON.stringify(value);
  localStorage.setItem(key, parsed);
  if (useCache) {
    cache[key] = value;
  }
}

export function getItem(key: string, useCache?: boolean) {
  if (useCache && cache[key] !== undefined) {
    return cache[key];
  } else {
    try {
      let value = localStorage.getItem(key);
      value = value ? JSON.parse(value) : undefined;
      if (useCache && value !== undefined) {
        cache[key] = value;
      }
      return value;
    } catch (e) {
      // TODO: Notify sentry about this case
      localStorage.removeItem(key);
      return null;
    }
  }
}

export function removeItem(key: string) {
  localStorage.removeItem(key);
  delete cache[key];
}
