export enum StateMutations {
  MERGE_STATE = 'MERGE_STATE',
  SET_STATE = 'SET_STATE',
  REMOVE_STATE = 'REMOVE_STATE',
  SET_UI_MESSAGE = 'SET_UI_MESSAGE',
  SET_REQUIRED_FIELDS = 'SET_REQUIRED_FIELDS',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  ADD_PROFILE = 'ADD_PROFILE',
  // Domain checker mutations
  ADD_DOMAIN_RESULT = 'ADD_DOMAIN_RESULT',
  RESET_DOMAIN_CHECKER = 'RESET_DOMAIN_CHECKER',
  CHANGE_DOMAIN_STATE_IN_CART = 'CHANGE_DOMAIN_STATE_IN_CART',
  SET_DOMAIN_ITEM_LOADING_STATE = 'SET_DOMAIN_ITEM_LOADING_STATE',
  SET_DOMAIN_CATEGORIES = 'SET_DOMAIN_CATEGORIES',
  // Hosting tutorial mutations
  SET_HOSTING_TUTORIAL_PRODUCTS = 'SET_HOSTING_TUTORIAL_PRODUCTS',
  SET_HOSTING_TUTORIAL_REMEMBERED_CONFIGURATION = 'SET_HOSTING_TUTORIAL_REMEMBERED_CONFIGURATION',
  SET_PREVIOSLY_SEARCHED_DOMAIN = 'SET_PREVIOSLY_SEARCHED_DOMAIN',
  // Analytics
  SET_ANALYTICS_DATA = 'SET_ANALYTICS_DATA',
  RESET_ANALYTICS_DATA = 'RESET_ANALYTICS_DATA',
}

export enum SidePanels {
  Hidden,
  Login,
}

export enum StoreActions {
  RESET_STATE = 'RESET_STATE',
  REMOVE_FILE = 'REMOVE_FILE',
  FETCH_REQ_FIELDS = 'FETCH_REQ_FIELDS',
  SET_USER_STEP = 'SET_USER_STEP',
  FETCH_BILLING_PROFILES = 'FETCH_BILLING_PROFILES',
  ENSURE_DOMAIN_PROFILES = 'ENSURE_DOMAIN_PROFILES',
  FETCH_DOMAIN_PROFILES = 'FETCH_DOMAIN_PROFILES',
  FETCH_DOMAIN_CATEGORIES = 'FETCH_DOMAIN_CATEGORIES',
  ENSURE_USER_DOMAINS = 'ENSURE_USER_DOMAINS',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  FETCH_USER_PROFILE = 'FETCH_USER_PROFILE',
  FETCH_PAYMENT_OPTIONS = 'FETCH_PAYMENT_OPTIONS',
  SET_THEME = 'SET_THEME',
}
