import { VueConstructor } from 'vue';
import { Notify } from 'quasar';

import env from '@env';
import { MessageService } from '@WS_UIkit';
import { scrollToElement } from '@loopia-group/utils';
import { get, isArray } from 'lodash-es';
import {
  ApiErrorHandlerOptions,
  ApiErrorResponseData,
  Message,
} from '@WS_UIkit/types';
import { CommonProfile } from '@/types';
import { logError } from '@/services/logger.service';
import { STEPS } from '@/services/const.enum';
import { AxiosError } from 'axios';

/*
  Project implementation of MessageService
 */
export class CartMessageService extends MessageService {
  constructor(options: any = {}) {
    super({
      isProductionBuild: env.isProductionBuild,
      development: env.development,
      ...options,
    });

    // debug mode
    // if (!env.isProductionBuild) {
    //   (window as any)._messageService = this;
    // }
  }

  processMessage(message: Message): Message {
    if (env.development && message) {
      Notify.create({
        type: 'negative',
        message: message.translationKey,
        timeout: 20000,
        progress: true,
        closeBtn: 'x',
      });
    }

    return super.processMessage(message);
  }

  processError(
    error: any,
    namespace: string,
    options: ApiErrorHandlerOptions = {}
  ) {
    const context: any = error.context || error.response?.data?.context;
    const step = context?.step as STEPS | undefined;
    let stepRelatedNamespace = step === STEPS.CART ? step : 'cart.' + step;

    if (context?.cartItemId) {
      // aim for exact item
      stepRelatedNamespace += '.' + context.cartItemId;
      // ignore wrapper error
      error = error.response?.data || error;
      // ignore another wrapper error
      const errors = error.context?.errors || [error];
      errors.forEach((error: any) =>
        super.processError(error, stepRelatedNamespace, options)
      );
    } else if (step) {
      super.processError(error, stepRelatedNamespace, options);
    } else {
      const data: ApiErrorResponseData =
        (error as AxiosError).response && (error as AxiosError).response!.data;
      // disable scroll if multiple errors are present
      if (isArray(data?.context?.errors)) {
        options.preventScroll = true;
      }
      super.processError(error, namespace, options);
    }
  }

  scrollToError() {
    setTimeout(() => {
      // setTimeout to wait for error classes applied to elements
      scrollToElement(
        (document.getElementsByClassName('q-field--error')[0] ||
          document.getElementsByClassName('ws-messages')[0]) as HTMLElement
      );
    }, 0);
    //super.scrollToError();
  }
}

// Create singleton class
export const messageService: CartMessageService = new CartMessageService({
  logger: logError,
});

// Message Service SINGLETON across app
// @link https://vuejs.org/v2/guide/plugins.html
export const vueMessagePlugin = {
  install: (Vue: VueConstructor) => {
    // TODO: move creation of singleton here after fallback exports are removed
    Vue.prototype.$messageService = messageService;
  },
};

export function getVatTaxTKeyInterceptor(
  profile: CommonProfile
  // eslint-disable-next-line no-unused-vars
): (tKey: string) => string {
  const countryCode = get(profile, 'countryCode');
  return (tKey: string) =>
    countryCode === 'CZ' ? tKey.replace(/vat_id/g, 'tax_id') : tKey;
}
