
import {computed, defineComponent, inject} from 'vue';
import WsButton from '@WS_Components/WsButton.vue';
import {SelectedProduct} from '@/modules/iwp/views/Product';
import {store} from '@/store/store';

export default defineComponent({
  name: 'CartNavigation',
  components: {WsButton},
  props: {

    selectedProduct: { type: String, default: null }
  },
  setup () {
    const iwpInjected = inject<SelectedProduct>('iwpProvider');
    const navigateBack = iwpInjected?.navigateBack;
    const navigateToStep = iwpInjected?.navigateToStep;
    const previousPath = iwpInjected?.previousPath;
    const selectedPackage = iwpInjected?.selectedPackage;
    const submitCartItem = iwpInjected?.submitCartItem;

    const cartTotal = computed(() => store?.state?.cart?.summary?.price?.vatIncluded);
    const readyToCheckout = computed(() => selectedPackage?.value !== '');
    const isCheckout = computed(() => previousPath?.value !== '');

    return {
      navigateBack,
      navigateToStep,
      previousPath,
      isCheckout,
      readyToCheckout,
      selectedPackage,
      submitCartItem,
      cartTotal
    };
  }
});
