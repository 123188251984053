import { EnvironmentValues, enviromentBaseValues } from './env.base';
import { assign } from '@loopia-group/utils';

export const enviromentProdValues: EnvironmentValues = assign<
  EnvironmentValues
>(
  {
    production: true,
    isProductionBuild: true,
  },
  enviromentBaseValues
);

export default enviromentProdValues;
