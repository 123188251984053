
import { computed, defineComponent, PropType } from 'vue';
import { QSpinnerDots, QSpinner } from 'quasar';
import { Theme } from '@loopia-group/services';
import WsCarySpinner from './WsCarySpinner.vue';

export enum SpinnerType {
  CARY = 'cary',
  CIRCULAR = 'circular',
  DOTS = 'dots',
  NONE = '',
}

export default defineComponent({
  name: 'WsSpinner',
  components: {
    QSpinner,
    QSpinnerDots,
    WsCarySpinner
  },
  props: {
    spinnerType: {
      type: String as PropType<SpinnerType>,
      default: ''
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
  },
  data(props) {
    const spinnerType = props.spinnerType as SpinnerType;
    const isTheme = (theme: Theme) => {
      return (this as any).isTheme(theme);
    };

    const spinnerTypes = {
      [SpinnerType.CIRCULAR]: 'q-spinner',
      [SpinnerType.CARY]: 'ws-cary-spinner',
      [SpinnerType.DOTS]: 'q-spinner-dots',
    };

    const currentSpinner = computed(() => {
      if (spinnerType in spinnerTypes) {
        return (spinnerTypes as Record<SpinnerType, string>)[spinnerType];
      } else if (isTheme(Theme.CARY)) {
        return 'ws-cary-spinner';
      } else if (isTheme(Theme.LOOPIA) || isTheme(Theme.ACTIVE_24)) {
        return 'q-spinner-dots';
      } else {
        return 'q-spinner';
      }
    });

    const getSpinnerDefaultSize = computed(() => {
      if (spinnerType && spinnerType === SpinnerType.CIRCULAR || isTheme(Theme.ADMIN)) {
        return props.width || '24px';
      } else {
        return props.width || '80px';
      }
    });

    const getSpinnerColor = computed(() => {
      if (props.color) {
        return props.color;
      } else if (isTheme(Theme.CARY)) {
        return 'dark';
      } else if (isTheme(Theme.LOOPIA)) {
        return 'white';
      } else {
        return undefined;
      }
    });

    return {
      Theme,
      SpinnerType,
      currentSpinner,
      getSpinnerDefaultSize,
      getSpinnerColor
    };
  },
});
