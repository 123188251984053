import Vue from 'vue';

import networkService from '@/services/network.service';
import { messageService } from '@/services/message.service';

import { State, LicenseType } from './businessMail.module.d';

// @ts-ignore
export default {
  namespaced: true,
  state: {
    prices: {},
  },
  getters: {
    licensePrice: ({ prices }: State) => (
      itemId: number,
      licenseType: LicenseType,
      licencesNumber: number = 1
      // @ts-ignore
    ) => ({
      actualPrice: prices[itemId]?.[licenseType].actualPrice * licencesNumber,
      marketingPrice: prices[itemId]?.[licenseType].marketingPrice * licencesNumber
    }),
    isItemPriceLoaded: ({ prices }: State) => (itemId: number): boolean =>
      !!prices[itemId],
  },
  mutations: {
    updatePrices(state: State, { prices, itemId }: any) {
      Vue.set(state.prices, itemId, prices);
    },
  },
  actions: {
    async fetchPrices({ commit, state }: any, itemId: number) {
      if (state.prices.length) {
        return;
      }
      try {
        const { data } = await networkService.get(
          `/business-mail/one-license-prices/${itemId}`
        );
        commit('updatePrices', { prices: data, itemId });
      } catch (err) {
        messageService.errorHandler('fetchBusinessMailPrice')(err);
      }
    },
  },
};
