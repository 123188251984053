
import { mapState } from 'vuex';
import {Vue, Component, Provide, Watch, ProvideReactive} from 'vue-property-decorator';
import WsButton from '@WS_Components/WsButton.vue';
import WsModal from '@WS_Components/Dialogs/WsModal.vue';
import HostingTutorialNavigation from '@/components/hostingTutorial/HostingTutorialNavigation.vue';
import HostingTutorialContent from '@/components/hostingTutorial/HostingTutorialContent.vue';
import { hostingTutorialStepsConfig } from '@/__mocks__/hostingTutorial';
import { QLinearProgress, QScrollArea } from 'quasar';
import {
  Cart,
  CartItem,
  HostingTutorialStandaloneProduct,
  HostingTutorialOption,
  HostingTutorialStep,
} from '@/types';
import { getCookieValue } from '@loopia-group/utils';
import { HostingTutorialState } from '@/store/const';
import {
  HOSTING_TUTORIAL_COOKIE,
  HOSTING_TUTORIAL_NAMESPACE,
  HOSTING_TUTORIAL_PROPERTY_KEYS,
  HOSTING_TUTORIAL_STEPS,
} from '@/services/const';
import { CartItemCode, HostingTutorialStepTypes } from '@/services/const.enum';
import { getItem, setItem } from '@loopia-group/utils';
import { hostingTutorialPatchCloseType } from '../../services/hosting-tutorial.service';

@Component({
  components: {
    HostingTutorialNavigation,
    HostingTutorialContent,
    QLinearProgress,
    QScrollArea,
    WsButton,
    WsModal,
  },
  // https://github.com/kaorun343/vue-property-decorator/issues/277#issuecomment-558594655
  inject: [],
  computed: {
    ...mapState(['hostingTutorial', 'cart']),
  },
})
export default class HostingTutorial extends Vue {
  domain: Record<string, any> = { domain: '' };
  @ProvideReactive('step') step: HostingTutorialStep | null = null;
  @Provide(HOSTING_TUTORIAL_PROPERTY_KEYS.DOMAIN) domainValue: Record<
    string,
    any
  > = this.domain;
  @Provide('close') closeMethod: Function = this.close;
  readonly hostingTutorial!: HostingTutorialState;
  readonly cart!: Cart;
  products: HostingTutorialStandaloneProduct[] | null = null;
  showing: boolean = false;
  shown: boolean = false;
  resolved: boolean = false;
  steps: Record<string, HostingTutorialStep> | null = null;

  @Watch('hostingTutorial.products', { deep: true })
  onHostingTutorialStandaloneProductsChange(
    products: HostingTutorialStandaloneProduct[]
  ): void {
    if (products.length > 0) {
      this.setProducts(products);
      this.filterSteps();
    }
  }

  get filteredProductsForStep(): HostingTutorialStandaloneProduct[] {
    if (this.step === null || this.products === null) {
      return [];
    }

    return this.filterProductsForStep(this.step);
  }

  filterProductsForStep(step: HostingTutorialStep): HostingTutorialStandaloneProduct[] {
    if (step.products === undefined) {
      return [];
    }

    return this.products!.filter(
      (product: HostingTutorialStandaloneProduct) => {
        const locations = new Set(product.locations);
        let hasLocations = 0;

        step!.products!.forEach(item => {
          if (locations.has(item)) {
            hasLocations += 1;
          }
        });

        if (hasLocations === step?.products?.length) {
          return product;
        }
      }
    );
  }

  filterSteps(): void {
    const stepsToRemove: Array<string> = [];

    for (const key in this.steps) {
      const step = this.steps[key];

      if (step.products === undefined) {
        continue;
      }

      if (this.filterProductsForStep(step).length === 0) {
        stepsToRemove.push(key);
        delete this.steps[key];
      }
    }

    if (stepsToRemove.length === 0) {
      return;
    }

    for (const key in this.steps) {
      const step = this.steps[key];
      const options = step.options;

      options.forEach((option: HostingTutorialOption, key) => {
        if (stepsToRemove.includes(option.toStep)) {
          step.options.splice(key, 1);
        }
      });
    }
  }

  @Watch('cart.items', { deep: true, immediate: true })
  onCartItemsChange(items: CartItem[]): void {
    if (this.shown && this.showing) {
      return;
    }

    if (items.length !== 1) {
      this.resolved = true;
      this.setSteps(null);

      return;
    }

    const code = items[0].code;
    const domain = items[0].properties?.domain;

    if (code === CartItemCode.DOMAIN_REGISTER) {
      this.setSteps(HOSTING_TUTORIAL_STEPS.MAIN);
    } else if (code === CartItemCode.DOMAIN_TRANSFER) {
      this.setSteps(HOSTING_TUTORIAL_STEPS.TRANSFER);
    } else {
      this.setSteps(null);
    }

    if (domain) {
      this.domain.domain = domain;
    }
  }

  @Watch('canShow')
  onCanShow(value: boolean) {
    if (value) {
      this.shown = true;
      this.showing = true;

      this.toggleBodyOverflowHidden();
    }
  }

  @Watch('resolved', {
    immediate: true,
  })
  onResolved(value: boolean) {
    if (value) {
      this.$emit('resolved');
    }
  }

  get isList(): boolean {
    return this.step?.type === HostingTutorialStepTypes.List;
  }

  get currentStepCode(): string | null {
    return this.step?.code || null;
  }

  get forceBackStepCode(): string | null {
    return this.step?.backToCode || null;
  }

  get progress(): number {
    if (!this.step) {
      return 1 / 4;
    }
    const counter = this.step.stepCounter || 0;

    return (1 / 4) * counter;
  }

  get isStepLast(): boolean {
    return this.progress === 1;
  }

  get canShow() {
    const disabledByUser = getCookieValue(HOSTING_TUTORIAL_COOKIE) === 'true';
    const itemsCount = this.cart?.items?.length;

    if (disabledByUser || !this.steps || (this.shown && !this.showing)) {
      this.resolved = true;
    }

    return (
      itemsCount === 1 &&
      this.products &&
      this.products.length > 0 &&
      this.step &&
      !this.shown &&
      !disabledByUser
    );
  }

  mounted() {
    this.checkShown();
    this.setProducts(this.hostingTutorial.products);
  }

  setProducts(products: HostingTutorialStandaloneProduct[]): void {
    this.products = products;
  }

  toggleBodyOverflowHidden(remove?: boolean): void {
    if (remove) {
      document.body.classList.remove('overflow-hidden');

      return;
    }

    document.body.classList.add('overflow-hidden');
  }

  checkShown() {
    const isShownId: any = getItem(HOSTING_TUTORIAL_NAMESPACE);

    if (!isShownId) {
      return;
    }

    this.shown = isShownId === this.cart?.uuid;
  }

  onNavigate(stepCode: string): void {
    if (!this.steps) {
      return;
    }

    if (stepCode === HOSTING_TUTORIAL_STEPS.CLOSE) {
      this.close(false);
      return;
    }

    this.step = this.steps[stepCode];
  }

  close(skip: boolean): void {
    this.showing = false;

    setItem(HOSTING_TUTORIAL_NAMESPACE, this.cart?.uuid, true);
    hostingTutorialPatchCloseType(skip).then(() => {
      this.resolved = true;
    });

    this.toggleBodyOverflowHidden(true);
  }

  setSteps(type: string | null): void {
    if (!type) {
      this.steps = null;

      return;
    }

    this.steps = hostingTutorialStepsConfig[type];

    if (this.steps) {
      this.step = this.steps[HOSTING_TUTORIAL_STEPS.STEP];
    }
  }
}
