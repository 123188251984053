
import { Component, Vue } from 'vue-property-decorator';
import { dom } from 'quasar';

import { Subscription, fromEvent } from 'rxjs';
import { throttle, debounceTime } from 'rxjs/operators';

const { height } = dom;

import VueStickyDirective from '@/vendor/vue-sticky-directive/vueSticky.directive';

@Component({
  directives: {
    sticky: VueStickyDirective,
  },
})
class SidebarLayout extends Vue {
  readonly mobile!: boolean;

  stickyOptions = {
    topSpacing: this.getTopSpacing(),
    bottomSpacing: 16,
    resizeSensor: true,
    containerSelector: '[data-v-sticky-container]',
    innerWrapperSelector: '[data-v-sticky-inner]',
    disabled: this.mobile,
  };

  resizeEvent: Subscription | null = null;

  created() {
    window.addEventListener('scroll', this.handleScroll);
  }
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    this.stickyOptions.topSpacing = this.getTopSpacing();
  }

  resizeHook() {
    const stream = fromEvent(window, 'resize');
    const controllerStream = stream.pipe(debounceTime(500));
    this.resizeEvent = stream
      .pipe(
        throttle(() => controllerStream, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe(() => {
        this.stickyOptions.disabled = this.mobile;
      });
  }

  destroySticky() {
    if (this.resizeEvent) {
      this.resizeEvent.unsubscribe();
    }
  }

  getTopSpacing() {
    const fullHeader = document.querySelector('.sticky-header-wrap');

    let spacing = 0;
    spacing += fullHeader ? height(fullHeader) + 24 : 0;

    return spacing;
  }
}

export default SidebarLayout;
