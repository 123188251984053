
import { Component, Vue, Prop } from 'vue-property-decorator';
import WsIcon from './WsIcon.vue';
import WsInput from './WsInput.vue';

@Component({
  components: { WsIcon, WsInput },
})
export default class WsNumberInput extends Vue {
  @Prop({ type: Number, default: 0 }) readonly value!: number;
  @Prop({ type: Number, default: 1 }) readonly step!: number;
  @Prop({ type: Number, default: null }) readonly max!: number | null;
  @Prop({ type: Number, default: null }) readonly min!: number | null;
  @Prop({ type: Boolean }) readonly disabled!: boolean;

  updateField(step: number) {
    this.onUpdate(this.value + step);
  }

  onUpdate(step: string | number) {
    const numberValue = typeof step === 'string' ? parseInt(step) : step;
    if (!isNaN(numberValue)) {
      if (
        (this.max !== null && this.max < numberValue) ||
        (this.min !== null && this.min > numberValue)
      ) {
        return;
      }
      this.$emit('input', numberValue);
    }
  }
}
