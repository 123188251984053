import type {I18nOptions, Formatter, Values, Locale} from 'vue-i18n';
import MessageFormat, {Msg} from 'messageformat';
import type {Observable} from 'rxjs';

interface CustomFormatterOptions extends I18nOptions {
  localeChanges?: Observable<Locale>;
}

// Custom message formatter to support ICU formatting
// Source: https://github.com/kazupon/vue-i18n/blob/dev/examples/formatting/custom/src/formatter.js
export class CustomFormatter implements Formatter {
  private _caches: {
    [key: string]: Msg;
  } = Object.create(null);
  private _locale = 'en-US';
  // custom Localisation function
  // fixes Error: Localisation function not found for locale
  // ref: https://github.com/messageformat/messageformat/issues/196#issuecomment-392467037
  private _formatter = new MessageFormat();
  // hot fix: Error: Locale "cs-CZ" not found in {}!
  // TODO: maybe it can be fixed by mergind custom object (with dev-EN definition) with some default
  private _devEnFormatter = new MessageFormat({
    ['dev-EN']: (x: any, ord: any) => {
      return ord ? 'few' : 'other';
    },
  });

  constructor(options: CustomFormatterOptions = {}) {
    if (options.locale) {
      this._locale = options.locale;
    }
    if (options.localeChanges) {
      options.localeChanges.subscribe(locale => (this._locale = locale));
    }
  }

  // TODO: add custom formater's support for <i18n> interpolation component
  // for example, see cart's src/components/PaymentOptions/PaymentCardInfo.vue
  // we could be able to solve it this way:
  // https://kazupon.github.io/vue-i18n/guide/interpolation.html#slots-syntax-usage
  // this formatter  doesn't support <i18n> component's slots (VNodes are not parsed, just [object Object] is written)

  interpolate(message: string, values: Values): any[] | null {
    // Transform Laravel style variables to VueI18n style    :var -> {var}
    if (values && typeof values === 'object') {
      for (const key in values) {
        message = message.replace(':' + key, '{' + key + '}');
      }
    }

    let locale = this._locale;
    if (locale === 'xx-XX') {
      // handles Error: Localisation function not found for locale "xx-XX" (pseudolang)
      locale = 'en-US';
    }

    const uniqCacheKey = message + locale;
    let fn = this._caches[uniqCacheKey];
    if (!fn) {
      // hot fix: Error: Locale "cs-CZ" not found in {}!
      fn =
        locale === 'dev-EN'
          ? this._devEnFormatter.compile(message, locale)
          : this._formatter.compile(message, locale);
      this._caches[uniqCacheKey] = fn;
    }
    return [fn(values)];
  }
}

export default CustomFormatter;
