import Vue from 'vue';
import type {AxiosError} from 'axios';

declare const Raven: any;

import i18nService from './i18n/i18n.service';
import {merge, castArray, get, uniqBy, remove, isArray} from 'lodash-es';
import type {
  RemoveMessagePayload,
  SetMessagePayload,
  ClearMessagePayload,
  MessagesProviderOptions,
  Message,
  MessagesWrap,
  ApiErrorHandlerOptions,
  ViolationsType,
  AnyWsError,
  Violation,
  MessagePath,
  MessageServiceGetters,
} from './message.service.d';

// https://googlechrome.github.io/samples/classes-es6/
// https://austincooper.dev/2019/08/09/vue-observable-state-store/
// https://vuejs.org/v2/api/#Vue-observable
// https://dev.to/aakatev/singleton-pattern-in-javascript-31gd

export class MessageService {
  options: any; // TODO: define options type in typescript
  messages: Record<string, MessagesWrap>;
  mutations: any;
  getters: MessageServiceGetters;

  // eslint-disable-next-line sonarjs/cognitive-complexity,max-lines-per-function
  constructor(options: any = {}) {
    this.options = merge(
      {
        isProductionBuild: false,
        development: false,
      },
      options
    );

    // The single source of truth.
    // this.messages = services's internal store (like vuex store).

    this.messages = Vue.observable({
      general: {path: 'general', messages: [], subscribers: 0, recursiveSubscribers: 0},
    });

    if (options.development) {
      const _window = window as any;
      if (!_window.__WS_UI_kit__) {
        _window.__WS_UI_kit__ = {};
      }
      _window.__WS_UI_kit__.messages = this.messages;
    }

    // Getters' purpose is make public access to internal properties
    // (for example this.messages).
    // Getters define public interface of the service's internal store,
    // so it won't be problem
    // to change internal implementation, this.messages object structure etc.

    this.getters = {
      messages: () => this.messages,
      subscribersByPath: (path: MessagePath): number => {
        const paths = castArray(path);
        let subscribersCount = 0;
        paths.forEach((thePath: string) => {
          subscribersCount += this.messages[thePath]?.subscribers || 0;
        });
        return subscribersCount;
      },
      recursiveSubscribersByPath: (path: MessagePath): number => {
        const paths = castArray(path);
        let recursiveSubscribersCount = 0;
        paths.forEach((thePath: string) => {
          recursiveSubscribersCount += this.messages[thePath]?.recursiveSubscribers || 0;
        });
        return recursiveSubscribersCount;
      },
      messagesByPath: (
        path: MessagePath,
        options?: MessagesProviderOptions
      ): Message[] => {
        if (!path) {
          return [];
        }
        const paths = castArray(path);
        const recursive = options?.recursive;

        let messagesArray: Message[] = [];
        paths.forEach((requestedPath: string) => {
          let messagesInPath: Message[] = [];
          if (recursive) {
            const allMessages: [string, MessagesWrap][] = Object.entries(
              this.messages
            );
            allMessages.forEach(entry => {
              // Skip this path if it's just a fallback
              const currentPath = entry[0];
              const currentMessageWrap = entry[1];

              let skipThePath = options?.fallback
                ? this.getters.subscribersByPath(currentPath) > 1
                : false;

              // skip currentPath if its parent (requestedPath)
              // is a recursive subscriber.
              // This means the more general requestedPath doesn't show
              // duplicate currentPath messages from its
              // children with more specific paths.
              if (
                currentPath !== requestedPath
                && currentPath.includes('.')
                && currentPath.startsWith(requestedPath)
                && this.getters.recursiveSubscribersByPath(requestedPath) > 0
              ) {
                skipThePath = true;
              }

              if (currentPath.startsWith(requestedPath) && !skipThePath) {
                messagesInPath!.push.apply(messagesInPath, currentMessageWrap.messages);
              }
            });
          } else {
            messagesInPath =
              options?.fallback && this.getters.subscribersByPath(requestedPath) > 1
                ? [] // Skip this path if it's just a fallback
                : this.messages[requestedPath]?.messages || [];
          }

          const messagesWaitingInGeneral = this.messages.general?.messages.filter(
            message =>
              recursive
                ? message.originalPath?.startsWith(requestedPath)
                : message.originalPath === requestedPath
          );
          messagesArray = messagesArray.concat(messagesWaitingInGeneral);
          messagesArray = messagesArray.concat(messagesInPath);
          messagesArray = uniqBy(messagesArray, 'translation');
        });
        return messagesArray;
      },
    };

    // Please, access this.messages through mutations just like vuex store does
    // and double-check if you haven't created reactivity issues -> use Vue.set,
    // Vue.delete, immutability etc... to remain reactivity of all properties.
    // See https://vuejs.org/v2/guide/reactivity.html

    this.mutations = {
      setMessages: (messages: Message[], path: string): void => {
        let messagesWrap: MessagesWrap = this.messages[path];
        let preserveOriginalPath = false;

        // If a listener doesn't exist for a particular subpath
        // (for example there is no listener for "form-1.type")
        // sets message to parent path if it has a recursive listener
        // (in this case "form-1").
        // Otherwise, it falls into 'general' path.
        if (!messagesWrap) {
          const key = Object.keys(this.messages).find(key =>
            path.startsWith(key) && this.messages[key].recursiveSubscribers > 0);
          if (key) {
            messagesWrap = this.messages[key];
            preserveOriginalPath = true;
          }
        }

        if (!messagesWrap) {
          messagesWrap = this.messages.general;
          preserveOriginalPath = true;
        }

        if (!messagesWrap) {
          return this.options.logger
            ? this.options.logger(
              'no general message wrap (view without general? ' +
              window.location.href +
              ')'
            )
            : null;
        }

        messages = uniqBy(
          messagesWrap.messages.concat(messages),
          'translationKey'
        );

        if (preserveOriginalPath) {
          messages.forEach(message => {
            if (!message.originalPath) {
              message.originalPath = path;
            }
          });
        }

        messagesWrap.messages.splice(0); // clear
        messagesWrap.messages.push(...messages); // fill-in
      },

      subscribe: (path: MessagePath, options: MessagesProviderOptions) => {
        const paths = castArray(path);

        paths.forEach((path: string) => {
          const wrap = this.messages[path] as MessagesWrap;
          if (wrap) {
            // subscriber on same path already exists
            // so subscribers +1
            Vue.set(
              this.messages[path],
              'subscribers',
              get(this.messages[path], 'subscribers', 0) + 1
            );
            Vue.set(
              this.messages[path],
              'recursiveSubscribers',
              get(this.messages[path], 'recursiveSubscribers', 0) + 1
            );
          } else {
            // new subscription, create wrap
            Vue.set(this.messages, path, {
              path,
              messages: [],
              subscribers: 1,
              recursiveSubscribers: options?.recursive ? 1 : 0
            });
          }
        });
      },

      unsubscribe: (path: MessagePath) => {
        const paths = castArray(path);

        paths.forEach((path: string) => {
          const wrap = this.messages[path];

          if (wrap && wrap.subscribers > 1) {
            // subscription exists, so subscribers - 1
            Vue.set(
              this.messages[path],
              'subscribers',
              get(this.messages[path], 'subscribers', 0) - 1
            );
          } else if (path !== 'general') {
            Vue.delete(this.messages, path);
          }
        });
      },
    };
  }

  subscribe(path: MessagePath, options?: MessagesProviderOptions) {
    this.mutations.subscribe(path, options);
    const generalMessages = this.messages.general?.messages;

    // scan general messages for fallbacked messages and potentially
    // move them to right place when it is available now after new subscriptions
    if (generalMessages?.length) {
      const paths = castArray(path);
      paths.forEach((_path: string) => {
        generalMessages
          .filter(
            (message: Message) =>
              message.originalPath && message.originalPath.startsWith(_path)
          )
          .forEach(message => {
            remove(generalMessages, message);
            this.mutations.setMessages([message], _path);
          });
      });
    }
  }

  unsubscribe(path: MessagePath, options?: MessagesProviderOptions) {
    this.mutations.unsubscribe(path, options);
  }

  // helper function
  _getWrap(
    messages: Record<string, MessagesWrap>,
    path: string
  ): MessagesWrap | null {
    return messages[path] || null;
  }

  getErrorKey(err: any): any {
    return ['key', 'translationKey', 'tKey', 'title'].find(
      key => err && err[key]
    );
  }

  unknownErrorMessage(unknownErrorKey?: string): Message {
    const sentryId: string | false | null =
      typeof Raven !== 'undefined' && Raven.lastEventId();
    return {
      translationKey:
        unknownErrorKey ||
        (sentryId
          ? 'wsk.general.unknown_error_id'
          : 'wsk.general.unknown_error'),
      values: sentryId ? {id: sentryId} : {},
    };
  }

  unknownError(
    namespace: string,
    error: any = 'unknown error ¯\\_(ツ)_/¯',
    options: ApiErrorHandlerOptions = {}
  ) {
    this.setStandardMessage(
      namespace,
      [this.unknownErrorMessage(options.unknownErrorKey)],
      options
    );

    if (this.options.development) {
      // eslint-disable-next-line no-console
      console.error(error);
    } else if (this.options.logger) {
      this.options.logger(error);
    }
  }

  setErrorMessage(
    namespace: string,
    tKeyOrMsg: string | Message[],
    options: ApiErrorHandlerOptions
  ): void {
    let messages: Message[] =
      typeof tKeyOrMsg === 'string'
        ? [{translationKey: tKeyOrMsg}]
        : tKeyOrMsg;
    const i18n = get(i18nService, 'i18n');

    if (i18n && i18n.t) {
      messages = messages.map(message => {
        if (message.translationKey && !i18n.t(message.translationKey)) {
          return this.unknownErrorMessage(options.unknownErrorKey);
        }
        return message;
      });
    }

    this.setStandardMessage(namespace, messages, options);
  }

  setFlashMessages(
    flashMessages: Message[]
  ): void {
    flashMessages.forEach(message => {
      const messages: Message[] = [message];
      this.setStandardMessage(message.path ?? `title.${message.type}`, messages);
    });
  };

  setStandardMessage(
    path: string,
    tKeyOrMsg: string | Message[],
    options: ApiErrorHandlerOptions = {}
  ): void {
    let messages: Message[] =
      typeof tKeyOrMsg === 'string'
        ? [{translationKey: tKeyOrMsg}]
        : tKeyOrMsg;

    if (options.keyInterceptor) {
      messages = messages.map((message: Message) => {
        const msg: Message = {...message};
        const interceptorResult: any = options.keyInterceptor!(
          msg.translationKey
        );
        if (typeof interceptorResult === 'string') {
          msg.translationKey = interceptorResult;
        } else {
          msg.translationKey = interceptorResult.translationKey;
          msg.values = interceptorResult.values;
        }
        return msg;
      });
    }

    this.setMessage({
      path,
      messages,
    });
  }

  processViolations(
    namespace: string,
    violations: ViolationsType,
    options: ApiErrorHandlerOptions
  ) {
    if (isArray(violations)) {
      this.processArrayOfViolations(violations, namespace, options);
    } else {
      for (const item in violations) {
        (violations as Record<string | number, Violation[]>)[item].forEach(
          violation => {
            const path = namespace
              ? `${namespace}.${item}.${violation.propertyPath}`
              : `${item}.${violation.propertyPath}`;

            this.setErrorMessage(
              path,
              violation.errors || null,
              options
            );
          }
        );
      }
    }
  }

  processArrayOfViolations(violations: any[], namespace: string, options: any) {
    (violations as Violation[]).forEach(violation => {
      const path = namespace ? `${namespace}.${violation.propertyPath}` : violation.propertyPath;

      this.setErrorMessage(
        path,
        violation.errors || null,
        options
      );
    });
  }

  /*
      USAGE of errorHandler

      apiCall(data).catch(errorHandler('yourNamespace');

      in template:
      <ws-message path="yourNamespace" />

      or dynamic
      apiCall(data).catch(errorHandler('yourNamespace' + rowNumber);

      in template:
      <ws-message :path="'yourNamespace' + rowNumber" />

      with options
      fetchCart().catch(
        errorHandler('general', {
          unknownErrorKey: 'exception.cart.not_fetched',
        })
      );

      let service to process error
      apiCall(data).catch(error => {
        errorHandler('yourNamespace')(error);
      });

    */
  errorHandler(
    namespace: string = 'general',
    options?: ApiErrorHandlerOptions
  ): any {
    return (error: AnyWsError) => {
      // ability to extend error processing method in child class
      return this.processError(error, namespace, options);
    };
  }

  // Can be extended on project basis
  // eslint-disable-next-line sonarjs/cognitive-complexity
  processError(
    error: any,
    namespace: string,
    options: ApiErrorHandlerOptions = {}
  ): any {
    if (this.options.development) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (!error) {
      return this.unknownError(namespace, error);
    }

    const errorData = (error as AxiosError)?.response?.data || error;
    let someKey: string | undefined;

    if(errorData.status === 429) {
      errorData.key = 'too_many_attempts';
    }

    if (options.propertyPath && !errorData.violations) {
      namespace += '.' + options.propertyPath;
    }

    if (isArray(errorData?.context?.errors)) {
      // Handle multiple error response from context
      errorData?.context?.errors.forEach((error: any) => {
        this.processError(error, namespace, options);
      });
    }

    switch (true) {
    case !!errorData.violations:
      this.processViolations(
        namespace,
        errorData.violations as ViolationsType,
        options
      );
      break;

    case !!(someKey = this.getErrorKey(errorData)): {
      if (someKey === 'title' && this.options.isProductionBuild) {
        // do not show backend error titles in production
        return this.unknownError(namespace, errorData, options);
      }

      const errorText = (errorData as any)[someKey!];
      this.setErrorMessage(namespace, errorText, options);
      if (someKey!.toLowerCase().includes('exception')) {
        // log all exceptions
        this.options.logger && this.options.logger(errorText);
      }
      break;
    }
    case typeof errorData === 'string':
      this.setErrorMessage(namespace, errorData as string, options);
      break;

    default:
      this.unknownError(namespace, errorData, options);
      break;
    }

    if (!options.preventScroll) {
      this.scrollToError();
    }
    // propagate error rejection in promise chains (if asked by "returnPromise")
    return options.returnPromise === true ? Promise.reject(error) : undefined;
  }

  // Can be implemented on project basis
  scrollToError() {
    // TODO: project specific?
  }

  // Can be implemented on project basis
  // For example, you can translate the message
  // using translation service in the project.
  processMessage(message: Message): Message {
    return message;
  }

  setMessage({path, messages}: SetMessagePayload) {
    messages = messages.map((message: Message) => {
      return this.processMessage(message);
    });

    // just one unknown error per messages
    messages = uniqBy(messages, 'translationKey');

    this.mutations.setMessages(messages, path);
  }

  removeMessage({path, index, msg}: RemoveMessagePayload) {
    const paths = castArray(path);
    paths.forEach((path: string) => {

      const messagesWrap: MessagesWrap | null = this._getWrap(
        this.messages,
        path
      );

      if (!messagesWrap) {
        return;
      }

      const messages = messagesWrap.messages;
      index = index || index === 0 ? index : messages.indexOf(msg!);

      if (index > -1) {
        if (!msg) {
          // if just index is provided, keep message object
          // for optional removal from general space
          msg = messages[index];
        }

        const targetMessagesArray = get(this.messages[path], 'messages');
        if (!targetMessagesArray) {
          return;
        }

        if (index > -1) {
          targetMessagesArray.splice(index, 1);
        }

        this.mutations.setMessages(targetMessagesArray, messagesWrap.path);
      }
    });
  }

  clearMessages(
    payload: ClearMessagePayload | string | string[],
    exactMatch: boolean = false
  ) {
    const payloads = castArray(payload);
    payloads.forEach((payload: any) => {
      const paths = this.preparePaths(payload);

      paths.forEach((path: string) => {
        if (exactMatch && path === 'general') {
          // clear originalPath === path in general namespace
          this.messages['general'].messages.forEach(message => {
            if (
              !message.originalPath ||
              paths
                .filter((val: any) => val !== 'general')
                .includes(message.originalPath)
            ) {
              this.removeMessage({
                path: 'general',
                msg: message,
              });
            }
          });
          return;
        }
        //let targetMessagesArray = this.messages[path]?.messages;
        if (this.messages[path]?.messages) {
          this.messages[path].messages.splice(0);
        }
      });
    });
  }

  preparePaths(payload: any) {
    if (typeof payload === 'string') {
      payload = {path: payload};
    }

    return castArray(payload.path);
  }

  hasMessages = (
    path: MessagePath,
    options?: MessagesProviderOptions
  ): boolean => !!this.getters.messagesByPath(path, options).length;

  createViolation(propertyPath: string, translationKey: string) {
    return {
      violations: [
        {
          propertyPath,
          errors: [
            {
              translationKey,
            },
          ],
        },
      ],
    };
  }
}
