var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isLoading)?_c('div',{staticClass:"row q-col-gutter-xl q-pa-md justify-center items-center full-height"},[_c('div',{staticClass:"preloader column justify-center items-center full-height ws-typography"},[_c('ws-spinner',{staticClass:"q-mb-xl"}),_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.cary_loading.top_line'))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('general.cary_loading.bottom_line'))+" ")])],1)]):_c('sidebar-layout',{staticClass:"order-view ws-typography q-mt-xl q-mb-5xl",scopedSlots:_vm._u([{key:"side",fn:function(){return [_c('div',{staticClass:"fixes sticky container issue when hiding/showing content",staticStyle:{"min-width":"100px"}}),_c('side-summary',{class:{
        'gt-xs': _vm.progressByUser[_vm.STEPS.RECAPITULATION] === _vm.COMPLETED,
      },attrs:{"edit-cb":_vm.setEditMode}},[(_vm.progressByUser[_vm.STEPS.SERVICE_SETTINGS] === _vm.COMPLETED)?_c('div',{staticClass:"summary-addon q-mt-3xl"},[_c('small',[_c('consent-checkboxes',{ref:"consents",staticClass:"text-left q-mb-lg"})],1),_c('ws-message',{staticClass:"q-mb-lg",attrs:{"banner":"","type":"alert","path":['general', 'checkout', 'cart.summary.quick_order']}}),_c('ws-button',{staticClass:"full-width cy--order-view--finish-order",attrs:{"primary":"","disable":_vm.checkoutInProgress},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('summary.finish_order'))+" ")])],1):_c('ws-message',{staticClass:"q-mt-lg",attrs:{"banner":"","type":"alert","path":['general', 'checkout', 'cart.summary.quick_order']}})],1)]},proxy:true}])},[_c('div',{staticClass:"sidebar-layout-item-wrapper changeable-order",class:{ reverse: _vm.isSwedishWorkflow && _vm.loggedIn }},[(
        !_vm.loggedIn ||
          _vm.isStandardWorkflow ||
          _vm.progressByUser[_vm.STEPS.BILLING_PROFILE] !== _vm.UNTOUCHED
      )?_c('order-process-item',{ref:_vm.STEPS.BILLING_PROFILE,class:!_vm.loggedIn || _vm.isStandardWorkflow ? 'q-mt-none' : 'q-mt-sm q-mt-sm-md',attrs:{"step":_vm.isSwedishWorkflow && !_vm.loggedIn
          ? _vm.STEPS.DOMAIN_PROFILE
          : _vm.STEPS.BILLING_PROFILE}},[_c('billing-profile')],1):_vm._e(),(
        _vm.loggedIn &&
          (_vm.isSwedishWorkflow ||
            (_vm.progressByUser[_vm.STEPS.DOMAIN_PROFILE] !== _vm.UNTOUCHED &&
              (_vm.cart.domainProfileRequired || _vm.allDomainProfilesDefined)))
      )?_c('order-process-item',{ref:_vm.STEPS.DOMAIN_PROFILE,class:_vm.isSwedishWorkflow && _vm.loggedIn ? 'q-mt-none' : 'q-mt-sm q-mt-sm-md',attrs:{"step":_vm.STEPS.DOMAIN_PROFILE,"read-only":_vm.allDomainProfilesDefined}},[_c('domain-profile')],1):_vm._e()],1),(_vm.loggedIn && _vm.progressByUser[_vm.STEPS.PAYMENT_METHOD] !== _vm.UNTOUCHED)?_c('order-process-item',{ref:_vm.STEPS.PAYMENT_METHOD,staticClass:"q-mt-sm q-mt-sm-md",attrs:{"step":_vm.STEPS.PAYMENT_METHOD}},[_c('payment-method')],1):_vm._e(),(
      _vm.loggedIn &&
        _vm.progressByUser[_vm.STEPS.SERVICE_SETTINGS] !== _vm.UNTOUCHED &&
        _vm.hasMandatory
    )?_c('order-process-item',{ref:_vm.STEPS.SERVICE_SETTINGS,staticClass:"q-mt-sm q-mt-sm-md",attrs:{"step":_vm.STEPS.SERVICE_SETTINGS}},[_c('service-settings')],1):_vm._e(),(_vm.recapitulationVisible)?[_c('sidebar-layout-item',{ref:_vm.STEPS.RECAPITULATION,staticClass:"recapitulation q-mt-sm q-mt-sm-md"},[_c('h5',{staticClass:"q-mb-3xl"},[_vm._v(" "+_vm._s(_vm.$t('recapitulation.title'))+" ")]),_c('cart-items',{attrs:{"read-only-mobile":""}})],1),(
        _vm.cart.migrationPromotion.uploadedFiles &&
          _vm.cart.migrationPromotion.uploadedFiles.length
      )?_c('sidebar-layout-item',{staticClass:"q-mt-sm q-mt-sm-md"},[_c('migration-recap',{attrs:{"files":_vm.cart.migrationPromotion.uploadedFiles}})],1):_vm._e(),(
        _vm.donationAvailable &&
          _vm.isTheme(_vm.Theme.CARY) &&
          !_vm.cart.donation.current.roundUpVersion &&
          !_vm.cart.donation.current.customDonation
      )?_c('sidebar-layout-item',{staticClass:"donation-layout-item q-mb-none q-mt-sm q-mt-sm-md"},[_c('order-donation')],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }