
import {Component, Prop, Watch, Inject} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {QRadio} from 'quasar';

import {WsFieldMixin} from './WsFieldMixin.vue';

@Component({
  props: {
    value: {
      type: [Object, Number, String, Boolean],
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
  },
  components: {
    QRadio,
  },
})
export class WsRadio extends mixins(WsFieldMixin) {
  @Prop(Boolean) readonly invisibleWrap!: boolean;
  @Prop({
    type: String,
    default: 'md',
  })
  readonly size!: boolean;
  @Prop({
    type: String,
    default: 'green',
  })
  readonly color!: boolean;
  @Prop({
    type: String,
    default: '',
  })
  readonly name!: string;
  @Prop({
    type: String,
    default: '',
  })
  readonly customClass!: string;
  @Prop({
    type: String,
    default: '',
  })
  readonly customRadioClass!: string;

  @Inject({from: 'formDataOverride', default: null}) formDataOverride: any;

  @Watch('value', {immediate: true})
  onValueChanged() {
    if (this.formDataOverride) {
      this.formDataOverride[this.name] = this.value;
    }

    // Only fire for non-immediate runs
    if (this.$parent && typeof this.$el !== 'undefined') {
      this.$emit('input', this.value);
      this.$parent.$emit('dirty', this.value);
    }
  }

  get rootElementClasses() {
    return [this.customClass, this.customRadioClass].join(' ');
  }

  set() {
    (this.$refs['qfield'] as QRadio).set();
  }
}

export default WsRadio;
