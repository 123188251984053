
import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {QItem, QItemSection, QList} from 'quasar';
import WsInput from '@WS_Components/WsInput.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import DomainProfileList from '@/components/DomainProfileList.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import {CartItem} from '@/types/api-types.d';
import {mapGetters, mapState} from 'vuex';
import {
  addValidations,
  generalPropertyList,
  productPropertyList,
  propertyRules,
  propertyValidation
} from '@/WS_UIkit/src';
import {Debounce, getSnakedKeyFactory} from '@loopia-group/utils';
import {Validator} from '@WS_UIkit/types';
import {cloneDeep, get} from 'lodash-es';
import {DomainProfileExtended} from '@/types';

const FEATURE_SUPPORTED_PROPS = ['domainProfileId', 'name'];

@Component({
  components: {WsCheckbox, DomainProfileList, QItem, QList, WsSelect, WsInput, QItemSection},
  computed: {
    ...mapState(['cartService']),
    ...mapGetters(['cartDomains', 'loggedIn']),
  },
})
export default class ProfilesSettings extends Vue {
  @Prop(Object) readonly initEditItem!: CartItem | null;
  readonly cartDomains!: string[];
  readonly loggedIn!: boolean;
  isDomainListVisible = true;
  editItem: CartItem | null = null;
  path = 'cart_item_settings';
  domainRules!: Validator[];

  mounted() {
    if (!this.editItem) {
      this.editItem = cloneDeep(this.initEditItem);
    }
  }

  created() {
    addValidations([generalPropertyList, productPropertyList]);
    this.domainRules = propertyRules('domain');
  }

  get item(): CartItem {
    // @ts-ignore
    return this.cartService.itemInEdit;
  }

  mapProperties(properties: any[], required = false) {
    const item = this.item || ({} as CartItem);
    const { code: key } = item;
    return properties.map(property => {
      const { rules, restriction } = propertyValidation(`${key}_${property}`);
      return {
        key,
        property,
        item,
        required,
        rules,
        restriction,
      };
    });
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('service_settings.props');
    return this.getKey(prop, key);
  }

  prepareItemForUpdate() {
    if (
      this.itemHasDomainProfile &&
      !this.editItem?.properties?.domainProfileId &&
      this.domainProfileList?.domainProfile?.id
    ) {
      // save selected domain profile even if user did not click (because global profile was already preselected)
      this.editItem!.properties!.domainProfileId = this.domainProfileList?.domainProfile?.id;
    }
  }

  get domainProfileList(): DomainProfileList | null {
    return get(this.$refs.domainProfileList, '[0]', null);
  }

  get itemHasDomainProfile(): boolean {
    return this.allSets.find(set => set.property === 'domainProfileId');
  }

  get allSets(): any[] {
    const result: any[] = [];
    const item = this.item || ({} as CartItem);

    if (item.mandatoryEditableProperties) {
      result.push.apply(
        result,
        this.mapProperties(item.mandatoryEditableProperties, true)
      );
    }
    if (item.optionalEditableProperties) {
      result.push.apply(
        result,
        this.mapProperties(item.optionalEditableProperties)
      );
    }

    let supportedProps = [...FEATURE_SUPPORTED_PROPS];
    if (!this.loggedIn) {
      supportedProps = supportedProps.filter(
        (propName: string) => propName !== 'domainProfileId'
      );
    }

    return result.filter((propObj: any) =>
      supportedProps.includes(propObj.property)
    );
  }

  handleDomainProfileUpdate(domainProfile: DomainProfileExtended) {
    if (this.itemHasDomainProfile && domainProfile && this.editItem &&
      this.editItem?.properties?.domainProfileId !== domainProfile.id) {
      this.editItem.properties = Object.assign({},
        this.editItem?.properties, { domainProfileId: domainProfile.id});
      this.$emit('profile', this.editItem);
    }
  }

  @Watch('editItem', { deep: true })
  @Debounce(300)
  onItemChange(item: CartItem | null) {
    if (item) {
      this.prepareItemForUpdate();
      this.$emit('profile', item);
    }
  }
}
