import Vue from 'vue';
import Vuex from 'vuex';
import env from '@env';
import { mutations } from './mutations';
import { actions } from './actions';
import { getters } from './getters';
import getInitialState from './appState';
import businessMail from './modules/businessMail.module';
import theme from './modules/theme.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  strict: env.isProductionBuild,
  state: getInitialState(),
  modules: {
    businessMail,
    theme,
  },
  actions,
  mutations,
  getters,
});

export default store;
