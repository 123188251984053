
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class DomainBadge extends Vue {
  @Prop()
    value!: string;
  @Prop()
    index!: number;
  @Prop(Boolean)
    error!: boolean;

  editMode: boolean = false;

  confirmChanges() {
    this.editMode = false;
    // @ts-ignore
    this.$refs.domainChangeInput.blur();
    this.$emit('changesDone');
  }

  removeDomain() {
    this.$emit('domain-removed');
  }
}
