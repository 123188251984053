
import { Component, Vue, Provide, Prop, Watch } from 'vue-property-decorator';

import CartItem from './CartItem.vue';
import { getSubItems } from './CartItemBundle.util';

@Component({
  components: { CartItem },
})
export default class CartItemBundle extends Vue {
  @Provide('settingsIconVisible') settingsIconVisible = false;

  @Prop({ default: () => ({}), type: Object }) item: any;

  get subItems() {
    return getSubItems(this.item);
  }

  @Watch('item', { immediate: true })
  async fetchBusinessMailPrices(item: any) {
    if (item.code === 'business-mail') {
      await this.$store.dispatch('businessMail/fetchPrices', this.item.id);
    }
  }

  get isPriceLoaded() {
    if (this.item.code === 'business-mail' && this.item.id) {
      const isItemPriceLoaded = this.$store.getters[
        'businessMail/isItemPriceLoaded'
      ];
      if (typeof isItemPriceLoaded === 'function') {
        return isItemPriceLoaded(this.item.id);
      }
    }
    return true;
  }
}
