import {config} from '@loopia-group/services';
import type {
  QueryAutocompletePrediction,
  PlacesServiceStatus,
  AddressComponents,
  AddressComponentsKeys,
  AddressComponentsExtended,
  PlaceResult,
} from './google-api.service.d';

let googleLocal: any = typeof google === 'undefined' ? null : google;

if (!googleLocal) {
  // wait for google to load
  setWaitTimeout(100);
}

let autocompleteService: google.maps.places.AutocompleteService =
  googleLocal && googleLocal.maps
    ? new googleLocal.maps.places.AutocompleteService(
      document.createElement('input')
    )
    : {};
let placeService: google.maps.places.PlacesService =
  googleLocal && googleLocal.maps
    ? new googleLocal.maps.places.PlacesService(document.createElement('div'))
    : {};

let currentPosition: any = null;

export function askForLocation() {
  if (!currentPosition && navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: any) => {
        currentPosition = position.coords;
      },
      () => {
        /* ignore errors */
      },
      {
        enableHighAccuracy: false,
        maximumAge: 5000,
      }
    );
  }
}

const predictionsCache: Record<string, any> = new Object(null);
// When changing this implementation beware of this cases:
// https://loopiagroup.atlassian.net/browse/PNO-667
// https://loopiagroup.atlassian.net/browse/ITDEV-6332
export function getPlacePredictions(
  input: string,
  types: string = '(cities)'
): Promise<QueryAutocompletePrediction[]> {
  if (!googleLocal) {
    return Promise.reject(new Error('google not loaded'));
  }

  if (!input) {
    return Promise.resolve([]);
  }

  const hash = `${input}::${types}`;
  if (predictionsCache[hash]) {
    return Promise.resolve(predictionsCache[hash]);
  }

  return new Promise((resolve, reject) => {
    autocompleteService.getPlacePredictions(
      {
        input,
        types: [types],
        // specifying location and radius did not enhance results, for example for
        // Sedmerovec 93 address.. it did not find it neither
        // therefore I am not going to make code more complex, without any value
        ...(currentPosition
          ? {
            radius: 5000,
            location: new google.maps.LatLng(
              currentPosition.latitude,
              currentPosition.longitude
            ),
          }
          : {
            bounds: config.bounds,
          }),
      },
      (results: QueryAutocompletePrediction[], status: PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          predictionsCache[hash] = results;
          resolve(results);
        } else {
          reject({results, status});
        }
      }
    );
  });
}

export function getPlaceAddress(placeId: string): Promise<AddressComponents> {
  return new Promise((resolve, reject) => {
    placeService.getDetails(
      {
        placeId,
        fields: ['address_components'],
      },
      (result: PlaceResult, status: PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          resolve(parseAddressComponents(result.address_components));
        } else {
          reject({result, status});
        }
      }
    );
  });
}

export function parseStreetFromComponents(
  components: AddressComponentsExtended
) {
  const street = components.route;
  const number = components.street_number || components.queryNumberParts || '';
  return street && number ? `${street} ${number}` : street || number;
}

export function parseCityFromComponents(components: AddressComponents): string {
  return (
    components.postal_town ||
    components.locality ||
    components.sublocality ||
    components.sublocality_level_5 ||
    components.sublocality_level_4 ||
    components.sublocality_level_3 ||
    components.sublocality_level_2 ||
    components.sublocality_level_1 ||
    components.administrative_area_level_5 ||
    components.administrative_area_level_4 ||
    components.administrative_area_level_3 ||
    components.administrative_area_level_2 ||
    components.administrative_area_level_1 ||
    ''
  );
}

export function parseAddressComponents(
  address_components: PlaceResult['address_components'] = []
): AddressComponents {
  const parsed_components: AddressComponents = {};
  const componentsLength = address_components.length;

  for (let i = 0; i < componentsLength; ++i) {
    const component = address_components[i];
    const types: AddressComponentsKeys[] = component.types as AddressComponentsKeys[];

    for (let ii = 0; ii < types.length; ++ii) {
      const type: AddressComponentsKeys = types[ii];

      parsed_components[type] = component.long_name;

      if (type === 'country') {
        parsed_components.country_code = component.short_name;
      }
    }
  }
  // console.log('parsed address', parsed_components);
  return parsed_components;
}

function setWaitTimeout(time: number) {
  setTimeout(() => {
    if (time > 30000) {
      return;
    }
    googleLocal = typeof google === 'undefined' ? null : google;
    if (!googleLocal) {
      setWaitTimeout((time *= 2));
    } else {
      autocompleteService = new googleLocal.maps.places.AutocompleteService();
      placeService = new googleLocal.maps.places.PlacesService(
        document.createElement('div')
      );
    }
  }, time);
}
