
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

// Components
import {byteConvert, getSnakedKeyFactory} from '@loopia-group/utils';
import { processProps } from '@/utilities';

import { CartItem, DomainProfile } from '@/types';
import { rmTld } from '@/filters';

@Component({
  filters: {rmTld},
  computed: {
    ...mapGetters(['domainProfileById']),
  },
})
export default class CartItemLabel extends Vue {
  @Prop({default: () => ({})}) item!: CartItem;
  readonly domainProfileById!: (id: number) => DomainProfile | null;
  readonly subscriptionKey = 'cart.product.addons.options.addon_ecommerce_subscription.';

  marketingName = '';

  mounted() {
    this.marketingName = this.item!.properties!.packageDetail?.marketingName?.en;
  }

  get properties(): CartItem['properties'] {
    return this.item?.properties || {};
  }

  get showDetails(): boolean {
    const properties = this.properties!;
    const storage = Boolean(properties!?.storageWithAddon);
    const domain = Boolean(properties!.domain);
    const allowedProp = Object.keys(properties).some(key => ['capacity', 'ram', 'type'].includes(key));

    return storage || domain || allowedProp;
  }

  get isDomainRegistration(): boolean {
    return this.item.code === 'domain-register';
  }

  get domainProfile(): DomainProfile | null {
    return this.properties!.domainProfileId
      ? this.domainProfileById(this.properties!.domainProfileId)
      : null;
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('product');
    return this.getKey(prop, key);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getKeySubtitle(prop: string): string {
    let tmpProp = prop;
    const tmpFactory = getSnakedKeyFactory('product_subtitle');

    if (this.properties?.type && this.item.code === 'site-builder') {
      tmpProp = tmpProp.concat('_' + this.properties.type);
    }
    if (this.item.code === 'hosting' && this.properties?.domain) {
      tmpProp = tmpProp.concat('_connected_domain');
    }
    if (this.item.code === 'hosting'
      && !this.properties?.domain
      && this.properties?.assignmentsWithAddon !== 0
    ) {
      tmpProp = tmpProp.concat('_marketing');
    }

    if (this.item.code === 'hosting'
      && this.properties?.assignmentsWithAddon === 0
    ) {
      // Due to SE premium hosting being only one with unlimited assignments
      // and BE is sending us this information as 0 we are creating translations
      // key just for it.
      tmpProp = tmpProp.concat('_unlimited_domains');
    }

    if (this.item.code === 'website-builder') {
      if (this.properties?.domain) {
        tmpProp = tmpProp.concat('_domain');
      }
      if (this.properties?.emailStorageWithAddon > 0) {
        tmpProp = tmpProp.concat('_storage');
      }
      if (this.properties?.ecommerceSubscription) {
        tmpProp = tmpProp.concat('_subscription');
      }
    }

    return tmpFactory(tmpProp);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  subtitleParams(properties: any) {
    let params = {
      ...properties,
    };

    if (properties?.capacity || properties?.ram) {
      params = {
        ...params,
        ...processProps(properties, {
          convert: ['capacity', 'ram'],
          ensure: ['name'],
        }),
      };
    }

    if (properties?.storageWithAddon) {
      params = {
        ...params,
        capacity: byteConvert(properties.storageWithAddon, 'MB'),
      };
    }

    if (properties?.assignmentsWithAddon) {
      params = {
        ...params,
        domainsCount: properties.assignmentsWithAddon || '?',
      };
    }

    if (properties?.emailStorageWithAddon) {
      params = {
        ...params,
        emailStorageWithAddon: byteConvert(properties.emailStorageWithAddon, 'GB'),
      };
    }

    if (properties?.ecommerceSubscription) {
      params = {
        ...params,
        ecommerceSubscription:
          this.$t(this.subscriptionKey + properties?.ecommerceSubscription) as string,
      };
    }

    if (
      this.item.code === 'hosting' && !this.properties?.domain ||
      this.item.code === 'hosting' && properties?.assignmentsWithAddon === 0 ||
      this.item.code === 'website-builder'
    ) {
      params = {
        ...params,
        marketingName: this.marketingName,
      };
    }
    return params;
  }
}
