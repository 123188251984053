
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

import { getSnakedKeyFactory } from '@loopia-group/utils';
import WsButton from '@WS_Components/WsButton.vue';
import { CartItem, StandaloneProduct } from '@/types/index.d';
import { resolvePrice } from '@/utilities';
import { logError } from '@/services/logger.service';
import { addToCart, upgradeItem } from '@/services/cart/cart-api.service';
import caryMageImg from '@/assets/images/Cary/cary-mage.png';
import caryGordulicImg from '@/assets/images/Cary/cary-gordulic.png';
import { XsellItem } from '../types/index';

@Component({
  components: {
    WsButton,
  },
  computed: {
    ...mapGetters(['cartItemById']),
  },
})
export class XSellBanner extends Vue {
  @Prop() readonly item!: XsellItem;
  readonly cartItemById!: (id: number) => CartItem;
  loading = false; // buy button loader

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('xsell.banner.product');
    return this.getKey(prop, key);
  }

  get relevantItem() {
    return (
      this.cartItemById(
        this.item?.reasonCartItemIds?.[0] || this.item.cartItemToUpgradeId || 0
      ) || { properties: {} }
    );
  }

  get XsellDomain() {
    return this.item.defaultConfiguration.prices[0];
  }

  get period() {
    const period = this.relevantItem?.period || 12;
    const xsellDomainPeriod = this.XsellDomain?.period;

    if (period > 12) {
      return 12;
    }

    if (this.item.uid === 'domain_for_domain' && xsellDomainPeriod === 24) {
      return xsellDomainPeriod;
    }

    return period;
  }

  get price(): number {
    let prices = this.item?.defaultConfiguration?.prices;
    let multiplier = 1;
    if (this.item.uid === 'backup_for_servers') {
      // bacup is handled differently as it is closely coupled with VPS so we need to count
      prices = this.item.properties?.backup?.options?.find(
        (o: any) => o.type === 'backup'
      )?.prices;
      multiplier =
        this.item?.defaultConfiguration?.configuration?.capacity / 1024; // to GB;
    }

    const price = resolvePrice(prices, this.period, true);

    if (price === null || price === undefined) {
      return multiplier;
    }

    return price * multiplier;
  }

  get properties() {
    return {
      voucher: this.item.voucherCode,
      domain: this.relevantItem?.properties?.domain,
      xsellDomainName: this.item?.defaultConfiguration?.configuration?.domain,
    };
  }

  get dataTrackingId() {
    if (this.item.uid === 'domain_for_domain') {
      const domain: string = this.properties.xsellDomainName;
      const dotIndex = domain.indexOf('.');
      const tld = domain.substring(dotIndex + 1);
      return `x-sell-buy-${this.item.uid}-${tld}`;
    } else {
      return `x-sell-buy-${this.item.uid}`;
    }
  }

  get imageUrl() {
    return this.item.uid === 'business_mail_for_domain' ? caryGordulicImg : caryMageImg;
  }

  get priceFrom() {
    return this.item.defaultConfiguration?.priceFrom;
  }

  buy() {
    this.loading = true;
    const item = this.item;

    const product: StandaloneProduct = {
      code: item.name,
      sellType: item.sellType || 'direct',
      properties: {
        domain: this.relevantItem?.properties?.domain,
        ...item.defaultConfiguration?.configuration,
      },
    };

    let request;

    if (item.sellType === 'up-sell' && item.cartItemToUpgradeId) {
      request = upgradeItem(item.cartItemToUpgradeId, product);
    } else {
      if (item.sellType === 'up-sell') {
        // report
        logError('Up-sell item missing cartItemToUpgradeId', item);
      }
      request = addToCart(product);
    }

    request
      .then(() => this.$emit('buy'))
      .catch(this.$messageService.errorHandler('general'))
      .finally(() => (this.loading = false));
  }
}
export default XSellBanner;
