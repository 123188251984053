import Vue from 'vue';
import { MutationTree } from 'vuex';

import { getItem, setItem } from '@WS_UIkit';
import { merge, get, cloneDeep, isArray } from 'lodash-es';

import {
  BillingProfile,
  CategoryProfileType,
  DomainProfile,
  DomainProfileExtended,
  HostingTutorialStandaloneProduct,
} from '@/types';
import {
  SetStatePayload,
  RemoveStatePayload,
  ChangeProfilePayload,
  AppState,
  SetRequiredFieldsPayload,
  DomainCheckerCategory
} from './const';
import { logError } from '@/services/logger.service';
import { Domain } from '@/components/domain-checker/dc.types';
import { requiredDpFieldsHash } from './utilities';
import { StorageKeys } from '@/services/const';
import {StateMutations} from '@/store/const.enum';

export const mutations: MutationTree<AppState> = {
  [StateMutations.MERGE_STATE](state, payload: Partial<AppState>) {
    merge(state, payload);
  },

  [StateMutations.SET_STATE](state, payload: SetStatePayload) {
    const partState = payload.path ? get(state, payload.path, null) : state;

    if (partState === null) {
      throw new Error('state not found: ' + payload.path);
    }

    if (
      !payload.prop &&
      isArray(partState) &&
      parseInt(payload.prop as string) !== 0 /* index 0 case */
    ) {
      // case: path is targeting array, but index not provided via prop, only value to be added
      partState.push(payload.value);
    } else if (payload.prop) {
      Vue.set(partState, payload.prop, payload.value);
    } else {
      throw new Error('payload.prop is required if payload.path is not array');
    }

    if (payload.persistent) {
      persistState(payload);
    }
  },

  [StateMutations.REMOVE_STATE](state, payload: RemoveStatePayload) {
    const partState = payload.path ? get(state, payload.path) : state;

    if (isArray(partState)) {
      partState.splice(parseInt(payload.prop), 1);
    } else {
      Vue.delete(partState, payload.prop);
    }
  },

  [StateMutations.UPDATE_PROFILE](state, payload: ChangeProfilePayload) {
    const type = payload.type;
    const profiles = cloneDeep(
      type === 'billing' ? state.billingProfiles : state.domainProfiles
    );

    if (!profiles) {
      logError('Cannot update profile, profiles not defined.');
      return;
    }

    const index = profiles.findIndex(
      (profile: BillingProfile | DomainProfile) =>
        profile.id === payload.profile.id
    );

    Vue.set(profiles!, index, payload.profile);
    updateProfiles(state, profiles, type);
  },

  // eslint-disable-next-line sonarjs/cognitive-complexity
  [StateMutations.ADD_PROFILE](state, payload: ChangeProfilePayload) {
    const type = payload.type;
    const billingType = type === 'billing';
    let profiles = cloneDeep(
      billingType ? state.billingProfiles : state.domainProfiles
    );

    if (!profiles) {
      if (billingType) {
        state.billingProfiles = [];
      } else {
        state.domainProfiles = [];
      }
      profiles = billingType ? state.billingProfiles : state.domainProfiles;
    }

    const index = profiles!.findIndex(
      (profile: BillingProfile | DomainProfileExtended) =>
        profile.id === payload.profile.id
    );

    if (index > -1) {
      // profile with same id is already there
      if (payload.upsert) {
        // make an update
        Vue.set(profiles!!, index, payload.profile);
        updateProfiles(state, profiles!, type);
      }
    } else {
      profiles!.push(payload.profile as any);
      updateProfiles(state, profiles!, type);
    }
  },

  [StateMutations.SET_REQUIRED_FIELDS](
    state,
    payload: SetRequiredFieldsPayload
  ) {
    const hash = requiredDpFieldsHash(payload.tlds, payload.profileCountry);
    if (!state.requiredDpFields) {
      Vue.set(state, 'requiredDpFields', Object.create(null));
    }
    Vue.set(state.requiredDpFields!, hash, payload.requiredFields);
  },

  /*
  ****************************
    Domain checker mutations
  ****************************
   */
  [StateMutations.SET_DOMAIN_CATEGORIES](
    state,
    categories: Array<DomainCheckerCategory>
  ) {
    state.domainCheckerCategories = categories;
  },

  [StateMutations.ADD_DOMAIN_RESULT](state, domain: Domain) {
    state.domainChecker.domains.push(domain);
  },

  [StateMutations.RESET_DOMAIN_CHECKER](state) {
    state.domainChecker.requestedDomains = [];
    state.domainChecker.searching = false;
    state.domainChecker.endOfStream = false;
    state.domainChecker.cartItemsLoading = [];
    state.domainChecker.domains = [];
    state.domainChecker.bundles = [];
  },

  [StateMutations.CHANGE_DOMAIN_STATE_IN_CART](
    state,
    payload: { domainName: string; inCart: boolean }
  ) {
    const domainIndex = state.domainChecker.domains.findIndex(
      (domain: Domain) => domain.name === payload.domainName
    );

    if (domainIndex === -1) {
      return;
    }

    state.domainChecker.domains[domainIndex].domainAlreadyInCart =
      payload.inCart;
  },

  [StateMutations.SET_DOMAIN_ITEM_LOADING_STATE](
    state,
    payload: { domainName: string; loading: boolean }
  ) {
    if (!payload.loading) {
      state.domainChecker.cartItemsLoading = state.domainChecker.cartItemsLoading.filter(
        item => item !== payload.domainName
      );
    } else if (
      !state.domainChecker.cartItemsLoading.includes(payload.domainName)
    ) {
      state.domainChecker.cartItemsLoading.push(payload.domainName);
    }
  },
  /*
    ****************************
      Hosting tutorial mutations
    ****************************
     */
  [StateMutations.SET_HOSTING_TUTORIAL_PRODUCTS](
    state,
    products: Array<HostingTutorialStandaloneProduct>
  ) {
    state.hostingTutorial.products = products;
  },

  [StateMutations.SET_HOSTING_TUTORIAL_REMEMBERED_CONFIGURATION](
    state,
    payload: { productName: string; configuration: object }
  ) {
    state.hostingTutorial.rememberedConfigurations[payload.productName] =
      payload.configuration;
  },

  [StateMutations.SET_PREVIOSLY_SEARCHED_DOMAIN](state, searchedDomain) {
    state.domainChecker.previouslySearchedDomain = searchedDomain;
  }
};

function updateProfiles(
  state: AppState,
  profiles: BillingProfile[] | DomainProfileExtended[],
  type: CategoryProfileType
) {
  if (type === 'billing') {
    state.billingProfiles = profiles as BillingProfile[];
  } else {
    state.domainProfiles = profiles as DomainProfileExtended[];
  }
}

function persistState(payload: SetStatePayload) {
  const persistentState =
    getItem(StorageKeys.persistentState) || Object.create(null);
  const { path = '', prop } = payload;
  persistentState[path + '_' + prop] = payload;
  setItem(StorageKeys.persistentState, persistentState);
}
