import { GetterTree } from 'vuex';
import env from '@env';

import { get, uniq } from 'lodash-es';
import { AppState, COMPLETED, UNTOUCHED } from './const';
import { requiredDpFieldsHash, mapCategories } from './utilities';
import {
  UserProfileType,
  BillingProfile,
  DomainProfileExtended,
  CartItem,
  PaymentOptionGroup,
} from '@/types';
import {
  RequiredFieldsFn,
  FindDomainInCartGetter,
  IsDomainLoadingGetter
} from './getters.d';
import { CartItemCode } from './getters.enum';
import {
  ORDER_STEPS,
  ORDER_STEPS2,
  COMMON_PROFILE_KEYS,
} from '@/services/const';
import { STEPS } from '@/services/const.enum';
import config from '@/services/config.service';
import { PAY_OPTIONS } from '@/components/paymentOptions/const';
import { NOT_SET_VALUES } from '@/const';
import { normalizeDomainProfile } from '@/services/profiles.service';
import { Domain } from '@/components/domain-checker/dc.types';
import {WORKFLOW} from '@/const.enum';

type AppGetters = GetterTree<AppState, AppState>;
type CartAnalytics = {
  mandatoryHasAny: boolean;
  mandatoryUncompleteCount: number;
  domainItems: CartItem[];
};

const IS_SWEDISH_WORKFLOW = config.workflow === WORKFLOW.SWEDISH;

export function partialState<T>(state: AppState): any {
  return (path: string): T => {
    return get(state, path);
  };
}

function loggedIn(state: AppState) {
  return !!state.cart?.user;
}

function isInRequiredFields(state: AppState, getters: any) {
  return (
    type: UserProfileType,
    profileCountry: string,
    prop: string
  ): boolean => {
    const reqFields = getters.requiredFields(type, profileCountry);
    if (!reqFields) {
      return false;
    }
    const { require, requireOneOf } = reqFields;
    return !!(
      (require && require.indexOf(prop) > -1) ||
        (requireOneOf &&
            requireOneOf.some((group: string[]) =>
              group.some(item => item === prop)
            ))
    );
  };
}

/*
 TODO: function requiredFields(state: AppState, getters: any): RequiredFieldsFn {
 ref:
 // eslint-disable-next-line max-len
 https://stackoverflow.com/questions/66171508/
*/
function requiredFields(state: any, getters: any): RequiredFieldsFn {
  return (
    type: UserProfileType | undefined | null,
    profileCountry?: string
  ) => {
    if (!state.requiredDpFields) {
      return null;
    }

    const hash = requiredDpFieldsHash(getters.tlds, profileCountry);
    const requiredDpFieldsGroup = state.requiredDpFields[hash];

    if (!requiredDpFieldsGroup) {
      return null;
    }

    return type ? requiredDpFieldsGroup[type] || null : requiredDpFieldsGroup;
  };
}

function getDomainProfile(state: AppState) {
  const domainProfile: DomainProfileExtended | null = IS_SWEDISH_WORKFLOW
    ? get(state.domainProfiles, '[0]', null)
    : normalizeDomainProfile(state.cart.domainProfile || null);
  return domainProfile;
}

function requiredDpFieldsSatisfied(state: AppState, getters: any): boolean {
  if (!state.requiredDpFields) {
    return true;
  }
  const domainProfile = getDomainProfile(state);

  if (!domainProfile) {
    return false;
  }
  const reqFields = getters.requiredFields(
    domainProfile.type,
    domainProfile.countryCode
  );
  if (!reqFields) {
    return true;
  }
  const { require, requireOneOf } = reqFields!;
  return (
    (!require ||
          require.every(
            (reqField: string) =>
              ![undefined, null, ''].includes((domainProfile as any)[reqField])
          )) &&
      (!requireOneOf ||
          requireOneOf.every((group: string[]) =>
            group.some(
              (reqField: string) =>
                ![undefined, null, ''].includes((domainProfile as any)[reqField])
            )
          ))
  );
}

function tlds(state: AppState): string[] {
  return uniq(
    cartAnalytics(state).domainItems.map(
      item => item.properties!.tld! as string
    )
  ).sort();
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function cartAnalytics(state: AppState): CartAnalytics {
  const cartItems = state.cart.items;
  const status: CartAnalytics = {
    mandatoryHasAny: false,
    mandatoryUncompleteCount: 0,
    domainItems: [],
  };
  if (!cartItems || !cartItems.length) {
    return status;
  }

  for (const item of cartItems) {
    if (
      [CartItemCode.DOMAIN_REGISTER, CartItemCode.DOMAIN_TRANSFER].includes(
            item.code as CartItemCode
      )
    ) {
      status.domainItems.push(item);
    }

    const mandatoryEditableProperties = item.mandatoryEditableProperties || [];
    if (mandatoryEditableProperties.length) {
      status.mandatoryHasAny = true;
      if (
        mandatoryEditableProperties.some(
          prop =>
            item.properties && NOT_SET_VALUES.includes(item.properties[prop])
        )
      ) {
        status.mandatoryUncompleteCount++;
      }
    }
  }

  return status;
}

function hasMandatory(state: AppState, getters: any): boolean {
  return (getters.cartAnalytics as CartAnalytics).mandatoryHasAny;
}

function uncompleteMandatory(state: AppState, getters: any): boolean {
  const status = getters.cartAnalytics as CartAnalytics;
  return (
    (status.mandatoryHasAny && status.mandatoryUncompleteCount > 0) ||
      state.mandatorySettingsHasIssue
  );
}

function mandatorySetsStepSkipped(state: AppState, getters: any): boolean {
  return !getters.uncompleteMandatory as boolean;
}

function cardPayOnly(state: AppState) {
  if (!state.paymentOptions) {
    return false;
  }

  const cardPayAvailable = state.paymentOptions.find(
    (opt: PaymentOptionGroup) =>
      opt.id === PAY_OPTIONS.CARD && opt.available === true
  );

  if (!cardPayAvailable) {
    return false;
  }

  const items = state.cart.items || [];
  for (const item of items) {
    if (item.period && item.period === 1) {
      return true;
    }
  }

  return false;
}

function cartDomains(state: AppState): Array<string | undefined> {
  const cartItems = state.cart.items;
  if (!cartItems?.length) {
    return [];
  }

  return uniq(
    cartItems
      .map((item: CartItem) => {
        return item?.properties?.domain;
      })
      .filter(domain => domain /* truthy values only */)
  );
}

// PNO-2574: domain profile set specifically for particular domain
function allDomainProfilesDefined(state: AppState): boolean {
  const domainItems = cartAnalytics(state).domainItems;

  if (domainItems.length === 0) {
    return false;
  }

  return domainItems.every(domain => !!domain?.properties?.domainProfileId);
}

function allUserDomains(state: AppState, getters: any): string[] {
  return uniq([...getters.cartDomains, ...state.userDomains]);
}

function cartTransferDomains(state: AppState): Array<string | undefined> {
  const cartItems = state.cart.items;
  if (!cartItems?.length) {
    return [];
  }

  return cartItems
    .filter((item: CartItem) => {
      return (
        item.code === CartItemCode.DOMAIN_TRANSFER && item?.properties?.domain
      );
    })
    .map((item: CartItem) => {
      return item.properties!.domain;
    });
}

function cartItemById(state: AppState) {
  return (id: number): CartItem | null => {
    if (!state.cart?.items?.length || !id) {
      return null;
    }
    return state.cart.items.find(item => item.id === id) || null;
  };
}

function userStepIndex(state: AppState, getters: any) {
  return (getters.orderSteps as STEPS[]).indexOf(state.userStep);
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function progressByUser(
  state: AppState,
  getters: any
): Record<STEPS, number /* percentage of done progress */> {
  const progress: Partial<Record<STEPS, number>> = Object.create(null);
  const progressByData = getters.progressByData as Record<STEPS, number>;

  const ORDER_STEPS = getters.orderSteps as STEPS[];
  const userStepIndex = ORDER_STEPS.indexOf(state.userStep);

  if (env.dev && env.dev.instantCheckout) {
    ORDER_STEPS.forEach(step => {
      progress[step] = COMPLETED;
    });
  } else {
    ORDER_STEPS.forEach((step, index) => {
      if (index > userStepIndex) {
        progress[step] = UNTOUCHED;
      } else if (step === state.userStep) {
        progress[step] = [UNTOUCHED, COMPLETED].includes(progressByData[step])
          ? 50
          : progressByData[step];
      } else {
        progress[step] = COMPLETED;
      }
    });
  }

  return progress as Record<STEPS, number>;
}

function progressByData(
  state: AppState,
  getters: any
): Record<STEPS, number /* percentage of done progress */> {
  const cart = state.cart;
  const domainProfile = getDomainProfile(state);

  return {
    [STEPS.CART]: cart.items?.length ? COMPLETED : UNTOUCHED,
    [STEPS.BILLING_PROFILE]: cart.billingProfile ? COMPLETED : UNTOUCHED,
    [STEPS.DOMAIN_PROFILE]:
        !cart.domainProfileRequired ||
        (domainProfile && getters.requiredDpFieldsSatisfied)
          ? COMPLETED
          : UNTOUCHED,
    [STEPS.PAYMENT_METHOD]:
        cart.paymentOption?.groupId &&
        (!getters.cardPayOnly || cart.paymentOption?.groupId === PAY_OPTIONS.CARD)
          ? COMPLETED
          : UNTOUCHED,
    [STEPS.SERVICE_SETTINGS]: getters.uncompleteMandatory
      ? UNTOUCHED
      : COMPLETED,
    [STEPS.RECAPITULATION]: COMPLETED,
  };
}

function stepHasIssues(
  state: AppState,
  getters: any
  // eslint-disable-next-line no-unused-vars
): (step: STEPS) => boolean {
  return step =>
    getters.progressByData[step] !== COMPLETED && state.userStep !== step;
}

function allStepsDone(state: AppState, getters: any): boolean {
  const progressByData = getters.progressByData;
  return (getters.orderSteps as STEPS[]).every(
    (step: STEPS) => (progressByData as any)[step] === COMPLETED
  );
}

function editBackdropActive(state: AppState, getters: any): boolean {
  return (
    !!state.inEditMode &&
      state.inEditMode !== state.userStep &&
      getters.orderSteps.indexOf(state.userStep) > 1
  );
}

function billingProfileById(state: AppState) {
  return (id: string | number | null | undefined): BillingProfile | null => {
    if ((!id && id !== 0) || !state.billingProfiles?.length) {
      return null;
    }
    return state.billingProfiles!.find(profile => profile.id === id) || null;
  };
}

function domainProfileById(state: AppState) {
  return (
    id: string | number | null | undefined
  ): DomainProfileExtended | null => {
    if ((!id && id !== 0) || !state.domainProfiles?.length) {
      return null;
    }
    return state.domainProfiles!.find(profile => profile.id === id) || null;
  };
}

function orderSteps() {
  return config.workflow === WORKFLOW.SWEDISH ? ORDER_STEPS2 : ORDER_STEPS;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function equalBillingAndDomainProfiles(state: AppState) {
  const domainProfile = getDomainProfile(state);

  if (!state.cart || (!state.cart.billingProfile && !domainProfile)) {
    // if both do not exist they are considered equal
    return true;
  }

  const bp: any = state.cart.billingProfile || {};
  const dp: any = domainProfile || {};
  const keys = COMMON_PROFILE_KEYS;
  const length = keys.length;
  let result = true;

  for (let i = 0; i < length; i++) {
    const key = keys[i];
    const bpValue = key === 'email' ? bp.emails[0] : bp[key];
    const dpValue = dp[key];
    if ((bpValue || dpValue) && bpValue !== dpValue) {
      result = false;
      break;
    }
  }

  return result;
}
/*
  Domain Checker getters
 */
function dcOnlyFreeDomains(state: AppState, getters: any): Domain[] {
  // all free
  // except errored ones
  // except checked ones
  return getters.dcAllDomains.filter(
    (domain: Domain) => domain.availableForPurchase === true
  );
}

function dcAllDomains(state: AppState): Domain[] {
  // all free+occupied including alternatives (different tld)
  return state.domainChecker.domains;
}

function dcCheckedDomains(state: AppState): Domain[] {
  // all searched domains (with or without errors)
  // returns only exact matches including tld !!!
  // CONSEQUENCE: if requested is without tld then its alternatives
  // results won't pass filter
  return state.domainChecker.domains.filter((domain: Domain) =>
    state.domainChecker.requestedDomains.includes(domain.name)
  );
}

function dcCheckedDomainsErrors(state: AppState, getters: any) {
  return (getters.dcCheckedDomains as Domain[])
    .map((domain: Domain) => {
      if (domain.error) {
        return {
          domainName: domain.name,
          error: domain.error,
        };
      }

      return null;
    })
    .filter((item: any) => item !== null);
}

const dcFindDomainInCart = (state: AppState): FindDomainInCartGetter => (
  domain: Domain
) => {
  const cartItems = state.cart?.items;

  if (!cartItems?.length) {
    return null;
  }

  const domainCartItem = cartItems.find(
    (cartItem: CartItem) =>
      [CartItemCode.DOMAIN_REGISTER, CartItemCode.DOMAIN_TRANSFER].includes(
              cartItem.code as CartItemCode
      ) && cartItem.properties?.domain === domain.name
  );

  return domainCartItem || null;
};

const isDomainLoading = (state: AppState): IsDomainLoadingGetter => domain => {
  return domain
    ? state.domainChecker.cartItemsLoading.includes(domain.name)
    : false;
};

const cartCoupons = (state: AppState): string | undefined => {
  const coupons = state.cart?.summary?.coupons;
  return coupons?.length ? coupons.map(c => c.code).join(', ') : undefined;
};

const vatPercentage = (state: AppState): number =>
  state.cart?.summary?.price?.vatPercentage || 0;

const vatValue = (
  state: AppState,
  getters: any
  // eslint-disable-next-line no-unused-vars
): ((value: number) => number) => (value: number): number => {
  const vat = getters.vatPercentage / 100;
  return value * vat;
};

const cartPrice = (state: AppState): number =>
  state.cart?.summary?.price?.vatExcluded || 0;

const cartItems = (state: AppState): CartItem[] => state.cart?.items || [];

const itemsGaee = (state: AppState, getters: any) =>
  getters.cartItems.map((item: CartItem) => item.gaee);

/*
 * Re-map the gaee obj's properties to match desired structure
 * https://loopiagroup.atlassian.net/browse/ITDEV-8074
 * Example below
{
  "event":"purchase",
  "ecommerce":{
    "currency":"EUR",
    "value":29.98,
    "affiliation":"Tradedoubler",
    "transaction_id":"abc123",
    "coupon":"ENDOFSUMMER",
    "items":[
      {
        "item_name":"Mini",
        "item_id":"product1",
        "price":11.99,
        "item_brand":"Websupport",
        "item_category":"Hosting",
        "item_category2":"The Hosting",
        "quantity":"1",
        "item_coupon":"SUMMER20"
      },
      {
        "item_name":"Product 2",
        "item_id":"product2",
        "price":12.99,
        "item_brand":"Domainhotelli",
        "item_category":"Server",
        "item_category2":"VPS",
        "quantity":"1"
      }
    ]
  }
}
 */

const itemsGA = (state: AppState, getters: any) => {
  return getters.cartItems.map(
    (item: CartItem) => {
      const categories = mapCategories(item.gaee?.categories);
      return item.gaee ? {
        item_name: item.gaee.name,
        item_id: item.gaee.id ?? item.gaee.sku,
        price: item.gaee.price,
        item_brand: item.gaee.brand,
        ...categories,
        quantity: item.gaee.quantity,
        ...(typeof item.gaee.affiliation === 'string' ? { affiliation: item.gaee.affiliation } : {}),
        ...(typeof item.gaee.coupon === 'string' ? { item_coupon: item.gaee.coupon } : {}),
      } : {};
    }
  );
};

const productIds = (state: AppState, getters: any) =>
  getters.itemsGaee.map((gaee: CartItem['gaee']) => gaee?.id);

const productNames = (state: AppState, getters: any) =>
  getters.itemsGaee.map((gaee: CartItem['gaee']) => gaee?.name);

const firstStepWithIssues = (state: AppState, getters: any): STEPS | null => {
  const step = getters.orderSteps.find(
    (step: STEPS) => getters.progressByData[step] !== COMPLETED
  );
  return step === -1 ? null : step;
};


export const getters: AppGetters = {
  partialState,
  loggedIn,
  tlds,
  requiredFields,
  isInRequiredFields,
  requiredDpFieldsSatisfied,
  cartAnalytics,
  hasMandatory,
  uncompleteMandatory,
  mandatorySetsStepSkipped,
  cardPayOnly,
  cartDomains,
  allDomainProfilesDefined,
  allUserDomains,
  cartTransferDomains,
  cartItemById,
  userStepIndex,
  progressByUser,
  progressByData,
  stepHasIssues,
  allStepsDone,
  editBackdropActive,
  billingProfileById,
  domainProfileById,
  orderSteps,
  equalBillingAndDomainProfiles,
  // Domain checker getters
  dcOnlyFreeDomains,
  dcAllDomains,
  dcCheckedDomains,
  dcCheckedDomainsErrors,
  dcFindDomainInCart,
  isDomainLoading,
  // analytics
  cartCoupons,
  vatPercentage,
  vatValue,
  cartPrice,
  cartItems,
  itemsGaee,
  itemsGA,
  productIds,
  productNames,
  firstStepWithIssues
};
