var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"payment-options",class:{ loading: _vm.loading }},[(_vm.loading)?_c('div',[_c('q-skeleton',{staticClass:"q-mt-md",attrs:{"type":"QRange","animation":"wave","height":"32px","width":"190px"}}),_c('q-skeleton',{staticClass:"q-mt-md",attrs:{"type":"QRange","animation":"wave","width":"470px","height":"16px"}}),_c('q-skeleton',{staticClass:"q-mt-md",attrs:{"type":"QRange","animation":"wave","width":"366px","height":"16px"}}),_c('q-skeleton',{staticClass:"q-mt-lg",attrs:{"type":"QRange","animation":"fade","height":"80px"}})],1):_c('ws-radio-list',{class:{
      'edit-mode': _vm.editMode,
      compact: _vm.compact,
      'hide-radio': _vm.hideRadio,
      'child-only': _vm.childOptionOnly,
      'has-children': _vm.groupHasChildrenOptions,
    },attrs:{"items":_vm.optionsProcessed,"mode":"item","invisible-wrap":!_vm.editMode && _vm.childOptionOnly,"without-delimiter":"","selected-bolded":!!_vm.cmps[_vm.get(_vm.selectedOption, 'id', null)]},on:{"ws-radio-selected":function($event){return _vm.$emit('selection-changed')}},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(_vm.editMode || !_vm.childOptionOnly)?_c('div',{staticClass:"payment-option",class:{
          disabled: scope.item.disable,
        }},[_c('p',{staticClass:"q-mb-none flex justify-between"},[_c('span',{staticClass:"span-line",class:{ 'text-bold': scope.isSelected }},[(
                scope.item.id === _vm.PAY_OPTIONS.LATER
                  &&
                  _vm.get(_vm.cart.billingProfile, 'invoiceDelivery') === _vm.INVOICE_DELIVERY_TYPE.EINVOICE)?[_vm._v(" "+_vm._s(_vm.$t('payment_options.' + scope.item.id + '.einvoice'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('payment_options.' + scope.item.id + '.label'))+" ")]],2),(scope.item.id === _vm.PAY_OPTIONS.CARD)?_c('small',{staticClass:"label-recommended text-ws-base110 q-ml-sm"},[_vm._v("("+_vm._s(_vm.$t('wsk.general.recommended'))+")")]):_vm._e(),(
              scope.item.available && scope.item.id === _vm.PAY_OPTIONS.CREDIT
            )?_c('small',{staticClass:"credit-balance",class:{ positive: parseFloat(scope.item.paymentOptions[0].credit) > 0 }},[_vm._v(_vm._s(_vm.$t('payment_options.credit.balance'))+" "+_vm._s(_vm.$n(scope.item.paymentOptions[0].credit, 'currency')))]):_vm._e()])]):_vm._e()]}},{key:"content",fn:function(scope){return [(_vm.cmps[scope.item.id] && (_vm.editMode || !_vm.specialIdIsSelected))?_c('div',{staticClass:"content",class:{
          'q-ml-xl q-pl-xs q-my-md': !_vm.compact && _vm.editMode,
        }},[_c(_vm.cmps[scope.item.id],{tag:"component",attrs:{"option-group":_vm.selectedGroup,"value":_vm.selectedOption,"edit-mode":_vm.editMode,"compact":_vm.compact},on:{"selected":_vm.onSubSelected}})],1):_vm._e()]}},(!_vm.childOptionOnly)?{key:"info",fn:function(scope){return [(_vm.editMode && scope.item.id === _vm.PAY_OPTIONS.CARD)?_c('small',{staticClass:"q-ml-2xl q-mb-md"},[_vm._v(" "+_vm._s(_vm.$t('payment_options.card.info'))+" ")]):_vm._e(),(_vm.editMode && scope.item.id === _vm.PAY_OPTIONS.LATER && _vm.atLeastOneDomainInCart)?_c('small',{staticClass:"q-ml-2xl q-mb-md"},[_vm._v(" "+_vm._s(_vm.$t('payment_options.later.domain_register_upon_payment'))+" ")]):_vm._e()]}}:null,{key:"disabled",fn:function(scope){return [_c('div',{staticClass:"disabled-option"},[_c('ws-icon',{staticClass:"q-ma-md",attrs:{"name":"info","size":"22"}}),_c('div',{staticClass:"q-py-md"},[_c('strong',[_vm._v(_vm._s(_vm.$t('payment_options.' + scope.item.id + '.label')))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t('payment_options.disabled_info')))])])],1)]}}],null,true),model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),(_vm.cardPayOnly && _vm.editMode)?_c('div',{staticClass:"q-mt-xl"},[_c('h5',{staticClass:"text-ws-base140"},[_vm._v(" "+_vm._s(_vm.$t('payment_options.card_pay_only.title'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t('payment_options.card_pay_only.text')))]),_c('p',{staticClass:"tooltip-link",domProps:{"innerHTML":_vm._s(_vm.xss(_vm.$t('payment_options.card_pay_only.tooltip')))}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }