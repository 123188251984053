
import {Watch, Component, Prop, Vue} from 'vue-property-decorator';
import InlineSvg from 'vue-inline-svg';
import './helpers/WsWrapper.js';
import {loadIcon} from '../assets/iconLoader';
import {config} from '@loopia-group/services';

@Component({
  components: {
    InlineSvg,
  },
})
export class WsIcon extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(Boolean) readonly wrap!: boolean;
  @Prop(String) readonly color!: string;
  @Prop(String) readonly bgColor!: string;
  @Prop(Boolean) readonly circle!: boolean;

  @Prop() readonly size!: number;
  @Prop({default: 16}) height!: string;
  @Prop({default: 16}) width!: string;

  loading = true;
  iconUrl = '';
  config = config;

  get classes() {
    const classes = ['ws-icon'];

    if (this.color) {
      classes.push('text-' + this.color);
    }

    if (this.unloaded) {
      classes.push('unloaded');
    }

    return classes;
  }

  get bgColorClass() {
    return 'text-' + this.bgColor;
  }

  get sizes() {
    let height;
    let width;

    if (this.size) {
      height = this.size.toString();
      width = this.size.toString();
    } else {
      height = this.height;
      width = this.width;
    }

    return `
      height: ${height}px;
      min-height: ${height}px;
      width: ${width}px;
      min-width: ${width}px;
    `;
  }

  get unloaded() {
    return this.loading || !this.iconUrl;
  }

  @Watch('name', {immediate: true})
  @Watch('config.theme', {immediate: true})
  async getIcon() {
    this.iconUrl = await loadIcon(this.name);
    this.loading = false;
  }
}

export default WsIcon;
