
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { QCardSection } from 'quasar';

import { redirect } from '@loopia-group/utils';
import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import { getLoginUrl } from '@/utilities';
import { STEPS } from '@/services/const.enum';
import { navigateToStep, performQuickOrder, setUserStep } from '@/services/order.service';
import { ROUTENAMES } from '@/const.enum';
import store from '@/store';
import { SidePanels, StateMutations } from '@/store/const.enum';

@Component({
  components: {
    QCardSection,
    WsButton,
    WsMessage,
    WsDelimiter,
  },
  computed: {
    ...mapGetters(['firstStepWithIssues', 'loggedIn']),
  },
})
export default class QuickOrderButton extends Vue {
  readonly firstStepWithIssues!: STEPS | null;
  readonly loggedIn!: boolean;

  loading: boolean = false;
  path: string = 'cart.summary.quick_order';

  login() {
    redirect(getLoginUrl(this.$router, ROUTENAMES.CART));
  }

  goNext() {
    if (!this.loggedIn) {
      this.openSideBar();

      return;
    }

    this.startQuickOrder();
  }

  openSideBar() {
    store.commit(StateMutations.MERGE_STATE, {
      ui: { sidePanel: SidePanels.Login },
    });
  }

  async startQuickOrder(): Promise<any> {
    this.$messageService.clearMessages(this.path);
    this.loading = true;

    try {
      await navigateToStep(STEPS.BILLING_PROFILE, false);
    } catch (error) {
      this.loading = false;
      // do not handle navigation error as it is handled in navigation guard
      return Promise.reject();
    }

    return performQuickOrder()
      .then(() => setUserStep(STEPS.RECAPITULATION))
      .catch(this.handleError)
      .finally(() => (this.loading = false));
  }

  async goToRecapitulation() {
    await setUserStep(STEPS.RECAPITULATION);
    await navigateToStep(STEPS.BILLING_PROFILE, false);
  }

  // Example error can be found in checkout.mock.ts, see 'const quickOrderMultiError'...
  async handleError(error: AxiosError) {
    const { status }: { status: number } = error?.response?.data;

    // Unauthorized
    if (status === 401) {
      this.login();
      return;
    }

    await this.goToRecapitulation();
    this.$messageService.errorHandler(this.path, {
      preventScroll: true,
    })(error); // also triggers multi-error handling internally
  }
}
