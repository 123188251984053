import {computed, ComputedRef, Ref, ref, watch} from 'vue';
import {defineStore} from 'pinia';
import {CartItem, PaymentOptionGroup} from '@/types';
import {Domain} from '@/components/domain-checker/dc.types';
import {CartItemCode} from './getters.enum';
import {ROUTENAMES} from '@/const.enum';
import router, {safePush} from '@/router';
import {remove, uniq} from 'lodash-es';
import {NOT_SET_VALUES} from '@/const';
import store from './store';
import {PAY_OPTIONS} from '@/components/paymentOptions/const';

type CartAnalytics = {
  mandatoryHasAny: boolean;
  mandatoryUncompleteCount: number;
  domainItems: CartItem[];
};

export function mapCategories(arr: string | string[]) {
  if (Array.isArray(arr)) {
    return arr.reduce(function (returnObj: {[key: string]: string}, value: string, i: number) {
      const label = 'item_category' + ((i === 0) ? '' : i + 1);
      return { ...returnObj, [label]: value };
    }, {});
  } else if (arr !== '') {
    return {['item_category']: arr};
  }

  return {};
}

// eslint-disable-next-line max-lines-per-function
export const useCartItemsStore = defineStore('cartItems', () => {
  // state
  const items: Ref<CartItem[]> = ref([]);
  // actions
  function updateItems (payload: Array<CartItem>) {
    items.value = payload.slice();
  }

  function removeItem (item: CartItem) {
    remove(items.value!, item);

    if (items.value!.length === 0) {
      safePush({ name: ROUTENAMES.EMPTY_CART });
    }
  }
  // getters
  const cartItems = computed(() => items.value || []);

  const itemsGaee = computed(() => items.value.map((item: CartItem) => item.gaee));

  const itemsGA = computed(() => items.value.map(
    (item: CartItem) => {
      const categories = mapCategories(item.gaee?.categories);
      return item.gaee ? {
        item_name: item.gaee.name,
        item_id: item.gaee.id ?? item.gaee.sku,
        price: item.gaee.price,
        item_brand: item.gaee.brand,
        ...categories,
        quantity: item.gaee.quantity,
        ...(typeof item.gaee.affiliation === 'string' ? { affiliation: item.gaee.affiliation } : {}),
        ...(typeof item.gaee.coupon === 'string' ? { item_coupon: item.gaee.coupon } : {}),
      } : {};
    }
  ));

  const dcFindDomainInCart = computed(() => (domain: Domain) => {
    if (!items?.value.length) {
      return null;
    }

    const domainCartItem = cartItems.value.find(
      (cartItem: CartItem) =>
        [CartItemCode.DOMAIN_REGISTER, CartItemCode.DOMAIN_TRANSFER].includes(
                cartItem.code as CartItemCode
        ) && cartItem.properties?.domain === domain.name
    );

    return domainCartItem || null;
  });

  const cartTransferDomains = computed(() => {
    const cartItems = items.value;
    if (!cartItems?.length) {
      return [];
    }

    return cartItems
      .filter((item: CartItem) => {
        return (
          item.code === CartItemCode.DOMAIN_TRANSFER && item?.properties?.domain
        );
      })
      .map((item: CartItem) => {
        return item.properties!.domain;
      });
  });

  const cartItemById = computed(() => {
    return (id: number): CartItem | null => {
      if (!items?.value.length || !id) {
        return null;
      }
      return items.value.find((item: CartItem) => item.id === id) || null;
    };
  });

  const cartDomains: ComputedRef<(string | undefined)[]> = computed(() => {
    const cartItems = items.value;
    if (!cartItems?.length) {
      return [];
    }

    return uniq(cartItems
      .map((item: CartItem) => { return item?.properties?.domain; })
      .filter(domain => domain /* truthy values only */));
  });

  const allUserDomains: ComputedRef<(string | undefined)[]> = computed (() =>  {
    return uniq([...cartDomains.value.filter(domain => Boolean(domain)), ...store.state.userDomains]);
  });

  const cardPayOnly: ComputedRef<boolean> = computed(() => {
    if (!store.state.paymentOptions) {
      return false;
    }

    const cardPayAvailable = store.state.paymentOptions.find(
      (opt: PaymentOptionGroup) => opt.id === PAY_OPTIONS.CARD && opt.available
    );

    if (!cardPayAvailable) {
      return false;
    }

    for (const item of items.value) {
      if (item.period && item.period === 1) {
        return true;
      }
    }

    return false;
  });

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const cartAnalytics: ComputedRef<CartAnalytics> = computed(() => {
    const cartItems = items.value;
    const status: CartAnalytics = {
      mandatoryHasAny: false,
      mandatoryUncompleteCount: 0,
      domainItems: [],
    };
    if (!cartItems || !cartItems.length) {
      return status;
    }

    for (const item of cartItems) {
      if (
        [CartItemCode.DOMAIN_REGISTER, CartItemCode.DOMAIN_TRANSFER].includes(
              item.code as CartItemCode
        )
      ) {
        status.domainItems.push(item);
      }

      const mandatoryEditableProperties = item.mandatoryEditableProperties || [];
      if (mandatoryEditableProperties.length) {
        status.mandatoryHasAny = true;
        if (
          mandatoryEditableProperties.some(
            (prop: any) =>
              item.properties && NOT_SET_VALUES.includes(item.properties[prop])
          )
        ) {
          status.mandatoryUncompleteCount++;
        }
      }
    }

    return status;
  });

  const hasMandatory: ComputedRef<boolean> = computed(() => {
    return cartAnalytics.value.mandatoryHasAny;
  });

  const uncompleteMandatory: ComputedRef<boolean> = computed(() => {
    const status = cartAnalytics.value as CartAnalytics;
    return (
      (status.mandatoryHasAny && status.mandatoryUncompleteCount > 0)
        || store.state.mandatorySettingsHasIssue
    );
  });

  const tlds: ComputedRef<string[]> = computed(() => {
    return uniq(cartAnalytics.value.domainItems
      .map(item => item.properties!.tld! as string)).sort();
  });

  watch(
    () => items.value.length,
    (itemsCount: number) => {
      if (itemsCount &&  router.currentRoute.name as ROUTENAMES === ROUTENAMES.EMPTY_CART) {
        safePush({ name: ROUTENAMES.CART });
      }
    },
    { immediate: true }
  );

  return {
    items,
    tlds,
    updateItems,
    removeItem,
    cartItemById,
    cartItems,
    itemsGaee,
    itemsGA,
    hasMandatory,
    cartDomains,
    cardPayOnly,
    allUserDomains,
    dcFindDomainInCart,
    cartTransferDomains,
    uncompleteMandatory,
  };
});
