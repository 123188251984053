
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { QForm } from 'quasar';

import { isEqual, get, cloneDeep } from 'lodash-es';
import { validateForm, required, getItem, removeItem } from '@WS_UIkit';
import config, { isSwedishWorkflow } from '@/services/config.service';
import {
  Cart,
  StoreDomainProfileRequest,
  DomainProfileExtended,
  CartItem,
  ProfileListConfig,
  CommonProfile,
  BillingProfile,
} from '@/types';

import { AppState } from '@/store/const';
import { StoreActions, StateMutations } from '@/store/const.enum';
import { ensureCartLoaded } from '@/store';
import { StorageKeys, INVALID_FORM_ERROR } from '@/services/const';
import {
  setDomainProfile,
  createDomainProfile,
} from '@/services/cart/cart-api.service';
import {
  cleanProfileByType,
  normalizeDomainProfile,
} from '@/services/profiles.service';
import { domainFromBillingProfile } from '@/services/order.service';
import { getVatTaxTKeyInterceptor } from '@/services/message.service';
import ProfileList from './ProfileList.vue';
import DomainProfileAdditionalFields from './DomainProfileAdditionalFields.vue';

type DomainPortalPartial =
  | DomainProfileExtended
  | Partial<DomainProfileExtended>
  | null;

const PATH = 'cart.domainProfile';
const PROFILE_NOT_SET_ERROR = 'validation.domain_profile.not_set';
const emptyNewProfile: Partial<DomainProfileExtended> = {
  type: 'personal',
};

@Component({
  components: {
    ProfileList,
    DomainProfileAdditionalFields,
    QForm,
  },
  computed: {
    ...mapState(['cart', 'domainProfiles', 'tmpDomainProfileData']),
    ...mapGetters(['domainProfileById', 'isInRequiredFields']),
  },
})
export default class DomainProfileList extends Vue {
  @Prop(Boolean)
  readonly editMode!: boolean;
  @Prop(Boolean)
  readonly editInPlace!: boolean;
  @Prop(Boolean)
  readonly small!: boolean;
  @Prop({ default: PATH })
  readonly path!: string;
  @Prop({ default: null })
  readonly item!: CartItem | null;

  readonly cart!: Cart;
  tmpDomainProfileData!: AppState['tmpDomainProfileData'];
  domainProfiles!: DomainProfileExtended[];
  domainProfileById!: (id: string | number) => DomainProfileExtended | null;
  isInRequiredFields!: (
    type: string,
    profileCountry: string,
    prop: string
  ) => boolean;
  domainProfileData: Partial<DomainProfileExtended> | null = null;
  updating = false;
  isSwedishWorkflow = isSwedishWorkflow;
  handleUnfinishedInProgress = false;
  required = required;
  showAdditionalInput = false;

  get loading() {
    return !this.domainProfiles;
  }

  get cfg(): ProfileListConfig {
    const domainProfile = this.domainProfile || {};
    const profileType = domainProfile.type || 'personal';
    const countryCode = domainProfile.countryCode || config.companyCountry;
    const isSwedishWorkflow = this.isSwedishWorkflow;
    const savedProfile: any = this.savedProfile || {};

    return {
      type: 'domain',
      newProfileLabel: 'domain_profile.new_profile_option',
      editTitle: 'domain_profile.edit.title',
      addressTitle: 'wsk.address.title',
      email: true,
      phone: true,
      street2: isSwedishWorkflow,
      // missing logic from https://loopiagroup.atlassian.net/wiki/spaces/PROJ/pages/7208325/6+Domain+profiles
      optional: {
        phone: !this.isInRequiredFields(profileType, countryCode, 'phone'),
        companyId: !this.isInRequiredFields(
          profileType,
          countryCode,
          'companyId'
        ),
        taxId: !this.isInRequiredFields(profileType, countryCode, 'taxId'),
        vatId: !this.isInRequiredFields(profileType, countryCode, 'vatId'),
      },
      disable: isSwedishWorkflow
        ? {
          type: true,
          name: !!savedProfile.name,
          organisation: !!savedProfile.organisation,
          companyId: !!savedProfile.companyId,
          idCard: !!savedProfile.idCard,
        }
        : {},
      path: this.path,
      companyCountry: config.companyCountry,
      emptyNewProfile: { ...emptyNewProfile },
      editMode: this.editMode,
      profiles: this.domainProfiles as CommonProfile[],
      save: this.innerSaveProfile,
      editInDrawer: !this.editInPlace && config.domainProfileCountLimit !== 1,
      appendForm: this.editInPlace,
      maxProfiles: config.domainProfileCountLimit,
    };
  }

  get editDisabled(): boolean {
    return !this.isSwedishWorkflow || false;
  }

  get sourceProfile(): DomainProfileExtended | null {
    const id = this.domainProfile?.id;
    let profile: any;
    if (id) {
      profile = this.domainProfileById(id);
    } else if (this.editMode && this.domainProfile) {
      // prefilled values case
      profile = this.domainProfile;
    }
    return (profile && cloneDeep(profile)) || this.savedProfile;
  }

  get savedProfile(): DomainProfileExtended | null {
    if (this.item && this.itemProfile) {
      return this.itemProfile;
    }
    // also fallback for item if it does not have set profile directly
    const profile: any = isSwedishWorkflow
      ? this.domainProfiles?.[0] || null
      : normalizeDomainProfile(this.cart?.domainProfile || null);
    return profile || null;
  }

  get itemProfile(): DomainProfileExtended | null {
    const domainProfileId = this.item?.properties?.domainProfileId;
    return domainProfileId
      ? cloneDeep(this.domainProfileById(domainProfileId))
      : null;
  }

  // is case when there was a problem to create domain profile from billing
  get errorCreationDomainProfile(): boolean {
    return !!(
      this.tmpDomainProfileData || getItem(StorageKeys.tmpDomainProfileData)
    );
  }

  get forceSetProfile(): boolean {
    // profile needs to be set, if it is required and not present on cart
    return !!(this.cart?.domainProfileRequired && !this.cart?.domainProfile);
  }

  public get domainProfile(): DomainPortalPartial {
    return this.domainProfileData || this.savedProfile || null;
  }

  public set domainProfile(domainProfile: DomainPortalPartial) {
    this.domainProfileData = domainProfile || null;
  }

  handleProfileSelection = (profile: DomainProfileExtended) => {
    if (profile) {
      this.domainProfileData = cloneDeep(profile);
    }
  };

  selectionChanged(newProfileSelected: boolean) {
    this.showAdditionalInput = newProfileSelected;
    // clear messages on selecting profile, but not on automated selection
    this.$messageService.clearMessages(this.path);
    // re-emit
    this.$emit('selection-changed');
  }

  @Watch('domainProfile', { deep: true })
  onDpChanged(profile: DomainPortalPartial) {
    // inform outside world about new profile being selected
    this.$emit('input', profile);
  }

  @Watch('item')
  onItemChanges() {
    // clear component between items PNO-1730
    this.domainProfileData = null;
  }

  @Watch('editMode')
  onEditChanged(editMode: boolean) {
    if (editMode) {
      if (this.item) {
        // start editing profile for item
        this.domainProfile = this.itemProfile;
      } else {
        // start editing cart domain profile
        this.startEditCartDp();
      }
    } else {
      this.domainProfile = null;
    }
  }

  // continue from onEditChanged to reduce function complexity
  startEditCartDp() {
    if (this.errorCreationDomainProfile) {
      this.handleUnfinishedDomainProfile();
    } else {
      const profile = this.isSwedishWorkflow
        ? this.domainProfiles?.[0] || null
        : normalizeDomainProfile(this.cart.domainProfile || null);

      this.domainProfile = profile || null;
    }
  }

  created() {
    this.loadProfiles().then(() => {
      if (this.errorCreationDomainProfile) {
        this.handleUnfinishedDomainProfile();
      }
    });
    // optionally item already present at this time
    this.onEditChanged(this.editMode);
    // initial emit
    this.$emit('input', this.domainProfile);
  }

  loadProfiles() {
    return this.$store
      .dispatch(StoreActions.FETCH_DOMAIN_PROFILES)
      .catch(this.$messageService.errorHandler(this.path + '-list'));
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  handleUnfinishedDomainProfile() {
    if (this.handleUnfinishedInProgress) {
      return; // only once
    }
    this.handleUnfinishedInProgress = true;

    ensureCartLoaded(() => {
      // wait for cart to be ready, so it will not flush domainProfile data
      // when loads with empty domainProfile

      const context: any =
        this.tmpDomainProfileData ||
        getItem(StorageKeys.tmpDomainProfileData) ||
        {};

      let { domainProfile } = context;
      const { error } = context;

      if (domainProfile) {
        // https://loopiagroup.atlassian.net/browse/PNO-1345
        if (domainProfile.billingProfileId) {
          const billingProfile = this.$store.state.billingProfiles.find(
            (profile: BillingProfile) =>
              profile.id === domainProfile.billingProfileId
          );
          domainProfile = domainFromBillingProfile(
            billingProfile,
            domainProfile
          );
        }

        this.domainProfile = domainProfile;
      }

      if (error) {
        this.$messageService.errorHandler(this.cfg.path, {
          keyInterceptor: getVatTaxTKeyInterceptor(domainProfile),
        })(error);
      }
      if (this.tmpDomainProfileData) {
        this.$store.commit(StateMutations.REMOVE_STATE, {
          prop: 'tmpDomainProfileData',
        });
      } else {
        removeItem(StorageKeys.tmpDomainProfileData);
      }
      this.handleUnfinishedInProgress = false;
    });
  }

  public saveProfile() {
    // sometimes I get "cannot read saveProfile of undefined", cannot reproduce
    // but making error not to stuck whole app
    const profileList = this.$refs.profileList as ProfileList;
    return profileList ? profileList.saveProfile() : this.innerSaveProfile();
  }

  // TODO make less complex function
  // eslint-disable-next-line sonarjs/cognitive-complexity
  innerSaveProfile() {
    this.$messageService.clearMessages([this.path, 'general'], true);

    const domainProfile = this.domainProfile;
    if (!domainProfile) {
      return Promise.reject(PROFILE_NOT_SET_ERROR);
    }

    const savedProfile = cloneDeep(this.savedProfile) || null;
    const dpForComparisons = cloneDeep(domainProfile);

    return this.validate().then((results: [boolean, boolean]) => {
      if (!results[0] || !results[1]) {
        return Promise.reject(INVALID_FORM_ERROR); // invalid new profile form
      }

      if (domainProfile.id) {
        if (
          !this.forceSetProfile &&
          savedProfile &&
          isEqual(dpForComparisons, savedProfile)
        ) {
          return Promise.resolve(domainProfile);
        }

        const savedVersion = this.domainProfileById(domainProfile.id);
        if (
          savedVersion && // safe check PNO-1750
          !isEqual(dpForComparisons, savedVersion)
        ) {
          return this.updateDomainProfile(domainProfile).then(
            () => this.setProfile(domainProfile as DomainProfileExtended) as any
          );
        } // else
        return this.setProfile(domainProfile as DomainProfileExtended);
      } else {
        return this.createDomainProfile();
      }
    });
  }

  setProfile(domainProfile = this.domainProfile as DomainProfileExtended) {
    if (this.item) {
      // TODO domainProfile here is not correct, need to fix it
      this.item.properties = this.item.properties || {};
      this.item.properties.domainProfileId = domainProfile.id;
      return Promise.resolve(domainProfile);
    } else {
      if (this.isSwedishWorkflow && !get(this.cart, 'domainProfileRequired')) {
        return Promise.resolve(domainProfile);
      }

      this.updating = true;

      const requestData = {
        domainProfileId: domainProfile.id,
      };
      return setDomainProfile(requestData).finally(
        () => (this.updating = false)
      );
    }
  }

  public validate() {
    return Promise.all([
      validateForm(this.$refs.form as Element & QForm),
      this.$refs.additionalFields
        ? validateForm(
            (this.$refs.additionalFields as Vue).$refs.form as Element & QForm
        )
        : Promise.resolve(true),
    ]);
  }

  createDomainProfile() {
    const requestData = cloneDeep(
      this.domainProfile as unknown
    ) as StoreDomainProfileRequest;
    cleanProfileByType(requestData);

    if (this.item) {
      requestData.cartItemId = this.item.id;
    }

    return createDomainProfile(requestData).then((response: any) => {
      const createdProfile = normalizeDomainProfile(response.data)!;

      this.$store.commit(StateMutations.ADD_PROFILE, {
        type: 'domain',
        profile: cloneDeep(createdProfile),
      });
      this.domainProfile = createdProfile;

      if (this.item) {
        this.item.properties = this.item.properties || {};
        this.item.properties.domainProfileId = createdProfile.id;
      } else {
        this.$store.commit(StateMutations.SET_STATE, {
          path: 'cart',
          prop: 'domainProfile',
          value: cloneDeep(createdProfile),
        });
      }
      return Promise.resolve(response.data);
    });
  }

  updateDomainProfile(profile: Partial<DomainProfileExtended>) {
    return this.$store.dispatch(StoreActions.UPDATE_PROFILE, {
      type: 'domain',
      profile,
    });
  }
}
