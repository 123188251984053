
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export class WsBackdrop extends Vue {
  @Prop(Boolean) readonly active!: boolean;

  backdropClicked($event: MouseEvent): void {
    this.$emit('backdropClicked', $event);
  }
}

export default WsBackdrop;
