var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-domain-profile-list row"},[_c('div',{staticClass:"col"},[_c('q-form',{ref:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('profile-list',{ref:"profileList",attrs:{"cfg":_vm.cfg,"disabled":_vm.updating || _vm.loading,"small":_vm.small,"loading":_vm.loading,"edit-disabled":_vm.editDisabled},on:{"selection-changed":_vm.selectionChanged,"input":_vm.handleProfileSelection},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showAdditionalInput)?_c('domain-profile-additional-fields',{ref:"additionalFields",staticClass:"col col-12",attrs:{"type":_vm.get(_vm.domainProfile, 'type'),"path":_vm.path,"disable":{
              idCard: !!(_vm.isSwedishWorkflow && _vm.get(_vm.savedProfile, 'idCard')),
            },"resolve-fields":{
              formHasPhone: true,
              editFormVisible: true,
              sourceProfile: _vm.sourceProfile,
            }},model:{value:(_vm.domainProfile),callback:function ($$v) {_vm.domainProfile=$$v},expression:"domainProfile"}}):_vm._e()]},proxy:true},{key:"beforeAddress",fn:function(){return [_c('h6',{staticClass:"group-heading q-mt-md q-mb-none"},[_vm._v(" "+_vm._s(_vm.$t('user.profile.title_permanent_address'))+" ")])]},proxy:true}]),model:{value:(_vm.domainProfile),callback:function ($$v) {_vm.domainProfile=$$v},expression:"domainProfile"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }