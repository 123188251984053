
import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import { mayGoNext } from '@/services/order.service';
import { STEPS } from '@/services/const.enum';
import MandatorySettings from '@/components/MandatorySettings.vue';
import OrderProcessActions from '@/components/OrderProcessActions.vue';
import OrderProcessError from '@/components/OrderProcessError.vue';
import {useCartItemsStore} from '@/store/cartItemsStore';

@Component({
  components: {
    OrderProcessError,
    WsButton,
    WsMessage,
    MandatorySettings,
    OrderProcessActions,
  },
  computed: {
    ...mapState(['cart', 'userStep', 'quickOrderInProgress']),
    ...mapGetters(['stepHasIssues']),
  },
})
export default class ServiceSettings extends Vue {
  readonly quickOrderInProgress!: boolean;

  step = STEPS.SERVICE_SETTINGS;
  PATH = 'cart.serviceSettings';
  cartItemsStore = useCartItemsStore();

  get editMode() {
    return this.$store.state.inEditMode === this.step;
  }

  get uncompleteMandatory() {
    return this.cartItemsStore.uncompleteMandatory;
  }

  clearMessages() {
    this.$messageService.clearMessages(
      [this.PATH, 'cart.summary.quick_order', 'cart.' + this.step, 'general'],
      true
    );
  }

  mayGoNext() {
    this.$messageService.clearMessages(['cart.summary.quick_order']);
    (this.$refs.mandatoryFields as MandatorySettings)
      .validate()
      .then((result: boolean) => {
        if (!result) {
          return this.$root.$messageService.scrollToError();
        }
        this.$root.$messageService.clearMessages([this.PATH, 'general'], true);
        mayGoNext();
      });
  }
}
