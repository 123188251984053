
import Vue, { defineComponent,  onMounted, ref, getCurrentInstance } from 'vue';

export default defineComponent({
  props: {
    anchor: {
      type: String,
      default: 'top middle'
    },
    // Set the position of the tooltip "arrow"
    arrowPosition: {
      type: String,
      default: 'bottom'
    },
    self: {
      type: String,
      default: 'bottom middle'
    },
    // To limit max length/width of the tooltip
    maxWidth: {
      type: String,
      default: '100%'
    },
    // Only show the tooltip when the parent is overflowing
    onOverflowOnly: {
      type: Boolean,
      default: false
    },
    onDark: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Array,
      default: undefined
    }
  },
  setup(props: any) {
    // @ts-ignore
    const instance: Vue = getCurrentInstance()?.proxy;
    const enabled = ref(true);

    onMounted(() => {
      if (props.onOverflowOnly) {
        const parent = (instance.$el.parentNode as HTMLElement);
        if (parent) {
          enabled.value = parent.scrollWidth > parent.clientWidth;
        }
      }
    });

    return {
      enabled,
    };
  },
  computed: {
    // Prep the class for the arrow positioning
    arrowClass(): String {
      return 'ws-arrow-' + this.arrowPosition;
    },
  }
});
