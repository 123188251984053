
import {QSlider} from 'quasar';
import {computed, defineComponent, onMounted, ref, watch, Ref, nextTick} from 'vue';
import WsInput from './WsInput.vue';

export default defineComponent({
  name: 'WsFormSlider',
  components: {
    QSlider,
    WsInput
  },
  props: {
    value: {type: [String, Number], required: true},
    baselineValue: {type: [String, Number], required: true},
    steps: {type: Object, required: true},
    step: {type: Number, default: 1},
    infinite: {type: Boolean},
    allowDecrease: {type: Boolean},
    initialStep: {type: Boolean, default: false},
    innerMinValue: {type: Number, default: undefined},
    disabled: {type: Boolean, default: false},
    legend: { type: Object, default: null }
  },


  // eslint-disable-next-line max-lines-per-function
  setup(props: any, vm: any) {

    const formSliderEl: Ref<null | HTMLElement> = ref(null);

    const sliderValue: Ref<number> = ref(0);

    watch(() => props.value, () => {
      sliderValue.value = Number(props.value);
    });


    onMounted(() => {
      addBaselineTrack();
      sliderValue.value = Number(props.baselineValue);
    });

    const addBaselineTrack = async () => {
      await nextTick();

      const div = document.createElement('div');
      div.classList.add(
        'q-slider__track',
        'q-slider__track--h',
        'q-slider__track--baseline',
        'absolute',
        'text-ws-navy-60'
      );
      div.style.width = valuePercentage.value + '%';

      // @ts-ignore
      const targetEl = formSliderEl.value.__vue__?.$el?.querySelector('.q-slider__track-container');
      targetEl?.append(div);
    };

    const applyBaselineWidth = () =>  {
      if (formSliderEl.value) {
        const percentage = Math.min(100, downgradePercentageThreshold.value);
        // @ts-ignore
        const sliderTrack = formSliderEl.value.__vue__?.$el?.querySelector('.q-slider__track--baseline');
        // @ts-ignore
        const sliderThumb = formSliderEl.value.__vue__?.$el?.querySelector('.q-slider__thumb-container');
        if (props.initialStep || props.innerMinValue) {
          nextTick(() => {
            if (sliderValue.value < startValue.value) {
              sliderValue.value = startValue.value;
              sliderThumb.style.left = valuePercentage.value + '%';
            }
          });
        }
        sliderTrack.style.setProperty('--slider-gradient-percentage', percentage + '%');
      }
    };

    const valuePercentage = computed(() => {
      let val;
      if (sliderValue.value >= props.baselineValue) {
        val = props.baselineValue;
      } else {
        val = sliderValue.value;
      }

      return ((val - minValue.value) / (maxValue.value - minValue.value)) * 100;
    });

    const startValue = computed((): number => {
      if (props.innerMinValue) {
        return props.innerMinValue;
      } else if (props.initialStep) {
        return Number([...stepsMap.value.keys()][1]);
      } else {
        return minValue.value;
      }
    });

    const downgradePercentageThreshold = computed(() => {
      const startThreshold =
        (startValue.value - minValue.value) /
        Number(props.baselineValue - minValue.value) * 100;
      const threshold =
        (sliderValue.value - minValue.value) /
        Number(props.baselineValue - minValue.value) * 100;

      if (!Number.isNaN(threshold)) {
        return threshold < startThreshold ? startThreshold : threshold;
      } else {
        return 100;
      }

    });

    const onValueChange = (value: any) =>  {
      applyBaselineWidth();
      vm.emit('input', value);
    };

    const stepsMap = computed(() => {
      const formattedSteps = props.steps;
      const formattedStepsKeys: number[] = Object.keys(formattedSteps).map(Number);
      formattedStepsKeys.sort((a,b) => a - b);

      const sortedSteps = new Map();
      if (props.initialStep) {
        const key = Number(formattedStepsKeys[0]) - (Number(formattedStepsKeys[1]) - Number(formattedStepsKeys[0]));
        sortedSteps.set(key, '');
      }

      for (const key of formattedStepsKeys) {
        sortedSteps.set(key, formattedSteps[key]);
      }

      return sortedSteps;
    });

    const minValue = computed((): number => Number([...stepsMap.value.keys()][0]));

    const maxValue = computed((): number => Number([...stepsMap.value.keys()].slice(-1)));

    const showTopbar = computed(() => !!vm.slots.default || props.infinite);

    return {
      maxValue, minValue, stepsMap, legendObj: props.legend, showTopbar, onValueChange,
      formSliderEl,
      downgradePercentageThreshold, valuePercentage, sliderValue, applyBaselineWidth
    };
  }

});
