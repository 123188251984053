
import {QTabs, QTab} from 'quasar';
import {
  PropType,
  defineComponent,
  getCurrentInstance,
  computed,
} from 'vue';
import {useTranslation} from './../composables/translation';
import {Theme} from '@loopia-group/services';
import {get} from 'lodash-es';

export default defineComponent({
  name: 'WsBtnToggle',
  components:{
    QTabs,
    QTab,
  },
  props: {
    options: {
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    }
  },
  emits: ['input'],
  setup(props, vm) {
    const {$t} = useTranslation();
    const instance = getCurrentInstance();
    const optionsProcessed = computed(() => {
      return props.options.map((option: { label: string; }) => {
        const opt = { ...option };
        opt.label = $t(option.label);
        return opt;
      });
    });

    const onUpdate = ((value: any): void => {
      vm.emit('input', value);
      if (instance?.proxy.$parent) {
        instance.proxy.$parent.$emit('dirty', value);
      }
    });

    return {onUpdate, optionsProcessed, Theme, get};
  }
});
