
import {computed, defineComponent} from 'vue';
import ProductItem from './Items/ProductItem.vue';


export default defineComponent({
  name: 'ProductList',
  components: {ProductItem},
  props: {
    items: {default: () => {}, type: Array},
    selected: {default: '', type: String},
  },
  setup(props) {

    const numberOfItems = computed(() => props.items.length);
    const customSize = computed(() => {
      return {
        'max-width': numberOfItems.value < 3 ? '848px' : '1296px',
      };
    });

    return {
      customSize,
      numberOfItems,
    };
  }
});
