export const commonModel = {
  container: { size: 12 },
  page: {
    style: { background: '#E5E5E5' },
    bgColors: {
      cary: ['#E5E5E5', 'white', '#f9f9f9', '#2c3742'],
      admin: ['white', '#E7F0F8', '#2C3843'],
    },
  },
};

export default commonModel;
