
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QSkeleton, QForm, QTooltip } from 'quasar';

import { Throttle } from '@loopia-group/utils';
import { cloneDeep } from 'lodash-es';
import { validateForm } from '@WS_UIkit';

import WsButton from '@WS_Components/WsButton.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsRadio from '@WS_Components/WsRadio.vue';
import WsRadioList from '@WS_Components/WsRadioList.vue';
import WsAddress from '@WS_Components/WsAddress.vue';
import WsDrawer from '@WS_Components/WsDrawer.vue';
import WsEdit from '@WS_Components/WsEdit.vue';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import { ProfileListConfig, CommonProfile } from '@/types';
import { commonProfileMock } from '@/services/mocks';
import { Theme } from '@loopia-group/services';
import ProfileInfo from './ProfileInfo.vue';

@Component({
  components: {
    WsRadio,
    WsRadioList,
    WsButton,
    WsMessage,
    WsAddress,
    WsDrawer,
    WsEdit,
    WsDelimiter,
    ProfileInfo,
    QSkeleton,
    QForm,
    QTooltip,
  },
  computed: {
    ...mapState(['devUtils']),
  },
})
export default class ProfileList extends Vue {
  @Prop({ default: null })
  readonly value!: CommonProfile;
  @Prop({ default: () => ({ profiles: [], path: 'drawer' }) })
  readonly cfg!: ProfileListConfig;
  @Prop(Boolean)
  readonly disable!: boolean;
  @Prop(Boolean)
  readonly loading!: boolean;
  @Prop(Boolean)
  readonly small!: boolean;
  @Prop({ default: false })
  readonly editDisabled?: boolean;

  readonly devUtils!: boolean;
  Theme = Theme;

  showFormInternal = false;
  selectedIndex: number | null = null;
  saveLoader = false;
  showDrawer = false;
  openingDrawer = false; // not to flash append slot ontent while opening drawer

  get model(): CommonProfile | null {
    return cloneDeep(this.value);
  }

  get showForm(): boolean {
    return !!(!this.loading && this.showFormInternal && this.cfg.editMode);
  }

  get profilesFiltered(): CommonProfile[] {
    // !!! later it might be also filitered by search query, etc.. keep in mind!
    if (!this.cfg.profiles) {
      return [];
    }
    return this.cfg.editMode ? this.cfg.profiles : [this.value];
  }

  get newProfileAllowed() {
    return this.cfg.editMode && !this.limitReached;
  }

  get showNewProfileForm() {
    return this.newProfileAllowed && this.newProfileSelected;
  }

  get newProfileSelected() {
    return (
      !this.limitReached && this.profilesFiltered.length === this.selectedIndex
    );
  }

  get appendList() {
    return !this.cfg.appendForm && !this.showDrawer;
  }

  get listEmpty() {
    return !this.profilesFiltered.length;
  }

  get maxOne() {
    return this.cfg.maxProfiles === 1;
  }

  get limitReached() {
    // reached limit of profiles
    return (
      this.cfg.maxProfiles && this.cfg.maxProfiles === this.cfg.profiles?.length
    );
  }

  get hasListLoadError() {
    return this.$messageService.hasMessages(this.cfg.path + '-list');
  }

  get editInDrawerEnabled(): boolean {
    return (
      !this.newProfileSelected && !!this.cfg.editInDrawer // new profile is not created in drawer
    );
  }

  @Watch('model')
  @Watch('showForm')
  onShowFormOrModelChanges() {
    const origVal = this.showDrawer;
    const newVal = this.editInDrawerEnabled && this.showForm;
    this.openingDrawer = newVal && !origVal;
    this.setShowDrawer(newVal);
  }

  // throttled, not to flash drawer on rapid changes
  @Throttle(200, { leading: false, trailing: true })
  setShowDrawer(value: boolean) {
    this.showDrawer = value;
  }

  @Watch('selectedIndex')
  onSelectionChanged(index: number | null) {
    this.showFormInternal = false;

    if (index === null) {
      this.emit(null);
    } else if (this.newProfileSelected) {
      this.emit(this.cfg.emptyNewProfile as any);
    } else {
      this.emit(this.profilesFiltered[index]);
    }
  }

  prevHash: string = '';
  @Watch('value', { immediate: true })
  @Watch('cfg.profiles', { deep: true })
  @Watch('cfg.editMode')
  @Throttle(300, { leading: false, trailing: true })
  // eslint-disable-next-line sonarjs/cognitive-complexity
  async onInputsChanged() {
    const hash = JSON.stringify({
      ...this.value,
      ...this.cfg.profiles,
      editMode: this.cfg.editMode,
    });

    if (
      !this.cfg.profiles ||
      hash === this.prevHash ||
      this.showForm /* protect edit in progress */
    ) {
      return;
    }

    this.prevHash = hash;

    if (this.value?.id) {
      // select option by value
      this.selectedIndex = this.profilesFiltered.findIndex(
        (profile: CommonProfile) => profile.id === this.value.id
      );
      // nexttick to get behind onSelectionChanged execution
      await this.$nextTick();
      this.showFormInternal = this.maxOne;
    } else if (this.value && this.cfg.editMode) {
      // select new profile
      const originalValue = this.value;
      this.selectedIndex = this.profilesFiltered.length;
      // immediatelly show form when selected new profile
      // nexttick to get behind onSelectionChanged execution
      await this.$nextTick();
      this.showFormInternal = true;
      // reEmit prefilled values to override emptyNewProfile
      await this.$nextTick();
      this.emit(originalValue);
    } else if (this.maxOne || this.listEmpty) {
      // immediatelly show form when one profile is maximum, or list is empty
      this.selectedIndex = 0;
      // nexttick to get behind onSelectionChanged execution
      await this.$nextTick();
      this.showFormInternal = true;
    } else {
      this.selectedIndex = null;
    }
  }

  public saveProfile(): Promise<any> {
    this.saveLoader = true;
    this.$root.$messageService.clearMessages(this.cfg.path);

    return validateForm(this.$refs.drawerForm as Element & QForm).then(((
      result: boolean
    ) => {
      if (result) {
        return this.cfg
          .save()
          .then(() => {
            // close form
            this.showFormInternal = false;
          })
          .catch((error: any) => {
            if (this.editInDrawerEnabled && this.showDrawer) {
              this.$messageService.errorHandler('drawer')(error);
            }
            return Promise.reject(error);
          })
          .finally(() => (this.saveLoader = false));
      } else {
        this.saveLoader = false;
        return Promise.resolve();
      }
    }) as any);
  }

  editProfile(index: number) {
    if (this.selectedIndex === index) {
      // just toggle edit of already selected
      this.showFormInternal = !this.showFormInternal;
    } else {
      this.selectedIndex = index;
      // nexttick to get behind onSelectionChanged execution
      this.$nextTick(() => {
        this.showFormInternal = true;
      });
    }
  }

  updateAddress(updatedProfile: Partial<CommonProfile>) {
    this.emit(updatedProfile);
  }

  emit(profile: Partial<CommonProfile> | null) {
    if (profile !== this.value) {
      this.$emit('input', cloneDeep(profile));
    }
  }

  devFill() {
    if (this.devUtils) {
      this.emit(cloneDeep(getMockedProfile()));
    }
  }
}

function getMockedProfile(): Partial<CommonProfile> {
  return (commonProfileMock as unknown) as Partial<CommonProfile>;
}
