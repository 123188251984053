import { ActionTree } from 'vuex';
import { AxiosResponse } from 'axios';

import { cloneDeep, get } from 'lodash-es';
import { setItem } from '@WS_UIkit';
import {
  File,
  BillingProfile,
  DomainProfile,
  PaymentOptionGroup,
  CategoryProfileType,
  CommonProfile,
  DomainProfileExtended,
} from '@/types';
import {
  AppState,
  SetRequiredFieldsPayload,
} from './const';
import getInitialState from './appState';
import { StorageKeys } from '@/services/const';
import {
  getBillingProfiles,
  getDomainProfiles,
  getPaymentOptions,
  getRequiredDpFields,
  removeFile,
  updateBillingProfile,
  updateDomainProfile,
} from '@/services/cart/cart-api.service';
import { fetchUserProfile, userDomains } from '@/services/user-api.service';
import { messageService } from '@/services/message.service';
import { normalizeDomainProfile } from '@/services/profiles.service';
import { OPTIONS_ORDER } from '@/components/paymentOptions/const';
import { getDomainCheckerCategories } from '@/services/domain-checker.service';
import {StateMutations, StoreActions} from '@/store/const.enum';

const updateMethods: Record<
  CategoryProfileType,
  // eslint-disable-next-line no-unused-vars
  (profile: any) => Promise<AxiosResponse>
> = { domain: updateDomainProfile, billing: updateBillingProfile };

export const actions: ActionTree<AppState, AppState> = {
  [StoreActions.RESET_STATE]({ commit }) {
    commit(StateMutations.MERGE_STATE, getInitialState());
  },

  [StoreActions.REMOVE_FILE](
    { state, commit },
    { file, path }: { file: File; path: string }
  ) {
    const files = get(state, path);
    if (!files) {
      return Promise.reject('exception.files_state_missing');
    }
    const serverFileName = file.serverFileName;
    const index = files.indexOf(file);
    return removeFile(serverFileName).then(() => {
      if (index > -1) {
        commit(StateMutations.REMOVE_STATE, {
          path,
          prop: index,
        });
      }
    });
  },

  [StoreActions.FETCH_DOMAIN_CATEGORIES]({ commit }) {
    return getDomainCheckerCategories().then(data => {
      commit(StateMutations.SET_DOMAIN_CATEGORIES, data);
    });
  },

  [StoreActions.FETCH_REQ_FIELDS](
    { getters, commit },
    profileCountry?: string
  ) {
    const path = 'required_fields';
    const tlds = getters.tlds;
    if (!tlds || !tlds.length) {
      // die sillently as user does not need required fields
      // if they do not have domains in cart
      return;
    }
    const cached = getters.requiredFields(undefined, profileCountry);
    if (cached) {
      return Promise.resolve();
    }
    return getRequiredDpFields(tlds, profileCountry)
      .then((response: AxiosResponse) => {
        commit(StateMutations.SET_REQUIRED_FIELDS, {
          tlds,
          profileCountry,
          requiredFields: response.data,
        } as SetRequiredFieldsPayload);
      })
      .catch(messageService.errorHandler(path));
  },

  [StoreActions.SET_USER_STEP]({ commit, state }, { step }) {
    commit(StateMutations.SET_STATE, { prop: 'userStep', value: step });

    const uuid = state.cart.uuid;
    setItem(StorageKeys.userStep, { step, uuid }, true);
  },

  [StoreActions.FETCH_BILLING_PROFILES]({ commit }) {
    return getBillingProfiles()
      .then(response => {
        const profiles: BillingProfile[] = response.data;
        commit(StateMutations.SET_STATE, {
          prop: 'billingProfiles',
          value: profiles,
        });
        return Promise.resolve(profiles);
      })
      .catch(
        messageService.errorHandler('billingProfiles', {
          returnPromise: true,
        })
      );
  },

  [StoreActions.ENSURE_DOMAIN_PROFILES]({ dispatch, state }) {
    const profilesPresent = state.domainProfiles?.length;
    return profilesPresent
      ? Promise.resolve(state.domainProfiles)
      : dispatch(StoreActions.FETCH_DOMAIN_PROFILES);
  },

  [StoreActions.FETCH_DOMAIN_PROFILES]({ commit }) {
    return getDomainProfiles()
      .then(response => {
        let profiles: DomainProfileExtended[] = response.data;
        profiles = profiles.map(
          (profile: DomainProfile) => normalizeDomainProfile(profile)!
        );
        commit(StateMutations.SET_STATE, {
          prop: 'domainProfiles',
          value: profiles,
        });
        return Promise.resolve(profiles);
      })
      .catch(
        messageService.errorHandler('domainProfiles', {
          returnPromise: true,
        })
      );
  },

  async [StoreActions.ENSURE_USER_DOMAINS]({ state, commit }) {
    if (state.userDomains?.length) {
      return state.userDomains;
    }
    try {
      const data = await userDomains();
      const domains = data?.domains;
      commit(StateMutations.SET_STATE, {
        prop: 'userDomains',
        value: domains,
      });
      return domains;
    } catch (err) {
      return messageService.errorHandler('general', {
        returnPromise: true,
      })(err);
    }
  },

  [StoreActions.UPDATE_PROFILE](
    { commit },
    { profile, type }: { profile: CommonProfile; type: CategoryProfileType }
  ): Promise<CommonProfile> {
    profile = cloneDeep(profile);
    if (type === 'domain') {
      profile = normalizeDomainProfile(profile as DomainProfile)!;
    }
    return updateMethods[type](profile).then(() => {
      commit(StateMutations.UPDATE_PROFILE, {
        type,
        profile,
      });
      return Promise.resolve(profile);
    });
  },

  [StoreActions.FETCH_USER_PROFILE]({ state, getters }) {
    const userProfile = state.userProfile;

    if (userProfile) {
      return Promise.resolve(userProfile);
    } else if (getters.loggedIn) {
      return fetchUserProfile();
    } else {
      return Promise.resolve();
    }
  },

  [StoreActions.FETCH_PAYMENT_OPTIONS]({ commit }) {
    return getPaymentOptions().then(response => {
      const sortedOptions: PaymentOptionGroup[] = [];
      const unlistedOptions: PaymentOptionGroup[] = [];
      response.data.forEach((option: PaymentOptionGroup) => {
        const index = OPTIONS_ORDER.indexOf(option.id);
        if (index > -1) {
          sortedOptions[index] = option;
        } else {
          unlistedOptions.push(option);
        }
      });
      const options = sortedOptions
        .filter(option => !!option)
        .concat(unlistedOptions);

      commit(StateMutations.SET_STATE, {
        prop: 'paymentOptions',
        value: options,
      });
    });
  },
};
