/**    crowdin integration     */

import { getQueryParams, isBrowser } from '@loopia-group/utils';

const lang = getQueryParams().l;

if (lang === 'xx-XX' && isBrowser) {
  const _jipt: any = [];
  (window as any)._jipt = _jipt;
  _jipt.push(['project', 'new-order']);

  const scriptTag = document.createElement('script'); // create a script tag
  const firstScriptTag = document.getElementsByTagName('script')[0]; // find the first script tag in the document
  scriptTag.src = '//cdn.crowdin.com/jipt/jipt.js'; // set the source of the script to your script
  firstScriptTag.parentNode!.insertBefore(scriptTag, firstScriptTag); // append the script to the DOM
}
