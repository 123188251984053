
import {defineComponent} from 'vue';


export default defineComponent({
  props: {
    short: Boolean,
    period: {type: Number, default: 1}
  }
});
