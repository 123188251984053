
import { Component, Prop, Vue } from 'vue-property-decorator';
import { QTooltip } from 'quasar';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import config from '@/services/config.service';
import { get } from 'lodash-es';
import { INVOICE_DELIVERY_TYPE } from '@/components/paymentOptions/const';
import { Cart } from '@/types';
import { mapState } from 'vuex';

@Component({
  inheritAttrs: false,
  components: {
    QTooltip,
    WsCheckbox,
    WsIcon,
    WsStateBanner,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class PaymentCardInfo extends Vue {
  @Prop(Boolean)
  readonly value!: boolean;
  @Prop(Boolean)
  readonly alone!: boolean;
  @Prop(Boolean)
  readonly disable!: boolean;

  readonly cart!: Cart;

  tosUrl = config.tosUrl;
  company = config.company;
  companyCountry = config.companyCountry;

  get eInvoiceEnabled(){
    return get(this.cart.billingProfile, 'invoiceDelivery') === INVOICE_DELIVERY_TYPE.EINVOICE;
  }
}
