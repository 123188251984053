
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {
  QList,
  QItem,
  QItemSection,
  QItemLabel,
  QAvatar,
  QSpinner,
  QForm,
} from 'quasar';
import { mapState, mapGetters } from 'vuex';

import { Cart, CartItem } from '@/types';
import { NOT_SET_VALUES } from '@/const';
import { cloneDeep, get } from 'lodash-es';
import { getSnakedKeyFactory, Debounce,} from '@loopia-group/utils';
import {
  required,
  minLength,
  validateInput,
  propertyRules,
  propertyValidation,
  productPropertyList,
  addValidations,
  generalPropertyList,
} from '@WS_UIkit';
import store from '@/store';
import { StateMutations } from '@/store/const.enum';
import { Validator } from '@WS_UIkit/types';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsInput from '@WS_Components/WsInput.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import { updateItem } from '@/services/cart/cart-api.service';

// TODO: dynamic imports?
import hostingImg from '@/assets/images/hosting.svg';
import sslImg from '@/assets/images/ssl-certifikaty.svg';
import vpsImg from '@/assets/images/virtual-server.svg';
import domainRegisterImg from '@/assets/images/registracia-domen.svg';
import domainTransferImg from '@/assets/images/transfer.svg';
import hostingWpImg from '@/assets/images/hosting-pre-wp.svg';
import hostingBusinessImg from '@/assets/images/biznis-hosting.svg';
import emailImg from '@/assets/images/vlastny-email.svg';
import easySeoImg from '@/assets/images/easySeo.svg';
import redirectImg from '@/assets/images/presmerovanie.svg';
import storageImg from '@/assets/images/storage.svg';
import vdcImg from '@/assets/images/VDC.svg';
import siteBuilderImg from '@/assets/images/vlastna-webstranka.svg';
import sslWildcardImg from '@/assets/images/ev-ssl.svg';
import windowsLicenceImg from '@/assets/images/dedikovany-win-server.svg';
import monitoringImg from '@/assets/images/monitoring.svg';
import backupImg from '@/assets/images/parameters.svg';
import managementImg from '@/assets/images/sprava-servera.svg';
import {useCartItemsStore} from '@/store/cartItemsStore';
import xss from 'xss';

const SUPPORTED_MANDATORY_PROPS: string[] = ['name', 'domain', 'eppCode'];
const STR_PROPERTIES_DOT = 'properties.';

interface MandatorySetting {
  key: string;
  property: string;
  hash: string;
  item: CartItem;
  itemOriginal: CartItem;
  rules: Validator[];
  restriction: string;
  msgsPath: string | string[];
}

@Component({
  inheritAttrs: false,
  components: {
    QList,
    QItem,
    QItemSection,
    QItemLabel,
    QAvatar,
    QSpinner,
    QForm,
    WsIcon,
    WsInput,
    WsButton,
    WsSelect,
    WsMessage,
  },
  computed: {
    ...mapState(['cart']),
    ...mapGetters(['cartDomains', 'uncompleteMandatory']),
  },
})
export default class MandatorySettings extends Vue {
  @Prop(Boolean)
  readonly editMode!: boolean;
  readonly cart!: Cart;
  uncompleteMandatory!: boolean;
  images: { [key: string]: string } = {
    'domain-register': domainRegisterImg,
    'domain-transfer': domainTransferImg,
    hosting: hostingImg,
    'hosting-wp': hostingWpImg,
    'hosting-business': hostingBusinessImg,
    email: emailImg,
    'easy-seo': easySeoImg,
    redirect: redirectImg,
    storage: storageImg,
    vdc: vdcImg,
    vps: vpsImg,
    'site-builder': siteBuilderImg,
    'ssl-single': sslImg,
    'ssl-wildcard': sslWildcardImg,
    'windows-licence': windowsLicenceImg,
    monitoring: monitoringImg,
    backup: backupImg,
    management: managementImg,
  };
  defaultImg: string = vpsImg;
  loaders: { [key: string]: boolean } = Object.create(null);
  models: { [key: string]: string } = Object.create(null);
  keyCache = Object.create(null);
  path = 'cart.mandatorySettings';
  toBeFocused: null | string = null;
  cartItemsStore = useCartItemsStore();

  xss = xss;

  data() {
    return {
      required,
      minLength,
    };
  }

  get manSets(): MandatorySetting[] {
    // just reference editMode to cause recalculation on editMode changes
    this.editMode;
    this.toBeFocused = null;

    if (!this.cartItemsStore.cartItems || !this.cartItemsStore.cartItems.length) {
      return [];
    }

    const result: MandatorySetting[] = [];
    for (const item of this.cartItemsStore.cartItems) {
      const relevantMandatoryProps = item.mandatoryEditableProperties?.filter(
        (prop: string) => SUPPORTED_MANDATORY_PROPS.includes(prop)
      );
      if (!relevantMandatoryProps?.length) {
        continue;
      }

      result.push.apply(
        result,
        relevantMandatoryProps.map((manProp: string) => {
          const hash = item.id + '-' + item.code + '-' + manProp;
          const { rules, restriction } = propertyValidation(
            item.code + '_' + manProp
          );

          if (
            this.toBeFocused === null &&
            (!item.properties ||
              NOT_SET_VALUES.includes(item.properties[manProp]))
          ) {
            this.toBeFocused = hash;
          }
          return {
            key: item.code,
            property: manProp,
            hash,
            item: cloneDeep(item),
            itemOriginal: item,
            msgsPath: [
              `${this.path}.id${item.id}.properties.${manProp}`,
              `cart.${item.id}.configuration.${manProp}`,
            ],
            rules,
            restriction,
          } as MandatorySetting;
        })
      );
    }
    return result;
  }

  get domainRules() {
    return propertyRules('domain');
  }

  @Watch('editMode')
  async onEditModeChanged(editMode: boolean) {
    if (editMode) {
      // TODO: Form reset does not work, why?;
      // (this.$refs.form as QForm).reset();
      this.resetModels();

      await this.$nextTick();
      this.validate();
    }
  }

  created() {
    this.resetModels();
    addValidations([generalPropertyList, productPropertyList]);
  }

  resetModels() {
    this.manSets.forEach(manSet => {
      Vue.set(
        this.models,
        manSet.hash,
        get(manSet.item, STR_PROPERTIES_DOT + manSet.property, '')
      );
    });
  }

  validate(): Promise<boolean> {
    if (
      Object.values(this.loaders).some((loading: boolean) => loading === true)
    ) {
      // submitting value in progress cannot validate, so send invalid
      return Promise.resolve(false);
    }
    // TODO: neither of following two works, because QForm only seems to check
    // direct child and dont go deeper, and custom validateForm walks only when
    // QForm validation returns false, and even does not collect results, just
    // runs validators to highlight invalid fields
    // return (this.$refs.form as QForm).validate();
    // return validateForm(this.$refs.form as Element & QForm);
    const promises: Promise<boolean>[] = [];
    this.manSets.forEach(manSet => {
      const cmp = (this.$refs['ref_' + manSet.hash] as any)[0];
      if (cmp.validate) {
        promises.push(cmp.validate());
      }
    });
    return Promise.all(promises).then((results: boolean[]) => {
      return Promise.resolve(results.every(result => result === true));
    });
  }

  emitUserActionTaken() {
    this.$emit('user-action-taken');
  }

  submit(manSet: MandatorySetting) {
    this.emitUserActionTaken();

    const inputElm = get(this.$refs, 'ref_' + manSet.hash + '[0]');
    if (!this.models[manSet.hash]) {
      return;
    } else if ((inputElm as any).validate) {
      validateInput(inputElm).then((result: boolean) => {
        if (!result) {
          return this.$messageService.scrollToError();
        }
        this.innerSubmit(manSet, inputElm as HTMLElement);
      });
    } else {
      this.innerSubmit(manSet, inputElm as HTMLElement);
    }
  }

  innerSubmit(manSet: MandatorySetting, inputElm: HTMLElement) {
    this.emitUserActionTaken();

    Vue.set(this.loaders, manSet.hash, true);
    const value = this.models[manSet.hash];
    const item = cloneDeep(manSet.item);
    const itemPath = this.path + '.id' + item.id;
    if (!item.properties) {
      item.properties = {};
    }
    store.commit(StateMutations.SET_STATE, {
      prop: 'mandatorySettingsHasIssue',
      value: false,
    });
    this.$messageService.clearMessages(itemPath);
    if (item.properties[manSet.property] === value) {
      // value did not changed, cancel update
      this.loaders[manSet.hash] = false;
      return;
    }
    item.properties[manSet.property] = value;
    updateItem(item)
      .catch(err => {
        this.$messageService.errorHandler(itemPath, {
          propertyPath: STR_PROPERTIES_DOT + manSet.property,
          keyInterceptor: (tKey: string) => {
            if (tKey === 'validation.domain_name.not_valid') {
              return {
                translationKey: 'validation.domain_name.not_valid_w_param',
                values: { domain: value },
              };
            }
            return tKey;
          },
        })(err);
      })
      .finally(() => {
        this.loaders[manSet.hash] = false;
        // focus to be able to hit enter and go to next step
        if (typeof inputElm.focus === 'function') {
          inputElm.focus();
        }
      });
  }

  isSet(manSet: MandatorySetting) {
    const value = get(manSet.item, STR_PROPERTIES_DOT + manSet.property, null);

    return (
      !this.loaders[manSet.hash] &&
      !get(this.$refs, 'ref_' + manSet.hash + '[0].messages.length') &&
      value === this.models[manSet.hash] &&
      !['', null, undefined].includes(value)
    );
  }

  @Debounce()
  clearMessages(path: string | string[]) {
    this.$messageService.clearMessages(path);
  }

  getKeyProduct(product: string, key: string): string {
    this.getKeyProduct = getSnakedKeyFactory('product');
    return this.getKeyProduct(product, key);
  }

  getKeyProp(prop: string, key: string): string {
    this.getKeyProp = getSnakedKeyFactory('service_settings.props');
    return this.getKeyProp(prop, key);
  }
}
