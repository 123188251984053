var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ws-select ws-field wrapper"},[_vm._t("custom-display",null,{"selectedValue":_vm.displayValue,"openDropdown":_vm.openDropdown}),_c('q-select',_vm._g(_vm._b({ref:"qfield",staticClass:"ws-select-inner",class:{
      'ws-select-chips': _vm.$attrs['use-chips'] === '',
      'ws-select-empty': _vm.value === '' || _vm.get(_vm.value, 'length') === 0,
      'ws-dark': _vm.dark,
      'q-field--error': _vm.errorDesign,
      'messages-overflow': _vm.messagesOverflow,
      'custom-display': _vm.isCustomDisplay
    },attrs:{"name":_vm.name,"emit-value":_vm.emitValue,"value":_vm.selectedValue,"options":_vm.filteredOptions,"display-value":_vm.displayValue,"popup-content-class":(_vm.$attrs['popup-content-class'] || '') + ' ws-select-content',"bg-color":_vm.bgColor || (_vm.dark ? 'dark' : 'white'),"standout":_vm.dark ? 'bg-dark text-white' : 'bg-white ws-text-darker',"dark":_vm.dark,"placeholder":_vm.getPlaceholder,"square":_vm.isTheme(_vm.Theme.ADMIN),"multiple":_vm.multiple,"outlined":"","use-chips":_vm.multiple,"no-error-icon":"","use-input":_vm.allowCustomValue || _vm.reduceOptionsByQuery || _vm.withInput || _vm.useInput,"fill-input":_vm.allowCustomValue && !_vm.multiple,"hide-selected":_vm.allowCustomValue && !_vm.multiple,"input-debounce":_vm.reduceOptionsByQuery ? 300 : undefined,"lazy-rules":"","transition-show":"jump-down","transition-hide":"jump-up","bottom-slots":"","error":!!_vm.messages.length,"error-message":(_vm.messages.length &&
        (_vm.messages[0].translation ||
          _vm.$t(_vm.messages[0].translationKey, _vm.messages[0].values))) ||
        '',"behavior":"menu","hide-dropdown-icon":""},on:{"filter":_vm.filterOptions,"new-value":_vm.createValue,"focus":_vm.onFocus,"input":_vm.onInput,"input-value":_vm.onInputValueChange,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEnter.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.icon)?{key:"append",fn:function(){return [_c('ws-icon',{attrs:{"name":_vm.icon}})]},proxy:true}:null,{key:"option",fn:function({ itemProps, opt, selected, toggleOption }){return [_c('q-item',_vm._b({on:{"!click":function($event){$event.stopPropagation();return toggleOption(opt)}}},'q-item',itemProps,false),[(_vm.multiple)?_c('q-item-section',{staticClass:"q-mr-sm",attrs:{"side":""}},[_c('ws-checkbox',{attrs:{"value":selected}})],1):_vm._e(),(opt.icon)?_c('q-item-section',{attrs:{"side":""}},[_c('ws-icon',{attrs:{"name":opt.icon,"height":"16"}})],1):_vm._e(),_c('q-item-section',[_c('q-item-label',[_vm._v(_vm._s(typeof opt === 'string' ? opt : opt.label))])],1)],1)]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(scope){return [_vm._t(slot,null,null,{ ...scope, emphasiseQuery: _vm.emphasiseQuery })]}}})],null,true)},'q-select',_vm.$attrs,false),_vm.$listeners))],2)
}
var staticRenderFns = []

export { render, staticRenderFns }