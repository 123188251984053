// define order steps order :D
import {STEPS} from '@/services/const.enum';

export const ORDER_STEPS: STEPS[] = [
  STEPS.CART,
  STEPS.BILLING_PROFILE,
  STEPS.DOMAIN_PROFILE,
  STEPS.PAYMENT_METHOD,
  STEPS.SERVICE_SETTINGS,
  STEPS.RECAPITULATION,
];

export const ORDER_STEPS2 = (() => {
  const orderSteps = [...ORDER_STEPS];
  orderSteps[1] = STEPS.DOMAIN_PROFILE;
  orderSteps[2] = STEPS.BILLING_PROFILE;
  return orderSteps;
})();

export const StorageKeys = {
  tmpDomainProfileData: 'pno_domain_profile_data',
  userStep: 'pno_user_step',
  transferPaymentData: 'pno_transfer_data',
  persistentState: 'pno_persistent_state',
};

export const INVALID_FORM_ERROR = 'validation.invalid_form';

export const COMMON_PROFILE_KEYS = [
  'name',
  'organisation',
  'type',
  'street',
  'city',
  'postalCode',
  'countryCode',
  'companyId',
  'taxId',
  'vatId',
];


export const HOSTING_TUTORIAL_PROPERTY_KEYS = {
  DOMAIN: 'domain',
  CAPACITY: 'capacity',
  DOMAINS_COUNT: 'domainsCount',
  PACKAGE_ID: 'packageId',
  TYPE: 'type',
  PRICES: 'prices',
};


export const HOSTING_TUTORIAL_NAMESPACE = 'hosting_tutorial';
export const HOSTING_TUTORIAL_COOKIE = `ws_${HOSTING_TUTORIAL_NAMESPACE}`;
export const HOSTING_TUTORIAL_QUERY_PARAM = 'hosting-tutorial';
export const HOSTING_TUTORIAL_STEPS = {
  SKIP: 'skip',
  MAIN: 'main',
  TRANSFER: 'transfer',
  CLOSE: 'close',
  STEP: 'step',
  STEP_1: 'step_1',
  STEP_1_1: 'step_1_1',
  STEP_2: 'step_2',
  STEP_1_1_1: 'step_1_1_1',
};

export const HOSTING_TUTORIAL_SELECTABLE_PROPERTIES = new Set([
  HOSTING_TUTORIAL_PROPERTY_KEYS.CAPACITY,
  HOSTING_TUTORIAL_PROPERTY_KEYS.DOMAINS_COUNT,
  HOSTING_TUTORIAL_PROPERTY_KEYS.PACKAGE_ID,
  HOSTING_TUTORIAL_PROPERTY_KEYS.TYPE,
]);

export const inputMasks: {[index: string]: string} = {
  'sk-SK': 'dd/MM/yyyy',
  'cs-CZ': 'dd/MM/yyyy',
  'sv-SE': 'yyyy-MM-dd',
  'en-SE': 'yyyy-MM-dd',
  'hu-HU': 'yyyy.MM.dd',
  'default': 'yyyy/MM/dd',
};

export const QDateLocale: {[index: string]: object} = {
  'sv-SE': {
    daysShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'],
    monthsShort: ['Jan', 'Febr', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'],
    months: [
      'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni',
      'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
    ],
  },
  'sk-SK': {
    daysShort: ['Ne', 'Po', 'Ut', 'Str', 'Štv', 'Pi', 'So'],
    monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'],
    months: [
      'Január', 'Február', 'Marec', 'April', 'Máj', 'Jún',
      'Júl', 'August', 'September', 'Október', 'November', 'December'
    ],
  },
  'cs-CZ': {
    daysShort: ['so', 'ne', 'po', 'út', 'st', 'čt', 'pá'],
    monthsShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čer',  'črc', 'srp', 'zář', 'říj', 'lis', 'pro'],
    months: [
      'leden', 'únor', 'březen', 'duben', 'květen', 'červen',
      'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'
    ],
  },
  'hu-HU': {
    daysShort: ['Vas', 'Hét', 'Ked', 'Sze', 'Csü', 'Pén', 'Szo'],
    monthsShort: ['Jan', 'Feb', 'Már', 'Ápr', 'Máj', 'Jún', 'Júl', 'Aug', 'Szept', 'Okt', 'Nov', 'Dec'],
    months: [
      'Január', 'Február', 'Március', 'Április', 'Május', 'Június',
      'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'
    ],
  }
};

export const UATrackingIDs: {[index: string]: string} = {
  'SK': 'UA-7283486-1',
  'CZ': 'UA-190673481-3',
  'SE': 'UA-157051575-1',
  'HU': 'UA-196866107-1',
};
