
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QSpinner } from 'quasar';

import { Cart } from '@/types';
import { isBrowser } from '@loopia-group/utils';
import { safePush } from '@/router';
import { logError } from '@/services/logger.service';
import cartIcon from '@/assets/images/cart.svg';
import {ROUTENAMES} from '@/const.enum';

@Component({
  components: {
    QSpinner,
  },
  computed: {
    ...mapState(['cart', 'checkoutInProgress']),
  },
})
export default class TopMiniCart extends Vue {
  @Prop()
  readonly index!: number;
  @Prop()
  readonly templateCartEl!: HTMLElement;

  readonly cart!: Cart;
  checkoutInProgress!: boolean;
  initialPlacement = true;

  data() {
    return {
      cartIcon,
    };
  }

  mounted() {
    this.hijack(this.templateCartEl);
  }

  @Watch('templateCartEl', { immediate: true })
  hijack(templateCartEl: HTMLElement) {
    if (!isBrowser || !templateCartEl || !this.$el) {
      return;
    }

    if (templateCartEl.style.display === 'none') {
      // already hijacked, hot reload?
      return;
    }

    const parentNode = templateCartEl.parentNode;
    if (!parentNode) {
      // something went wrong, did someone change HF template?
      logError(`parentNode missing. parentNode: ${parentNode}`);
      return;
    }
    parentNode.insertBefore(this.$el, templateCartEl);

    templateCartEl.style.display = 'none';
    this.initialPlacement = false;
  }

  navigateToCart() {
    if (!this.checkoutInProgress) {
      safePush({ name: ROUTENAMES.CART });
    }
  }
}
