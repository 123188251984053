
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import { Throttle, byteConvert } from '@loopia-group/utils';
import { get } from 'lodash-es';
import { QuasarFile } from '@WS_UIkit';

import WsUploader from '@WS_Components/WsUploader.vue';
import WsRadio from '@WS_Components/WsRadio.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsLabelLink from '@WS_Components/WsLabelLink.vue';
import { Cart, File, UploadedInvoice } from '@/types';
import { ensureCartLoaded } from '@/store';
import config, { AppConfig } from '@/services/config.service';
import { StateMutations, StoreActions } from '@/store/const.enum';
import { setAssistance, fetchCart } from '@/services/cart/cart-api.service';
import { QUploader } from 'quasar';

const PATH = 'migration.upload';
const UPLOAD_URL = '/promotion-for-migration';
const UPLOAD_FILES_PATH = 'migrationPromotion.uploadedFiles';
const MESSAGES_PATH = 'cart.' + UPLOAD_FILES_PATH;

const ALLOWED_TYPES: string[] = [
  'image/jpeg',
  // 'image/png',
  'application/pdf',
];
const ALLOWED_SIZE: { value: number; text: string } = {
  value: 5242880,
  text: '5MB',
};

@Component({
  components: {
    WsUploader,
    WsRadio,
    WsMessage,
    WsLabelLink,
    WsCheckbox,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class MigrationUpload extends Vue {
  readonly cart!: Cart;
  config: AppConfig = config;
  // null => no migration, false => do no need assistance with migration,
  // true => do need assistance
  needAssistance: boolean | null = null;
  uploadReveal = false;
  loading = false;
  uploadUrl = UPLOAD_URL;

  created() {
    ensureCartLoaded(() => (
      this.uploadReveal = !!this.filesFromCart.length
    ));
    this.uploadUrl = this.config.apiUrl + UPLOAD_URL;
  }

  get filesFromCart(): UploadedInvoice[] {
    // keeping full reactivity
    return (
      (this.cart &&
        this.cart.migrationPromotion &&
        this.cart.migrationPromotion.uploadedFiles) ||
      []
    );
  }

  get filesProcessed(): any[] {
    return this.filesFromCart.map((file: any) => {
      // copying props for displaying purposes
      file.name = file.clientFileName || 'unknown file name';
      if (file.fileSize) {
        file.__sizeLabel = byteConvert(file.fileSize);
      }
      return file;
    });
  }

  filesFilter(files: any[]) {
    return files.filter(file => {
      let allowed = false;
      if (!this.isAllowedFileType(file.type)) {
        this.$messageService.setStandardMessage(
          MESSAGES_PATH,
          'wsk.ws_uploader.validation.file_type'
        );
      } else if (!this.isAllowedFileSize(file.size)) {
        this.$messageService.setStandardMessage(MESSAGES_PATH, [
          {
            translationKey: 'wsk.ws_uploader.validation.file_size',
            values: { limit: ALLOWED_SIZE.text },
          },
        ]);
      } else {
        allowed = true;
      }
      return allowed;
    });
  }

  isAllowedFileType(type: string) {
    return ALLOWED_TYPES.includes(type);
  }

  isAllowedFileSize(size: number) {
    return size < ALLOWED_SIZE.value;
  }

  processUploaded(info: any) {
    const xhr: XMLHttpRequest = info.xhr;
    let file: File;
    try {
      file = JSON.parse(xhr.response);
    } catch (error) {
      return this.$messageService.errorHandler(MESSAGES_PATH)(error);
    }

    // update also cart in store

    this.$store.commit(StateMutations.SET_STATE, {
      path: MESSAGES_PATH,
      value: file,
    });

    // cart price condition changed (0 files -> 1 file),
    // we need recalculated price PNO-1846
    if (this.filesFromCart.length === 1) {
      fetchCart();
    }
  }

  removeFile(file: QuasarFile | UploadedInvoice) {
    if (!(file as UploadedInvoice).clientFileName) {
      // is QuasarFile
      file =
        this.filesFromCart.find(
          (f: UploadedInvoice) => f.clientFileName === (file as QuasarFile).name
        ) || file;
    }
    this.$store
      .dispatch(StoreActions.REMOVE_FILE, {
        file,
        path: MESSAGES_PATH,
      })
      .then(() => this.onFileRemoved(file as any))
      .catch(this.$messageService.errorHandler(PATH));
  }

  onFileRemoved(file: File) {
    // cart price condition changed (1 file -> 0 files),
    // we need recalculated price PNO-1846
    if (this.filesFromCart.length === 0) {
      fetchCart();
    }

    (this.$refs.wsUploader as any) /* WsUploader */
      .removeFile(file);
  }

  @Watch('cart.migrationPromotion.needAssistance', { immediate: true })
  onCartAssistanceChange(newVal: boolean) {
    if (this.needAssistance !== newVal) {
      this.needAssistance = newVal;
    }
  }

  @Watch('uploadReveal', { immediate: true })
  onRevealChange(newVal: boolean, oldVal: boolean|undefined) {
    let needAssistance = get(
      this.cart,
      'migrationPromotion.needAssistance',
      null
    );
    if (!newVal) {
      // on unchecking migration checkbox set null value
      needAssistance = null;

      // on unchecking migration checkbox remove files and reset the uploader
      // ignore the first immediate trigger of this watcher (when oldValue is undefined),
      // because that causes the file(s) to be removed upon page refresh
      if (oldVal !== undefined) {
        this.filesFromCart.forEach( fileFromCart => {
          if (!this.filesFromCart.length) {
            return;
          }
          this.removeFile(fileFromCart);
          ((this.$refs.wsUploader as WsUploader)?.$refs.qUploader as QUploader)
            .reset();
        });
      }

    } else if (needAssistance === null) {
      // on chacking migration checkbox set default value
      needAssistance = false;
    }
    // otherwise set actual state from cart if differs from already set
    if (this.needAssistance !== needAssistance) {
      this.needAssistance = needAssistance;
    }
  }

  @Watch('needAssistance')
  onAssistanceChange(newVal: boolean | null) {
    this.setAssistance(newVal);
  }

  @Throttle(500)
  setAssistance(newVal: boolean | null) {
    if (get(this.cart, 'migrationPromotion.applicable')) {
      setAssistance(newVal);
    }
  }

  destroyed() {
    // if leaving the screen without files, set assistance to null
    if (this.filesFromCart.length === 0) {
      this.setAssistance(null);
    }
  }
}
