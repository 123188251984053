
import { config, Bundler } from '@loopia-group/services';
import {copyToClipboard} from 'quasar';
import WsFieldMixin from './WsFieldMixin.vue';
import {generatePassword} from '@loopia-group/utils';
import {computed, defineComponent, onMounted, Ref, ref, watch} from 'vue';
import {omit} from 'lodash-es';
import WsTooltip from './WsTooltip.vue';

const components = {WsTooltip};
// https://loopiagroup.atlassian.net/browse/STDR-325
// this should be removed when projects using this component are using Vite
if (config.bundler === Bundler.webpack) {
  Object.assign(components, {'WsPasswordStrength': () => import('./WsPasswordStrength.vue')});
  Object.assign(components, {'WsInput': () => import('./WsInput.vue')});
  Object.assign(components, {'WsIcon': () => import('./WsIcon.vue')});
}

export default defineComponent({
  name: 'WsPasswordInput',
  components: components,
  props: {
    value: {
      type: String,
      default: '',
    },
    autocomplete: {
      type: String,
      default: 'password',
    },
    hideicon: {
      type: Boolean,
      default: false,
    },
    expose: {
      type: Boolean,
      default: false,
    },
    strength: {
      type: Boolean,
      default: false,
    },
    strengthAbove: {
      type: Boolean,
      default: false,
    },
    allowRandom: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['expose', 'validity-changed', 'value-changed', 'input'],
  // eslint-disable-next-line max-lines-per-function
  setup(props, vm) {

    const qfield = ref(null);

    const model: Ref<string> = ref('');
    const exposed: Ref<boolean> = ref(false);
    const showCapsAlert: Ref<boolean> = ref(false);
    const showCopied: Ref<boolean> = ref(false);
    const isValid: Ref<boolean> = ref(false);
    const touched: Ref<boolean> = ref(false);

    let exposeByClick = false;
    let isRandom = false;

    onMounted(() => {
      model.value = props.value;
    });

    /***  Methods  ***/

    const detectCapsLock = (event: KeyboardEvent) => {
      showCapsAlert.value = event.getModifierState && event.getModifierState('CapsLock');
      isRandom = false;
    };

    const hideTooltip = () => {
      showCapsAlert.value = false;
    };

    const toggleExpose = (value?: boolean) => {
      if (value === undefined) {
        exposed.value = exposeByClick = !exposeByClick;
      } else if (!exposeByClick) {
        exposed.value = value;
      } else {
        return;
      }
      vm.emit('expose', exposed.value);
    };

    const onGeneratePassword = () => {
      isRandom = true;
      model.value = generatePassword();

      copyToClipboard(model.value)
        .then(() => {
          showCopied.value = true;
          setTimeout(() => {
            showCopied.value = false;
          }, 5000);
        });
    };

    /***  Computed  ***/

    const hasMessages = computed(() => {
      return !!(qfield.value as unknown as WsFieldMixin)?.messages?.length;
    });

    const listeners = computed(() => {
      return omit({...vm.listeners}, 'input');
    });

    /***  Watchers  ***/

    // Password exposure
    watch(
      () => props.expose,
      (value: boolean) => {
        exposed.value = value;
      }
    );

    // Password validity
    watch(
      isValid,
      () => {
        vm.emit('validity-changed', isValid.value);
      }
    );

    // Value prop
    watch(
      () => props.value,
      () => {
        model.value = props.value;
      }
    );

    // Model
    watch(
      model,
      () => {
        vm.emit('value-changed', model.value);
        vm.emit('input', model.value, isRandom);
      }
    );

    return {
      // Refs
      qfield,
      // Variables
      model,
      exposed,
      showCapsAlert,
      showCopied,
      isValid,
      touched,
      // Methods
      detectCapsLock,
      hideTooltip,
      toggleExpose,
      onGeneratePassword,
      omit,
      // Computed
      hasMessages,
      listeners,
    };
  }
});
