import Vue from 'vue';
import { Theme } from '@loopia-group/services';
import config from '@/services/config.service';
import {StoreActions} from '@/store/const.enum';

export default {
  namespaced: true,
  state: {
    theme: config.theme,
  },
  getters: {
    currentTheme: (state: any) => {
      return state.theme;
    },
  },
  mutations: {
    setTheme(state: any, theme: Theme) {
      Vue.set(state, 'theme', theme);
    },
  },
  actions: {
    // @ts-ignore
    [StoreActions.SET_THEME]({ commit }, { theme }) {
      commit('setTheme', theme);
    },
  },
};
