
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import {QSlider} from 'quasar';

import {debounce, DebouncedFunc} from 'lodash-es';
import {config} from '@loopia-group/services';
import {WsFieldMixin} from './WsFieldMixin.vue';
import {WsSliderOptions} from './WsSlider';

@Component({
  components: {
    QSlider,
  },
})
export class WsSlider extends mixins(WsFieldMixin) {
  @Prop({required: true, type: Number}) declare readonly value: number;
  @Prop({required: true}) readonly config!: WsSliderOptions;
  @Prop({default: 'top'}) readonly position!: string;

  get cfg(): WsSliderOptions {
    return {
      // defaults
      step: 1,
      max: null,
      // maxLabel: '∞',
      min: 0,
      // minLabel: '0',
      price: undefined,
      debounceTime: 200,
      // actual
      ...this.config,
    };
  }

  emitValue(value: number) {
    this.$emit('input', value);
  }

  created() {
    this.emitValue = debounce(this.emitValue, this.cfg.debounceTime) as DebouncedFunc<(val: number) => void>;
  }

  get currency() {
    return config.currency;
  }

  get price(): number {
    return this.value * this.cfg.price!;
  }
}

export default WsSlider;
