export enum EditableAddressProps {
  Name = 'name',
  Organisation = 'organisation',
  Email = 'email',
  Emails = 'emails',
  Phone = 'phone',
  Street = 'street',
  Street2 = 'street2',
  City = 'city',
  PostalCode = 'postalCode',
  CompanyId = 'companyId',
  TaxId = 'taxId',
  VatId = 'vatId',
}
