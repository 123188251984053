
import {computed, defineComponent} from 'vue';
import PackageItem from '@/modules/iwp/components/Items/PackageItem.vue';


export default defineComponent({
  name: 'PackageList',
  components: {PackageItem},
  props: {
    items: {default: () => {}, type: Array},
    selected: {default: '', type: String},
  },
  setup (props) {
    const packageItems = computed(() => null);
    const numberOfItems = computed(() => props.items?.length ?? 0);
    const customSize = computed(() => {
      return {
        'max-width': numberOfItems.value < 3 ? '848px' : '1296px',
      };
    });

    return {
      customSize,
      numberOfItems,
      packageItems,
    };
  }
});
