
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

import { CartExtended } from '@/store/const';
import { StoreActions } from '@/store/const.enum';
import store from '@/store';
import { STEPS } from '@/services/const.enum';
import { isStandardWorkflow } from '@/services/config.service';
import {
  deleteNote,
  updateNote,
  reloadCfgAndValidate,
} from '@/services/cart/cart-api.service';
import {
  setEditMode,
  setUserStep,
} from '@/services/order.service';
import EmptyCart from './EmptyCart.vue';
import MigrationUpload from '../components/MigrationUpload.vue';
import SidebarLayout from '@/components/SidebarLayout.vue';
import SideSummary from '@/components/SideSummary.vue';
import CartItems from '../components/cart/CartItems.vue';
import CouponList from '@/components/CouponList.vue';
import { scrollToTop } from '@loopia-group/utils';
import WsButton from '@WS_Components/WsButton.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import WsInput from '@WS_Components/WsInput.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsOptional from '@WS_Components/WsOptional.vue';
import QuickOrderButton from '@/components/QuickOrderButton.vue';
import { NavigationGuardNext, Route } from 'vue-router';
import {useCartItemsStore} from '@/store/cartItemsStore';

@Component({
  components: {
    QuickOrderButton,
    WsButton,
    WsOptional,
    WsMessage,
    WsCheckbox,
    WsInput,
    EmptyCart,
    SidebarLayout,
    SideSummary,
    CartItems,
    CouponList,
    MigrationUpload,
  },
  computed: {
    ...mapState(['cart', 'quickOrderInProgress']),
    ...mapGetters(['orderSteps', 'loggedIn', 'cartDomains']),
  },
})
export default class CartView extends Vue {
  readonly cart!: CartExtended;
  readonly orderSteps!: STEPS[];
  readonly loggedIn!: boolean;
  readonly cartDomains!: string[];
  readonly quickOrderInProgress!: boolean;

  noteReveal = false;
  loading = false;
  note: string = '';
  isStandardWorkflow = isStandardWorkflow;
  cartItemsStore = useCartItemsStore();

  created() {
    this.$messageService.clearMessages('general');
    store.dispatch(StoreActions.SET_USER_STEP, {
      step: STEPS.CART,
    });
    setEditMode(STEPS.CART);
    scrollToTop();
  }

  @Watch('cart.note', { immediate: true })
  cartNoteChanged(note: string, previousNote: string) {
    if (note && !previousNote && !this.loading) {
      this.note = note;
      this.noteReveal = true;
    }
  }

  @Watch('noteReveal')
  cartRevealChanged(noteReveal: boolean) {
    if (!noteReveal) {
      this.note = '';
      this.updateNote('');
    }
  }

  updateNote(note: string) {
    note = note.trim();
    this.loading = true;
    if (note) {
      updateNote(note).finally(() => (this.loading = false));
    } else {
      deleteNote().finally(() => (this.loading = false));
    }
  }

  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (!this.cartItemsStore.cartItems.length || !this.loggedIn) {
      // only if there is something to validate or user is not logged in
      return next();
    }

    try {
      await this.validateCart();
      next();
    } catch (error) {
      // ensure user step stays on cart
      await setUserStep(STEPS.CART);
      next(false);
    }
  }

  async validateCart() {
    if (this.cartItemsStore.cartItems.length) {
      try {
        await reloadCfgAndValidate();
      } catch (error) {
        this.$messageService.errorHandler()(error);
        throw error;
      }
    }
  }

  destroyed() {
    this.$messageService.clearMessages('general', true);
  }
}
