import csobLogo from '@/assets/images/Banks/csob.svg';
import czspLogo from '@/assets/images/Banks/czsp.svg';
import fioLogo from '@/assets/images/Banks/fio.svg';
import kbLogo from '@/assets/images/Banks/kb.svg';
import mBankLogo from '@/assets/images/Banks/mBank.svg';
import postovaLogo from '@/assets/images/Banks/postova.svg';
import raiffeisenLogo from '@/assets/images/Banks/raiffeisen.svg';
import sberbankLogo from '@/assets/images/Banks/sberbank.svg';
import slspLogo from '@/assets/images/Banks/slsp.svg';
import tatrabankaLogo from '@/assets/images/Banks/tatrabanka.svg';
import unicreditLogo from '@/assets/images/Banks/unicredit.svg';
import vubLogo from '@/assets/images/Banks/VUB.svg';
import otpLogo from '@/assets/images/Banks/otp.svg';

export const bankImages = {
  csob: csobLogo,
  csas: czspLogo,
  fio: fioLogo,
  komercni: kbLogo,
  mBank: mBankLogo,
  postova: postovaLogo,
  raiffeisen: raiffeisenLogo,
  raiffeisenbank: raiffeisenLogo,
  sberbank: sberbankLogo,
  slsp: slspLogo,
  tatrabanka: tatrabankaLogo,
  tatrapay: tatrabankaLogo,
  unicredit: unicreditLogo,
  vub: vubLogo,
  vubeplatby: vubLogo,
  otp: otpLogo,
};

export default bankImages;
