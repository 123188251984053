
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {mapState} from 'vuex';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import {isSwedishWorkflow} from '@/services/config.service';
import {STEPS} from '@/services/const.enum';
import {computed} from 'vue';
import {Debounce} from '@loopia-group/utils';

@Component({
  components: {
    WsMessage,
    WsStateBanner
  },
  computed: {
    ...mapState(['userStep']),
  },
})
export default class OrderProcessError extends Vue {
  @Prop()
  readonly path!: string;

  // used in cartItemSettings
  @Prop()
  readonly itemSettingsCode!: string;

  isSwedishWorkflow = isSwedishWorkflow;
  messages: boolean = false;
  isRequiredFieldError: boolean = false;
  getMsgs: any = null;

  data() {
    return {
      STEPS
    };
  }

  mounted() {
    // Workaround due reactivity issues while accessing messageService.
    // Also, v-if usage in template was breaking reactivity of getMsgs(v-show used instead as it works).
    this.$nextTick(() => {
      this.getMsgs = computed(() => {
        return this.$root.$messageService.getters.messagesByPath(this.path, {recursive: true});
      });
    });
  }

  @Watch('getMsgs', { immediate: false, deep: true })
  @Debounce(100)
  handleMessages() {
    this.messages = !!this.getMsgs.length;
    if(this.getMsgs.length) {
      this.isRequiredFieldError = this.getMsgs.some((err: any) =>
        err.translationKey.includes('validation.required'));
    }else {
      this.isRequiredFieldError = false;
    }
  };
}
