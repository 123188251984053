
import { Component, Inject, Prop, Watch } from 'vue-property-decorator';
import {QSelect} from 'quasar';
import {mixins} from 'vue-class-component';
import {emphasiseQuery} from '@loopia-group/utils';
import {escape, get} from 'lodash-es';
import {WsFieldMixin} from './WsFieldMixin.vue';
import WsIcon from './WsIcon.vue';
import { Theme } from '@loopia-group/services';
import type {Options, OptionsWithLabel} from './WsSelect';

@Component({
  components: {
    QSelect,
    WsIcon,
  },
})
export class WsSelect extends mixins(WsFieldMixin) {
  @Prop({default: null}) declare readonly value: string | number;
  @Prop({}) readonly options!: Options | OptionsWithLabel;
  @Prop(Boolean) readonly allowCustomValue!: boolean;
  @Prop(Boolean) readonly withInput!: boolean;
  @Prop(Boolean) readonly useInput!: boolean;
  @Prop(Boolean) readonly reduceOptionsByQuery!: boolean;
  @Prop(Boolean) readonly openOptionsOnFocus!: boolean;
  @Prop(Boolean) readonly dark!: boolean;
  @Prop(Boolean) readonly errorDesign!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(String) readonly bgColor!: string;
  @Prop(String) readonly placeholder!: string;
  @Prop({default: false}) declare readonly messagesOverflow: boolean;
  @Prop({type: String, default: 'arrow_down'}) readonly icon!: string;
  @Prop({type: String, default: ''}) readonly name!: string;
  @Prop({type: Boolean, default: false}) readonly emitValue!: string;

  @Inject({from: 'formDataOverride', default: null}) formDataOverride: any;

  selectedValue: any = null;
  query: string | null | number = null;
  cachedOptions: string[] | null = null;
  filteredOptions: any[] = [];
  timeoutRef: NodeJS.Timeout | null = null;
  Theme = Theme;

  mounted() {
    this.filteredOptions = this.options;
  }

  public get qField(): QSelect {
    return get(this, '$refs.qfield', {});
  }

  public get optionIndex() {
    return (this.qField as any) /* optionIndex is not public!!! */.optionIndex;
  }

  handleEnter() {
    if (this.allowCustomValue && this.multiple && this.query !== '') {
      this.qField.setOptionIndex(-1);
    }
  }

  public get getPlaceholder() {
    return this.selectedValue ? null : this.placeholder;
  }

  public filter(query: string) {
    this.query = query;
    (this.qField as QSelect).filter(query);
  }

  get displayValue() {
    try {
      return (this.options as OptionsWithLabel)
        .find(o => o.value?.toString() ===
          (this.selectedValue?.value?.toString() || this.selectedValue?.toString()))?.label;
    } catch (e) {
      return this.selectedValue?.label || this.selectedValue?.value?.toString() || this.selectedValue;
    }
  }

  @Watch('value', {immediate: true})
  onValueChanged(newVal: string) {
    if (this.emitValue && this.formDataOverride) {
      this.formDataOverride[this.name] = newVal;
    }

    // Sometimes values come as JSON strings
    try {
      newVal = JSON.parse(newVal);
    } catch {
    } finally {
      this.selectedValue = newVal;
      this.$emit('change', this.selectedValue);
    }
  }

  @Watch('options', {immediate: true})
  onOptionsChanged() {
    this.updateOptions();
  }

  openDropdown() {
    this.qField.showPopup();
  }

  closeDropdown() {
    this.qField.hidePopup();
  }

  get isCustomDisplay() {
    return !!this.$scopedSlots['custom-display'];
  }

  onInput(value: any) {
    this.selectedValue = value;
    this.$emit('input', value);
  }

  // eslint-disable-next-line no-unused-vars
  filterOptions(val: string, update: (fn: () => void) => void) {
    update(() => this.updateOptions(val));
  }

  updateOptions(query = this.query?.toString() || '') {
    if (query !== '' && this.reduceOptionsByQuery) {

      query = query.toLowerCase();

      // @ts-ignore
      this.filteredOptions = this.options?.filter((option: any) => {
        if (typeof option === 'string') {
          return option.toLowerCase().includes(query);
        } else if (option?.label) {
          return option.label.toLowerCase().includes(query);
        }
      });
    } else {
      this.filteredOptions = this.options;
    }
  }

  createValue(val: any, done: any) {
    if (!this.multiple) {
      return;
    }

    if (val.length > 0 && !this.filteredOptions.includes(val)) {
      done(val, 'add-unique');
    }
  }

  /*
    emphasiseQuery - highlights matches of query string in option

    EXAMPLE USAGE

      <template v-slot:option="scope">
      <q-item
        class="ws-places-input-item"
        v-bind="scope.itemProps"
        v-on="scope.itemEvents"
      >
        <q-item-section
          v-html="
            scope.emphasiseQuery(scope.opt)
          "
        ></q-item-section>
      </q-item>
    </template>
  */
  public emphasiseQuery(option: string): string {
    if (!this.query) {
      return option;
    }
    return `<span>${emphasiseQuery(option, escape(this.query.toString().trim()))}</span>`;
  }

  onInputValueChange(value: string) {
    this.query = value;

    if (this.allowCustomValue && !this.multiple) {
      this.selectedValue = this.query;
    }
  }

  onFocus() {
    if (this.openOptionsOnFocus) {
      // seTimeout to wait for QSelect to be ready, otherwise it is flickering
      // ref: https://loopiagroup.atlassian.net/browse/PNO-870?focusedCommentId=140895
      this.timeoutRef = setTimeout(() => {
        this.qField?.showPopup?.();
      }, 300);
    }
  }

  destroyed() {
    clearTimeout(this.timeoutRef!);
  }
}

export default WsSelect;
