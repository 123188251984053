import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { set } from 'lodash-es';
import { getItem, removeItem } from '@WS_UIkit';
import {
  AppState,
  TransferPaymentData,
} from './const';
import { store } from '@/store';

import { Domain } from '@/components/domain-checker/dc.types';
import { StorageKeys } from '@/services/const';
import { trackSearchResult } from '@/services/analytics.service';
import router, { safePush } from '@/router';
import {StateMutations, StoreActions} from '@/store/const.enum';
import {ROUTENAMES} from '@/const.enum';

let lastTldsHash: string; // for change detection

store?.watch(
  // watch tld for changs... on change fetch required fields
  (state, getters) => ({ state, tlds: getters.tlds }),
  ({ state, tlds }: { state: AppState; tlds: string[] }) => {
    const country = state?.cart?.domainProfile?.countryCode;
    const hash = tlds.toString() + '/' + country;
    if (!tlds.length || lastTldsHash === hash) {
      return;
    }
    lastTldsHash = hash;
    store.dispatch(StoreActions.FETCH_REQ_FIELDS, country);
  }
);

store?.watch(
  (state, getters) => getters.cardPayOnly,
  () => {
    store.dispatch(StoreActions.FETCH_PAYMENT_OPTIONS);
  }
);

// setup cart items hook
// more info: docs/embedding.md
const cartItemsCountSubject = new BehaviorSubject<number>(0);
const cartItemsCountObservable = cartItemsCountSubject
  .asObservable()
  .pipe(distinctUntilChanged());
set(window, '__WS_New_Order__.hooks.cartItemsCount', cartItemsCountObservable);

store?.watch(
  state => state?.cart?.items,
  items => {
    cartItemsCountSubject.next(items?.length || 0);

    let transferPaymentData: TransferPaymentData;
    if (!items) {
      return;
    }
    if (
      !items.length &&
      !store.state.cart.initial &&
      [ROUTENAMES.CART, ROUTENAMES.ORDER].includes(
        router.currentRoute.name as ROUTENAMES
      )
    ) {
      safePush({ name: ROUTENAMES.EMPTY_CART });
    }

    if (
      items.length &&
      (transferPaymentData = getItem(
        StorageKeys.transferPaymentData,
        true
      ) as TransferPaymentData) &&
      transferPaymentData.options
    ) {
      // Clear transfer data from localStorage and store
      removeItem(StorageKeys.transferPaymentData);
      store.commit(StateMutations.REMOVE_STATE, {
        prop: 'transferPaymentData',
      });
    }
  }
);

// tracking domain search
store?.watch(
  (state) => state.domainChecker?.domains[0],
  (domainSearchResult: Domain) => {
    if (
      !domainSearchResult ||
      domainSearchResult.name === store.state.domainChecker.previouslySearchedDomain
    ) {
      return;
    }
    store.commit(StateMutations.SET_PREVIOSLY_SEARCHED_DOMAIN, domainSearchResult.name);
    trackSearchResult({
      query: store.state.domainChecker.requestedDomains.join(', '),
      result: domainSearchResult,
    });
  }
);

const unwatchDevUtils = store?.watch(
  state => state.devUtils,
  devUtils => {
    if (!devUtils) {
      return;
    }

    // initialise developer service on forst devUtils turned on
    import(/* webpackChunkName: "developer" */ '@/services/developer.service');
    // setTimeout for case when immediate value is true
    setTimeout(() => unwatchDevUtils(), 0);
  },
  {
    immediate: true,
  }
);
