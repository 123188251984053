
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WsCarySpinner',
  props: {
    color: {
      type: String,
      default: 'dark',
    },
    width: {
      type: String,
      default: '80px',
    },
    height: {
      type: String,
      default: '30px',
    }
  },
});
