
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { QIcon } from 'quasar';
import { mdiInformationOutline } from '@quasar/extras/mdi-v4';

import { get } from 'lodash-es';
import WsBadge from '@WS_Components/WsBadge.vue';
import { PaymentOption, PaymentOptionGroup } from '@/types';
import { bankImages } from './bankImages';

interface PaymentOptionExtended extends PaymentOption {
  isDisabled: boolean;
}

const OPTIONS_ORDER = [
  'slsp',
  'vubeplatby',
  'postova',
  'csob',
  'tatrapay',
  'unicredit',
];

@Component({
  components: {
    WsBadge,
    QIcon,
  },
  data() {
    return {
      mdiInformationOutline,
    };
  },
})
export default class PaymentOptionBanking extends Vue {
  @Prop({ default: () => ({}) })
  readonly optionGroup!: PaymentOptionGroup;
  @Prop({ default: () => ({}) })
  readonly value!: PaymentOption | PaymentOptionExtended;
  @Prop(Boolean)
  readonly editMode!: boolean;
  @Prop(Boolean)
  readonly compact!: boolean;

  selectedOption: PaymentOptionExtended | null = null;
  imgs = bankImages;

  get optionsSorted(): PaymentOptionExtended[] {
    if (!this.editMode && this.value) {
      return this.optionGroup.paymentOptions
        .filter((opt: PaymentOption) => opt.id === this.value.id)
        .map((opt: PaymentOption) => ({
          ...opt,
          isDisabled: (opt.errors && !!opt.errors.length) || !opt.available,
        }));
    }

    const sortedOptions: PaymentOptionExtended[] = [];
    const unsorted: PaymentOptionExtended[] = [];
    this.optionGroup.paymentOptions.forEach((opt: PaymentOption) => {
      const index = OPTIONS_ORDER.indexOf(opt.id);
      const tmpOption: PaymentOptionExtended = {
        ...opt,
        isDisabled: (opt.errors && !!opt.errors.length) || !opt.available,
      };
      if (index > -1) {
        sortedOptions[index] = tmpOption;
      } else {
        unsorted.push(tmpOption);
      }
    });
    return sortedOptions.filter(opt => !!opt).concat(unsorted);
  }

  @Watch('value', { immediate: true })
  onValueChanged(newVal: PaymentOption) {
    if (!newVal) {
      this.selectedOption = null;
      return;
    }
    if (get(this.selectedOption, 'id') === newVal.id) {
      // already selected
      return;
    }
    this.autoSelect();
  }

  @Watch('optionGroup')
  autoSelect() {
    if (!this.optionsSorted) {
      return;
    }
    if (
      !this.editMode ||
      (!this.selectedOption && this.optionsSorted.length === 1)
    ) {
      this.selectedOption = this.optionsSorted[0];
      return;
    }
    if (this.value) {
      for (let i = 0; i < this.optionsSorted.length; i++) {
        const opt = this.optionsSorted[i];
        if (opt.id === this.value.id) {
          this.selectedOption = opt;
          break;
        }
      }
    }
  }

  selectOption(opt: PaymentOption) {
    if (opt.isDisabled || !this.editMode) {
      return;
    }
    this.$emit('selected', opt);
  }
}
