
import { Component, Inject, Vue } from 'vue-property-decorator';
import WsButton from '@WS_Components/WsButton.vue';
import HostingTutorialBanner from '@/components/hostingTutorial/HostingTutorialBanner.vue';
import {
  HOSTING_TUTORIAL_COOKIE,
  HOSTING_TUTORIAL_STEPS,
} from '@/services/const';

@Component({
  components: {
    WsButton,
    HostingTutorialBanner,
  },
})
export default class HostingTutorialPrompt extends Vue {
  @Inject(HOSTING_TUTORIAL_STEPS.CLOSE) private close!: Function;
  prompt: boolean = false;

  submitPrompt() {
    this.setCookie();
    this.close(false);
  }

  setCookie() {
    const cookie = `${HOSTING_TUTORIAL_COOKIE}=${this.prompt}`;
    const date = new Date();
    date.setDate(date.getDate() + 30);
    const expires = `expires=${date}`;
    const path = 'path=/';

    document.cookie = `${cookie};${expires};${path}`;
  }
}
