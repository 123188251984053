
import { Component, Vue } from 'vue-property-decorator';
import { QPage, QSkeleton } from 'quasar';
import { mapState } from 'vuex';
import WsSpinner from '@WS_Components/WsSpinner.vue';
import { Theme } from '@loopia-group/services';

import { CartExtended } from '@/store/const';

import OrderStepper from '@/components/OrderStepper.vue';
import WsButton from '@WS_Components/WsButton.vue';

@Component({
  components: {
    WsSpinner,
    WsButton,
    QPage,
    QSkeleton,
    OrderStepper,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class OrderLayout extends Vue {
  readonly cart!: CartExtended;
  Theme = Theme;

  get loading() {
    return this.cart.initial;
  }
}
