
import { Component, Prop, Vue } from 'vue-property-decorator';
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import { QCard, QCardSection } from 'quasar';

@Component({
  components: {
    QCard,
    QCardSection,
    WsIcon,
    WsCheckbox,
    WsStateBanner,
  },
})
export default class HostingTutorialBanner extends Vue {
  @Prop({ default: false }) declare readonly value: boolean;

  get localValue(): boolean {
    return this.value;
  }

  emitValue(value: boolean): void {
    this.$emit('input', value);
  }
}
