
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QCardSection, QIcon, QTooltip } from 'quasar';
import { mdiPencil } from '@quasar/extras/mdi-v4';
import { mapState, mapGetters } from 'vuex';

import { round, get } from 'lodash-es';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsEdit from '@WS_Components/WsEdit.vue';
import WsCard from './WsCard.vue';
import { Cart, DomainProfile } from '@/types';
import { COMPLETED } from '@/store/const';
import { safePush } from '@/router';
import { STEPS } from '@/services/const.enum';
import { normalizeDomainProfile } from '@/services/profiles.service';
import { isSwedishWorkflow } from '@/services/config.service';
import ProfileInfo from './ProfileInfo.vue';
import PaymentOptions from './paymentOptions/PaymentOptions.vue';

@Component({
  components: {
    QCardSection,
    QIcon,
    WsEdit,
    ProfileInfo,
    PaymentOptions,
    WsCard,
    WsIcon,
    QTooltip,
  },
  computed: {
    ...mapState(['cart', 'domainProfiles', 'inEditMode']),
    ...mapGetters([
      'progressByUser',
      'loggedIn',
      'allDomainProfilesDefined',
      'stepHasIssues',
    ]),
  },
})
export default class SideSummary extends Vue {
  @Prop() readonly editCb?: () => {};
  readonly cart!: Cart;
  readonly loggedIn!: boolean;
  domainProfiles!: DomainProfile[];
  trueValue = true;
  isSwedishWorkflow = isSwedishWorkflow;

  data() {
    return {
      mdiPencil,
      STEPS,
      COMPLETED,
    };
  }

  get domainProfile() {
    return this.isSwedishWorkflow
      ? get(this.domainProfiles, '[0]', null)
      : normalizeDomainProfile(this.cart.domainProfile || null);
  }

  get priceVat(): number {
    const vatIncluded = get(this, 'cart.summary.price.vatIncluded', 0);
    const vatExcluded = get(this, 'cart.summary.price.vatExcluded', 0);
    return round(vatIncluded - vatExcluded, 2);
  }

  goTo(routeName: string) {
    safePush({ name: routeName });
  }
}
