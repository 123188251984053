
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import { QBtn } from 'quasar';

import { redirect } from '@loopia-group/utils';
import WsDelimiter from '@WS_Components/WsDelimiter.vue';
import WsButton from '@WS_Components/WsButton.vue';
import { STEPS } from '@/services/const.enum';
import { getLoginUrl } from '@/utilities';
import config, { AppConfig } from '@/services/config.service';
import { navigateToStep } from '@/services/order.service';
import logoWebsupportSvg from '@/assets/images/ws-logo-full.svg';
import LogoA24Svg from '@/assets/images/A24/a24-logo-black.svg';
import LogoLoopiaSvg from '@/assets/images/loopia-logo-full.svg';
import A24loginFooterImg from '@/assets/images/A24/a24-footer-placeholder.png';
import A24loginFooterImg2x from '@/assets/images/A24/a24-footer-placeholder@2x.png';
import loginFooterImg from '@/assets/images/Cary/cary-help.png';
import {StoreActions} from '@/store/const.enum';
import {ROUTENAMES} from '@/const.enum';
import { Theme } from '@loopia-group/services';
import { computed } from 'vue';

@Component({
  components: {
    WsDelimiter,
    WsButton,
    QBtn,
  },
  computed: {
    ...mapGetters(['orderSteps']),
  },
})
export default class LoginSection extends Vue {
  orderSteps!: STEPS[];
  config: AppConfig = config;
  footerTextUrl = config.supportUrl || 'https://www.google.com';
  loadingRedirect = false;
  loadingGoNext = false;

  data() {
    const currentTheme = (this as any).currentTheme();

    const getImages = computed(() => {
      switch (currentTheme) {
      case Theme.ACTIVE_24:
        return {
          logo: {
            'src': LogoA24Svg,
            'alt': 'Active24 logo',
            'class': 'q-mb-sm',
          },
          footerImage: {
            'srcset': A24loginFooterImg2x,
            'src': A24loginFooterImg,
            'alt': 'Active24 placeholder',
            'class': 'footer-img',
          }};
      case Theme.LOOPIA:
        return {
          logo: {
            'src': LogoLoopiaSvg,
            'alt': 'Loopia logo',
            'class': 'q-mb-sm',
          },
          footerImage: {}
        };
      case Theme.CARY:
      default:
        return {
          logo: {
            'src': logoWebsupportSvg,
            'alt': 'Websupport logo',
            'class': 'logo',
          },
          footerImage: {
            'srcset': loginFooterImg,
            'src': loginFooterImg,
            'alt': 'heroes',
            'class': 'footer-img',
          }
        };
      }
    });

    const { logo, footerImage } = getImages.value;
    return {
      ROUTENAMES,
      logo,
      footerImage
    };
  }

  goToLogin() {
    this.loadingRedirect = true;
    // TODO: parametrize redirect url from outside somehow
    if (this.$route.name === ROUTENAMES.DOMAIN_CHECKER) {
      redirect(getLoginUrl(this.$router, ROUTENAMES.DOMAIN_CHECKER));
    } else {
      // return back to CART (https://loopiagroup.atlassian.net/browse/PNO-2156)
      redirect(getLoginUrl(this.$router, ROUTENAMES.CART));
    }
  }

  goNext() {
    this.loadingGoNext = true;
    this.$store.dispatch(StoreActions.SET_USER_STEP, {
      step: this.orderSteps[1],
    });
    navigateToStep(this.orderSteps[1]).finally(
      () => (this.loadingGoNext = false)
    );
  }
}
