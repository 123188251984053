import Router from 'vue-router';
import {ROUTENAMES} from '@/const.enum';
import CartView from './views/CartView.vue';
import OrderView from './views/OrderView.vue';
import EmptyCartView from './views/EmptyCart.vue';
import OrderLayout from '@/views/OrderLayout.vue';

export const setupCheckout = (router: Router) => {
  setupRoutes(router);
};

const setupRoutes = (router: Router) => {
  if (router) {
    router.addRoute({
      name: ROUTENAMES.CHECKOUT,
      path: '/',
      component: OrderLayout,
      children: [
        {
          path: '',
          name: ROUTENAMES.CART,
          component: CartView,
        },
        {
          name: ROUTENAMES.ORDER,
          path: ROUTENAMES.ORDER,
          component: OrderView,
        },
        {
          name: ROUTENAMES.EMPTY_CART,
          path: ROUTENAMES.EMPTY_CART,
          component: EmptyCartView
        },
      ],
    });
  }
};
