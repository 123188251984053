
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QTooltip } from 'quasar';
import { TranslateResult } from 'vue-i18n';

import { wsClickOutside } from '@loopia-group/utils';
import WsIcon from '@WS_Components/WsIcon.vue';
import { Price } from '../dc.types';
import { CartItem } from '@/types';

type CartItemCustomPrice = CartItem['price'] & { prolongPrice: number };

@Component({
  components: {
    WsIcon,
    QTooltip,
  },
  directives: {
    wsClickOutside,
  },
})
export default class PriceCell extends Vue {
  @Prop()
  readonly price!: Price | CartItemCustomPrice;
  @Prop({ type: Number, required: true })
  readonly period!: number;
  @Prop({ type: Boolean, default: true })
  readonly showPeriod!: boolean;
  @Prop(Number)
  readonly prolongPeriod!: number;
  @Prop(Boolean)
  readonly transfer!: boolean;

  get unifiedPrice(): Price {
    if ((this.price as Price).actualPrice) {
      return this.price as Price;
    }
    const price = this.price as CartItemCustomPrice;
    return {
      actualPrice: price.vatExcluded || 0,
      marketingPrice: price.originalPrice ?? null,
      prolongPrice: price.prolongPrice || null,
      transferPrice: 0,
    };
  }

  get hasMarketingPrice() {
    const marketingPrice = this.unifiedPrice.marketingPrice;
    return (
      typeof marketingPrice === 'number' &&
      this.unifiedPrice.actualPrice < marketingPrice
    );
  }

  get hasProlongPrice() {
    const prolongPrice = this.price.prolongPrice;
    return (
      typeof prolongPrice === 'number' &&
      prolongPrice !== this.unifiedPrice.actualPrice
    );
  }

  get calcPeriod(): TranslateResult {
    const period = this.period;
    const periodInMonths = period % 12;
    return this.$t(
      periodInMonths ? 'wsk.general.period.months' : 'wsk.general.period.years',
      {
        period: periodInMonths ? period : period / 12,
        _fallback: 'cart.per_period',
      }
    );
  }

  get calcProlongPeriod(): TranslateResult {
    const period = this.prolongPeriod || this.period;
    const periodInMonths = period % 12;
    return this.$t(
      periodInMonths
        ? 'wsk.general.period.next.months'
        : 'wsk.general.period.next.years',
      {
        period: periodInMonths ? period : period / 12,
        _fallback: 'cart.next_period',
      }
    );
  }

  hideTooltip() {
    (this.$refs.tooltip as any)?.hide();
  }
}
