import { render, staticRenderFns } from "./WsBackdrop.vue?vue&type=template&id=0c77dd0f&scoped=true"
import script from "./WsBackdrop.vue?vue&type=script&lang=ts"
export * from "./WsBackdrop.vue?vue&type=script&lang=ts"
import style0 from "./WsBackdrop.vue?vue&type=style&index=0&id=0c77dd0f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c77dd0f",
  null
  
)

export default component.exports