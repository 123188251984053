
import {QDialog, QBtn} from 'quasar';
import {mdiClose} from '@quasar/extras/mdi-v4';
import {computed, defineComponent} from 'vue';
export default defineComponent({
  name: 'WsModal',
  components: {
    QDialog,
    QBtn,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cfg: {
      type: Object,
      default: () => ({
        title: '',
      }),
    },
  },
  defineEmits: ['input'],
  setup(props, vm) {
    const attrs = computed(() => {
      const contentClass = vm.attrs['content-class']
        ? ` ${vm.attrs['content-class']}`
        : '';
      return {
        ...vm.attrs,
        ['content-class']: `wsk-root wsk-app${contentClass}`,
      };
    });

    return {attrs, mdiClose};
  }
});
