
import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import { getSnakedKeyFactory } from '@loopia-group/utils';
import { Subscription, fromEvent } from 'rxjs';
import { throttle, debounceTime } from 'rxjs/operators';
import { WORKFLOW } from '@/const.enum';
import { STEPS } from '@/services/const.enum';
import config from '@/services/config.service';
import { stepEnabled, navigateToStep } from '@/services/order.service';

@Component({
  computed: {
    ...mapState(['cart']),
    ...mapGetters(['progressByUser', 'orderSteps', 'editBackdropActive']),
  },
})
export default class OrderStepper extends Vue {
  orderSteps!: STEPS[];
  progressByUser!: Record<STEPS, number>;

  config = config;
  stepsKeyPrefix = 'cart.steps.';

  stepperTopOffset: number = 0;
  navElements: NodeListOf<Element> | undefined = undefined;
  observers: Array<MutationObserver> = [];

  resizeEvent: Subscription | null = null;

  data() {
    return {
      stepEnabled,
      navigateToStep,
    };
  }

  mounted() {
    this.setupStepper();
    const stream = fromEvent(window, 'resize');
    const controllerStream = stream.pipe(debounceTime(50));
    this.resizeEvent = stream
      .pipe(
        throttle(() => controllerStream, {
          leading: true,
          trailing: true,
        })
      )
      .subscribe(this.setStepperOffset);
  }

  //!FALBACK to be deleted after settlement about header class
  destroyed() {
    this.observers.forEach(obs => obs.disconnect());
    if (this.resizeEvent) {
      this.resizeEvent.unsubscribe();
    }
  }
  //------>end of fallback

  get visibleSteps() {
    return this.orderSteps.filter(step =>
      config.workflow === WORKFLOW.SWEDISH
        ? step !== STEPS.BILLING_PROFILE
        : step !== STEPS.DOMAIN_PROFILE
    );
  }

  setupStepper() {
    //!FALBACK to be deleted after settlement about header class
    const el = document.querySelectorAll('.new-order-spa-header');
    if (!el.length) {
      this.hookFalbackClasses();
    }
    //------>end of fallback

    this.setStepperOffset();
  }

  setStepperOffset() {
    let topNavsHeight = 0;
    if (!this.navElements?.length) {
      return;
    }
    this.navElements.forEach((element: any) => {
      topNavsHeight += element.offsetHeight;
    });

    this.stepperTopOffset = topNavsHeight - 2; // minus 2px correction
  }

  get stepperStyle() {
    //!FALBACK to be deleted after settlement about header class
    const newOrderHeader = document.querySelectorAll('.new-order-spa-header');
    //------>end of fallbackend of fallback

    const isSticky = this.$route.name !== 'cart' || !newOrderHeader.length;

    return {
      top: (isSticky ? this.stepperTopOffset : '0') + 'px',
      position: isSticky ? 'sticky' : 0,
    };
  }

  getKey(key: string): string {
    this.getKey = getSnakedKeyFactory('cart.steps', (keys: string[]) => {
      if (keys[0] === 'domain-profile') {
        keys[0] = 'user_information';
      }
      return keys;
    });
    return this.getKey(key);
  }

  //!Fallback to be deleted after settlement about header class
  hookFalbackClasses() {
    const selectors =
      this.config.company === 'se-ws'
        ? '.header-fixed'
        : '.hf .menu__padding nav';
    this.navElements = document.querySelectorAll(selectors);
    for (const el of this.navElements) {
      const observer = new MutationObserver(this.setStepperOffset);
      observer.observe(el, { attributes: true });
      this.observers.push(observer);
    }
  }
  //------>end of fallback
}
