import { render, staticRenderFns } from "./WsToggle.vue?vue&type=template&id=4fd2f722&scoped=true"
import script from "./WsToggle.vue?vue&type=script&lang=ts"
export * from "./WsToggle.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd2f722",
  null
  
)

export default component.exports