
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isItemBundle } from './CartItemBundle.util';

import { CartItem as CartItemType } from '@/types';
import { STEPS } from '@/services/const.enum';
import CartItem from '@/modules/checkout/components/cart/CartItem.vue';
import CartItemBundle from './CartItemBundle.vue';

@Component({
  components: {
    CartItemBundle,
    CartItem,
  },
})
export default class CartItemRow extends Vue {
  @Prop({ default: () => ({}) }) readonly item!: CartItemType;
  @Prop(String) readonly path!: string;
  @Prop(Boolean)
  readonly bordered!: boolean;

  get isItemBundle() {
    return isItemBundle(this.item);
  }

  get itemExtended() {
    // preparation for bundles
    return { ...this.item, isDisabled: this.isDisabled };
  }

  // has curently throwed error, does not include errors by flags in item properties
  get hasError(): boolean {
    return this.item?.id
      ? this.$messageService.hasMessages(STEPS.CART + '.' + this.item.id, {
        recursive: true,
      })
      : false;
  }

  get isDisabled() {
    return this.hasError;
  }
}
