
import { Component, Vue } from 'vue-property-decorator';
import { Theme } from '@loopia-group/services';

@Component({})
export default class ThemeSwitcher extends Vue {
  Theme = Theme;

  switchTheme = (theme: string) => {
    // @ts-ignore
    window.switchTheme(theme);
  };
}
