import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import network from './network.service';
import { axiosConfig } from './network.service';

// TODO: oboe - really no types?
// @ts-ignore
import oboe from 'oboe';

import { ProductBundle, StandaloneProduct } from '@/types';
import store from '@/store';
import {
  addBundleToCart as bundleToCart,
  addToCart,
  removeFromCart,
} from '@/services/cart/cart-api.service';
import { Domain, DomainBundle } from '@/components/domain-checker/dc.types';
import { trackSearch } from '@/services/analytics.service';
import {StateMutations} from '@/store/const.enum';

// needed for mocking! Uncomment if needed
// import { mockStreamingResponse } from '@/services/mocks';

let domainCheckProcess: any = null;

export function getDomainCheckerCategories(): AxiosPromise<
  Record<string, any>
  > {
  const networkObject: AxiosRequestConfig = {
    method: 'get',
    url: '/domain-checker/tld-categories',
  };

  return network(networkObject).then(
    (response): AxiosResponse => {
      return response.data;
    }
  );
}

export function stopRunningCheck() {
  // abort previous already running process
  domainCheckProcess?.abort?.();
  domainCheckProcess?.reject?.('ws-abort');

  store.commit(StateMutations.RESET_DOMAIN_CHECKER);
}

const urlGarbageRegexp = /^((http(s)?:\/\/)?(www\.)?)(.+\..+)/;
// eslint-disable-next-line max-lines-per-function
export function checkDomains(
  isNewSearch: boolean,
  domains: string[],
  page: number = 1,
  perPage: number = 10
): Promise<any> {
  if (!domains.length) {
    return Promise.reject('no domain to check');
  }

  const domainsParams = new URLSearchParams();
  domains.map((domainName: string) => {
    // clean domain from unnecessary stuff like www., https://, ...
    // ref: https://loopiagroup.atlassian.net/browse/PNO-2804
    domainName = domainName.replace(urlGarbageRegexp, '$5');
    domainsParams.append('domains[]', domainName);
  });

  // Pagination
  domainsParams.append('page', '' + page);
  domainsParams.append('per-page', '' + perPage);

  const url =
    axiosConfig.baseURL + '/domain-checker/check-domains?' + domainsParams;

  if (isNewSearch) {
    stopRunningCheck();
    setDomainCheckerState('requestedDomains', domains);

    trackSearch({
      query: domains.join(','),
    });
  }

  return new Promise((resolve, reject) => {
    // http://oboejs.com/examples
    changeDomainSearchingStatus(true);

    // Uncomment to mock response on your own
    // setTimeout(() => { // delay to check loading state
    //   resolve(
    //     mockStreamingResponse(store, {
    //       changeDomainSearchingStatus,
    //       setDomainCheckerEndOfStream,
    //     })
    //   );
    // }, 3000);
    // return;

    // eslint-disable-next-line no-unreachable
    domainCheckProcess = oboe({
      method: 'GET', // optional
      headers: {
        ...axiosConfig.headers.common,
        accept: 'application/json, text/plain, */*',
      }, // optional
      withCredentials: axiosConfig.withCredentials, // optional, browser only
      url,
    })
      .node('!.*', (domainThing: Domain) => {
        // This callback will be called everytime a new object is
        // found in the domains array.
        // TODO: dispatch action - add to store
        store.commit(StateMutations.ADD_DOMAIN_RESULT, domainThing);
      })
      .done((things: Domain[]) => {
        // http://oboejs.com/api#done-event
        changeDomainSearchingStatus(false);

        if (things.length < perPage) {
          setDomainCheckerEndOfStream(true);
        }
        // TODO: maybe we don't need to resolve with whole object, because we parsed it by small parts before
        resolve(things);
      })
      .fail((error: any) => {
        // TODO: better typecheck for 'error'
        // http://oboejs.com/api#fail-event
        changeDomainSearchingStatus(false);
        setDomainCheckerEndOfStream(true);
        reject(error);
      });

    // dirty hack, since documentation for oboe gone somewhere to unknown
    // I didn't find a way how to listen to abortion
    domainCheckProcess.reject = reject;
  });
}

export function addDomainToCart(
  domain: Domain,
  additionalProductProps: Partial<StandaloneProduct> = {}
): Promise<any> {
  const domainProduct: StandaloneProduct = {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    code: 'domain-register',
    name: `.${domain.tld} doména`,
    sellType: 'direct',
    price: {
      vatExcluded: domain.price?.actualPrice || 0,
    },
    properties: {
      domain: domain.name,
      period: domain.period,
    },
    ...additionalProductProps,
  };

  return addToCart(domainProduct)
    .then(response => {
      setDomainInCart(domain.name, true);

      // Successfuly added to cart
      return Promise.resolve(response.data);
    })
    .catch(({ response }) => {
      return Promise.reject(response.data);
    });
}

export function removeDomainFromCart(domain: Domain): Promise<any> {
  const domainCartItem = store.getters.dcFindDomainInCart(domain);
  return removeFromCart(domainCartItem)
    .then(response => {
      setDomainInCart(domain.name, false);

      return Promise.resolve(response.data);
    })
    .catch(({ response }) => {
      return Promise.reject(response.data);
    });
}

export function addBundleToCart(bundle: DomainBundle): Promise<any> {
  const mainDomain = bundle.mainDomain;
  const discountedDomain = bundle.discountedDomain;
  const bundleProduct: ProductBundle = {
    products: [
      {
        code: 'domain-register',
        name: `.${mainDomain.tld} doména`,
        sellType: 'direct',
        price: {
          vatExcluded: mainDomain.price?.actualPrice || 0,
        },
        properties: {
          domain: mainDomain.name,
          period: mainDomain.period,
        },
      },
      {
        code: 'domain-register',
        name: `.${discountedDomain.tld} doména`,
        sellType: 'direct',
        price: {
          vatExcluded: discountedDomain.price?.actualPrice || 0,
        },
        properties: {
          domain: discountedDomain.name,
          period: discountedDomain.period,
        },
      },
    ],
  };

  return bundleToCart(bundleProduct)
    .then(response => {
      setDomainInCart(bundle.mainDomain.name, true);
      setDomainInCart(bundle.discountedDomain.name, true);

      // Successfuly added to cart
      return Promise.resolve(response.data);
    })
    .catch(({ response }) => {
      return Promise.reject(response.data);
    });
}

export function removeBundleFromCart(bundle: DomainBundle): Promise<any> {
  return Promise.all(
    [bundle.mainDomain, bundle.discountedDomain].map(domain => {
      const cartItem = store.getters.dcFindDomainInCart(domain);
      return removeFromCart(cartItem)
        .then(response => {
          setDomainInCart(domain.name, false);
          return Promise.resolve(response.data);
        })
        .catch(({ response }) => {
          return Promise.reject(response.data);
        });
    })
  );
}

export function setDomainInCart(domain: string, inCart: boolean) {
  store.commit(StateMutations.CHANGE_DOMAIN_STATE_IN_CART, {
    domainName: domain,
    inCart,
  });
}

export function getTldCategories(): Promise<AxiosResponse> {
  return network.get('/domain-checker/tld-categories');
}

export function setDomainCheckerEndOfStream(value: boolean) {
  setDomainCheckerState('endOfStream', value);
}

function changeDomainSearchingStatus(value: boolean) {
  setDomainCheckerState('searching', value);
}

function setDomainCheckerState(prop: string, value: any) {
  store.commit(StateMutations.SET_STATE, {
    path: 'domainChecker',
    prop,
    value,
  });
}

export function getBundles(domains: string[]): Promise<DomainBundle[]> {
  if (!domains.length) {
    return Promise.resolve([]);
  }

  const domainsParam = new URLSearchParams();
  domains.map((domainName: string) => {
    domainsParam.append('domains[]', domainName);
  });

  return network
    .get('/domain-checker/check-bundles?' + domainsParam)
    .then(result => {
      return Promise.resolve(result.data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
