
import {computed, defineComponent, inject, ref, watch, PropType} from 'vue';
import {useTranslation} from '@/WS_UIkit/src/composables/translation';
import WsButton from '@WS_Components/WsButton.vue';
import wordpressNice from '@/assets/images/wordpress-nice.svg';
import loopiaLogo from '@/assets/images/loopia-logo.svg';
import woocommerce from '@/assets/images/woocommerce.svg';
import xss from 'xss';
import {CategoryItemConfig} from '@/modules/iwp/components/Items/ProductItem';
import {SelectedProduct} from '@/modules/iwp/views/Product';


export default defineComponent({
  name: 'ProductItem',
  components: {WsButton},
  props:  {
    item: { type: Object as PropType<CategoryItemConfig>, default: () => {}},
  },
  setup (props) {
    const {$t} = useTranslation();
    const itemTitle = computed(() => $t('modules.i_want_product.' + props.item?.name + '.title'));

    const selectedProduct = inject('iwpProvider') as SelectedProduct;
    const currentlySelectedProduct = selectedProduct.selectedProduct || ref('');
    const updateSelectedProduct = selectedProduct.updateSelectedProduct;


    const isSelected = ref(false);
    const isRecommended = ref(props.item.name.includes('siteBuilder'));

    const logoSrc = computed(() => {
      return props.item?.name.includes('siteBuilder') ?
        loopiaLogo :
        (props.item?.name.includes('nonEcommerce') ? wordpressNice : woocommerce);
    });

    const selectedBtnLabel = computed(() => {
      return isSelected.value ?
        'modules.i_want_product.website.remove_btn_label' :
        'modules.i_want_product.website.select_btn_label';
    });

    const updateSelected = () => {
      updateSelectedProduct(props.item?.name);
    };

    watch(currentlySelectedProduct, () => {
      isSelected.value = props.item.name === currentlySelectedProduct.value.toString();
    });

    return {
      isRecommended,
      isSelected,
      itemTitle,
      logoSrc,
      selectedBtnLabel,

      updateSelected,
    };
  },
  methods: {xss}
});
