
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { QSkeleton } from 'quasar';
import xss from 'xss';
import { trackPaymentMethod } from '@/services/analytics.service';

import {
  Cart,
  CartItem,
  PaymentOption,
  PaymentOptionGroup,
  SetPaymentOptionRequest,
} from '@/types';
import { get } from 'lodash-es';
import WsRadioList from '@WS_Components/WsRadioList.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import { StoreActions } from '@/store/const.enum';
import store from '@/store';
import { setPaymentOption } from '@/services/cart/cart-api.service';
import PaymentOptionCard from './PaymentOptionCard.vue';
import PaymentOptionBanking from './PaymentOptionBanking.vue';
import { AxiosResponse } from 'axios';
import { INVOICE_DELIVERY_TYPE, PAY_OPTIONS, ProcessedOption } from './const';
import {useCartItemsStore} from '@/store/cartItemsStore';

@Component({
  components: {
    WsRadioList,
    WsIcon,
    QSkeleton,
  },
  computed: {
    ...mapState(['cart', 'paymentOptions']),
  },
})
export default class PaymentOptions extends Vue {
  @Prop({ default: () => {} })
  readonly value!: SetPaymentOptionRequest;
  @Prop()
  readonly path!: string;
  @Prop(Boolean)
  readonly editMode!: boolean;
  @Prop(Boolean)
  readonly hideRadio!: boolean;
  @Prop(Boolean)
  readonly compact!: boolean;

  readonly cart!: Cart;

  cartItemsStore = useCartItemsStore();
  paymentOptions!: PaymentOptionGroup[];
  selectedGroup: PaymentOptionGroup | null = null;
  selectedOption: PaymentOption | null = null;
  cmps: { [key: string]: any /* Vue */ } = {
    card: PaymentOptionCard,
    banking: PaymentOptionBanking,
  };
  PAY_OPTIONS = PAY_OPTIONS;
  INVOICE_DELIVERY_TYPE = INVOICE_DELIVERY_TYPE;
  xss = xss;

  get loading(): boolean {
    return !this.paymentOptions?.length;
  }

  get cardPayOnly(): boolean {
    return this.cartItemsStore.cardPayOnly;
  }

  get groupHasComponent(): boolean {
    const id = this.selectedGroup?.id;
    return !!(id && this.cmps[id]);
  }

  get atLeastOneDomainInCart(): boolean {
    return !!this.cart.items?.find(
      (item: CartItem) => item.code === 'domain-register' || item.code === 'domain-transfer'
    );
  }

  get groupHasChildrenOptions(): boolean {
    return !!this.selectedGroup?.paymentOptions?.length;
  }

  get childOptionOnly(): boolean {
    // display only child option without parent title, radio or paddings.. etc..
    return !!(
      this.groupHasComponent &&
      this.selectedOption &&
      !this.specialIdIsSelected
    );
  }

  get specialIdIsSelected(): boolean {
    return ['new-card-nets-easy'].includes(get(this.selectedOption, 'id', ''));
  }

  get optionsProcessed(): ProcessedOption[] {
    const options = this.paymentOptions;
    if (!options || !options.length) {
      return [];
    }
    let optionsProcessed = [];

    if (this.cardPayOnly) {
      optionsProcessed = options.filter(opt => {
        return opt.id === PAY_OPTIONS.CARD;
      });
    } else if (!this.editMode) {
      if (get(this.value, 'groupId')) {
        optionsProcessed = options.filter(opt => {
          return opt.id === get(this.value, 'groupId');
        });
      } else {
        optionsProcessed = [options[0]];
      }
    } else {
      optionsProcessed = options.map(opt => {
        return { ...opt, disable: this.isDisabled(opt) };
      });
    }
    return optionsProcessed;
  }

  created() {
    this.loadOptions();
  }

  @Watch('editMode')
  onEditModeChanged(editMode: boolean) {
    if (editMode) {
      this.loadOptions().then(() => {
        this.emitInput();
      });
    }
  }

  loadOptions() {
    return store
      .dispatch(StoreActions.FETCH_PAYMENT_OPTIONS)
      .catch(this.$messageService.errorHandler(this.path));
  }

  isDisabled(opt: PaymentOptionGroup) {
    const theOnlyOption: any =
      opt.paymentOptions?.length === 1 && opt.paymentOptions?.[0];
    return !opt.available || (!!theOnlyOption && !theOnlyOption.available);
  }

  // handling root selection
  @Watch('selectedGroup')
  onGroupChange(selectedGroup: PaymentOptionGroup) {
    if (!selectedGroup || selectedGroup.id === PAY_OPTIONS.TRANSFER) {
      this.selectedOption = null;
    } else if (
      selectedGroup.paymentOptions &&
      selectedGroup.paymentOptions.length === 1
      // preselect first option, if there is only one..
      // some option group even do not display sub options, but have one
    ) {
      this.selectedOption = selectedGroup.paymentOptions[0];
    } else if (
      this.selectedOption &&
      !selectedGroup.paymentOptions.find(
        opt => opt.id === this.selectedOption!.id
      )
    ) {
      this.selectedOption = null;
    }
    this.emitInput();
  }

  @Watch('selectedOption')
  @Watch('selectedOption.recurrenceState')
  onOptionChange() {
    this.emitInput();
  }

  @Watch('paymentOptions')
  onOptionsChange() {
    // allow update selected with new flags from BE (recurrenceCanBeChanged, etc..) by
    // reselecting same ids (group, opt) in new options provided
    this.select(this.getRequestData());
  }

  @Watch('value', { immediate: true })
  onValueChange(value?: SetPaymentOptionRequest) {
    if (value) {
      this.autoSelect(value);
    }
  }

  @Watch('optionsProcessed', { immediate: true })
  onOptionsProcessedChange(optionsProcessed?: ProcessedOption[]) {
    if (get(optionsProcessed, 'length') && this.value) {
      this.autoSelect(this.value);
    }
  }

  emitInput() {
    this.$emit('input', this.getRequestData());
  }

  getRequestData(): SetPaymentOptionRequest {
    return {
      groupId: this.selectedGroup?.id || null,
      optionId: this.selectedOption?.id || null,
      recurrence: this.selectedOption?.recurrenceState || null,
    };
  }

  autoSelect(value: SetPaymentOptionRequest) {
    if (
      // options not provided
      !this.optionsProcessed.length ||
      // already selected?
      (value.groupId &&
        get(this.selectedGroup, 'id') === value.groupId &&
        get(this.selectedOption, 'id') === value.optionId)
    ) {
      // do not select
      return;
    } else if (value.groupId) {
      this.select(value);
    } else if (this.optionsProcessed.length && !this.selectedGroup) {
      this.selectedGroup = this.optionsProcessed[0];
    }
  }

  select(value: SetPaymentOptionRequest) {
    const valueGroupId = value.groupId;
    const valueOptId = value.optionId;
    this.optionsProcessed.some((group: PaymentOptionGroup) => {
      if (group.id === valueGroupId) {
        this.selectedGroup = group;
        this.selectedGroup.paymentOptions.some((opt: PaymentOption) => {
          if (opt.id === valueOptId) {
            this.selectedOption = opt;
            if (value!.recurrence !== undefined && value!.recurrence !== null) {
              this.selectedOption.recurrenceState = value!.recurrence;
            }
            return true;
          }
          this.selectedOption = null;
          return false;
        });
        return true;
      }
      return false;
    });
  }

  // handling sub-options selections from PaymentOptionGroups
  onSubSelected(newSelected: PaymentOption) {
    this.selectedOption = newSelected;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  savePaymentOption(): Promise<AxiosResponse> {
    const selectedGroup = this.selectedGroup;
    if (
      !selectedGroup ||
      // transfer does not require selected sub option
      (!this.selectedOption && selectedGroup.id !== PAY_OPTIONS.TRANSFER)
    ) {
      if (selectedGroup && !this.selectedOption) {
        if (selectedGroup.id === PAY_OPTIONS.CARD) {
          return Promise.reject('validation.payment_option.not_set_card');
        } else if (selectedGroup.id === PAY_OPTIONS.BANKING) {
          return Promise.reject('validation.payment_option.not_set_banking');
        }
      }
      return Promise.reject('validation.payment_option.not_set');
    }

    const selectedOption =
      this.selectedOption || selectedGroup.paymentOptions[0];
    // ITDEV-8159 - Track selected payment method separately from purchase
    trackPaymentMethod(selectedGroup.id);

    return setPaymentOption({
      groupId: selectedGroup.id,
      optionId:
        selectedGroup.id === PAY_OPTIONS.TRANSFER ? null : selectedOption.id,
      recurrence: selectedOption.recurrenceState,
    } as SetPaymentOptionRequest);
  }
}
