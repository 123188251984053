import {defineStore} from 'pinia';
import {Ref, ref, shallowRef, triggerRef} from 'vue';

import {IwpTreeBranch, IwpTreeConfig} from './index.d';
import { messageService } from '@/services/message.service';
import axios from '@/services/network.service';
import config from '@/services/config.service';
export let iwpInitConfig: IwpTreeConfig;

export const initConfig = (newConfig: IwpTreeConfig) => {
  iwpInitConfig = newConfig;
};

export const useIwpStore = defineStore('iwp', () => {
  // IWP products
  const products = shallowRef({});

  // IWP config
  const iwpConfig: Ref<IwpTreeConfig> = ref(iwpInitConfig);

  const isLoading: Ref<boolean> = ref(true);

  const getIWPNode = (path: string): IwpTreeBranch => {
    let iwpNode: IwpTreeConfig = iwpConfig.value;
    path.split('.').forEach((pathNode: string) => {
      iwpNode = iwpNode?.childNodes?.[pathNode] ?? {};
    });
    return iwpNode as IwpTreeBranch;
  };

  const getProducts = async () =>  {
    try {
      // TODO make productCategory dynamic upon  using it for other product categories
      setTimeout(() => console.log(), 3000);
      const data = await axios(config.apiUrl + '/product-tutorial/', {
        method: 'get',
        params: {
          productCategory: 'website',
        }
      });
      Object.assign(products.value, data.data);
      triggerRef(products);
      isLoading.value = false;
    } catch(err) {
      messageService.errorHandler()(err);
    }
  };

  return {
    iwpConfig,
    isLoading,
    products,
    getIWPNode,
    getProducts,
  };
});
