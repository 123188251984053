
import {QDrawer} from 'quasar';

import WsButton from './WsButton.vue';
import {omit} from 'lodash-es';

import {defineComponent, onMounted, computed} from 'vue';

export default defineComponent({
  name: 'WsDrawer',
  components: {QDrawer, WsButton},
  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
    },
    fullHeightContent: {
      type: Boolean,
    },
    preventClose: {
      type: Boolean,
    },
  },

  setup(props, vm)  {
    onMounted(() => {
      vm.emit('mounted');
    });

    const listeners = computed(() => omit({...vm.listeners}, 'input'));

    const handleDrawerVisibility = (state = false) =>  {
      vm.emit('input', (!state && props.preventClose) || state);
    };

    return {
      listeners,
      handleDrawerVisibility
    };
  }

});
