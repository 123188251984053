var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-x-sell-banner row content-center"},[(_vm.desktop)?_c('div',{staticClass:"img-wrap col-auto"},[_c('img',{staticClass:"image",attrs:{"src":_vm.imageUrl,"alt":"x-sell"}})]):_vm._e(),_c('div',{staticClass:"wrap row col content-center",class:{
      'q-py-xl q-pr-xl q-pl-md items-center': _vm.desktop,
      'q-pa-lg': _vm.tablet,
      'q-pt-lg q-pb-md q-px-md': _vm.mobile,
    }},[_c('div',{staticClass:"content col composite-text",class:_vm.desktop ? 'self-center' : ''},[_c('caption',{staticClass:"text-ws-base140"},[_vm._v(" "+_vm._s(_vm.$t('xsell.caption'))+" ")]),_c('h6',{staticClass:"text-white q-mb-sm q-mb-md-md"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getKey(_vm.item.uid, 'title'), _vm.properties))+" ")]),_c('small',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.$t(_vm.getKey(_vm.item.uid, 'description'), _vm.properties))+" ")])]),_c('div',{staticClass:"action",class:{
        'text-center col-auto column': !_vm.mobile,
        'q-ml-5xl': _vm.desktop,
        'q-ml-2xl': _vm.tablet,
        'q-mt-md col-12 row justify-between items-center': _vm.mobile,
      }},[_c('div',{staticClass:"col-auto",class:{ 'q-mb-md': !_vm.mobile }},[_c('div',{staticClass:"ws-typo-h5-desktop text-white"},[(_vm.priceFrom)?[_vm._v(" "+_vm._s(_vm.$t('general.price_from'))+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.$n(_vm.price, 'currency'))+" ")],2),_c('div',{staticClass:"ws-typo-small-desktop text-ws-base140"},[_vm._v(" "+_vm._s(_vm.$t( `wsk.general.period.per.short.${ _vm.period % 12 ? 'months' : 'years' }`, { period: _vm.period % 12 ? _vm.period : _vm.period / 12, } ))+" ")])]),_c('div',{staticClass:"col-auto"},[_c('ws-button',{directives:[{name:"ws-analytics",rawName:"v-ws-analytics",value:(_vm.dataTrackingId),expression:"dataTrackingId"}],attrs:{"secondary":"","small":"","forced-desktop":"","icon":"cart","icon-size":16,"label":"wsk.general.buy","loading":_vm.loading},on:{"click":_vm.buy}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }