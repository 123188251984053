
import { Component, Vue, Watch, Prop, Provide } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

import { getSnakedKeyFactory } from '@loopia-group/utils';
import WsMessage from '@WS_Components/WsMessage.vue';

import { Cart, CartItem } from '@/types';
import { StoreActions } from '@/store/const.enum';
import { STEPS } from '@/services/const.enum';
import { CartServiceStore } from '@/services/cart/cart.store.d';
import {
  removeFromCart,
  removeDonation,
  getXsellItems,
  fetchCart,
} from '@/services/cart/cart-api.service';
import { toggleEditItem } from '@/services/cart/cart.service';
import config, { AppConfig } from '@/services/config.service';
import RemoveBtn from '@/components/RemoveBtn.vue';
import XSellBanner from '../XSellBanner.vue';
import CartItemRow from './CartItemRow.vue';
import HostingTutorial from '@/components/hostingTutorial/HostingTutorial.vue';
import { XsellItem } from '../../types/index';
import { Theme } from '@loopia-group/services';
import {useCartItemsStore} from '@/store/cartItemsStore';
import {remove} from 'lodash-es';
import {safePush} from '@/router';
import {ROUTENAMES} from '@/const.enum';
import SideDrawer from '@/modules/checkout/components/cart/SideDrawer.vue';

const PATH = 'cart-items';

@Component({
  components: {
    WsMessage,
    RemoveBtn,
    XSellBanner,
    CartItemRow,
    SideDrawer,
    HostingTutorial,
  },
  computed: {
    ...mapState(['cart', 'cartService']),
    ...mapGetters(['loggedIn']),
  },
})
export default class CartItems extends Vue {
  @Provide()
  @Prop(Boolean)
  readonly settingsIconVisible!: boolean; // settings button has its own column in table
  @Provide()
  @Prop(Boolean)
  readonly readOnlyMobile!: boolean; // do not show control buttons (settings, delete) in mobile view
  @Prop(Boolean) readonly bordered!: boolean;
  @Prop(Boolean) readonly xsell!: boolean; // allow xsell feature
  readonly cart!: Cart;
  readonly cartService!: CartServiceStore;
  readonly loggedIn!: Boolean;

  Theme = Theme;
  donationLoader = false;
  cartStep: STEPS = STEPS.CART;
  PATH = PATH;
  cfg: AppConfig = config;
  xsellItems: {
    xSellProducts: XsellItem[];
    upSellProducts: XsellItem[];
  } | null = null;
  xsellUsed: any /* uuid */ | null = null;
  isHostingTutorialResolved: boolean = false;
  cartItemsStore = useCartItemsStore();

  created() {
    if (this.cartItemsStore.cartItems?.length === 0) {
      fetchCart().catch(
        this.$messageService.errorHandler('general', {
          unknownErrorKey: 'exception.cart.not_fetched',
        })
      );
    }
  }

  get itemsXsell(): XsellItem[] {
    return this.xsellItems
      ? [...this.xsellItems.upSellProducts, ...this.xsellItems.xSellProducts]
      : [];
  }

  get showXsell() {
    return this.xsell && this.isHostingTutorialResolved;
  }

  updateXsell() {
    // load new x-sell products as this is driven by BE
    getXsellItems()
      .then(response => (this.xsellItems = response.data))
      .catch(() => this.xsellItems = null);
  }

  set drawerVisible(visible: boolean) {
    if (!visible) {
      toggleEditItem(null);
    }
  }
  get drawerVisible() {
    return !!this.cartService.itemInEdit;
  }

  get donationApplied(): boolean {
    const current = this.cart?.donation?.current;
    return !!(current?.roundUpVersion || current?.customDonation);
  }

  onHostingTutorialResolved() {
    this.updateXsell();
    this.isHostingTutorialResolved = true;
  }

  @Watch('cart', { immediate: true })
  cartChanged() {
    this.updateXsell();

    if (this.loggedIn && this.cartItemsStore.cartItems.length) {
      // we need domain profiles to show non default profiles on domain-register cart items
      this.$store.dispatch(StoreActions.ENSURE_DOMAIN_PROFILES);
    }
  }

  get messages() {
    return this.$messageService.getters.messagesByPath(PATH);
  }

  @Provide()
  removeItem(item: CartItem) {
    return removeFromCart(item)
      .then(() => {
        this.cartItemsStore.removeItem(item);
        const items = this.cart?.items || [];
        remove(items!, item);
        if (!items?.length) {
          safePush({ name: ROUTENAMES.EMPTY_CART });
        }
      })
      .catch(this.$messageService.errorHandler(PATH));
  }

  removeDonation() {
    this.donationLoader = true;
    removeDonation()
      .catch(this.$messageService.errorHandler(PATH))
      .finally(() => (this.donationLoader = false));
  }

  getKey(prop: string, key: string): string {
    this.getKey = getSnakedKeyFactory('product');
    return this.getKey(prop, key);
  }
}
