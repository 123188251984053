
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';
import { QForm } from 'quasar';

import { cloneDeep, get, isEqual } from 'lodash-es';
import { validateForm, required, CompanyId } from '@WS_UIkit';
import WsCheckbox from '@WS_Components/WsCheckbox.vue';
import { INVALID_FORM_ERROR } from '@/services/const';
import config, { isSwedishWorkflow } from '@/services/config.service';
import {
  Cart,
  BillingProfile,
  StoreBillingProfileRequest,
  ProfileListConfig,
} from '@/types';
import { StateMutations, StoreActions } from '@/store/const.enum';
import {
  setBillingProfile,
  createBillingProfile,
} from '@/services/cart/cart-api.service';
import ProfileList from './ProfileList.vue';
import { cleanProfileByType } from '@/services/profiles.service';

const PATH = 'cart.billingProfile';
const emptyNewProfile: { emails: []; type: 'personal' } = {
  emails: [],
  type: 'personal',
};

@Component({
  components: {
    WsCheckbox,
    ProfileList,
    QForm,
  },
  computed: {
    ...mapState(['cart', 'billingProfiles']),
    ...mapGetters(['billingProfileById', 'isInRequiredFields']),
  },
})
export default class BillingProfileList extends Vue {
  @Prop(Boolean)
  readonly editMode!: boolean;
  @Prop(Boolean)
  readonly requireDomainFields!: boolean; // require fields needed for domain profile

  readonly cart!: Cart;
  isInRequiredFields!: (
    type: string,
    profileCountry: string,
    prop: string
  ) => boolean;
  billingProfiles!: BillingProfile[];
  billingProfileById!: (id: string | number) => BillingProfile | null;
  public billingProfile: Partial<BillingProfile> | null = null;
  updating = false;
  isSwedishWorkflow = isSwedishWorkflow;
  bonusCardAvailable = config.company !== CompanyId.WEBSUPPORT_SE;

  data() {
    return {
      required,
      PATH,
    };
  }

  get loading() {
    return !this.billingProfiles;
  }

  get savedProfile(): BillingProfile | null {
    const billingProfileId = this.billingProfile?.id;
    if (billingProfileId) {
      return this.billingProfileById(billingProfileId);
    }
    return this.cart?.billingProfile || null;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  get cfg(): ProfileListConfig {
    const billingProfile = this.billingProfile || {};
    const profileType = billingProfile.type || 'personal';
    const countryCode = billingProfile.countryCode || config.companyCountry;
    const isSwedishWorkflow = this.isSwedishWorkflow;
    const savedProfile: any = this.savedProfile || {};

    return {
      type: 'billing',
      newProfileLabel: 'billing_profile.new_profile_option',
      editTitle: 'billing_profile.edit.title',
      addressTitle: 'user.profile.title_billing',
      emails: true,
      phone: isSwedishWorkflow,
      street2: isSwedishWorkflow,
      optional: {
        emails:
          // for SE emails is always required for billing profile (ITDEV-6521)
          countryCode !== 'SE' &&
          (!this.requireDomainFields ||
            !this.isInRequiredFields(profileType, countryCode, 'emails')),
        companyId:
          !this.requireDomainFields ||
          !this.isInRequiredFields(profileType, countryCode, 'companyId'),
        taxId:
          // for HU vatId is always required for billing profile (PNO-1340)
          countryCode !== 'HU' &&
          (!this.requireDomainFields ||
            !this.isInRequiredFields(profileType, countryCode, 'taxId')),
        vatId:
          !this.requireDomainFields ||
          !this.isInRequiredFields(profileType, countryCode, 'vatId'),
      },
      disable: isSwedishWorkflow ? { type: true, companyId: !!savedProfile.companyId } : {},
      path: PATH,
      companyCountry: config.companyCountry,
      emptyNewProfile,
      editMode: this.editMode,
      profiles: this.billingProfiles,
      save: this.innerSaveProfile,
      editInDrawer: config.billingProfileCountLimit !== 1,
      oneOfThreeRuleEnabled: true,
      maxProfiles: config.billingProfileCountLimit,
    };
  }

  created() {
    this.loadProfiles();
  }

  @Watch('editMode', { immediate: true })
  onEditChanged(state: boolean) {
    if (state) {
      // start editing cart billing profile
      this.billingProfile = this.cart.billingProfile
        ? cloneDeep(this.cart.billingProfile)
        : null;
    } else {
      this.billingProfile = null;
    }
  }

  @Watch('billingProfile', { immediate: true })
  onBpChanged(profile: BillingProfile) {
    // inform outside world about new profile being selected
    this.$emit('input', profile);
  }

  selectionChanged() {
    // clear messages on selecting profile, but not on automated selection
    this.$messageService.clearMessages(PATH);

    // re-emit
    this.$emit('selection-changed');
  }

  loadProfiles() {
    this.$store
      .dispatch(StoreActions.FETCH_BILLING_PROFILES)
      .catch(this.$messageService.errorHandler(PATH + '-list'));
  }

  public saveProfile() {
    // sometimes I get "cannot read saveProfile of undefined", cannot reproduce
    // but making error not to stuck whole app
    const profileList = this.$refs.profileList as ProfileList;
    return profileList ? profileList.saveProfile() : this.innerSaveProfile();
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  innerSaveProfile(): Promise<any> {
    this.$messageService.clearMessages([PATH, 'general'], true);

    const billingProfile = this.billingProfile;
    if (!billingProfile) {
      return Promise.reject('validation.billing_profile.not_set');
    }

    return this.validate().then((result: boolean) => {
      if (!result) {
        return Promise.reject(INVALID_FORM_ERROR); // invalid new profile form
      }

      if (billingProfile.id) {
        if (isEqual(billingProfile, this.cart.billingProfile)) {
          return Promise.resolve(billingProfile);
        }
        if (
          !isEqual(billingProfile, this.billingProfileById(billingProfile.id))
        ) {
          return this.updateBillingProfile(
            billingProfile as BillingProfile
          ).then(() => this.setProfile(billingProfile as BillingProfile));
        } // else
        return this.setProfile(billingProfile as BillingProfile);
      } else {
        return this.createBillingProfile();
      }
    });
  }

  setProfile(
    billingProfile: BillingProfile = this.billingProfile as BillingProfile
  ) {
    if (!billingProfile || !billingProfile.id) {
      return Promise.reject('validation.billing_profile.not_set');
    }

    this.updating = true;
    const requestData: any = {
      billingProfileId: get(billingProfile, 'id'),
    };

    return setBillingProfile(requestData).finally(
      () => (this.updating = false)
    );
  }

  validate() {
    return validateForm(this.$refs.form as Element & QForm);
  }

  createBillingProfile() {
    const requestData: Partial<StoreBillingProfileRequest> = cloneDeep(
      this.billingProfile!
    );
    cleanProfileByType(requestData);

    return createBillingProfile(requestData as StoreBillingProfileRequest).then(
      (response: any) => {
        const createdProfile: BillingProfile = response.data;
        // temporary partial fix of BE bug PNO-1240, can be removed after fix
        (createdProfile as any).vatId =
          (createdProfile as any).vatId || requestData.vatId;
        this.$store.commit(StateMutations.ADD_PROFILE, {
          type: 'billing',
          profile: createdProfile,
        });
        this.billingProfile = createdProfile;
        this.$store.commit(StateMutations.SET_STATE, {
          path: 'cart',
          prop: 'billingProfile',
          value: cloneDeep(createdProfile),
        });
        return Promise.resolve(response.data);
      }
    );
  }

  updateBillingProfile(profile: BillingProfile) {
    return this.$store.dispatch(StoreActions.UPDATE_PROFILE, {
      type: 'billing',
      profile,
    });
  }
}
