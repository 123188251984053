
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';

import {
  QForm,
  QField,
  QItem,
  QItemSection,
  QItemLabel,
  QExpansionItem,
} from 'quasar';

import { get } from 'lodash-es';
import { validateForm, required } from '@WS_UIkit';
import WsInput from '@WS_Components/WsInput.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsList from '@WS_Components/WsList.vue';
import WsMessage from '@WS_Components/WsMessage.vue';

import { Cart } from '@/types';
import { INVALID_FORM_ERROR } from '@/services/const';
import { applyCoupon, removeCoupon } from '@/services/cart/cart-api.service';
import { BonusList } from '@/components/BonusList.vue';

@Component({
  components: {
    QForm,
    QItem,
    QItemSection,
    QItemLabel,
    QExpansionItem,
    WsInput,
    WsIcon,
    WsButton,
    WsList,
    WsMessage,
    BonusList,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export class CouponList extends Vue {
  readonly cart!: Cart;
  code: string = '';
  loaders: { [key: string]: boolean } = { submit: false };
  expansion = false;
  required = required;

  get items() {
    return get(this.cart, 'summary.coupons', []);
  }

  @Watch('items', { immediate: true })
  onItemsChanged(items: any[]) {
    if (items.length) {
      this.expansion = true;
    }
  }

  @Watch('code')
  onCodeChanged(code: string) {
    if (!code /*code was ceared */) {
      ((this.$refs.input as WsInput).$refs.qfield as QField).resetValidation();
    }
  }

  submit() {
    if (this.loaders.submit || !this.code) {
      return;
    }
    this.loaders.submit = true;
    validateForm(this.$refs.form as Element & QForm)
      .then((result: boolean) => {
        if (!result) {
          return Promise.reject(INVALID_FORM_ERROR); // invalid new profile form
        }
        return applyCoupon(this.code).then(() => this.cleanForm());
      })
      .catch(this.$messageService.errorHandler('cart.summary.discount'))
      .finally(() => (this.loaders.submit = false));
  }

  removeItem(code: string) {
    if (this.loaders[code]) {
      return;
    }
    Vue.set(this.loaders, code, true);
    removeCoupon(code)
      .catch(this.$messageService.errorHandler('cart.summary.discount_rm'))
      .finally(() => (this.loaders[code] = false));
  }

  cleanForm() {
    this.code = '';
    ((this.$refs.input as WsInput).$refs.qfield as QField).blur();
    (this.$refs.form as QForm).resetValidation();
    // setTimeout because await this.$nextTick(); DOES NOT WORK
    setTimeout(() => {
      (this.$refs.form as QForm).resetValidation();
    }, 0);
  }
}
export default CouponList;
