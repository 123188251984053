
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import config from './services/config.service';
import LayoutDefault from './views/LayoutDefault.vue';
import ThemeSwitcher from '@/components/developer/ThemeSwitcher.vue';

@Component({
  components: {
    ThemeSwitcher,
    LayoutDefault,
  },
  computed: {
    ...mapState(['devUtils']),
  },
})
export default class App extends Vue {
  conf = config;
}
