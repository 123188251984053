//Forked from
// https://github.com/renatodeleao/vue-sticky-directive

import StickySidebar from 'sticky-sidebar';

const eventNamesMap = {
  'affix.top.stickySidebar': 'affix-top',
  'affixed.top.stickySidebar': 'affixed-top',
  'affix.bottom.stickySidebar': 'affix-bottom',
  'affixed.bottom.stickySidebar': 'affixed-bottom',
  'affix.container-bottom.stickySidebar': 'affix-container-bottom',
  'affixed.container-bottom.stickySidebar': 'affixed-container-bottom',
  'affix.unbottom.stickySidebar': 'affix-unbottom',
  'affixed.unbottom.stickySidebar': 'affixed-unbottom',
  'affix.static.stickySidebar': 'affix-static',
  'affixed.static.stickySidebar': 'affixed-static',
};

// eslint-disable-next-line max-lines-per-function
const VueStickyDirective = () => {
  // acccess StickySidebar Instance via vnode.elm._stickySidebar
  const NS = '_stickySidebar';
  const DEFAULTS = {
    topSpacing: 0,
    bottomSpacing: 0,
    containerSelector: false,
    innerWrapperSelector: '[data-v-sticky-inner]',
    resizeSensor: true,
    stickyClass: 'is-affixed',
    minWidth: 0,
  };

  const EVENT_NAMES_MAP = eventNamesMap;

  /**
   * Emit
   *
   * @desc Mimic vnode.context.$emit(event, data) https://stackoverflow.com/a/40720172/2801012
   * @param {Vnode} vnode
   * @param {String} name
   * @param {Object} data - Any data accessible at v-on:event-name payload
   */
  const emit = (vnode: any, name: any, data: any) => {
    const handlers =
      (vnode.data && vnode.data.on) ||
      (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
      handlers[name].fns(data);
    }
  };

  /**
   * Handling eventlistener
   *
   * @param {Event} e — native event object
   * @param {vnode} vnode - target vnode
   * @returns {Function} emit
   */
  const handleEvents = function(e: EventIntf, vnode: any) {
    //console.log(`emmit ${EVENT_NAMES_MAP[e.type]}`);

    const humanizedEventName = EVENT_NAMES_MAP[e.type];

    emit(vnode, humanizedEventName, {
      evtName: humanizedEventName,
      vnode: vnode,
    });
  };

  /**
   * handleEventsReference
   *
   * @desc a reference where handleEvents will be assigned to allow add and remove eventListeners
   */
  let handleEventsReference: any;

  /**
   * mergeOptions with type verification and fallback to current options
   * @param {*} oldOpts
   * @param {*} newOpts
   */
  const mergeOptions = (oldOpts: any, newOpts: any) => {
    if (typeof newOpts === 'undefined' || typeof newOpts === 'object') {
      return { ...oldOpts, ...newOpts };
    }
    // eslint-disable-next-line no-console
    console.warn(
      'v-sticky binding must be an object, \'fallbacking\' to previous option set'
    );
    return oldOpts;
  };

  const destroySticky = (el: any) => {
    if (el[NS]) {
      el[NS].destroy();
      el[NS] = undefined;
      Object.keys(EVENT_NAMES_MAP).map(evtName => {
        el.removeEventListener(evtName, handleEventsReference);
      });
    }
  };

  return {
    inserted(el: any, binding: any, vnode: any) {
      if (binding.value.disabled) {
        return;
      }

      const opts = mergeOptions(DEFAULTS, binding.value);
      vnode.context.$nextTick(() => {
        el[NS] = new StickySidebar(el, opts);

        handleEventsReference = function(e: any) {
          handleEvents(e, vnode);
        };

        Object.keys(EVENT_NAMES_MAP).map(function(evtName) {
          el.addEventListener(evtName, handleEventsReference);
        });
      });
    },

    unbind(el: any /*, binding: any, vnode: any */) {
      destroySticky(el);
    },

    update(el: any, binding: any /*, vnode: any */) {
      if (!el[NS]) {
        return;
      }
      if (binding.value.disabled) {
        return destroySticky(el);
      }
      el[NS].options = mergeOptions(el[NS].options, binding.value);
      el[NS].updateSticky();
    },

    componentUpdated(el: any /*, binding: any, vnode: any */) {
      if (el[NS]) {
        el[NS].updateSticky();
      }
    },
  };
};

export default VueStickyDirective();

type EventIntf = {
  type: keyof typeof eventNamesMap;
};
