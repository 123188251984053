
import { Component, Vue } from 'vue-property-decorator';

import { mapState } from 'vuex';
import { ROUTENAMES } from '@/const.enum';
import carySorry from '@/assets/images/Cary/cary-sorry.png';
import LayoutDefault from '../../../views/LayoutDefault.vue';
import DomainSearchBar from '@/components/domain-checker/DomainSearchBar.vue';
import { SearchQuery } from '@/components/domain-checker/dc.types';
import { Location } from 'vue-router';
import { Theme } from '@loopia-group/services';
import WsInput from '@WS_Components/WsInput.vue';

@Component({
  components: {
    WsInput,
    LayoutDefault,
    DomainSearchBar,
  },
  computed: {
    ...mapState(['cart']),
  },
})
export default class EmptyCart extends Vue {
  carySorry = carySorry;
  Theme = Theme;

  goToDomainChecker(search: SearchQuery) {
    if (search) {
      // redirect to domain checker
      this.$router.push({
        name: ROUTENAMES.DOMAIN_CHECKER,
        query: {
          qd: search.domains.join(','),
          freeOnly: search.freeOnly,
        },
      } as Location);
    }
  }
}
