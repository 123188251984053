
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Component from 'vue-class-component';
import { PricesItem } from '../../types';

@Component
export default class HostingTutorialPrice extends Vue {
  @Prop({ required: true }) readonly priceItem!: PricesItem;

  get period(): number {
    return this.priceItem.period;
  }

  get price(): number | null {
    const price = this.priceItem.actualPrice;

    if (price === undefined) {
      return null;
    }

    const period = this.priceItem.period || 1;
    if (price) {
      return Math.round((price / period) * 100) / 100;
    }

    return price;
  }
}
