import Axios, { AxiosRequestConfig } from 'axios';
// import {
//   registerDomainCheckerMocks,
//   registerCartMocks,
//   registerBillingProfilesMocks,
//   registerCheckoutMocks,
//   registerDomainProfilesMocks,
//   registerPaymentOptionsMocks,
//   registerRequiredFieldsMocks,
//   registerUserMocks,
//   registerContactFormMocks,
//   registerXsellMocks,
// } from './mocks';

import { merge, get } from 'lodash-es';
import { i18nService } from '@WS_UIkit';

import env from '@env';
import config from '@/services/config.service';
import { fetchCart } from '@/services/cart/cart-api.service';
import MockAdapter from 'axios-mock-adapter';

export const axiosConfig = {
  baseURL: config.apiUrl || undefined,
  withCredentials: true,
  headers: {
    common: {
      'WS-Company': config?.company || 'sk-ws',
      'ws-language': i18nService.getLang(),
    },
  },
};

const axios = Axios.create(axiosConfig);

i18nService.afterInit.then(() => {
  i18nService.langChanges.subscribe((lang: string) => {
    axios.defaults.headers.common['ws-language'] = lang;
  });
});

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    // do nothing
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // console.log('response error', error);
    // console.dir(error);

    const errorResponse = get(error, 'response');
    const errorData = get(errorResponse, 'data');

    if (
      errorData &&
      (errorData.key === 'exception.cart.not_found' ||
        errorData.key === 'exception.cart.not_prepared_yet')
    ) {
      // https://loopiagroup.atlassian.net/browse/PNO-1234
      // https://loopiagroup.atlassian.net/browse/PNO-1319
      // get new cart, throw away old
      fetchCart();
    }

    if (
      errorResponse &&
      errorResponse.status === 404 &&
      (!errorData.key || errorData.key.includes(' '))
    ) {
      // error does not have correct key (keys may not include spaces)
      errorData.key = 'validation.resource_not_found';
    }

    return Promise.reject(error);
  }
);

// MOCK responses implementation for dev builds only
if (!env.isProductionBuild) {
  // doc: https://github.com/ctimmerm/axios-mock-adapter
  const mockSingleton = new MockAdapter(axios, { delayResponse: 300 });

  // registerDomainCheckerMocks(mockSingleton);
  // registerCartMocks(mockSingleton);
  // registerBillingProfilesMocks(mockSingleton);
  // registerCheckoutMocks(mockSingleton);
  // registerDomainProfilesMocks(mockSingleton);
  // registerPaymentOptionsMocks(mockSingleton);
  // registerRequiredFieldsMocks(mockSingleton);
  // registerUserMocks(mockSingleton);
  // registerContactFormMocks(mockSingleton);
  // registerXsellMocks(mockSingleton);

  // pass all not mocked request to real API
  mockSingleton.onAny().passThrough();
}

export function changeAxiosConfig(config: Partial<AxiosRequestConfig>) {
  merge(axios.defaults, config);
}

export default axios;

// Missing docs:
// This is how to cancel request from intercpetor:
// config = {
//   ...config,
//   cancelToken: new Axios.CancelToken((cancel) => cancel('Cancel request reason'))
// };
