/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable max-lines */
import { getShortUID } from '@/services/mocks/utilities';
import { CartItem } from '@/types';
import MockAdapter from 'axios-mock-adapter/types';

const email = 'kokosynasnehu22@gmail.com';

export const cartMockData = getMockData(getShortUID);
export const cartMockDataStatic = getMockData();

// eslint-disable-next-line max-lines-per-function
function getMockData(UIDprovider: () => string = () => 'static') {
  return {
    billingProfile: {
      id: 275005,
      userId: 185005,
      type: 'personal',
      name: 'Kokos Velky',
      street: 'Postova 5',
      city: 'Petr\u017ealka',
      postalCode: '85101',
      countryCode: 'SK',
      company: 'sk-ws',
      emails: [email],
      bonusCard: '',
    },
    domainProfileRequired: true,
    consent3rdBilling: false,
    consent3rdDomain: true,
    domainProfile: {
      id: 92701,
      userId: 185005,
      type: 'personal',
      firstName: 'Kokos',
      lastName: 'Velky',
      street: 'Postova 5',
      city: 'Petr\u017ealka',
      postalCode: '85101',
      countryCode: 'SK',
      email,
      phone: null,
      passport: null,
      idCard: null,
    },
    paymentOption: {
      groupId: 'card',
      optionId: 'card-id123',
      recurrence: true,
    },
    note: null,
    migrationPromotion: {
      applicable: false,
      needAssistance: null,
      uploadedFiles: [],
    },
    items: [
      {
        id: 3883,
        code: 'domain-register',
        period: 12,
        sellType: 'direct',
        properties: {
          domain: 'example-' + UIDprovider() + '.sk',
          premium: true,
          domainProfileId: null,
          tld: 'sk',
        },
        options: {
          period: [
            {
              period: 12,
              price: 8.9,
              onlyCardPayment: false,
            },
          ],
        },
        price: {
          vatExcluded: 8.9,
          discountReason: null,
          originalPrice: null,
        },
        coupon: null,
        mandatoryEditableProperties: [],
        optionalEditableProperties: ['domainProfileId'],
        prolongPrice: {
          vatExcluded: 12.5,
        },
        gaee: {
          id: 'sk-domain-registration',
          name: 'SK Domain Registration',
          categories: ['Domain','Domain Registration'],
          brand: 'Websupport',
          price: '11.90',
          quantity: 1,
        },
      },
      {
        id: 3892,
        code: 'vdc',
        period: 12,
        sellType: 'direct',
        properties: {
          name: 'example-vdc-' + UIDprovider(),
          cpu: 25,
          ram: 59,
          capacity: 345,
          ip: 3,
          monitoring: false,
          management: false,
          backup: false,
        },
        options: {
          period: [
            {
              period: 1,
              price: 695.63,
              onlyCardPayment: true,
            },
            {
              period: 6,
              price: 3965.09,
              onlyCardPayment: false,
            },
            {
              period: 12,
              price: 7512.8,
              onlyCardPayment: false,
            },
          ],
        },
        price: {
          vatExcluded: 7512.8,
          discountReason: 'user',
          originalPrice: 8347.56,
        },
        coupon: null,
        mandatoryEditableProperties: ['name'],
        optionalEditableProperties: [],
        prolongPrice: {
          vatExcluded: 7512.8,
        },
        gaee: {
          id: 'vdc-2',
          name: 'Virtual Data Center',
          categories: ['Server','Virtual Data Center'],
          brand: 'Websupport',
          price: '1145.34',
          quantity: 1,
        },
      },
      {
        id: 3895,
        code: 'site-builder',
        period: 12,
        sellType: 'direct',
        properties: {
          type: 'extended',
          domain: 'example-' + UIDprovider() + '.cz',
        },
        options: {
          period: [
            {
              period: 1,
              price: 9.9,
              onlyCardPayment: true,
            },
            {
              period: 12,
              price: 118.8,
              onlyCardPayment: false,
            },
          ],
        },
        price: {
          vatExcluded: 118.8,
          discountReason: null,
          originalPrice: null,
        },
        coupon: null,
        mandatoryEditableProperties: ['domain'],
        optionalEditableProperties: [],
        prolongPrice: {
          vatExcluded: 118.8,
        },
        gaee: {
          id: 'personal-site-builder',
          name: 'Personal Site Builder',
          categories: ['Site Builder'],
          brand: 'Websupport',
          price: '58.80',
          quantity: 1,
          affiliation: 'TEST123',
        },
      },
      {
        id: 3898,
        code: 'vps',
        period: 12,
        prolongPeriod: 12,
        sellType: 'direct',
        properties: {
          name: 'example-vps-' + UIDprovider(),
          osName: 'Ubuntu 18.04',
          osTemplate: 'minimal',
          cpu: 4,
          ram: 4096,
          capacity: 102400,
          snapshotSlot: 1,
        },
        options: {
          period: [
            {
              period: 1,
              price: 47.91,
              onlyCardPayment: true,
            },
            {
              period: 3,
              price: 143.73,
              onlyCardPayment: false,
            },
            {
              period: 6,
              price: 273.09,
              onlyCardPayment: false,
            },
            {
              period: 12,
              price: 517.43,
              onlyCardPayment: false,
            },
          ],
        },
        price: {
          vatExcluded: 517.43,
          discountReason: 'user',
          originalPrice: 574.92,
        },
        coupon: null,
        mandatoryEditableProperties: ['name', 'snapshotSlot'],
        optionalEditableProperties: ['monitoring', 'management', 'backup'],
        gaee: {
          id: 'linux-vps-starter',
          name: 'Virtualny server',
          categories: ['Server','Virtual Private Server'],
          brand: 'Websupport',
          price: '517.43',
          quantity: 1,
        },
        prolongPrice: {
          vatExcluded: 517.43,
          discountReason: 'user',
          originalPrice: 517.43,
        },
      },
      {
        id: 23925,
        code: 'the-hosting-wordpress',
        period: 12,
        sellType: 'direct',
        properties: {
          capacity: 3072,
          domainsCount: 1,
          domain: 'csvsdvsdv.hu',
        },
        options: {
          period: [
            {
              period: 1,
              price: 2020,
              onlyCardPayment: true,
            },
            {
              period: 3,
              price: 6060,
              onlyCardPayment: false,
            },
            {
              period: 6,
              price: 12120,
              onlyCardPayment: false,
            },
            {
              period: 12,
              price: 20153,
              onlyCardPayment: false,
            },
          ],
        },
        price: {
          vatExcluded: 20153,
          discountReason: 'user',
          originalPrice: 24240,
        },
        coupon: null,
        mandatoryEditableProperties: ['domain'],
        optionalEditableProperties: [],
        gaee: {
          id: 'multi-hosting',
          name: 'Multihosting',
          categories: ['Web hosting'],
          brand: 'Websupport',
          price: '20153.00',
          quantity: 1,
          coupon: 'ABXJ3L2QTL',
        },
        prolongPrice: {
          vatExcluded: 20153,
          discountReason: 'user',
          originalPrice: 20153,
        },
      },
    ],
    donation: {
      current: {
        roundUpVersion: null,
        customDonation: 0,
        donationVatExcluded: 0,
      },
      options: [
        {
          roundUpVersion: 'v1',
          donation: 0.32,
          totalPriceVatIncluded: 11,
        },
        {
          roundUpVersion: 'v2',
          donation: 0.82,
          totalPriceVatIncluded: 11.5,
        },
        {
          roundUpVersion: 'v3',
          donation: 1.82,
          totalPriceVatIncluded: 12.5,
        },
      ],
    },
    summary: {
      count: 4,
      price: {
        vatExcluded: 1471.48,
        vatIncluded: 1765.776,
        vatOnly: 294.296,
        vatPercentage: 20,
      },
      currency: {
        name: 'EUR',
        symbol: '\u20ac',
      },
      coupons: [
        {
          code: 'ABXJ3L2QTL',
          bonuses: [
            {
              property: 'bonus.property.capacity',
              value: '100.00',
              percentage: false,
            },
          ],
        },
      ],
    },
    user: {
      id: 185005,
      login: 'kokosvelky',
      customerType: 'new',
      emails: [email],
    },
    uuid: 2707,
  };
}

export const domainItems = [
  {
    id: 23886,
    code: 'domain-transfer',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'mozaweb.hu',
      premium: false,
      tld: 'hu',
    },
    options: {
      period: [
        {
          period: 12,
          price: 1350,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 1350,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId', 'eppCode'],
    gaee: {
      id: 'hu-domain-registration',
      name: 'HU Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '1350.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 1350,
      discountReason: null,
      originalPrice: 1350,
    },
  },
  {
    id: 23898,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.com',
      premium: false,
      tld: 'com',
    },
    options: {
      period: [
        {
          period: 12,
          price: 3690,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 3690,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'com-domain-registration',
      name: 'COM Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '3690.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 3690,
      discountReason: null,
      originalPrice: 3690,
    },
  },
  {
    id: 23901,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.sk',
      premium: false,
      tld: 'sk',
    },
    options: {
      period: [
        {
          period: 12,
          price: 2700,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 2700,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'sk-domain-registration',
      name: 'SK Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '2700.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 3700,
      discountReason: null,
      originalPrice: 3700,
    },
  },
  {
    id: 23904,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.cz',
      premium: false,
      tld: 'cz',
    },
    options: {
      period: [
        {
          period: 12,
          price: 4200,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 4200,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'cz-domain-registration',
      name: 'CZ Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '4200.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 4200,
      discountReason: null,
      originalPrice: 4200,
    },
  },
  {
    id: 23907,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.store',
      premium: false,
      tld: 'store',
    },
    options: {
      period: [
        {
          period: 12,
          price: 1990,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 1990,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'store-domain-registration',
      name: 'STORE Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '1990.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 15500,
      discountReason: null,
      originalPrice: 15500,
    },
  },
  {
    id: 23910,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.online',
      premium: false,
      tld: 'online',
    },
    options: {
      period: [
        {
          period: 12,
          price: 1490,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 1192,
      discountReason: 'bundle',
      originalPrice: 1490,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'online-domain-registration',
      name: 'ONLINE Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '1490.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 9300,
      discountReason: null,
      originalPrice: 9300,
    },
  },
  {
    id: 23913,
    code: 'domain-register',
    period: 24,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'csvsdvsdv.hu',
      premium: false,
      tld: 'hu',
    },
    options: {
      period: [
        {
          period: 24,
          price: 2380,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 2380,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'hu-domain-registration',
      name: 'HU Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '2380.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 4760,
      discountReason: null,
      originalPrice: 4760,
    },
  },
  {
    id: 23916,
    code: 'hosting-business',
    period: 12,
    sellType: 'direct',
    properties: {},
    options: {
      period: [
        {
          period: 1,
          price: 9360,
          onlyCardPayment: true,
        },
        {
          period: 12,
          price: 93630,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 93630,
      discountReason: 'user',
      originalPrice: 112320,
    },
    coupon: null,
    mandatoryEditableProperties: ['domain'],
    optionalEditableProperties: [],
    gaee: {
      id: 'business-hosting',
      name: 'Business Hosting',
      categories: ['Web Hosting'],
      brand: 'Websupport',
      price: '93630.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 93630,
      discountReason: 'user',
      originalPrice: 93630,
    },
  },
  {
    id: 23922,
    code: 'site-builder',
    period: 12,
    sellType: 'direct',
    properties: {
      type: 'basic',
    },
    options: {
      period: [
        {
          period: 1,
          price: 1990,
          onlyCardPayment: true,
        },
        {
          period: 12,
          price: 23880,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 23880,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: ['domain'],
    optionalEditableProperties: [],
    gaee: {
      id: 'business-site-builder',
      name: 'Business Site Builder',
      categories: ['Site Builder'],
      brand: 'Websupport',
      price: '23880.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 23880,
      discountReason: null,
      originalPrice: 23880,
    },
  },
  {
    id: 23925,
    code: 'the-hosting-wordpress',
    period: 12,
    sellType: 'direct',
    properties: {
      capacity: 3072,
      domainsCount: 1,
      domain: 'csvsdvsdv.hu',
    },
    options: {
      period: [
        {
          period: 1,
          price: 2020,
          onlyCardPayment: true,
        },
        {
          period: 3,
          price: 6060,
          onlyCardPayment: false,
        },
        {
          period: 6,
          price: 12120,
          onlyCardPayment: false,
        },
        {
          period: 12,
          price: 20153,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 20153,
      discountReason: 'user',
      originalPrice: 24240,
    },
    coupon: null,
    mandatoryEditableProperties: ['domain'],
    optionalEditableProperties: [],
    gaee: {
      id: 'the-hosting-wordpress-start',
      name: 'The Hosting Wordpress Start',
      categories: ['Web Hosting'],
      brand: 'Websupport',
      price: '20153.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 20153,
      discountReason: 'user',
      originalPrice: 20153,
    },
  },
  {
    id: 23931,
    code: 'domain-register',
    period: 12,
    sellType: 'direct',
    properties: {
      domainProfileId: null,
      domain: 'defsefsfs.cz',
      premium: false,
      tld: 'cz',
    },
    options: {
      period: [
        {
          period: 12,
          price: 4200,
          onlyCardPayment: false,
        },
      ],
    },
    price: {
      vatExcluded: 4200,
      discountReason: null,
      originalPrice: null,
    },
    coupon: null,
    mandatoryEditableProperties: [],
    optionalEditableProperties: ['domainProfileId'],
    gaee: {
      id: 'cz-domain-registration',
      name: 'CZ Domain Registration',
      categories: ['Domain','Domain Registration'],
      brand: 'Websupport',
      price: '4200.00',
      quantity: 1,
    },
    prolongPrice: {
      vatExcluded: 4200,
      discountReason: null,
      originalPrice: 4200,
    },
  },
];
export const storageItem: CartItem = {
  id: 326395,
  code: 'storage',
  period: 12,
  prolongPeriod: 12,
  sellType: 'direct',
  properties: {
    capacity: 884736,
  },
  options: {
    period: [
      {
        period: 1,
        price: 126.56,
        onlyCardPayment: true,
      },
      {
        period: 6,
        price: 721.39,
        onlyCardPayment: false,
      },
      {
        period: 12,
        price: 1366.85,
        onlyCardPayment: false,
      },
    ],
  },
  price: {
    vatExcluded: 1366.85,
    discountReason: 'user',
    originalPrice: 1518.72,
  },
  coupon: null,
  mandatoryEditableProperties: ['name'],
  optionalEditableProperties: [],
  gaee: {
    id: 'storage',
    name: 'Storage',
    categories: ['Server'],
    brand: 'Websupport',
    price: '1366.85',
    quantity: 1,
  },
  prolongPrice: {
    vatExcluded: 1366.85,
    discountReason: 'user',
    originalPrice: 1366.85,
  },
};
export const winLicenceItem: CartItem = {
  id: 326362,
  code: 'windows-licence',
  period: 12,
  prolongPeriod: 12,
  sellType: 'direct',
  properties: {},
  options: {
    period: [
      {
        period: 12,
        price: 280.8,
        onlyCardPayment: false,
      },
    ],
  },
  price: {
    vatExcluded: 280.8,
    discountReason: null,
    originalPrice: null,
  },
  coupon: null,
  mandatoryEditableProperties: [],
  optionalEditableProperties: [],
  gaee: {
    id: 'windows-licence',
    name: 'Windows Licence',
    categories: ['Server'],
    brand: 'Websupport',
    price: '280.80',
    quantity: 1,
  },
  prolongPrice: {
    vatExcluded: 280.8,
    discountReason: null,
    originalPrice: 280.8,
  },
};

const itemsProperties: Record<string, any> = {
  management: {
    name: 'management',
    options: [
      {
        type: 'empty',
        value: 'false',
        prices: [],
      },
      {
        type: 'advanced',
        value: 'true',
        prices: [
          {
            period: 12,
            actualPrice: 1440,
            marketingPrice: 1440,
          },
        ],
      },
    ],
  },
  snapshotSlot: {
    name: 'snapshotSlot',
    options: [
      {
        type: 'slot_1',
        value: '1',
        prices: [
          {
            period: 1,
            actualPrice: 0,
            marketingPrice: null,
          },
          {
            period: 3,
            actualPrice: 0,
            marketingPrice: null,
          },
          {
            period: 6,
            actualPrice: 0,
            marketingPrice: null,
          },
          {
            period: 12,
            actualPrice: 0,
            marketingPrice: null,
          },
        ],
      },
      {
        type: 'slot_2',
        value: '2',
        prices: [
          {
            period: 1,
            actualPrice: 4.9,
            marketingPrice: null,
          },
          {
            period: 3,
            actualPrice: 14.7,
            marketingPrice: null,
          },
          {
            period: 6,
            actualPrice: 29.4,
            marketingPrice: null,
          },
          {
            period: 12,
            actualPrice: 58.8,
            marketingPrice: null,
          },
        ],
      },
      {
        type: 'slot_3',
        value: '3',
        prices: [
          {
            period: 1,
            actualPrice: 9.8,
            marketingPrice: null,
          },
          {
            period: 3,
            actualPrice: 29.4,
            marketingPrice: null,
          },
          {
            period: 6,
            actualPrice: 58.8,
            marketingPrice: null,
          },
          {
            period: 12,
            actualPrice: 117.6,
            marketingPrice: null,
          },
        ],
      },
      {
        type: 'slot_4',
        value: '4',
        prices: [
          {
            period: 1,
            actualPrice: 14.7,
            marketingPrice: null,
          },
          {
            period: 3,
            actualPrice: 44.1,
            marketingPrice: null,
          },
          {
            period: 6,
            actualPrice: 88.2,
            marketingPrice: null,
          },
          {
            period: 12,
            actualPrice: 176.4,
            marketingPrice: null,
          },
        ],
      },
      {
        type: 'slot_5',
        value: '5',
        prices: [
          {
            period: 1,
            actualPrice: 19.6,
            marketingPrice: null,
          },
          {
            period: 3,
            actualPrice: 58.8,
            marketingPrice: null,
          },
          {
            period: 6,
            actualPrice: 117.6,
            marketingPrice: null,
          },
          {
            period: 12,
            actualPrice: 235.2,
            marketingPrice: null,
          },
        ],
      },
    ],
  },
  monitoring: {
    name: 'monitoring',
    options: [
      {
        type: 'empty',
        value: 'false',
        prices: [],
      },
      {
        type: 'advanced',
        value: 'true',
        prices: [
          {
            period: 12,
            actualPrice: 228,
            marketingPrice: 228,
          },
        ],
      },
    ],
  },
  backup: {
    name: 'backup',
    options: [
      {
        type: 'empty',
        value: 'false',
        prices: [],
      },
      {
        type: 'backup',
        value: 'true',
        prices: [
          {
            period: 12,
            actualPrice: 4.8,
            marketingPrice: 4.8,
          },
        ],
        min: 2,
        max: 500,
      },
    ],
  },
};

export const mockBusinessMailItem = {
  id: 2190278,
  code: 'business-mail',
  period: 12,
  prolongPeriod: 12,
  sellType: 'direct',
  properties: {
    packageId: '3b086020-ed05-469c-aed4-a142063e88b9',
    addons: {
      advanced_licences_limit: 1,
      basic_licences_limit: 1,
      premium_licences_limit: 1
    },
    packageDetail: {
      id: '3b086020-ed05-469c-aed4-a142063e88b9',
      marketingName: {
        sk: 'Biznis Mail'
      },
      prices: [
        {
          period: 12,
          prolongPrice: 0,
          newPrice: 0
        },
        {
          period: 6,
          prolongPrice: 0,
          newPrice: 0
        },
        {
          period: 3,
          prolongPrice: 0,
          newPrice: 0
        }
      ],
      addons: [
        {
          type: 'numeric',
          step: 1,
          max: null,
          monthlyPrice: 1.99,
          name: 'basic_licences_limit'
        },
        {
          type: 'numeric',
          step: 1,
          max: null,
          monthlyPrice: 3.99,
          name: 'advanced_licences_limit'
        },
        {
          type: 'numeric',
          step: 1,
          max: null,
          monthlyPrice: 6.99,
          name: 'premium_licences_limit'
        }
      ],
      parameters: []
    },
    domain: 'mocking-cart.com',
    freeTrial: true
  },
  options: {
    period: [
      {
        period: 3,
        price: 0,
        onlyCardPayment: false
      },
      {
        period: 6,
        price: 0,
        onlyCardPayment: false
      },
      {
        period: 12,
        price: 0,
        onlyCardPayment: false
      }
    ]
  },
  price: {
    vatExcluded: 0,
    discountReason: 'free_trial',
    originalPrice: 47.88
  },
  coupon: null,
  mandatoryEditableProperties: [
    'domain'
  ],
  optionalEditableProperties: [
    'addons'
  ],
  settings: {
    addons: [
      {
        type: 'numeric',
        step: 1,
        monthlyPrice: 1.99,
        name: 'basic_licences_limit',
        base: 0,
        value: 1,
        filters: {
          min: 0,
          max: null
        }
      },
      {
        type: 'numeric',
        step: 1,
        monthlyPrice: 3.99,
        name: 'advanced_licences_limit',
        base: 0,
        value: 1,
        filters: {
          min: 0,
          max: null
        }
      },
      {
        type: 'numeric',
        step: 1,
        monthlyPrice: 6.99,
        name: 'premium_licences_limit',
        base: 0,
        value: 1,
        filters: {
          min: 0,
          max: null
        }
      }
    ]
  },
  prolongPrice: {
    vatExcluded: 0,
    discountReason: 'free_trial',
    originalPrice: 0
  },
  gaee: {
    id: 'business-email',
    name: 'Business Email',
    categories: [
      'Email'
    ],
    brand: 'Websupport',
    price: 0,
    quantity: 1
  }
};

export const validationError = {
  type: '/errors/validation',
  status: 422,
  title: 'The given data was invalid.',
  violations: [
    {
      propertyPath: 'vatId',
      errors: [
        {
          translationKey: 'validation.required',
        },
      ],
    },
  ],
};

export const errorFromCartValidationAPI = {
  type: '/errors/httpException',
  status: 400,
  title: 'Cart items validation failed.',
  key: 'exception.cart_items.validation.failed',
  context: {
    errors: [
      {
        type: '/errors/exception',
        status: 500,
        title: 'Domain not available.',
        key: 'exception.integrity.domain_not_available',
        context: {
          cartItemId: 3883, // update to one in your cart if needed
          field: 'configuration.domain',
          errors: ['validation.domain_name.not_available'],
          step: 'cart',
        },
      },
    ],
  },
};

export function registerCartMocks(mock: MockAdapter) {
  // Write a message to console as developer notice
  // eslint-disable-next-line no-console
  console.info('Registering cart API mocks');

  mock.onGet(/\/cart$/).reply(200, cartMockData);
  mock.onPost(/\/cart\/items/).reply(200, cartMockData);
  mock.onGet(/\/cart\/items\/validate$/).reply(200);
  // mock.onGet(/\/cart\/items\/validate$/).reply(400, errorFromCartValidationAPI);
  mock.onPut(/\/cart/).reply(200, cartMockData);
  // mock.onPut(/\/cart/).reply(422, validationError);
  const props = ['monitoring', 'backup', 'management', 'snapshotSlot'];
  props.forEach(prop => {
    mock
      .onGet(/\/cart\/items\/properties/, {
        params: { property: prop },
      })
      .reply(200, itemsProperties[prop]);
  });
}
