
import {Component, Vue, Prop, Inject} from 'vue-property-decorator';
import {QToggle} from 'quasar';
import axios, {Method} from 'axios';

@Component({
  components: {
    QToggle,
  },
})
export class WsToggle extends Vue {
  @Prop({
    type: [Boolean, String],
    default: false,
  }) readonly value!: boolean | string;

  @Prop({
    type: String,
    default: '',
  }) readonly name!: string;

  @Prop({
    type: String,
    default: '',
  }) readonly label!: string;

  @Prop({
    type: String,
    default: 'ws-primary',
  }) readonly color!: string;

  @Prop({
    type: String
  }) readonly toggleOffUrl!: string;

  @Prop({
    type: String
  }) readonly ajaxMethod!: Method;

  @Inject({from: 'formDataOverride', default: null}) formDataOverride: any;

  loading = false;

  created() {
    if (this.formDataOverride) {
      this.formDataOverride[this.name] = !!this.value;
    }
  }

  onUpdate(value: any) {
    if (this.formDataOverride) {
      this.formDataOverride[this.name] = !!value;
    }
    this.$emit('input', value);
    if(this.$parent) {
      this.$parent.$emit('dirty', value);
    }
    // Trigger ajax call on toggle off
    if (!value && this.toggleOffUrl && this.ajaxMethod) {
      this.toggleOffAjaxCall();
    }
  }

  async toggleOffAjaxCall() {
    this.loading = true;

    try {
      const response = await axios({
        url: this.toggleOffUrl,
        method: this.ajaxMethod,
      });

      this.$emit('toggleOffCallFinish', response);
    } catch (error) {
      this.$root.$messageService.errorHandler()(error);
    }

    this.loading = false;
  }
}

export default WsToggle;
