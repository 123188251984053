/* eslint-disable no-unused-vars */
// TODO: REFACTOR!!! Looks very bad :/

import Vue from 'vue';
import VueI18n, {I18nOptions, TranslateResult} from 'vue-i18n';
import {Subject} from 'rxjs';

import DEFAULT_LANG_UI_MESSAGES from '../../assets/translations/wsuikit.json';
import {getQueryParams, isBrowser} from '@loopia-group/utils';
import {get, castArray} from 'lodash-es';

import type {EsModuleContent} from '@loopia-group/ts/types/wsk_types.d';
import CustomFormatter from './i18n.custom-formatter';
import type {InitOptions, TranslationProvider} from './types';
import {config, configObservable} from '@loopia-group/services';

const RX_TWO_LETTER_LANG = /^\w{2}$/;
const RX_FOUR_LETTER_LANG = /^\w{2}-\w{2}$/;

let initResolve: (value?: any) => void;
let i18n: any;
let translationProvider: TranslationProvider;
let currency = 'EUR';
let logger: (log?: any) => void = () => {
};
const reportedKeys: string[] = [];

export const i18nService: any = {
  init,
  getLang,
  setLang,
  loadLanguageAsync,
  i18n,
  afterInit: new Promise(resolve => {
    initResolve = resolve;
  }),
  langChanges: undefined,
  t: undefined,
};

export default i18nService;

export const DEFAULT_LANG = 'dev-EN';
const DEFAULT_FALLBACK = ['en-US', DEFAULT_LANG];
const DEFAULT_DATE_FORMAT = {
  short: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  shortWithTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
};
const loadedLanguages: string[] = [DEFAULT_LANG]; // default language that is preloaded
const langChangesSubject = new Subject();
let supportedLanguages: string[] = Object.keys(config.languages || {});

// initialisation
let pickedLang = DEFAULT_LANG;

function init(options: InitOptions) {
  pickedLang = pickLang();
  i18nService.langChanges = langChangesSubject.asObservable();
  translationProvider = options.provider;

  if (options.logger) {
    logger = options.logger;
  }

  const formatter: CustomFormatter = new CustomFormatter({
    locale: pickedLang,
    localeChanges: i18nService.langChanges,
  });

  i18n = i18nService.i18n = new VueI18n({
    locale: pickedLang,
    // docs: https://kazupon.github.io/vue-i18n/guide/fallback.html#explicit-fallback-with-decision-maps
    fallbackLocale: {
      'sk-SK': ['cs-CZ'],
      'cs-CZ': ['sk-SK'],
      default: DEFAULT_FALLBACK,
    },
    formatter,
    messages: {
      [DEFAULT_LANG]: options.messages,
    },
    missing,
    silentTranslationWarn: true,
  } as I18nOptions);

  i18n.mergeLocaleMessage(DEFAULT_LANG, DEFAULT_LANG_UI_MESSAGES);

  if (options.currency) {
    currency = options.currency;
  }

  // update currency
  i18n.setNumberFormat(pickedLang, {
    currency: {
      style: 'currency',
      currency,
    },
  });

  // update date
  i18n.setDateTimeFormat(pickedLang, DEFAULT_DATE_FORMAT);

  if (pickedLang !== DEFAULT_LANG) {
    loadLanguageAsync(pickedLang)
      .then(() => {
        initResolve(i18nService);
        setLang(pickedLang);
      })
      .catch((error: Error) => logger(error));
  } else {
    initResolve(i18nService);
    setLang(pickedLang);
  }

  setCustomTranslateMethod();
  i18nService.t = tFactory(i18n);
}

function missing(locale: any, key: any, vue: any, values: any) {
  // translation did not happen at all
  // TODO: why sometimes [0] and another time [1]?
  if (!get(values, '[0]._silent') && !get(values, '[1]._silent')) {
    const missingKeyHash = [locale, key, JSON.stringify(values)].join(' // ');
    if (!reportedKeys.includes(missingKeyHash)) {
      logger({
        type: 'missing_translation',
        data: {locale, key, values},
      });
    }
    reportedKeys.push(missingKeyHash);
  }
}

configObservable.subscribe(() => {
  i18nService.afterInit.then(() => {
    const newPickedLang = pickLang();
    if (newPickedLang !== pickedLang) {
      pickedLang = newPickedLang;
      setLang(pickedLang);
    }
  });
});

/*


  definitions ==========================================================

*/

function setCustomTranslateMethod() {
  // We decorate translation-retrieving functions so that empty strings
  // (effectively untranslated strings) are replaced with translation keys (like undefined strings are).
  // Source: https://github.com/kazupon/vue-i18n/issues/563#issuecomment-546852949

  Vue.prototype.$t = function (key: string, values: any) {
    this.$t = tFactory(this.$i18n);
    return this.$t(key, values);
  };
}

// extracted to share implementation with service, so it can be exposed
// for usage in other services
function tFactory(i18n: any) {
  return (key: string, values?: Record<string, any>) => {
    return getTranslation(i18n, key, values);
  };
}

function getTranslation(
  customI18n: any,
  key: string,
  values?: Record<string, any>,
  repeats = false /* infinite loop protection */
) {
  if (!customI18n) {
    customI18n = i18n;
  }
  let result = customI18n.t(key, values);

  /* "explicit" feature support
    Example: source.json
     "summary": {
        "explicit": "Summary",
        "title": "Order summary",
        "continue_btn": "Continue to order",
        ...
      }
    then
      $t('summary.title') --> "Order summary"
    and
      $t('summary') --> "Summary"
  */

  if (typeof result === 'object') {
    result = result.explicit || key;
  }

  // fallback developer English
  if (result === key) {
    result = customI18n.t(key, DEFAULT_LANG, values);
  }

  // it may be object also in default language
  if (typeof result === 'object') {
    result = result.explicit || key;
  }

  // fallback if translation is not available
  if (!repeats && key === result && values && values._fallback !== undefined) {
    result = getTranslation(customI18n, values._fallback, values, true);
  }

  return result;
}

function pickLang() {
  supportedLanguages = Object.keys(config.languages || {});
  //@ts-ignore
  let queryParams: QueryParams = {};
  let htmlLang = '';
  if (isBrowser) {
    queryParams = getQueryParams();
    htmlLang = document.querySelector('html')!.getAttribute('lang') || '';
  }
  const pickedLang =
      _findSupportedLang(supportedLanguages, [
        get(queryParams, 'l'),
        get(queryParams, 'lang'),
      ])
    ||
      _findSupportedLang(supportedLanguages, htmlLang)
    ||
      _findSupportedLang(supportedLanguages, _detectBrowserLangs())
    ||
      DEFAULT_LANG;

  return ensureLangFormat(pickedLang);
}

export function setLang(lang: string, country?: string): void {
  if (lang === i18n.locale) {
    return;
  }
  if (lang !== 'xx-XX') {
    // xx-XX is pseudolang for in-context translations)
    lang =
      _findSupportedLang(supportedLanguages, [lang], country) || DEFAULT_LANG;
  }

  if (!loadedLanguages.includes(lang)) {
    loadLanguageAsync(lang).then(_setLang);
  } else {
    _setLang(lang);
  }
}

function _setLang(lang: string) {
  if (isBrowser) {
    document.querySelector('html')!.setAttribute('lang', lang);
  }

  i18n.locale = lang;

  // update currency
  i18n.setNumberFormat(lang, {
    currency: {
      style: 'currency',
      currency,
    },
  });

  // update date
  i18n.setDateTimeFormat(lang, DEFAULT_DATE_FORMAT);

  langChangesSubject.next(lang);
  return lang;
}

function loadLanguageAsync(lang: string): Promise<string> {
  return Promise.all([
    // ui kit translations
    import(`../../assets/translations/wsk.${lang}.json`).then(
      getMergeMessages(lang)
    ),
    // app translations
    translationProvider(lang).then(getMergeMessages(lang)),
  ]).then(() => Promise.resolve(lang));
}

function getMergeMessages(lang: string) {
  return (msgs: EsModuleContent) => {
    // let actualMsgs =.catch((error: Error) => console.error(error)) i18n.getLocaleMessage(lang);
    // i18n.setLocaleMessage(lang, Object.assign(msgs.default, actualMsgs));
    i18n.mergeLocaleMessage(lang, msgs.default);
    loadedLanguages.push(lang);

    return Promise.resolve(lang);
  };
}

export function getLang() {
  return (i18n && i18n.locale) || DEFAULT_LANG;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function _findSupportedLang(
  supportedLanguages: string[] = [],
  languages: string | string[],
  // country preference, for example if there is en-US and en-SE, which one to choose?
  // The one by preferred country
  country?: string
) {
  if (!languages) {
    return null;
  } else if (!supportedLanguages.length) {
    return '';
  }

  let resultLang = '';

  for (const lang of castArray(languages)) {
    if (!lang) {
      continue;
    } else if (lang === 'xx-XX') {
      return lang;
    }

    const twoLetterLang = lang.slice(0, 2); // get 2 letter type
    for (const supLang of supportedLanguages) {
      if (languagesMatch(supLang, twoLetterLang, resultLang, country)) {
        resultLang = supLang;
      }
    }
  }
  return resultLang;
}

function languagesMatch(supLang: string, lang: string, resultLang: string, country: string | undefined) {
  return supLang.startsWith(lang) &&
    (!resultLang || (country && supLang.toUpperCase().includes(country)));
}

function _detectBrowserLangs(): string[] {
  let languages = navigator.languages && navigator.languages.concat();
  if (!languages) {
    languages = [navigator.language || (navigator as any).userLanguage];
  } else {
    // normalize case
    languages = languages.map(lang => {
      return normalizeLangCase(lang);
    });
  }

  return languages.length ? languages : [];
}

function normalizeLangCase(lang: string) {
  if (lang.match(RX_TWO_LETTER_LANG)) {
    return lang.toLowerCase();
  }
  if (lang.match(RX_FOUR_LETTER_LANG)) {
    const parts = lang.split('-');
    return [parts[0].toLowerCase(), parts[1].toUpperCase()].join('-');
  }
  return lang;
}

export function ensureLangFormat(lang: string): string {
  if (lang.indexOf('-') === -1) {
    return lang;
  }
  const parts = lang.split('-');
  lang = parts[0].toLowerCase() + '-' + parts[1].toUpperCase();
  return lang;
}


let t: any = null;
i18nService.afterInit.then(() => {
  t = i18nService.i18n;
});

/**
 * Checks whether provided translation key
 * has a value pair in current locale and
 * returns translated result or empty string
 *
 * Example: trans('some.translation.key', { param1: 1, param2: 2 };
 * Returns: 'Some translation {param1} and {param2}' or ''
 *
 * @param {string}              key     Translation key
 * @param {Object<string, any>} params  Params for translation
 * @return {TranslateResult | string}   Returns translated result or empty string
 */
export function trans(key: string, params?: { [key: string]: any }): TranslateResult {
  const lang = i18nService.getLang();

  if (t && t.te && t.te(key, lang)) {
    return i18nService.t(key, params);
  }

  return '';
}
