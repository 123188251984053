
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QTooltip } from 'quasar';

import WsIcon from '@WS_Components/WsIcon.vue';

@Component({
  components: { WsIcon, QTooltip },
})
export default class PremiumBadge extends Vue {
  @Prop(Boolean) readonly iconOnly!: boolean;
}
