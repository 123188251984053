
import {Component, Prop, Vue} from 'vue-property-decorator';
import {UI_SIZE, UI_VARIANT} from './WsStateBanner.enum';
import xss from 'xss';
import WsIcon from './WsIcon.vue';
import WsButton from './WsButton.vue';
import WsHtml from './helpers/WsHtml.vue';

@Component({
  components: {
    WsHtml,
    WsIcon,
    WsButton
  },
})
export class WsStateBanner extends Vue {
  @Prop({type: String, default: UI_SIZE.LARGE}) readonly size!: UI_SIZE;
  @Prop({type: String, default: UI_VARIANT.DEFAULT}) readonly type!: UI_VARIANT;
  @Prop({type: Boolean, default: false}) readonly closable !: boolean;
  @Prop({type: Boolean, default: false}) readonly icon!: boolean;

  @Prop(String) readonly title!: string;
  @Prop(String) readonly text!: string;
  @Prop(String) readonly button!: string;
  @Prop(String) readonly headerIcon!: string;

  xss = xss;
  isClosed = false;

  get buttonLabel() {
    return this.button ? JSON.parse(this.button).label : '';
  }

  get buttonUrl() {
    return this.button ? JSON.parse(this.button).url : '';
  }

  get buttonIcon() {
    return this.button ? JSON.parse(this.button).icon : '';
  }

  getIcon() {
    if (this.headerIcon) {
      return this.headerIcon;
    }

    switch (this.type) {
    case UI_VARIANT.NEUTRAL:
    case UI_VARIANT.SUCCESS:
      return 'success_full';
    case UI_VARIANT.WARNING:
      return 'alert_triangle_full';
    case UI_VARIANT.ERROR:
      return 'alert_circle_full';
    case UI_VARIANT.DEFAULT:
    default:
      return 'info_full';
    }
  }

  closeBanner() {
    this.isClosed = true;
  }
}

export default WsStateBanner;
