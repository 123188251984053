import { Bundler, config } from '@loopia-group/services';

export async function loadIcon(iconName: string): Promise<any> {
  if (!iconName) {
    return Promise.reject('No iconName provided.');
  }

  const bundler = config.bundler === Bundler.rollup ? 'rollup' : 'webpack';
  const loadFunction = await import(/* @vite-ignore */`./loaders/${bundler}.loader.js`);
  return await loadFunction.default('icon', iconName, config.theme);
}

export async function loadIssuer(issuerName: string): Promise<any> {
  if (!issuerName) {
    return Promise.reject('No issuerName provided.');
  }

  const bundler = config.bundler === Bundler.rollup ? 'rollup' : 'webpack';

  const loadFunction = await import(/* @vite-ignore */`./loaders/${bundler}.loader.js`);
  return await loadFunction.default('issuer', issuerName, config.theme);
}
