var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.cart.summary)?_c('ws-card',{staticClass:"cmp-side-summary ws-typography",class:{ 'q-mb-2xl': _vm.mobile }},[(_vm.loggedIn && _vm.progressByUser[_vm.STEPS.CART] === _vm.COMPLETED)?_c('div',{staticClass:"desktop-section",class:{
      'q-mb-3xl':
        _vm.progressByUser[_vm.STEPS.BILLING_PROFILE] === _vm.COMPLETED ||
        _vm.progressByUser[_vm.STEPS.DOMAIN_PROFILE] === _vm.COMPLETED ||
        _vm.progressByUser[_vm.STEPS.PAYMENT_METHOD] === _vm.COMPLETED,
    }},[_c('div',{staticClass:"changeable-order",class:{ reverse: _vm.isSwedishWorkflow }},[(_vm.progressByUser[_vm.STEPS.BILLING_PROFILE] === _vm.COMPLETED)?_c('q-card-section',{staticClass:"ws-hover-width-trigger",class:_vm.isSwedishWorkflow ? 'q-mt-md' : 'q-mt-none'},[_c('h6',{staticClass:"ws-typo-button q-mb-sm"},[_vm._v(" "+_vm._s(_vm.$t('cart.steps.billing_profile'))+" "),_c('ws-edit',{attrs:{"small":"","name":"edit"},nativeOn:{"click":function($event){return _vm.editCb(_vm.STEPS.BILLING_PROFILE)}}})],1),_c('div',{staticClass:"item q-py-sm q-px-md",class:{
            active: _vm.inEditMode === _vm.STEPS.BILLING_PROFILE,
            error: _vm.stepHasIssues(_vm.STEPS.BILLING_PROFILE),
          }},[_c('profile-info',{attrs:{"densed":"","strong":"","profile":_vm.cart.billingProfile}})],1)]):_vm._e(),(
          _vm.progressByUser[_vm.STEPS.DOMAIN_PROFILE] === _vm.COMPLETED &&
            (_vm.cart.domainProfileRequired ||
              _vm.allDomainProfilesDefined ||
              _vm.isSwedishWorkflow)
        )?_c('q-card-section',{staticClass:"ws-hover-width-trigger",class:_vm.isSwedishWorkflow ? 'q-mt-none' : 'q-mt-md'},[_c('h6',{staticClass:"ws-typo-button q-mb-sm"},[_vm._v(" "+_vm._s(_vm.$t( _vm.isSwedishWorkflow ? 'cart.steps.user_information' : 'cart.steps.domain_profile' ))+" "),(!_vm.allDomainProfilesDefined)?_c('ws-edit',{attrs:{"small":"","name":"edit"},nativeOn:{"click":function($event){return _vm.editCb(_vm.STEPS.DOMAIN_PROFILE)}}}):_vm._e()],1),(_vm.allDomainProfilesDefined)?_c('div',[_c('div',{staticClass:"row items-center q-py-sm"},[_c('div',{staticClass:"domain-profiles-info-icon"},[_c('ws-icon',{attrs:{"name":"info","size":"22"}})],1),_c('q-tooltip',{attrs:{"content-class":"ws-tooltip ws-arrow-bottom","target":".domain-profiles-info-icon","anchor":"top middle","self":"bottom middle"}},[_vm._v(" "+_vm._s(_vm.$t('domain_profile.all_domain_profiles_defined.tooltip'))+" ")]),_c('p',{staticClass:"q-mb-none q-ml-sm specific-domain-profile-text"},[_vm._v(" "+_vm._s(_vm.$t('domain_profile.all_domain_profiles_defined.explicit'))+" ")])],1)]):_c('div',{staticClass:"item q-py-sm q-px-md",class:{
            active: _vm.inEditMode === _vm.STEPS.DOMAIN_PROFILE,
            error: _vm.stepHasIssues(_vm.STEPS.DOMAIN_PROFILE),
          }},[_c('profile-info',{attrs:{"densed":"","strong":"","profile":_vm.domainProfile}})],1)]):_vm._e()],1),(_vm.progressByUser[_vm.STEPS.PAYMENT_METHOD] === _vm.COMPLETED)?_c('q-card-section',{staticClass:"ws-hover-width-trigger q-mt-md"},[_c('h6',{staticClass:"ws-typo-button q-mb-sm"},[_vm._v(" "+_vm._s(_vm.$t('cart.steps.payment_method'))+" "),_c('ws-edit',{attrs:{"small":"","name":"edit"},nativeOn:{"click":function($event){return _vm.editCb(_vm.STEPS.PAYMENT_METHOD)}}})],1),_c('payment-options',{class:{
          active: _vm.inEditMode === _vm.STEPS.PAYMENT_METHOD,
          error: _vm.stepHasIssues(_vm.STEPS.PAYMENT_METHOD),
        },attrs:{"value":_vm.cart.paymentOption,"edit-mode":false,"hide-radio":true,"compact":""}})],1):_vm._e()],1):_vm._e(),_vm._t("beforePrices"),_c('q-card-section',{staticClass:"summary-wrapper q-pa-none"},[_c('h6',{staticClass:"title q-mb-md"},[_vm._v(" "+_vm._s(_vm.$t('summary.title'))+" ")]),_c('small',[_c('table',{staticClass:"price-table",attrs:{"cellspacing":"0","cellpadding":"0","width":"100%"}},[_c('tbody',[_c('tr',[_c('td',[_c('small',[_vm._v(_vm._s(_vm.$t('summary.vat_excluded'))+":")])]),_c('td',{staticClass:"text-bold"},[_c('small',[_vm._v(_vm._s(_vm.$n(_vm.cart.summary.price.vatExcluded, 'currency')))])])]),(_vm.cart.summary.discount)?_c('tr',[_c('td',[_c('small',[_vm._v(_vm._s(_vm.$t('summary.discount'))+":")])]),_c('td',{staticClass:"text-bold text-ws-success"},[_c('small',[_vm._v(_vm._s(_vm.cart.summary.discount))])])]):_vm._e(),_c('tr',[_c('td',[_c('small',[_vm._v(" "+_vm._s(_vm.$t('summary.vat', { value: _vm.cart.summary.price.vatPercentage, }))+": ")])]),_c('td',{staticClass:"text-bold"},[_c('small',[_vm._v(_vm._s(_vm.$n(_vm.priceVat, 'currency')))])])])]),_c('tfoot',{staticClass:"total"},[_c('tr',[_c('td',{staticClass:"text"},[_c('small',[_vm._v(_vm._s(_vm.$t('summary.sum'))+":")])]),_c('td',{staticClass:"price text-right ws-typo-paragraph-desktop"},[_c('strong',[_vm._v(_vm._s(_vm.$n(_vm.cart.summary.price.vatIncluded, 'currency')))])])])])])])]),_vm._t("default")],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }