
import { Component, Vue, Prop } from 'vue-property-decorator';

import { isNumber } from 'lodash-es';
import { PricesItem } from '@/types';
import { resolvePrice } from '@/utilities';
import { Price } from '@/components/domain-checker/dc.types';

@Component({})
export class PricePeriod extends Vue {
  @Prop()
  readonly price!: number | null | undefined | Price;
  @Prop()
  readonly prices!: PricesItem[];
  @Prop({ default: 12 })
  readonly period!: number; // in months
  @Prop({ default: 1 })
  readonly multiplier!: number; // to multiply price by some value
  @Prop(Boolean)
  readonly periodUnder!: number; // change period placing
  @Prop(Boolean)
  readonly plain!: boolean; // plain color
  @Prop(Boolean)
  readonly individual!: boolean; // individual state allowed
  @Prop(String)
  readonly between!: string; // inline content between price and period
  @Prop(Boolean)
  readonly hideFree!: boolean;
  @Prop(Boolean)
  readonly from!: boolean; // adds "from" word, for example: "from 100€/year"
  @Prop(Boolean)
  readonly verbose!: boolean; // longer period words "mesacne" instead of "mes."

  get actualPrice() {
    let price = resolvePrice(this.prices, this.period);

    if (price === undefined) {
      price = resolvePrice(this.price, this.period);
    }
    if (this.multiplier && isNumber(price)) {
      //@ts-ignore
      price *= this.multiplier;
    }

    return price;
  }

  get units() {
    return this.period % 12 ? 'months' : 'years';
  }

  get periodCalc() {
    return this.units === 'years' ? this.period / 12 : this.period;
  }

  get periodKey() {
    return `wsk.general.period.per${this.verbose ? '' : '.short'}.${
      this.units
    }`;
  }
}

export default PricePeriod;
