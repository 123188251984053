import Router from 'vue-router';
import ProductItem from '@/modules/iwp/components/Items/ProductItem.vue';

import Product from './views/Product.vue';
import type {IwpTreeConfig, IwpTreeBranch} from './index.d';
import {CategoryItemConfig} from './components/Items/ProductItem';
import {initConfig} from '@/modules/iwp/store';
import config from '@/services/config.service';
import {CompanyId} from '@/WS_UIkit/src/const';

export const setupIwp = (router: Router): void => {
  if(
    config.company === CompanyId.LOOPIA_SE // enable it only for Loopia.se production & staging
    // env === 'development' // only for dev/testing
  ) {
    initConfig(iwpConfig);
    setupRoutes(router);
  }
};

const setupRoutes = (router: Router) => {
  if (router) {
    router.addRoute({
      name: 'IWP',
      path: '/iwp/:path',
      component: Product,
    });
  }
};

const iwpConfig: IwpTreeConfig = {
  childNodes: {
    website: {
      title: 'modules.i_want_product.website.step_1.title',
      categories: [
        {label: 'modules.i_want_product.website.categories.website.nonEcommerce', value: 'website.nonEcommerce'},
        {label: 'modules.i_want_product.website.categories.website.ecommerce', value: 'website.ecommerce'},
      ],
      component: ProductItem,
      props: {
        name: 'modules.i_want_product.website.ecommerce.websiteBuilder',
        title: 'modules.i_want_product.website.ecommerce.websiteBuilder.title',
        prices: 2,
        period: 'week',
        benefits: {},
        category: 'test'
      } as CategoryItemConfig,
      childNodes: {
        ecommerce: {
          // eslint-disable-next-line sonarjs/no-duplicate-string
          title: 'modules.i_want_product.website.step_2.title',
          component: ProductItem,
          childNodes: {
            hosting: {
              title: 'modules.i_want_product.website.step_2.title',
              benefits: [
                'capacity',
                'webpages',
                'mailboxes',
                'free_ssl'
              ],
            } as IwpTreeBranch,
            websiteBuilder: {
              title: 'modules.i_want_product.website.step_2.title',
              benefits: [
                'capacity',
                'webpages',
                'mailboxes',
                'free_ssl'
              ],
            } as IwpTreeBranch,
          },
          props: {
            name: 'module.i_want_product.website.ecommerce.websiteBuilder',
            title: 'module.i_want_product.website.ecommerce.websiteBuilder.title',
            prices: 2,
            period: 'week',
            benefits: {},
            category: 'test'
          } as CategoryItemConfig,
        },
        nonEcommerce: {
          title: 'modules.i_want_product.website.step_2.title',
          component: ProductItem,
          childNodes: {
            hosting: {
              title: 'modules.i_want_product.website.step_2.title',
              benefits: [
                'capacity',
                'webpages',
                'mailboxes',
                'free_ssl'
              ],
            } as IwpTreeBranch,
            websiteBuilder: {
              title: 'modules.i_want_product.website.step_2.title',
              benefits: [
                'capacity',
                'webpages',
                'mailboxes',
                'free_ssl'
              ],
            } as IwpTreeBranch,
          },
          props: {
            name: 'module.i_want_product.website.nonEcommerce.websiteBuilder',
            title: 'module.i_want_product.website.nonEcommerce.websiteBuilder.title',
            prices: 2,
            period: 'week',
            benefits: {},
            category: 'test'
          } as CategoryItemConfig,
        },
      }
    },
  }
};
