
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {UI_SIZE} from './const.enum';
import {loadIcon} from '../assets/iconLoader';

@Component
export class WsCountryFlag extends Vue {
  @Prop({type: String, required: true}) readonly country!: string;
  @Prop({type: String, default: UI_SIZE.MEDIUM}) readonly size!: UI_SIZE;

  backgroundImage = '';

  @Watch('country', {immediate: true})
  async loadCountry() {
    try {
      this.backgroundImage = await loadIcon('flags/' + this.country.toUpperCase());
    } catch (err) {
      this.$messageService.errorHandler('wscountryflag-loadFlag')(err);
    }
  }
}

export default WsCountryFlag;
