
import {Component, Vue, Prop} from 'vue-property-decorator';

import WsButton from './WsButton.vue';
import {redirect} from '@loopia-group/utils';

@Component({
  components: {
    WsButton,
  },
})
export class WsBack extends Vue {
  @Prop({default: null}) readonly callback!: Function | null;
  @Prop(String) readonly url!: string;
  @Prop({type: String, default: 'arrow_left'}) readonly icon!: string;
  @Prop(Boolean) readonly isCancel!: boolean;

  goBack() {
    if (this.callback) {
      this.callback();
    } else if (this.url) {
      redirect(this.url);
    } else if (this.$router) {
      this.$router.go(-1);
    }
  }
}

export default WsBack;
