
import xss from 'xss';
import { CreateElement, RenderContext } from 'vue';

export default {
  functional: true,
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    element: {
      type: String,
      default: 'span'
    }
  },
  render(h: CreateElement, context: RenderContext)  {
    const sanitizedHtml = xss(context.props.value);

    return h(context.props.element, {
      domProps: {
        innerHTML: sanitizedHtml,
      },
    });
  },
};
