var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"cmp-mandatory-settings ws-typography",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;!_vm.uncompleteMandatory && _vm.mayGoNext()}}},[_c('div',{staticClass:"title-section",class:[_vm.desktop ? 'q-mb-2xl' : 'q-mb-xl']},[_c('h5',[_vm._v(_vm._s(_vm.$t('service_settings.title')))]),_c('small',[_vm._v(_vm._s(_vm.$t('service_settings.intro')))])]),(_vm.stepHasIssues(_vm.step))?_c('ws-message',{class:{ 'q-mb-lg': _vm.editMode },attrs:{"banner":"","path":'cart.' + _vm.step,"type":"alert","messages":[
      {
        translationKey: 'cart.invalid_step.' + _vm.step,
      },
    ]}}):_vm._e(),(!_vm.stepHasIssues(_vm.step) || _vm.editMode)?_c('mandatory-settings',{ref:"mandatoryFields",attrs:{"path":_vm.PATH,"edit-mode":_vm.editMode},on:{"user-action-taken":_vm.clearMessages}}):_vm._e(),_c('div',{staticClass:"row full-width justify-center",class:[_vm.editMode ? 'q-mt-xl' : '']},[_c('order-process-error',{attrs:{"path":'cart.' + _vm.step}}),_c('order-process-actions',{attrs:{"button-text":_vm.$t(
          _vm.step === _vm.userStep
            ? 'service_settings.submit_btn'
            : 'wsk.general.save_changes'
        ),"path":[_vm.PATH, 'cart.' + _vm.step],"step":_vm.step,"disable":_vm.uncompleteMandatory},on:{"click":_vm.mayGoNext}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }