
import { Component, Vue, Watch } from 'vue-property-decorator';

import WsButton from '@WS_Components/WsButton.vue';
import WsBack from '@WS_Components/WsBack.vue';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsInput from '@WS_Components/WsInput.vue';
import { commonModel } from './commonModel';

const defaults = {
  btn: {
    hasLabel: true,
    label: 'Button',
    hasIcon: false,
    iconRight: false,
    icon: 'cart',
    variation: 'primary',
    size: 'lg',
    loading: false,
    dark: false,
    isCancel: false,
  },
};

@Component({
  components: {
    WsButton,
    WsBack,
    WsSelect,
    WsInput,
  },
})
export default class ButtonTestPage extends Vue {
  sizes = [undefined, 'small', 'smaller'];
  states = ['default', 'disabled'];
  variations = ['primary', 'secondary', 'light', 'ghost'];
  inlineSizes = [undefined, 'small'];
  inlineColors = [undefined, 'white', 'ws-base100'];
  selectedOption = '';
  customValue = '';

  model = {
    ...commonModel,
    ...defaults,
    page: {
      ...commonModel.page,
      style: {
        ...commonModel.page?.style,
        background: '#E5E5E5',
      },
    },
    container: { size: 2 },
  };

  backCallback() {
    // eslint-disable-next-line no-console
    console.log('back button clicked');
  }

  created() {
    this.$emit('model', this.model);
  }

  @Watch('model.btn.dark')
  onDarkModeChanged(dark: boolean) {
    this.model.page.style.background = dark ? 'var(--ws-base150)' : '#E5E5E5';
  }
}
