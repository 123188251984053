
import {
  QItem,
  QItemSection,
  QItemLabel,
  QAvatar,
  QSeparator,
  QField,
} from 'quasar';

import { debounce, omit } from 'lodash-es';
import {config} from '@loopia-group/services';
import WsFieldMixin from './WsFieldMixin.vue';
import {WsInput} from './WsInput.vue';
import {WsSelect} from './WsSelect.vue';
import {WsCountryFlag} from './WsCountryFlag.vue';
import phonePrefixes from './phonePrefixes.json';
import { computed, defineComponent, Ref, ref, watch } from 'vue';
import { useTranslation } from '../composables/translation';

interface PhonePrefix {
  id: string;
  country: string;
  code: string;
  label?: string;
  preferred?: true;
}

const preferredPrefixes = {
  HU: ['SK', 'CZ', 'SE'],
  SK: ['CZ', 'HU', 'SE'],
  CZ: ['SK', 'HU', 'SE'],
  SE: ['NO', 'DE', 'FI']
};

export default defineComponent({
  name: 'WsPhoneInput',
  components: {
    QItem,
    QItemSection,
    QItemLabel,
    QAvatar,
    QSeparator,
    WsCountryFlag,
    WsSelect,
    WsInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    restrict: {
      type: String,
      default: '0-9 +',
    },
    optionalText: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  // eslint-disable-next-line
  setup(props, vm) {
    const {$t} = useTranslation();

    const options: PhonePrefix[] = phonePrefixes.map(prefix => ({
      ...prefix,
      label: `${prefix.country} (${prefix.id})`.toLocaleLowerCase(),
    }));

    const prefix: Ref<PhonePrefix> = ref(options[0]);
    const phoneNumber: Ref<String> = ref(props.value);
    const numOfSuggested: Ref<number> = ref(0);
    const preferredOptions: PhonePrefix[] = [];

    const input = ref(null);
    const select = ref(null);

    let fullValue = '';
    let suggestedAdded = false;

    /***  Methods  ***/

    const findPrefixByCountry = (country: string) => {
      const id = country.toLowerCase();
      return options.find((prefix: PhonePrefix) => prefix.id === id);
    };

    const addPreferred = (countries: string[]) => {
      countries.forEach((country: string) => {
        const found = findPrefixByCountry(country);
        if (found) {
          preferredOptions.push(found);
        }
      });
    };

    const presetPrefixes = () => {
      const country = config.companyCountry;

      if (!props.value) {
        prefix.value = findPrefixByCountry(country) || options[0];
      }

      // add additional suggested prefixes for selected country
      const countries = (preferredPrefixes as Record<string, string[]>)[country] || [];

      // condition fixes multiple adding, ref: PNO-1350
      if (!suggestedAdded) {
        addPreferred(countries);
        // add preselected prefix at the top
        preferredOptions.unshift(prefix.value);
      }

      numOfSuggested.value = (countries && countries.length + 1) || 0;
      suggestedAdded = true;
    };

    const getPrefixFromNumber = (value: string): PhonePrefix | null => {
      const foundIndex = options.findIndex((prefix: PhonePrefix) =>
        value.startsWith(prefix.code)
      );

      if (foundIndex > -1 && options[foundIndex]) {
        return options[foundIndex];
      }

      return null;
    };

    const _handleValue = debounce (() => {
      let newNumber: string;
      let newPrefix: PhonePrefix | null;

      // Prepare/clean up the phone number
      newNumber = phoneNumber.value
      // Replace double zeroes with a +
        .replace(/^00/, '+')
      // Remove spaces between numbers
        .replace(/(\s|\.)/g, '');

      // Set the prefix automatically if entered with the phone number
      if (newNumber.startsWith('+')) {
        newPrefix = getPrefixFromNumber(newNumber);

        if (newPrefix) {
          prefix.value = newPrefix;
          newNumber = newNumber.replace(newPrefix.code, '');
        }
      }

      phoneNumber.value = newNumber;

      validate();
      (input.value as unknown as HTMLElement)?.focus();

      // Emit full phone number with prefix if it changed
      const newFullValue = phoneNumber.value ? prefix.value.code + phoneNumber.value : '';
      if (fullValue !== newFullValue) {
        fullValue = newFullValue;
        vm.emit('input', fullValue);
      }
    }, 100);


    const _validate = async () => {
      // TODO: fix after WsFieldMixin rewrite
      (input.value as unknown as WsFieldMixin)?.clearMessages?.();
      return (input.value as unknown as QField)?.validate();
    };

    /***  On created  ***/

    presetPrefixes();


    /***  Computed  ***/
    const optionsOrdered = computed(() => {
      return [...preferredOptions, ...options];
    });

    // TODO: fix after WsFieldMixin rewrite
    const messages = computed(() => (input.value as unknown as WsFieldMixin)?.messages || []);

    const hasMessage = computed((): boolean => {
      return !!messages.value.length;
    });

    const message = computed(() => {
      return hasMessage.value
        ? $t(messages.value[0].translationKey, messages.value[0].values)
        : '';
    });

    /***  Throttles  ***/

    const validate = debounce(_validate);


    /***  Watchers  ***/

    // Set the prefix automatically if entered into the input
    watch(
      () => props.value,
      _handleValue,
      {immediate: false}
    );

    // New prefix
    watch(
      [phoneNumber, prefix],
      () => {
        _handleValue();
      }
    );


    return {
      // Refs
      input,
      select,

      // Variables
      prefix,
      numOfSuggested,

      // Methods
      omit,

      // Computed
      message,
      optionsOrdered: optionsOrdered.value as Array<any>,
      phoneNumber,
      hasMessage,
    };

  },
});
