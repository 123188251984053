export enum ROUTENAMES {
  HOME = 'home',
  CHECKOUT = 'checkout',
  ORDER_PAGE = 'order-page',
  ORDER = 'order',
  CART = 'cart',
  DEVELOPER = 'developer',
  DASHBOARD = 'dashboard',
  COMPONENTS = 'components',
  STATE = 'state',
  CONFIG = 'config',
  THANK_YOU = 'thank-you',
  DOMAIN_CHECKER = 'domain-checker',
  EMPTY_CART = 'empty-cart',
  OPTIONAL = 'mandatory', // mandatory as optional step  ¯\_(ツ)_/¯
  SERVER_FORM = 'server',
  MONITORING_FORM = 'monitoring',
  MANAGEMENT_FORM = 'management',
  IWP_PRODUCT = 'IWP',
}

export enum WORKFLOW {
  // !CAUTION: numbers are required, otherwise it will count
  // from 0 and will mismatch from BE numbering
  STANDARD = 1, // other countries
  SWEDISH = 2,
}
