
import { Component, Vue, Prop } from 'vue-property-decorator';
import { QIcon } from 'quasar';
import { mdiPlus } from '@quasar/extras/mdi-v4';

import WsIcon from '@WS_Components/WsIcon.vue';
import migrationBirdsImg from '@/assets/images/Migracia.svg';
import migrationImg from '@/assets/images/Cary/cary-migration.png';

@Component({
  components: {
    QIcon,
    WsIcon,
  },
})
export class MigrationRecap extends Vue {
  @Prop({ default: [] })
  readonly files!: { clientFileName: string }[];

  data() {
    return {
      migrationBirdsImg,
      migrationImg,
      mdiPlus,
    };
  }
}
export default MigrationRecap;
