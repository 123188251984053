
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import { computed, ComputedRef, Ref, ref, watchEffect } from 'vue';
import { useTranslation } from '@/WS_UIkit/src/composables/translation';
import { CartItem } from '@/types';
import xss from 'xss';


export default {
  components: {
    WsStateBanner
  },
  props: {
    item: {
      type: Object as unknown as CartItem,
      required: true,
    }
  },

  setup(props: any) {
    const $t = (translationKey: string, variables?: any) => {
      const $t = useTranslation().$t;
      return xss($t(translationKey, variables));
    };
    const title: Ref<String> = ref('');
    const text: Ref<String> = ref('');
    const customDisplayCondition: Ref<Boolean> = ref(true);

    const shouldDisplay: ComputedRef<Boolean> = computed(
      () => { return customDisplayCondition.value && !!title.value && !!text.value; }
    );

    function fillOutBMPostfixMigrationData(): void {
      title.value = $t('cart_item.notices.business_mail.license_count_increase.title') as string;
      text.value = $t(
        'cart_item.notices.business_mail.license_count_increase.text',
        { count: props.item.properties.minimumNumberOfLicenses }
      ) as string;

      watchEffect(() => {
        customDisplayCondition.value = !props.item.properties.hasEnoughNumberOfLicenses;
      });
    }

    function fillOutFreeTrialBMData(): void {
      title.value = $t('cart_item.notices.business_mail.free_trial.title') as string;
      text.value = $t('cart_item.notices.business_mail.free_trial.text') as string;
    }

    function fillOutFreeTrialBMPostfixMigrationData(): void {
      title.value = $t('cart_item.notices.business_mail.free_trial_postfix.title') as string;
      text.value = $t(
        'cart_item.notices.business_mail.free_trial_postfix.text',
        { count: props.item.properties.minimumNumberOfLicenses }
      ) as string;
    }

    function fillOutBMMinimumNumberOfPremiumLicensesData(): void {
      title.value = $t('cart_item.notices.business_mail.free_trial_premium_licenses_notice.title',
        { count: props.item.properties.minimumNumberOfLicenses }
      ) as string;
      text.value = $t(
        'cart_item.notices.business_mail.free_trial_premium_licenses_notice.text',
        { count: props.item.properties.minimumNumberOfLicenses }
      ) as string;
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    watchEffect(() => {
      if (props.item?.code === 'business-mail') {
        if (props.item?.properties?.freeTrial) {
          if (props.item?.properties?.minimumNumberOfLicenses) {
            fillOutFreeTrialBMPostfixMigrationData();
          } else {
            fillOutFreeTrialBMData();
          }
        } else if (props.item?.properties?.minimumNumberOfLicenses) {
          fillOutBMPostfixMigrationData();
        }
      }

      if (props.item?.code === 'business_mail_premium' && props.item?.properties?.minimumNumberOfLicenses) {
        fillOutBMMinimumNumberOfPremiumLicensesData();
      }
    });

    return {
      title,
      text,
      shouldDisplay
    };
  }
};
