import { CartItem } from '@/types';
import { store } from '@/store/store';
import { StateMutations } from '@/store/const.enum';

export function toggleEditItem(item: CartItem | null) {
  const currentItemInEdit = store.state.cartService.itemInEdit;
  store.commit(StateMutations.SET_STATE, {
    path: 'cartService',
    prop: 'itemInEdit',
    value: item && item.id !== currentItemInEdit?.id ? item : null,
  });
}

export function updateEditItem(item: CartItem | null) {
  store.commit(StateMutations.SET_STATE, {
    path: 'cartService',
    prop: 'itemInEdit',
    value: item ? item : null,
  });
}

export function setLoader(namespace: number, value?: boolean) {
  const payload = {
    path: 'cartService.loaders',
    prop: namespace,
    value,
  };
  if (value) {
    store.commit(StateMutations.SET_STATE, payload);
  } else {
    delete payload.value;
    store.commit(StateMutations.REMOVE_STATE, payload);
  }
}

export function getLoaders() {
  return store.state.cartService.loaders;
}
