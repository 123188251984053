
import {Component, Vue} from 'vue-property-decorator';

import oldCompImage from '../assets/images/Image_OldComp.svg';
import chromeIcon from '../assets/images/Images_Chrome.svg';
import ffIcon from '../assets/images/Images_Firefox.svg';
import {isOutdatedBrowser, isIE} from '@loopia-group/utils';
import xss from 'xss';

@Component({})
export class WsOutdatedModal extends Vue {
  get el(): HTMLElement {
    return this.$refs['outdated-modal'] as HTMLElement;
  }

  data() {
    return {
      oldCompImage,
      chromeIcon,
      ffIcon,
    };
  }

  mounted() {
    if (isOutdatedBrowser() || isIE()) {
      this.show();
    }
  }

  xss = xss;

  show() {
    const el = this.el;
    el.className = el.className + ' show';
  }

  clickOutside(e: MouseEvent) {
    const contentEl = this.$refs['dialogbox-content'] as Node;
    if (_contains(contentEl, e.target as Node) || contentEl === e.target) {
      return;
    }
    this.hide();
  }

  hide() {
    const el = this.el;
    el.className = el.className.replace('show', '');
  }
}

export default WsOutdatedModal;

function _contains(parent: Node | null, child: Node | null) {
  while (child) {
    child = child.parentNode;
    if (child === parent) {
      return true;
    }
  }
  return false;
}
