// productsFiltered transformation from Product.vue, should probably move it to store or separate service/util
export const filterProducts = (products: Object, selectedCategory: string): Array<object> => Object.entries(products)
  .filter((item) => item[0].includes(selectedCategory))
  .map(((item: [string, any]) => {
    return {
      name: item[0],
      packages: [...item[1]],
      prices: {
        ...item[1][0].defaultConfiguration.prices[1]
      },
    };
  }));

export const getPackages = (products: {[index: string]:any}, selectedProduct: string) => products[selectedProduct]?.map(
  (item: any) => {
    return ({
      path: selectedProduct,
      productName: item.name,
      ...item.defaultConfiguration.configuration,
      prices: item.defaultConfiguration.prices[1],
      properties: item.properties,
    });
  }
);
