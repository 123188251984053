import { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import network from './network.service';
import { getCookieValue } from '@loopia-group/utils';
import {
  HostingTutorialStandaloneProductLocalOption,
  HostingTutorialStandaloneProductLocalOptions,
  HostingTutorialStandaloneProductPropertyOption,
  HostingTutorialStandaloneProduct,
  HostingTutorialStandaloneProductProperty,
  HostingTutorialStandaloneProductPropertyOptionAttributeConditional,
} from '@/types';
import { getSnakedKeyFactory } from '@loopia-group/utils';
import store from '@/store';
import { HOSTING_TUTORIAL_COOKIE } from '@/services/const';
import {StateMutations} from '@/store/const.enum';

export function hostingTutorialGetProducts(): AxiosPromise<
  HostingTutorialStandaloneProduct[]
  > {
  const networkObject: AxiosRequestConfig = {
    method: 'get',
    url: '/hosting-tutorial',
  };

  return network(networkObject).then(
    (response): AxiosResponse => {
      return response.data;
    }
  );
}

export function hostingTutorialPatchCloseType(
  isSkipped: boolean
): Promise<AxiosResponse<string>> {
  const networkObject: AxiosRequestConfig = {
    method: 'patch',
    url: '/cart/hosting-tutorial-record',
    data: {
      noHostingSelected: !isSkipped,
      skipped: isSkipped,
    },
  };

  return network(networkObject).then(
    (response): AxiosResponse => {
      return response.data;
    }
  );
}

export function hostingTutorialSetProducts(): Promise<AxiosResponse<
  HostingTutorialStandaloneProduct[]
> | void> | null {
  const cookie = getCookieValue(HOSTING_TUTORIAL_COOKIE);

  if (cookie === 'test') {
    return null;
  }

  return hostingTutorialGetProducts().then(products => {
    if (products) {
      store.commit(StateMutations.SET_HOSTING_TUTORIAL_PRODUCTS, products);

      return products;
    }
  });
}

export function hostingTutorialGetPreviousStep(stepCode: string): string {
  if (!stepCode) {
    return '';
  }

  const stepArray = stepCode!.split('_');
  stepArray.pop();

  return stepArray.join('_');
}

export function hostingTutorialGetExcludedProperties(
  properties: Record<string, any>
): Set<string> {
  const excluded: Set<string> = new Set([]);

  Object.keys(properties).forEach((key: string) => {
    if (excluded.has(key)) {
      return;
    }

    const options = properties[key].options;

    if (options) {
      options.forEach((option: Record<string, any>) => {
        const conditionalValues: HostingTutorialStandaloneProductPropertyOption[] =
          option.conditionalValues;

        if (conditionalValues) {
          conditionalValues.forEach(
            (item: HostingTutorialStandaloneProductPropertyOption) => {
              if (item.property!) {
                excluded.add(item.property!);
              }
            }
          );
        }
      });
    }
  });

  return excluded;
}

/**
 * Will output label translation string for option in
 * the HostingAddToCart select
 * @param productName {string}
 * @param propertyName {string}
 * @param value {Record<string, any> | string}
 * @param additionalProperties {Record<string, string | number> | null}
 */
export function hostingTutorialGetOptionLabel(
  productName: string,
  propertyName: string,
  value: Record<string, any> | string,
  additionalProperties: Record<string, string | number> | null = null
): string {
  let key = `${productName}_property_${propertyName}_option`;

  if (typeof value === 'string' && !additionalProperties) {
    key += `_${value}`;
  }

  if (typeof value === 'string' && productName === 'site-builder') {
    return hostingTutorialGetProductTranslation(value, 'product.site_builder');
  }

  return hostingTutorialGetProductTranslation(key);
}

export function hostingTutorialGetProductTranslation(
  key: string,
  base: string = 'hosting_tutorial.add_to_cart'
): string {
  const getSnakedTranslation = getSnakedKeyFactory(base);

  return getSnakedTranslation(key);
}

export function hostingTutorialGetOptions(
  property: HostingTutorialStandaloneProductProperty,
  product: HostingTutorialStandaloneProduct
): HostingTutorialStandaloneProductLocalOptions {
  if (!property) {
    return [];
  }

  const options: HostingTutorialStandaloneProductLocalOption[] = [];
  const { name } = property;
  let itemOption: HostingTutorialStandaloneProductLocalOption | null = null;

  if (!property.options) {
    return [];
  }

  property.options.forEach(
    (option: HostingTutorialStandaloneProductPropertyOption) => {
      if (option.conditionalValues) {
        option.conditionalValues.forEach(
          (
            item: HostingTutorialStandaloneProductPropertyOptionAttributeConditional
          ) => {
            const value = {
              [name]: `${option.value}`,
              [item.property!]: `${item.value}`,
            };
            const label = hostingTutorialGetOptionLabel(
              product.name,
              name,
              value,
              option.additionalProperties
            );

            itemOption = {
              label,
              value,
              prices: item.prices,
            };
            options.push(itemOption);
          }
        );
      } else {
        const value = {
          [name]: `${option.value}`,
        };
        const label = hostingTutorialGetOptionLabel(
          product.name,
          name,
          option.value,
          option.additionalProperties
        );

        if (!option.prices) {
          return;
        }

        itemOption = {
          label,
          value,
          prices: option.prices,
          additionalProperties: option.additionalProperties,
        };
        options.push(itemOption);
      }
    }
  );

  return options;
}
