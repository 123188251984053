/* eslint-disable sonarjs/no-duplicate-string */
import { HostingTutorialStandaloneProduct, HostingTutorialStep } from '@/types';
import {HOSTING_TUTORIAL_STEPS} from '@/services/const';
import { HostingTutorialStepTypes } from '@/services/const.enum';

export const hostingTutorialStepSkip: Record<string, HostingTutorialStep> = {
  step: {
    code: 'step',
    title: 'hosting_tutorial.step_title',
    text: 'hosting_tutorial.step_text',
    question: 'hosting_tutorial.step_question',
    description: 'hosting_tutorial.step_description',
    stepCounter: 1,
    options: [
      {
        title: 'hosting_tutorial.step.option3_title',
        text: 'hosting_tutorial.step.option3_text',
        name: 'hosting_tutorial.step.option3_name',
        toStep: HOSTING_TUTORIAL_STEPS.SKIP,
      },
    ],
    type: HostingTutorialStepTypes.Default,
  },
};

export const hostingTutorialStepsTransfer: Record<
  string,
  HostingTutorialStep
> = {
  step: {
    code: 'step',
    title: 'hosting_tutorial.step_transfer_title',
    text: 'hosting_tutorial.step_transfer_text',
    question: 'hosting_tutorial.step_transfer_question',
    description: 'hosting_tutorial.step_transfer_description',
    stepCounter: 1,
    options: [
      {
        title: 'hosting_tutorial.step_transfer.option1_title',
        text: 'hosting_tutorial.step_transfer.option1_text',
        name: 'hosting_tutorial.step_transfer.option1_name',
        toStep: 'close',
      },
      {
        title: 'hosting_tutorial.step_transfer.option2_title',
        text: 'hosting_tutorial.step_transfer.option2_text',
        name: 'hosting_tutorial.step_transfer.option2_name',
        toStep: 'step_1',
      },
    ],
    type: HostingTutorialStepTypes.Default,
  },
  step_1: {
    code: 'step_1',
    title: 'hosting_tutorial.step_1_transfer_title',
    text: 'hosting_tutorial.step_1_transfer_text',
    question: 'hosting_tutorial.step_1_transfer_question',
    description: 'hosting_tutorial.step_1_transfer_description',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['self'],
  },
};

export const hostingTutorialStepsMain: Record<string, HostingTutorialStep> = {
  // Default screen
  step: {
    code: 'step',
    title: 'hosting_tutorial.step_title',
    text: 'hosting_tutorial.step_text',
    question: 'hosting_tutorial.step_question',
    description: 'hosting_tutorial.step_description',
    stepCounter: 1,
    options: [
      {
        title: 'hosting_tutorial.step.option1_title',
        text: 'hosting_tutorial.step.option1_text',
        name: 'hosting_tutorial.step.option1_name',
        toStep: 'step_1',
        dataTracking: 'tutorial_0_1'
      },
      {
        title: 'hosting_tutorial.step.option2_title',
        text: 'hosting_tutorial.step.option2_text',
        name: 'hosting_tutorial.step.option2_name',
        toStep: 'step_2',
        dataTracking: 'tutorial_0_2'
      },
      {
        title: 'hosting_tutorial.step.option3_title',
        text: 'hosting_tutorial.step.option3_text',
        name: 'hosting_tutorial.step.option3_name',
        toStep: HOSTING_TUTORIAL_STEPS.SKIP,
        dataTracking: 'tutorial_0_3'
      },
    ],
    type: HostingTutorialStepTypes.Default,
  },
  // First steps
  step_1: {
    code: 'step_1',
    title: 'hosting_tutorial.step_1_title',
    text: 'hosting_tutorial.step_1_text',
    question: 'hosting_tutorial.step_1_question',
    description: 'hosting_tutorial.step_1_description',
    stepCounter: 2,
    options: [
      {
        title: 'hosting_tutorial.step_1.option1_title',
        text: 'hosting_tutorial.step_1.option1_text',
        name: 'hosting_tutorial.step_1.option1_name',
        toStep: 'step_1_1',
        dataTracking: 'tutorial_1_1'
      },
      {
        title: 'hosting_tutorial.step_1.option2_title',
        text: 'hosting_tutorial.step_1.option2_text',
        name: 'hosting_tutorial.step_1.option2_name',
        toStep: 'step_1_2',
        dataTracking: 'tutorial_1_2'
      },
      {
        title: 'hosting_tutorial.step_1.option3_title',
        text: 'hosting_tutorial.step_1.option3_text',
        name: 'hosting_tutorial.step_1.option3_name',
        toStep: 'step_1_3',
        dataTracking: 'tutorial_1_3'
      },
    ],
    type: HostingTutorialStepTypes.Default,
  },
  step_2: {
    code: 'step_2',
    title: 'hosting_tutorial.step_2_title',
    text: 'hosting_tutorial.step_2_text',
    stepCounter: 4,
    options: [
      {
        title: 'hosting_tutorial.step_1_1.option1_title',
        text: 'hosting_tutorial.step_1_1.option1_text',
        name: 'hosting_tutorial.step_1_1.option1_name',
        toStep: 'step_1_1',
        dataTracking: 'tutorial_2_1',
      }
    ],
    type: HostingTutorialStepTypes.List,
    products: ['self'],
  },
  // Sub steps
  step_1_1: {
    code: 'step_1_1',
    title: 'hosting_tutorial.step_1_1_title',
    text: 'hosting_tutorial.step_1_1_text',
    question: 'hosting_tutorial.step_1_1_question',
    description: 'hosting_tutorial.step_1_1_description',
    stepCounter: 3,
    options: [
      {
        title: 'hosting_tutorial.step_1_1.option1_title',
        text: 'hosting_tutorial.step_1_1.option1_text',
        name: 'hosting_tutorial.step_1_1.option1_name',
        toStep: 'step_1_1_1',
        dataTracking: 'tutorial_1_1_1',
      },
      {
        title: 'hosting_tutorial.step_1_1.option2_title',
        text: 'hosting_tutorial.step_1_1.option2_text',
        name: 'hosting_tutorial.step_1_1.option2_name',
        toStep: 'step_1_1_2',
        dataTracking: 'tutorial_1_1_2',
      },
    ],
    type: HostingTutorialStepTypes.Default,
    products: ['self'],
  },
  step_1_2: {
    code: 'step_1_2',
    title: 'hosting_tutorial.step_1_2_title',
    text: 'hosting_tutorial.step_1_2_text',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['advised', 'email'],
  },
  step_1_3: {
    code: 'step_1_3',
    title: 'hosting_tutorial.step_1_3_title',
    text: 'hosting_tutorial.step_1_3_text',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['advised', 'redirect'],
  },
  step_3_1: {
    code: 'step_3_1',
    title: 'hosting_tutorial.step_3_1_title',
    text: 'hosting_tutorial.step_3_1_text',
    question: 'hosting_tutorial.step_3_1_question',
    description: 'hosting_tutorial.step_3_1_description',
    stepCounter: 2,
    options: [
      {
        title: 'Title',
        text: 'text',
        name: 'something',
        toStep: 'step_1_1',
        dataTracking: 'tutorial_3_1',
      },
    ],
    type: HostingTutorialStepTypes.Default,
  },
  // Sub steps
  step_1_1_1: {
    code: 'step_1_1_1',
    description: 'hosting_tutorial.step_1_1_1_description',
    title: 'hosting_tutorial.step_1_1_1_title',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['site-builder'],
    showAllLink: true,
  },
  step_1_1_2: {
    code: 'step_1_1_2',
    title: 'hosting_tutorial.step_1_1_2_title',
    description: 'hosting_tutorial.step_1_1_2_description',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['wordpress'],
    showAllLink: true,
  },
  step_1_1_3: {
    code: 'step_1_1_3',
    title: 'hosting_tutorial.step_1_1_3_title',
    description: 'hosting_tutorial.step_1_1_3_description',
    stepCounter: 4,
    options: [],
    type: HostingTutorialStepTypes.List,
    products: ['web', 'business'],
    showAllLink: true,
  },
};

export const hostingTutorialStepsConfig: Record<string, any> = {
  main: hostingTutorialStepsMain,
  transfer: hostingTutorialStepsTransfer,
};

export const hostingTutorialMockProducts: HostingTutorialStandaloneProduct[] = [
  {
    name: 'hosting-business',
    properties: { domain: { name: 'domain' } },
    minPriceConfiguration: {
      actualPrice: 100,
      period: 12,
    },
    defaultConfiguration: {
      configuration: {},
      prices: [
        { period: 1, actualPrice: 26.98, marketingPrice: 26.98 },
        { period: 12, actualPrice: 269.89, marketingPrice: 323.76 },
      ],
    },
    locations: ['self', 'site-builder'],
  },
  {
    name: 'the-hosting-wordpress',
    minPriceConfiguration: {
      actualPrice: 100,
      period: 12,
    },
    properties: {
      capacity: {
        name: 'capacity',
        options: [
          {
            value: '1024',
            conditionalValues: [
              {
                property: 'domainsCount',
                value: '1',
                prices: [
                  { period: 1, actualPrice: 2.39, marketingPrice: 2.39 },
                  { period: 3, actualPrice: 7.17, marketingPrice: 7.17 },
                  { period: 6, actualPrice: 14.34, marketingPrice: 14.34 },
                  { period: 12, actualPrice: 23.88, marketingPrice: 23.88 },
                ],
              },
            ],
          },
          {
            value: '102400',
            conditionalValues: [
              {
                property: 'domainsCount',
                value: '3',
                prices: [
                  { period: 1, actualPrice: 4.79, marketingPrice: 4.79 },
                  { period: 3, actualPrice: 14.37, marketingPrice: 14.37 },
                  { period: 6, actualPrice: 28.74, marketingPrice: 28.74 },
                  { period: 12, actualPrice: 35.88, marketingPrice: 35.88 },
                ],
              },
            ],
          },
          {
            value: '153600',
            conditionalValues: [
              {
                property: 'domainsCount',
                value: '5',
                prices: [
                  { period: 1, actualPrice: 7.19, marketingPrice: 7.19 },
                  { period: 3, actualPrice: 21.57, marketingPrice: 21.57 },
                  { period: 6, actualPrice: 43.14, marketingPrice: 43.14 },
                  { period: 12, actualPrice: 47.88, marketingPrice: 47.88 },
                ],
              },
              {
                property: 'domainsCount',
                value: '10',
                prices: [
                  { period: 1, actualPrice: 9.59, marketingPrice: 9.59 },
                  { period: 3, actualPrice: 28.77, marketingPrice: 28.77 },
                  { period: 6, actualPrice: 57.54, marketingPrice: 57.54 },
                  { period: 12, actualPrice: 95.88, marketingPrice: 95.88 },
                ],
              },
            ],
          },
        ],
      },
      domainsCount: {
        name: 'domainsCount',
        options: [
          {
            value: '1',
            conditionalValues: [
              {
                property: 'capacity',
                value: '1024',
                prices: [
                  { period: 1, actualPrice: 2.39, marketingPrice: 2.39 },
                  { period: 3, actualPrice: 7.17, marketingPrice: 7.17 },
                  { period: 6, actualPrice: 14.34, marketingPrice: 14.34 },
                  { period: 12, actualPrice: 23.88, marketingPrice: 23.88 },
                ],
              },
            ],
          },
          {
            value: '3',
            conditionalValues: [
              {
                property: 'capacity',
                value: '102400',
                prices: [
                  { period: 1, actualPrice: 4.79, marketingPrice: 4.79 },
                  { period: 3, actualPrice: 14.37, marketingPrice: 14.37 },
                  { period: 6, actualPrice: 28.74, marketingPrice: 28.74 },
                  { period: 12, actualPrice: 35.88, marketingPrice: 35.88 },
                ],
              },
            ],
          },
          {
            value: '5',
            conditionalValues: [
              {
                property: 'capacity',
                value: '153600',
                prices: [
                  { period: 1, actualPrice: 7.19, marketingPrice: 7.19 },
                  { period: 3, actualPrice: 21.57, marketingPrice: 21.57 },
                  { period: 6, actualPrice: 43.14, marketingPrice: 43.14 },
                  { period: 12, actualPrice: 47.88, marketingPrice: 47.88 },
                ],
              },
            ],
          },
          {
            value: '10',
            conditionalValues: [
              {
                property: 'capacity',
                value: '153600',
                prices: [
                  { period: 1, actualPrice: 9.59, marketingPrice: 9.59 },
                  { period: 3, actualPrice: 28.77, marketingPrice: 28.77 },
                  { period: 6, actualPrice: 57.54, marketingPrice: 57.54 },
                  { period: 12, actualPrice: 95.88, marketingPrice: 95.88 },
                ],
              },
            ],
          },
        ],
      },
    },
    defaultConfiguration: {
      configuration: { domainsCount: 10, capacity: 153600 },
      prices: [
        { period: 1, actualPrice: 9.59, marketingPrice: 9.59 },
        { period: 3, actualPrice: 28.77, marketingPrice: 28.77 },
        { period: 6, actualPrice: 57.54, marketingPrice: 57.54 },
        { period: 12, actualPrice: 95.88, marketingPrice: 95.88 },
      ],
    },
    locations: ['self'],
  },
  {
    name: 'site-builder',
    minPriceConfiguration: {
      actualPrice: 100,
      period: 12,
    },
    properties: {
      type: {
        name: 'type',
        options: [
          {
            type: 'basic',
            value: 'basic',
            prices: [
              { period: 1, actualPrice: 4.9, marketingPrice: 4.9 },
              { period: 12, actualPrice: 58.8, marketingPrice: 58.8 },
            ],
            additionalProperties: null,
          },
          {
            type: 'extended',
            value: 'extended',
            prices: [
              { period: 1, actualPrice: 9.9, marketingPrice: 9.9 },
              { period: 12, actualPrice: 118.8, marketingPrice: 118.8 },
            ],
            additionalProperties: null,
          },
        ],
      },
    },
    defaultConfiguration: {
      configuration: { type: 'extended' },
      prices: [
        { period: 1, actualPrice: 9.9, marketingPrice: 9.9 },
        { period: 12, actualPrice: 118.8, marketingPrice: 118.8 },
      ],
    },
    locations: ['self'],
  },
];
/* eslint-enable sonarjs/no-duplicate-string */
