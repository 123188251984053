
import {Component, Inject, InjectReactive, Prop, Vue} from 'vue-property-decorator';
import WsSelect from '@WS_Components/WsSelect.vue';
import WsMessage from '@WS_Components/WsMessage.vue';
import WsButton from '@WS_Components/WsButton.vue';
import WsStateBanner from '@WS_Components/WsStateBanner.vue';
import WsIcon from '@WS_Components/WsIcon.vue';
import PricePeriod from '@/components/PricePeriod.vue';
import { UI_VARIANT } from '@/WS_UIkit/src/components/const.enum';
import { QItem, QItemLabel, QItemSection, QToggle } from 'quasar';
import { getDiscount } from '@/utilities';
import {
  HostingTutorialStandaloneProductLocalOption,
  HostingTutorialStandaloneProductLocalOptions,
  HostingTutorialStandaloneProductConfiguration,
  HostingTutorialStandaloneProductProperty,
  HostingTutorialStandaloneProduct,
  PricesItem,
  StandaloneProduct,
  HostingTutorialStandaloneProductDefaultConfiguration,
  HostingTutorialStep,
} from '@/types';
import {
  hostingTutorialGetExcludedProperties,
  hostingTutorialGetOptions,
} from '@/services/hosting-tutorial.service';
import { addToCart } from '@/services/cart/cart-api.service';
import {
  HOSTING_TUTORIAL_NAMESPACE,
  HOSTING_TUTORIAL_PROPERTY_KEYS,
  HOSTING_TUTORIAL_SELECTABLE_PROPERTIES,
  HOSTING_TUTORIAL_STEPS,
} from '@/services/const';
import { mapState } from 'vuex';
import { HostingTutorialState } from '../../store/const';
import {StateMutations} from '../../store/const.enum';

@Component({
  components: {
    PricePeriod,
    QItem,
    QItemSection,
    QItemLabel,
    QToggle,
    WsSelect,
    WsMessage,
    WsButton,
    WsStateBanner,
    WsIcon,
  },
  computed: {
    ...mapState(['hostingTutorial']),
  },
})
export default class HostingTutorialAddToCart extends Vue {
  @Prop() readonly product!: HostingTutorialStandaloneProduct;
  @Prop() readonly rememberedConfiguration!: Record<string, any>;
  @InjectReactive('step') private step!: HostingTutorialStep | null;
  @Inject(HOSTING_TUTORIAL_PROPERTY_KEYS.DOMAIN) private domainValue!: Record<
    string,
    any
  >;
  @Inject(HOSTING_TUTORIAL_STEPS.CLOSE) private close!: Function;
  configuration: Record<
    string,
    HostingTutorialStandaloneProductConfiguration
  > = {};
  pricesId: number = 1;
  formModel: Record<string, any> = {};
  loading: boolean = false;
  readonly NAMESPACE: string = HOSTING_TUTORIAL_NAMESPACE;
  readonly alertType: UI_VARIANT = UI_VARIANT.ALERT;
  readonly hostingTutorial!: HostingTutorialState;
  lastPickedPeriod: number = 1;

  get hasOnlyPrices(): boolean {
    return Object.keys(this.configuration).length <= 1;
  }

  get hasErrorMessages(): boolean {
    return this.$messageService.hasMessages(this.NAMESPACE);
  }

  get dataTrackingId(): string {
    return `tutorial_${this.step?.code}_${this.product.name}_add`;
  }

  emitType() {
    if (this.formModel?.type?.type) {
      this.$emit('selected-value', this.formModel.type.type);
    }
  }

  created() {
    this.initConfiguration();
    this.initFormModel();
    this.emitType();
  }

  selectChange(
    option: Record<string, string | number>,
    item: HostingTutorialStandaloneProductConfiguration
  ): void {
    const firstAttribute = item.name;
    const optionItem = this.getOptionItem(firstAttribute, option);

    if (option.period !== undefined) {
      this.lastPickedPeriod = option.period as number;
    }

    if (optionItem && 'prices' in optionItem) {
      this.formModel.prices = optionItem.prices.find(
        item => item.period === this.lastPickedPeriod
      );

      if (this.formModel.prices === undefined) {
        this.formModel.prices = optionItem.prices
          .reduce((prev, curr) => prev.period < curr.period ? prev : curr);
      }

      this.configuration.prices.options = optionItem.prices;
      this.pricesId += 1;
    }

    this.$store.commit(
      StateMutations.SET_HOSTING_TUTORIAL_REMEMBERED_CONFIGURATION,
      {
        productName: this.product.name,
        configuration: this.formModel,
      }
    );

    this.emitType();
  }

  getOptionItem(firstAttribute: string, option: Record<string, string | number>) {
    const optionItem:
      | HostingTutorialStandaloneProductLocalOption
      | PricesItem
      | undefined = this.configuration[firstAttribute].options.find(
        (item: HostingTutorialStandaloneProductLocalOption | PricesItem) => {
          if ('value' in item) {
            return item.value === option;
          }
        }
      );
    return optionItem;
  }

  submit() {
    if (this.loading) {
      return;
    }

    this.clearErrors();
    this.loading = true;
    let properties: Record<string, any> = {};

    Object.keys(this.formModel).forEach((key: string) => {
      if (key === HOSTING_TUTORIAL_PROPERTY_KEYS.PRICES) {
        return;
      }

      const item = this.formModel[key];

      if (typeof item === 'object') {
        properties = { ...properties, ...item };

        return;
      }

      properties[key] = item;
    });

    const product: StandaloneProduct = {
      code: this.product.name,
      properties,
      sellType: 'direct',
      period: this.formModel.prices.period,
    };

    addToCart(product)
      .then(() => {
        this.close();
      })
      .catch(this.$messageService.errorHandler(this.NAMESPACE))
      .finally(() => {
        this.loading = false;
      });
  }

  clearErrors() {
    this.$messageService.clearMessages(this.NAMESPACE);
  }

  getSelectWrapperClasses(key: string): string {
    if (key === HOSTING_TUTORIAL_PROPERTY_KEYS.PRICES) {
      return this.hasOnlyPrices ? 'col-12' : 'col-12 col-md-8';
    }

    return 'col-12 col-md-4';
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  initConfiguration() {
    if (!this.productProperties) {
      return;
    }

    const defaultConfiguration: HostingTutorialStandaloneProductDefaultConfiguration = this
      .product.defaultConfiguration;

    Object.keys(this.productProperties)?.forEach((key: string) => {
      if (!HOSTING_TUTORIAL_SELECTABLE_PROPERTIES.has(key)) {
        return;
      }

      const options: HostingTutorialStandaloneProductLocalOptions = hostingTutorialGetOptions(
        this.productProperties[key],
        this.product
      );

      if (options.length > 0) {
        // Handles conditionalValue duplicates from BE
        // such as capacity/domainsCount
        if ('value' in options[0] && options[0].value) {
          const optionValueKeys: string[] = Object.keys(options[0].value);

          if (optionValueKeys.length === 2) {
            this.formModel[key] = {};

            optionValueKeys.forEach((optionValueKey: string) => {
              this.formModel[key][
                optionValueKey
              ] = `${defaultConfiguration.configuration[optionValueKey]}`;
            });
          }
        }
        this.configuration[key] = {
          name: key,
          options,
          value: { [key]: defaultConfiguration.configuration[key] },
        };
      } else {
        this.formModel[key] = defaultConfiguration.configuration[key];
      }
    });

    this.configuration.prices = {
      name: HOSTING_TUTORIAL_PROPERTY_KEYS.PRICES,
      options: defaultConfiguration.prices,
      value: defaultConfiguration.prices[0],
    };
  }

  initFormModel(): void {
    if (
      this.hostingTutorial.rememberedConfigurations.hasOwnProperty(
        this.product.name
      )
    ) {
      this.formModel = this.hostingTutorial.rememberedConfigurations[
        this.product.name
      ];
      return;
    }
    this.formModel.prices = this.configuration.prices?.value;

    if (this.configuration) {
      Object.keys(this.configuration).forEach((key: string) => {
        if (this.formModel[key]) {
          return;
        }
        this.formModel[key] = this.configuration[key].value;
      });
    }

    Object.keys(this.productProperties).forEach((key: string) => {
      if (this.formModel[key]) {
        return;
      }

      if (key === HOSTING_TUTORIAL_PROPERTY_KEYS.DOMAIN) {
        this.formModel[key] = this.domainValue.domain;
      } else {
        this.formModel[key] = this.product.defaultConfiguration!.configuration![
          key
        ];
      }
    });

    this.emitType();
  }

  get discount(): number | null {
    return getDiscount(
      this.formModel.prices?.actualPrice!,
      this.formModel.prices?.marketingPrice!
    );
  }

  get productProperties(): Record<
    string,
    HostingTutorialStandaloneProductProperty
    > {
    const { properties } = this.product;

    if (!properties) {
      return {};
    }

    const excluded: Set<string> = hostingTutorialGetExcludedProperties(
      properties
    );

    if (excluded.size > 0) {
      excluded!.forEach((key: string) => {
        delete properties[key];
      });
    }

    return properties;
  }

  mbToGb(value: string | number) {
    return +value / 1024;
  }

  translationValues(
    values: Record<string, string>,
    additionalProperties: Record<string, string | number> | null = {}
  ): Record<string, string | number> {
    if (!values || typeof values === 'string') {
      return values;
    }
    let processedValue: Record<string, string | number> = {};

    Object.keys(values).forEach((key: string) => {
      if (key === HOSTING_TUTORIAL_PROPERTY_KEYS.CAPACITY) {
        processedValue = { [key]: this.mbToGb(+values[key]) };
      }
    });

    let processedAdditionalProperties: Record<
      string,
      string | number
    > | null = null;

    if (
      additionalProperties &&
      additionalProperties[HOSTING_TUTORIAL_PROPERTY_KEYS.CAPACITY]
    ) {
      processedAdditionalProperties = {
        ...additionalProperties,
        [HOSTING_TUTORIAL_PROPERTY_KEYS.CAPACITY]: this.mbToGb(
          additionalProperties[HOSTING_TUTORIAL_PROPERTY_KEYS.CAPACITY]
        ),
      };
    }

    return { ...values, ...processedValue, ...processedAdditionalProperties };
  }
}
