
import { Component, Prop, Vue } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';

import { setEditMode } from '@/services/order.service';
import WsEdit from '@WS_Components/WsEdit.vue';
import WsBackdrop from './WsBackdrop.vue';
import { STEPS } from '@/services/const.enum';
import SidebarLayoutItem from './SidebarLayoutItem.vue';

@Component({
  components: {
    WsEdit,
    WsBackdrop,
    SidebarLayoutItem,
  },
  computed: {
    ...mapGetters(['editBackdropActive']),
    ...mapState(['inEditMode']),
  },
})
export class OrderProcessItem extends Vue {
  @Prop()
  readonly step!: STEPS;
  @Prop({ default: false })
  declare readonly readOnly: boolean;

  readonly inEditMode!: STEPS;
  setEditMode = setEditMode;

  get editMode() {
    return this.inEditMode === this.step;
  }
}
export default OrderProcessItem;
