import { render, staticRenderFns } from "./ConsentCheckboxes.vue?vue&type=template&id=6bb7d862&scoped=true"
import script from "./ConsentCheckboxes.vue?vue&type=script&lang=ts"
export * from "./ConsentCheckboxes.vue?vue&type=script&lang=ts"
import style0 from "./ConsentCheckboxes.vue?vue&type=style&index=0&id=6bb7d862&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bb7d862",
  null
  
)

export default component.exports