
import { Component, Vue, Prop } from 'vue-property-decorator';

import { QTooltip } from 'quasar';
import {
  BillingProfile,
  CommonProfile,
  CommonProfileKeys,
  DomainProfile,
} from '@/types';
import { get } from 'lodash-es';
import { simpleWrapperCmp } from '@loopia-group/utils';
import i18nService from '@/WS_UIkit/src/services/i18n/i18n.service';

@Component({
  components: {
    QTooltip,
  },
})
export class ProfileInfo extends Vue {
  @Prop({ default: () => {} })
  readonly profile!: CommonProfile;
  @Prop(Boolean)
  readonly verbose!: boolean;
  @Prop(Boolean)
  readonly small!: boolean;
  @Prop(Boolean)
  readonly densed!: boolean;
  @Prop(Boolean)
  readonly strong!: boolean; // strong first line

  spanCmp = simpleWrapperCmp('span');
  smallCmp = simpleWrapperCmp('small');
  currentLang = i18nService.getLang();

  get email() {
    const profile = this.profile;
    return (
      (profile as DomainProfile).email ||
      (profile as BillingProfile).emails?.[0]
    );
  }

  get tKeys(): Record<string, string> {
    return {
      phone: 'wsk.profile.phone.label',
      phone_short: 'wsk.profile.phone.short',
      email: 'wsk.profile.email.label',
      companyId: 'wsk.address.company_id.label',
      taxId: 'wsk.address.tax_id.label',
      vatId:
        this.profile.countryCode === 'CZ'
          ? 'wsk.address.tax_id.label'
          : 'wsk.address.vat_id.label',
    };
  }

  get companyInfo() {
    const keys: Array<CommonProfileKeys> =
      this.profile.countryCode === 'CZ'
        ? ['companyId', 'vatId']
        : ['companyId', 'taxId', 'vatId'];
    return keys.filter(key => !!get(this.profile, key));
  }

  get label() {
    const profile: any = this.profile;
    return (
      profile.organisation ||
        (this.currentLang === 'hu-HU' ?
          profile.lastName + ' ' + profile.firstName :
          profile.firstName + ' ' + profile.lastName) ||
        profile.name
    );
  }
}
export default ProfileInfo;
