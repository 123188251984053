import { AxiosResponse } from 'axios';

import store from '@/store';
import { LoginCheckResponse } from '@/types';
import network from './network.service';
import {StateMutations} from '@/store/const.enum';

export function signUp(user: any): Promise<AxiosResponse> {
  if (user.billingProfile?.companyId) {
    user.billingProfile.companyId = user.billingProfile.companyId.toString();
  }

  return network.post('/users', user);
}

export function fetchUserProfile(): Promise<AxiosResponse> {
  return network.get('/users').then(updateUserState);
}

export function checkLogin(login: string): Promise<LoginCheckResponse> {
  return network
    .post('/users/check-login', { login })
    .then((response: AxiosResponse) => response.data);
}

export async function userDomains(): Promise<{ domains: string[] }> {
  const response: AxiosResponse = await network.get('/users/domains');
  return response.data;
}

// helpers
function updateUserState(response: AxiosResponse): AxiosResponse {
  store.commit(StateMutations.SET_STATE, {
    prop: 'userProfile',
    value: response.data,
  });
  return response.data;
}
